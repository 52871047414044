import { useState } from "react";
import Main from "../Constants/Main";
import {
	InvoiceNumber,
	AddressContainer,
	InvoiceAddress,
	PayableAmount,
	InvoiceTable,
	TableHead,
	Desc,
	Qty,
	Rate,
	Amount,
	TableBody,
	TableCalc,
	LeftCalc,
	RightCalc,
	ActionButtons,
} from "./InvoicePreview";
import Modal from "../Constants/ModalComponent";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import styled from "styled-components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import Button from "../Constants/FormComponents/Button";

const Accept = () => {
	const [acceptModal, setAcceptModal] = useState<boolean>(false);
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const handleAcceptModal = () => {
		setAcceptModal(!acceptModal);
	};
	const handleRejectModal = () => {
		setRejectModal(!rejectModal);
	};

	return (
		<>
			<Main
				tabName="Invoice Preview"
				rightSubHeader={<></>}
				mainBody={
					<>
						<AcceptHeader>
							<InvoiceNumber>
								<div style={{ backgroundColor: "#CD0A0A" }}></div>
								<h4>Invoice #43442</h4>
							</InvoiceNumber>
							<div>
								<Button
									title="Accept"
									onClick={handleAcceptModal}
									size="md"
									variant="default"
									textColor="normal"
									icon={<DoneOutlinedIcon />}
								/>
								<Button
									title="Reject"
									onClick={handleRejectModal}
									size="md"
									variant="reject"
									textColor="normal"
									icon={<CloseIcon />}
								/>
							</div>
						</AcceptHeader>

						<AddressContainer>
							<InvoiceAddress>
								<div>
									<h6>Invoice from</h6>
									<h5>Snowchild Inc</h5>
									<p>27, Ozumba Mbadiwe, Lagos Nigeria</p>
								</div>
								<div>
									<h6>Invoice Number</h6>
									<h5>765454545</h5>
								</div>
								<div>
									<h6>Sent to</h6>
									<h5>After Hours inc</h5>
									<p>33, Bode Thomas, Lagos. Nigeria</p>
								</div>
								<div>
									<h6>Creation Date</h6>
									<h5>18/04/2022</h5>
								</div>
								<div>
									<h6>Due Data</h6>
									<h5>09/08/2022</h5>
								</div>
							</InvoiceAddress>
							<PayableAmount>
								<p>Total Payable</p>
								<h1> ₦ 1,120,000.00</h1>
							</PayableAmount>
						</AddressContainer>
						<InvoiceTable>
							<TableHead>
								<Desc>Item Description</Desc>
								<Qty>Quantity</Qty>
								<Rate>Rate</Rate>
								<Amount>Amount</Amount>
							</TableHead>
							<TableBody>
								<Desc>Bag of Rice</Desc>
								<Qty>3</Qty>
								<Rate>₦ 20,000,000</Rate>
								<Amount>₦ 20,000,000</Amount>
							</TableBody>
							<TableBody>
								<Desc>Bag of Rice</Desc>
								<Qty>3</Qty>
								<Rate>₦ 20,000,000</Rate>
								<Amount>₦ 20,000,000</Amount>
							</TableBody>
						</InvoiceTable>
						<TableCalc>
							<LeftCalc>
								<div>
									<p>Payment Terms</p>
									<h6>
										Lorem ipsum yada yada put in lyrics of The Weeknd’s Phantom Regret
										here.
									</h6>
								</div>
								<p>Invoice link</p>
								<Link to="/">https://bridger.invoice9748504</Link>
							</LeftCalc>
							<RightCalc>
								<div>
									<p>Sub Total</p>
									<h6>₦200,000</h6>
								</div>
								<div>
									<p>Miscellenous</p>
									<h6>₦1,200,000</h6>
								</div>
								<div>
									<p>Tax</p>
									<h6>₦1,200,000</h6>
								</div>
								<div>
									<p>Discount</p>
									<h6>₦1,200,000</h6>
								</div>
								<div>
									<p>Total</p>
									<h6>₦2,000,000</h6>
								</div>
							</RightCalc>
						</TableCalc>
						<ActionButtons>
							<div>
								<Button title="share" size="sm" variant="default" textColor="normal" />
								<Button title="Delete" size="sm" variant="reject" textColor="normal" />
							</div>
							<Button
								title="Download Pdf"
								size="sm"
								variant="reject"
								textColor="normal"
								icon={<CloudDownloadIcon />}
							/>
						</ActionButtons>
					</>
				}
			/>
			<Modal
				toggleModal={handleAcceptModal}
				heading=""
				subHeading=""
				modal={acceptModal}
				size="lg"
				body={
					<>
						<AcceptContainer>
							<h5>Accept Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>76897833</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>Oluwadamilare Adetokunbo</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>1,200,000</h4>
								</div>
							</AcceptDetails>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleAcceptModal}
									size="md"
									variant="secondary"
									textColor="default"
								/>
								<button onClick={handleAcceptModal}>Cancel</button>
								<Button title="Accept" size="md" variant="accept" textColor="brand" />
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleRejectModal}
				heading=""
				subHeading=""
				modal={rejectModal}
				size="lg"
				body={
					<>
						<AcceptContainer>
							<h5>Reject Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>76897833</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>Oluwadamilare Adetokunbo</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>1,200,000</h4>
								</div>
							</AcceptDetails>
							<div>
								<label>Reason for rejection</label>
								<textarea></textarea>
							</div>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleRejectModal}
									size="md"
									variant="secondary"
									textColor="default"
								/>
								<Button title="Reject" size="md" variant="reject" textColor="brand" />
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
		</>
	);
};

export default Accept;

const AcceptHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	div {
		display: flex;
		gap: 20px;
	}
	button {
		color: white;
		font-weight: 400;
		padding: 15px 30px;
		border-color: transparent;
		display: flex;
		align-items: center;
		gap: 5px;
		height: 48px;
	}
`;

const AcceptContainer = styled.div`
	width: 90%;
	margin: auto;
	h5 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 23px;
		text-align: left;
		color: black;
	}

	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
		font-weight: 400;
	}
	h4 {
		font-size: 14px;
		color: black;
		font-size: 500;
		margin-top: 10px;
		margin-bottom: 0px;
	}
	textarea {
		height: 120px;
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-top: 5px;
	}
`;

const AcceptDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AcceptButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 40px;
	button {
		padding: 15px 30px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 500;
		:first-child {
			color: var(--mainBlue);
			border: 1px solid var(--mainBlue);
		}
		:last-child {
			color: white;
			border: transparent;
		}
	}
`;
