import styled from "styled-components";

import "react-responsive-carousel/lib/styles/carousel.min.css";

type Props = {
	imgSrc: string;
	title: string;
	paragraph: string;
	style: React.CSSProperties;
};
export default function Slider({ style, imgSrc, title, paragraph }: Props) {
	return (
		<Wrapper style={style}>
			<SliderImage src={imgSrc} />

			<CarouselHeader>{title}.</CarouselHeader>
			<CarouselParagraph>{paragraph}</CarouselParagraph>
		</Wrapper>
	);
}
const Wrapper = styled.div`
	padding-top: 120px;
	overflow: hidden;
	color: #ffffff;
	width: 100%;
	margin: 0;
	height: 100vh;
`;
const SliderImage = styled.img`
	object-fit: none;
	margin-top: 0;
	padding-top: 0;
	max-width: 372px;
	max-height: 400px;
`;

const CarouselHeader = styled.h3`
	font-size: 24px;
	padding-top: 40px;
	margin-top: 0;
`;
const CarouselParagraph = styled.p`
	font-size: 12px;
	font-weight: 400;
	max-width: 450px;
	margin: 0 auto;
`;
