import React, { useState, useContext, useEffect } from "react";
import Main from "../Constants/Main";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AppContext, CustomerProps } from "../../Context";
import AddSellerModal from "../Customer/AddSellerModal";
import Button from "../Constants/FormComponents/Button";
import Input from "../Constants/FormComponents/Input";
import TextArea from "../Constants/FormComponents/TextArea";
import { FormatCurrency } from "../Constants/utils";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";

import {
	DetailsHeader,
	InvoiceNumber,
	SmallPayableAmount,
	AddressContainer,
	InvoiceAddress,
	PayableAmount,
	InvoiceTable,
	TableHead,
	SmallDesc,
	Desc,
	SmallQty,
	Qty,
	Rate,
	Amount,
	LeftCalc,
	RightCalc,
	ActionButtons,
	TableCalc,
	TableBody,
	DetailsContainer,
} from "./OrderPreview";
import Alert from "../Constants/Alert";

// type ItemProps = {
// 	description: string;
// 	unit_price: number;
// 	quantity: number;
// 	amount: number;
// };

const CreateOrders = () => {
	const navigate = useNavigate();
	const [purchasePage, setPurchasePage] = useState(1);
	const [detailsState, setDetailsState] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errCreate, setErrCreate] = useState("");
	const [errCreateModal, setErrCreateModal] = useState(false);
	const [selected, setSelected] = useState<Array<CustomerProps>>([]);

	const [itemList, setItemList] = useState<any>([
		{
			description: "",
			unit_price: 0,
			quantity: 0,
			amount: 0,
		},
	]);

	const [orderForm, setOrderForm] = useState({
		items: itemList,
		sub_total: 0,
		tax: 0,
		discount: 0,
		order_number: "",
		title: "",
		summary: "",
		supply_date: "",
		order_date: "",
		due_date: "",
		currency: "ngn",
		misc: 0,
		payment_terms: "",
		attachment: "",
		customer: "",
		customer_name: "",
		total: 0,
	});

	const {
		handleCustomerSellerModal,
		allCustomerData,
		userProfile,
		fetchAllPurchaseOrderData,
	} = useContext(AppContext);

	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setOrderForm({
			...orderForm,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleSelectedCustomer = (target: String) => {
		const selectCustomer = allCustomerData.filter(
			customer => customer.uid === target
		);
		setSelected(selectCustomer);
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setOrderForm({
			...orderForm,
			[event.currentTarget.name]: event.currentTarget.value,
		});
		handleSelectedCustomer(event.currentTarget.value);
	};

	const closeErrCreateModal = () => {
		setErrCreateModal(false);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.type === "number" && e.currentTarget.value?.length > 0) {
			setOrderForm({
				...orderForm,
				[e.target.name]: Math.abs(Number(e.target.value)),
			});
		} else {
			setOrderForm({
				...orderForm,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleMisc = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOrderForm({
			...orderForm,
			[event.currentTarget.name]: event.currentTarget.value,
			total: Number(orderForm.sub_total) + Number(event.currentTarget.value),
		});
	};

	const {
		title,
		order_number,
		order_date,
		supply_date,
		due_date,
		customer,
		payment_terms,
		sub_total,
		misc,
		total,
		items,
	} = orderForm;

	const DisableBtnOne = () => {
		if (
			title &&
			order_number &&
			order_date &&
			supply_date &&
			due_date &&
			customer
		) {
			return false;
		} else {
			return true;
		}
	};

	const DisableButtonTwo = () => {
		if (items[0]?.quantity && items[0]?.description && items[0]?.unit_price) {
			return false;
		} else {
			return true;
		}
	};

	const handleItemChange = (
		event: React.FormEvent<HTMLInputElement>,
		index: number
	) => {
		const { name, value } = event.currentTarget;
		const list = [...itemList];
		list[index][name] = value;
		list[index]["amount"] = Math.abs(
			list[index]["unit_price"] * list[index]["quantity"]
		);
		setItemList(list);
		let subtotal = 0;
		for (let i = 0; i < itemList.length; i++) {
			subtotal += itemList[i].amount;
		}
		setOrderForm({
			...orderForm,
			sub_total: subtotal,
			total: Number(subtotal) + Number(orderForm.misc),
		});
	};

	const handleDetailToggle = () => {
		setDetailsState(!detailsState);
	};

	const AddInvoiceItem = () => {
		const newItemlist = [
			...itemList,
			{
				description: "",
				unit_price: 0,
				quantity: 0,
				amount: 0,
			},
		];
		setItemList(newItemlist);
		setOrderForm({ ...orderForm, items: newItemlist });
	};

	const handleNextPage = () => {
		setPurchasePage(2);
		setDetailsState(false);
	};

	useEffect(() => {
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
	}, []);

	const purchaseOrder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/inventory/purchase_orders/", orderForm)
			.then(() => {
				setIsLoading(false);
				fetchAllPurchaseOrderData();
				navigate("/purchase-order");
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrCreateModal(true);
				setErrCreate(err?.response?.data?.errors[0]?.detail);
			});
	};

	const handlePrevPage = () => {
		setPurchasePage(1);
	};

	const DeletePO = (id: any) => {
		const newItemList = itemList.filter(
			(item: any) => id !== itemList.indexOf(item)
		);
		DisableButtonTwo();
		let subtotal = 0;
		for (let i = 0; i < newItemList.length; i++) {
			subtotal += newItemList[i].amount;
		}
		setItemList(newItemList);
		setOrderForm({
			...orderForm,
			sub_total: subtotal,
			total: Number(subtotal) + Number(orderForm.misc),
			items: newItemList,
		});
	};

	return (
		<>
			<Main
				tabName="Create Purchase Order"
				rightSubHeader={<></>}
				mainBody={
					<>
						{detailsState ? (
							<DetailsContainer>
								<StepsDiv>
									<Steps></Steps>
									{purchasePage === 2 ? (
										<div onClick={handleNextPage}>
											<KeyboardBackspaceIcon /> Go Back
										</div>
									) : null}
								</StepsDiv>
								<DetailsHeader>
									<InvoiceNumber>
										<div></div>
										<h4>Invoice #{order_number}</h4>
									</InvoiceNumber>
									<SmallPayableAmount>
										<p>Total Payable</p>
										<h1> ₦ {total}</h1>
									</SmallPayableAmount>
								</DetailsHeader>
								<AddressContainer>
									<InvoiceAddress>
										<div>
											<div>
												<h6>Purchase Order from</h6>
												<h5>
													{userProfile?.first_name} {userProfile?.last_name}
												</h5>
												<p>{userProfile?.address}</p>
											</div>
											<div>
												<h6>Recipient</h6>
												<h5>{selected[0].name}</h5>
												<p>{selected[0].address}</p>
											</div>
										</div>
										<div>
											<div>
												<h6>Purchase Order Number</h6>
												<h5>{order_number}</h5>
											</div>

											<div>
												<h6>Creation Date</h6>
												<h5>{order_date}</h5>
											</div>
											<div>
												<h6>Due Data</h6>
												<h5>{due_date}</h5>
											</div>
										</div>
									</InvoiceAddress>
									<PayableAmount>
										<p>Total Payable</p>
										<h1> ₦ {total}</h1>
									</PayableAmount>
								</AddressContainer>
								<InvoiceTable>
									<TableHead>
										<SmallDesc>Item</SmallDesc>
										<Desc>Item Description</Desc>
										<SmallQty>Qty</SmallQty>
										<Qty>Quantity</Qty>
										<Rate>Rate</Rate>
										<Amount>Amount</Amount>
									</TableHead>

									{orderForm?.items?.map((item: any, i: any) => {
										return (
											<TableBody key={i}>
												<Desc>{item?.description}</Desc>
												<SmallDesc>{item?.description}</SmallDesc>
												<Qty>{item?.quantity}</Qty>
												<SmallQty>{item?.quantity}</SmallQty>
												<Rate>₦ {item?.unit_price}</Rate>
												<Amount>
													₦ {Number(item?.unit_price) * Number(item?.quantity)}
												</Amount>
											</TableBody>
										);
									})}
								</InvoiceTable>
								<TableCalc>
									<LeftCalc>
										<div>
											<p>Payment Terms</p>
											<h6>{payment_terms}</h6>
										</div>
										<p>Purchase Order link</p>
									</LeftCalc>
									<RightCalc>
										<div>
											<p>Sub Total</p>
											<h6>₦ {FormatCurrency(sub_total)}</h6>
										</div>
										<div>
											<p>Miscellenous</p>
											<h6>₦ {misc}</h6>
										</div>
										<div>
											<p>Total</p>
											<h6>₦{FormatCurrency(total)}</h6>
										</div>
									</RightCalc>
								</TableCalc>
								<ActionButtons>
									<div></div>
									<div>
										<Button
											title="Edit Purchase Order"
											onClick={handleNextPage}
											size="md"
											textColor="brand"
											variant="secondary"
										/>
										<Button
											title="Save"
											onClick={purchaseOrder}
											size="md"
											textColor="normal"
											variant="default"
											isLoading={isLoading}
										/>
									</div>
								</ActionButtons>
							</DetailsContainer>
						) : (
							<div className="container">
								<StepsDiv>
									<Steps>STEP {purchasePage} OF 2 : Add Purchase Order Details</Steps>
									{purchasePage === 2 ? (
										<div onClick={handlePrevPage}>
											<KeyboardBackspaceIcon /> Go Back
										</div>
									) : null}
								</StepsDiv>

								{purchasePage === 1 ? (
									<>
										<Form>
											<FormGroupContainer>
												<Input
													inputType="text"
													title="Purchase Order Title"
													name="title"
													value={title}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="number"
													title="Purchase Order Number"
													name="order_number"
													value={order_number}
													min="0"
													pattern="^\d]+/g"
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Purchase Order Creation Date"
													max={supply_date}
													name="order_date"
													value={order_date}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Supply Date"
													min={order_date}
													name="supply_date"
													value={supply_date}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Payment Due Date"
													min={order_date}
													name="due_date"
													value={due_date}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="text"
													title="Currency (₦)"
													name="currency"
													value="Naira"
													size="md"
													isDisabled
													onChange={handleChange}
													required
												/>

												<SelectWrapper
													size="md"
													title="Customer name"
													children={
														<>
															<select
																onChange={handleSelectChange}
																name="customer"
																value={customer}
																style={{ marginBottom: "15px" }}
															>
																<option value="">--- Select Customer ---</option>
																{allCustomerData?.map((customer, i) => {
																	return (
																		<option value={`${customer.uid}`} key={i}>
																			{customer.name}
																		</option>
																	);
																})}
															</select>
															<Button
																title="Add Customer"
																onClick={handleCustomerSellerModal}
																size="md"
																textColor="brand"
																variant="secondary"
																icon={<PersonAddAlt1Icon />}
															/>
														</>
													}
												/>
												<TextArea
													name="Summary"
													size="md"
													onChange={handleAreaChange}
													title="Purchase Order Summary"
												/>
												<FormGroup></FormGroup>
											</FormGroupContainer>
										</Form>
									</>
								) : (
									<>
										<ItemsContainer>
											{itemList?.map((item: any, index: any) => {
												return (
													<DetailsForm key={index}>
														<DescGroup>
															<Label>Item Description</Label>
															<input
																type="text"
																name="description"
																onChange={event => handleItemChange(event, index)}
																value={item?.description}
															/>
														</DescGroup>
														<PriceGroup>
															<Label>Price per unit</Label>
															<input
																type="number"
																name="unit_price"
																onChange={event => handleItemChange(event, index)}
																value={item?.unit_price}
															/>
														</PriceGroup>
														<QuantityGroup>
															<Label>Quantity</Label>
															<input
																type="number"
																name="quantity"
																onChange={event => handleItemChange(event, index)}
																value={item.quantity}
															/>
														</QuantityGroup>
														<AmountGroup>
															<Label>Amount</Label>
															<input
																type="number"
																name="amount"
																value={item.amount}
																disabled
															/>
														</AmountGroup>
														{itemList?.length > 1 ? (
															<DeleteBtn
																onClick={() => {
																	DeletePO(index);
																}}
															>
																Delete
															</DeleteBtn>
														) : null}
													</DetailsForm>
												);
											})}

											<PaymentTerms>
												<TextArea
													name="payment_terms"
													title="Payment Terms"
													value={payment_terms}
													size="lg"
													onChange={handleAreaChange}
												/>
												<div>
													<Button
														title="Add another item"
														onClick={AddInvoiceItem}
														size="md"
														textColor="brand"
														variant="secondary"
													/>
												</div>
											</PaymentTerms>
											<OrderCalc>
												<SubTotal>
													<p>Sub Total</p>
													<h6>₦{FormatCurrency(orderForm?.sub_total)}</h6>
												</SubTotal>
												<Misc>
													<p>Miscellanous</p>
													<input type="number" onChange={handleMisc} name="misc" />
												</Misc>
												<SubTotal>
													<p>Total</p>
													<h6>₦{FormatCurrency(orderForm?.total)}</h6>
												</SubTotal>
											</OrderCalc>
										</ItemsContainer>
									</>
								)}
								<FormBottom>
									<FormBottomPagination>
										<PageButtons>
											<button
												style={{
													backgroundColor: purchasePage === 1 ? "#302361" : "white",
													color: purchasePage === 1 ? "white" : "#302361",
												}}
											>
												1
											</button>
											<button
												style={{
													backgroundColor: purchasePage === 2 ? "#302361" : "white",
													color: purchasePage === 2 ? "white" : "#302361",
												}}
											>
												2
											</button>
										</PageButtons>
										{purchasePage === 1 && (
											<Button
												title="Continue"
												onClick={handleNextPage}
												size="md"
												textColor="normal"
												variant="default"
												disabled={DisableBtnOne()}
											/>
										)}
										{purchasePage === 2 && (
											<Button
												title="Continue"
												onClick={handleDetailToggle}
												size="md"
												textColor="normal"
												variant="default"
												disabled={DisableButtonTwo()}
											/>
										)}
									</FormBottomPagination>
								</FormBottom>
							</div>
						)}
					</>
				}
			/>

			<AddSellerModal />
			<Alert
				alertSeverity={"error"}
				open={errCreateModal}
				alertTitle={errCreate || "Error"}
				closeModal={closeErrCreateModal}
			/>
		</>
	);
};

export default CreateOrders;

export const Steps = styled.p`
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 15px;
`;

export const StepsDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	div {
		color: black;
		font-weight: 500;
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 4px;
		:hover {
			cursor: pointer;
		}
	}
`;
export const Form = styled.form`
	// width: 790px;
	position: relative;
	@media (max-width: 1366px) {
		width: calc(100% - 58px);
	}
	@media (max-width: 1200px) {
		width: 100%;
	}
	@media (max-width: 991px) {
		height: calc(100vh - 200px);
	}
`;
export const ItemsContainer = styled.div`
	padding-bottom: 100px;
`;
export const FormGroupContainer = styled.div`
	// width: 790px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: white;
	padding-bottom: 60px;
	@media (max-width: 1366px) {
		width: 100%;
		display: flex;
		overflow-y: scroll;
		flex-wrap: wrap;
	}
`;
export const FormGroup = styled.div`
	text-align: left;
	width: 358px;
	position: relative;
	margin-top: 12px;
	margin-bottom: 12px;
	label {
		font-size: 14px;
		font-weight: 400;
		margin: 0px;
		color: #484848;
		text-align: left;
		width: 100%;
	}
	input {
		width: 300px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	select {
		width: 100%;
		height: 48px;
		border: 1px solid #d9d9d9;
		padding-right: 5px;
		padding-left: 5px;
	}
	textarea {
		width: 300px;
		height: 100px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
	}
	@media (max-width: 1366px) {
		width: 48%;
		input {
			width: calc(100% - 23px);
		}
		textarea {
			width: calc(100% - 23px);
		}
	}

	@media (max-width: 576px) {
		width: 100%;
		input {
			width: calc(100% - 23px);
		}
		textarea {
			width: calc(100% - 23px);
		}
	}
`;
export const AddCustomer = styled.div`
	background-color: white;
	color: var(--mainBlue);
	border: 1px solid var(--mainBlue);
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-size: 14px;
	width: 182px;
	height: 40px;
	margin-top: 20px;
`;
export const FormBottom = styled.div`
	background-color: white;
	width: 100%;
	position: fixed;
	bottom: 0px;
	height: 70px;
	display: flex;
	align-items: center;
`;
export const FormBottomPagination = styled.div`
	display: flex;
	width: 790px;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 1200px) {
		width: calc(100% - 290px);
	}
	@media (max-width: 991px) {
		width: 92%;
		background-color: white;
		bottom: 0px;
		height: 70px;
		padding-right: 20px;
		right: 10px;
	}
`;
export const PageButtons = styled.div`
	display: flex;
	gap: 10px;
	button {
		background-color: var(--mainBlue);
		border-radius: 2px;
		width: 32px;
		height: 32px;
		font-size: 14px;
		outline: 0;
		border-color: transparent;
		color: white;
	}
`;
export const NextButton = styled.button`
	background-color: var(--mainBlue);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	width: 121px !important;
	outline: 0;
	border-color: transparent;
	height: 48px;
	:disabled {
		background-color: #ccc;
		color: var(--mainBlue);
	}
`;
export const DetailsForm = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 20px;
	gap: 20px;
	flex-wrap: wrap;
	@media (max-width: 1440px) {
		// width: 700px;
		gap: 10px;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;
export const Label = styled.label`
	font-size: 14px;
	font-weight: 400;
	margin: 0px;
	color: #484848;
	text-align: left;
	width: 100%;
	@medida (max-width:426px) {
		font-sie: 12px;
	}
`;
export const DescGroup = styled.div`
	text-align: left;
	width: 270px;
	position: relative;
	input {
		width: 250px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width: 1200px) {
		width: 30%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
		input {
			width: 100%;
		}
	}
`;
export const AmountGroup = styled.div`
	text-align: left;
	width: 220px;
	position: relative;
	input {
		width: 200px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width: 1200px) {
		width: 18%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
		input {
			width: 100%;
		}
	}
`;
export const PriceGroup = styled.div`
	text-align: left;
	width: 170px;
	position: relative;
	input {
		width: 150px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width: 1200px) {
		width: 18%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
		input {
			width: 100%;
		}
	}
`;
export const QuantityGroup = styled.div`
	text-align: left;
	width: 140px;
	position: relative;
	input {
		width: 120px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width: 1200px) {
		width: 18%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
		input {
			width: 100%;
			height: 40px;
		}
	}
`;
export const DeleteBtn = styled.button`
	background-color: transparent;
	border-color: transparent;
	width: 50px;
	height: 60px;
	padding-top: 15px;
	color: #cd0a0a;
	font-weight: bold;
	:hover {
		cursor: pointer;
	}

	@media (max-width: 768px) {
		width: 250px;
	}

	@media (max-width: 576px) {
		width: 200px;
	}
`;
export const SmallDeleteBtn = styled.button`
	background-color: #cd0a0a !important;
	color: white !important;
	border: transparent;
	font-size: 12px;
	@media (min-width: 426px) {
		display: none !important;
	}
`;
export const PaymentTerms = styled.div`
	display: flex;
	justify-content: space-between;
	text-align: left;
	margin-top: 30px;
	padding-right: 50px;
	div {
		width: 378px;
		:last-child {
			width: 200px;
			margin-top: 20px;
		}
	}
	section {
		margin-top: 30px;
		label {
			font-weight: 500 !important;
			font-size: 14px !important;
			color: var(--mainBlue);
			margin-left: 0px;
			margin-top: 12px;
		}
		p {
			color: var(--textAsh);
		}
	}
	@media (max-width: 768px) {
		flex-direction: column-reverse;
		justify-content: center;
		button {
			margin-bottom: 20px;
		}
	}
	@media (max-width: 426px) {
		width: 100%;
		padding-right: 0px;
		border-color: transparent;
		div {
			width: 100%;
			:last-child {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
		}
		textarea {
			width: 100%;
			box-sizing: border-box;
			padding-top: 10px;
		}
	}
`;

export const OrderCalc = styled.div`
	border-top: 1px solid #e6e6e6;
	width: 310px;
	margin-left: auto;
	padding-top: 30px;
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 18px;
		font-weight: 500;
	}
`;
export const SubTotal = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const Misc = styled.div`
	margin-top: 30px;
	margin-bottom: 30px;
	p {
		width: 300px;
	}
	input {
		width: 290px;
		height: 48px;
		border: 1px solid #d9d9d9;
		padding-left: 10px;
	}
`;
