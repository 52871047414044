import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logoTwo.svg";
import styled from "styled-components";
import axios from "axios";
import * as constants from "../Constants/api";

export default function VerifyEmail() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const getKey = queryParams.get("key") as string;
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const verify = async () => {
		try {
			await axios.post(`${constants.API_URL}/accounts/verify-email/`, {
				key: getKey,
			});
			setSuccess(true);
			setError(false);
			setTimeout(() => {
				navigate("/");
			}, 2000);
		} catch (_err) {
			setError(true);
			setSuccess(false);
		}
	};

	useEffect(() => {
		verify();
	}, []);
	return (
		<LoginWrapper>
			<Link to="/">
				<img src={Logo} alt="Bridger Logo" />
			</Link>
			<LoginContainer>
				{success && (
					<SuccessMessage className="success">
						Account Successfully Activated proceed to{" "}
						{/* <Link to="/">Verify your Phone number</Link> */}
					</SuccessMessage>
				)}
				{error && (
					<ErrorMessage>
						Unable to complete Activation, Please check your mail for Correct
						Activation link{" "}
					</ErrorMessage>
				)}
			</LoginContainer>
		</LoginWrapper>
	);
}

const LoginWrapper = styled.div`
	width: 100%;
	background-color: #fff;
	padding-bottom: 55px;

	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;

	@media (max-width: 576px) {
		//height:100%;
	}
`;

const LoginContainer = styled.div`
	background-color: white;
	width: 536px;
	display: grid;
	margin: auto;
	margin-top: 16px;
	border-radius: 16px;
	padding-bottom: 55px;
	padding: 20px;
	min-height: 200px;

	@media (max-width: 576px) {
		width: 343px;
		margin: auto;
		margin-top: 16px;
		padding-bottom: 55px;
	}
`;
const SuccessMessage = styled.div`
	color: #0f0f0fc7;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 0px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	a {
		margin-left: 5px;
		color: #8326fa;
	}
`;
const ErrorMessage = styled.div`
	color: #cd0a0a;
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 0px;
	border: 0.5px solid #cd0a0a;
	height: 42px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
`;
