import React, { useState } from "react";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import { PieChart, Pie, Cell } from "recharts";
import { ToastContainer, toast } from "react-toastify";
import uuid from "react-uuid";
import moneyIcon from "../../../assets/moneyIcon.png";
import ModalComponent from "../../Constants/ModalComponent";
import Button from "../../Constants/FormComponents/Button";
import Input from "../../Constants/FormComponents/Input";
import View from "./View";
import OTP from "../../Constants/OTP";
import { httprequest } from "../../Constants/api";
import { FormatCurrency } from "../../Constants/utils";
import { useNavigate } from "react-router-dom";

type Props = {
	account_balance: number;
	wallet_bank_name: string;
	wallet_account_number: string;
	credit: number;
	debit: number;
	userProfile: any;
	walletUid: string;
	business_name: string;
	getWalletDetails: any;
	jumbotronText: any;
};

export default function WalletTop({
	account_balance,
	wallet_bank_name,
	wallet_account_number,
	credit,
	debit,
	userProfile,
	walletUid,
	business_name,
	getWalletDetails,
	jumbotronText,
}: Props) {
	const navigate = useNavigate();
	const data = [
		{ name: "Income", value: Math.floor(credit) },
		{ name: "Expenses", value: Math.floor(debit) },
	];

	const COLORSINCOME = ["#302361", "#E8E8E8"];
	const COLORSEXPENSE = ["#E8E8E8", "#CD0A0A"];

	const [stayAheadWthBrigderModal, setStayAheadWthBrigderModal] =
		useState(false);

	const [addMoneyModal, setAddMoneyModal] = useState(false);
	const [withdrawMoneyModal, setWithdrawMoneyModal] = useState(false);
	const [confirmTransactionModal, setConfirmTransactionModal] = useState(false);
	const [confirmPinModal, setConfirmPinModal] = useState(false);

	const [otp, setOtp] = useState("");
	const [withdrawalDetails, setWithdrawalDetails] = useState({
		wallet: walletUid,
		amount: "",
		narration: "Withdrawal to Bank",
		client_reference: uuid(),
		pin: otp,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setWithdrawalDetails({
			...withdrawalDetails,
			wallet: walletUid,
			client_reference: uuid(),
			[e.target.name]: e.target.value,
		});
	};

	const onChange = (value: string) => {
		setOtp(value);
		setWithdrawalDetails({
			...withdrawalDetails,
			wallet: walletUid,
			client_reference: uuid(),
			pin: value,
		});
	};

	const copyToClipboard = () => {
		copy(`${wallet_bank_name}, ${wallet_account_number}`);
		toast.success("Copied");
		setAddMoneyModal(!addMoneyModal);
	};

	const withdrawMoney = () => {
		httprequest
			.post("/wallet/withdraw/bank/", withdrawalDetails)
			.then(res => {
				if (res.status === 201) {
					setConfirmPinModal(!confirmPinModal);
					setWithdrawMoneyModal(!withdrawMoneyModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.success("Withdrawal successful");
					getWalletDetails();
					setOtp("");
					setWithdrawalDetails({ ...withdrawalDetails, amount: "" });
				}
			})
			.catch(error => {
				setOtp("");
				setConfirmPinModal(!confirmPinModal);
				setWithdrawMoneyModal(!withdrawMoneyModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setWithdrawalDetails({ ...withdrawalDetails, amount: "" });
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleAddMoney = () => {
		setAddMoneyModal(!addMoneyModal);
	};

	const handleStayAheadwtBridger = () => {
		setStayAheadWthBrigderModal(!stayAheadWthBrigderModal);
	};
	const sendMoney = () => {
		navigate("/makepayment/direct-transfer");
	};

	const handleWithdrawMoney = () => {
		setWithdrawMoneyModal(!withdrawMoneyModal);
	};

	const handleConfirmTransaction = () => {
		setConfirmTransactionModal(!confirmTransactionModal);
	};

	const handleConfirmPin = () => {
		setConfirmPinModal(!confirmPinModal);
	};
	return (
		<Wrapper>
			<First>
				<MainAccount>
					<span>
						<h3>{business_name}</h3> <h4>{wallet_bank_name}</h4>{" "}
						<h5>{wallet_bank_name}</h5> <p>{wallet_account_number}</p>
					</span>{" "}
					<span>
						<h3>Available Balance</h3> <p>₦{`${FormatCurrency(account_balance)}`}</p>
					</span>
				</MainAccount>
				<Quick>
					{" "}
					<h2>Quick Actions</h2>{" "}
					<Actions>
						<ActionButtons
							style={{ backgroundColor: "#FFF8F2" }}
							onClick={handleAddMoney}
						>
							{" "}
							<img src={moneyIcon} alt="money Icon" /> Add Money
						</ActionButtons>{" "}
						<ActionButtons
							style={{ backgroundColor: "#F6F3FF" }}
							onClick={handleWithdrawMoney}
						>
							{" "}
							<img src={moneyIcon} alt="money Icon" /> Withdraw Money
						</ActionButtons>{" "}
						<ActionButtons style={{ backgroundColor: "#cde6c5" }} onClick={sendMoney}>
							{" "}
							<img src={moneyIcon} alt="money Icon" /> Send Money
						</ActionButtons>{" "}
						<ActionButtons style={{ backgroundColor: "#d6cfce", cursor: "none" }}>
							{" "}
							<img src={moneyIcon} alt="money Icon" /> Link Account
						</ActionButtons>
					</Actions>
				</Quick>
			</First>
			<Second>
				<Outstanding>
					<h4>Outstanding Payment</h4> <h3>₦0</h3> <p>Due Date: Not Set</p>
				</Outstanding>{" "}
				<div>
					<Total>
						<PieChart width={80} height={80}>
							<Pie
								data={data}
								cx={40}
								cy={40}
								innerRadius={18}
								outerRadius={30}
								fill="#8884d8"
								dataKey="value"
							>
								{data?.map((_entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORSINCOME[index % COLORSINCOME?.length]}
									/>
								))}
							</Pie>
						</PieChart>

						{/* </ResponsiveContainer> */}
						<span>
							<h5>Total Income</h5> <p>₦{`${FormatCurrency(credit)}`}</p>
						</span>
					</Total>{" "}
					<Total>
						<PieChart width={80} height={80}>
							<Pie
								data={data}
								cx={40}
								cy={40}
								innerRadius={18}
								outerRadius={30}
								fill="#8884d8"
								dataKey="value"
							>
								{data?.map((_entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={COLORSEXPENSE[index % COLORSEXPENSE.length]}
									/>
								))}
							</Pie>
						</PieChart>
						<span>
							<h5>Total Expenses</h5> <p>₦{`${FormatCurrency(debit)}`}</p>
						</span>
					</Total>
				</div>
			</Second>
			<div>
				<Stay>
					<h4>{jumbotronText.title}</h4>{" "}
					{jumbotronText?.plainText && (
						<p>
							{jumbotronText?.plainText?.substring(0, 280)}{" "}
							<span onClick={handleStayAheadwtBridger}>...Read more</span>
						</p>
					)}
				</Stay>{" "}
			</div>

			<ModalComponent
				size="lg"
				toggleModal={handleStayAheadwtBridger}
				modal={stayAheadWthBrigderModal}
				heading={<ModalHeading>{jumbotronText.title}</ModalHeading>}
				subHeading=""
				body={
					<ModalWrapperStay>
						<p dangerouslySetInnerHTML={{ __html: jumbotronText?.note }}></p>
					</ModalWrapperStay>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleAddMoney}
				modal={addMoneyModal}
				heading={<ModalHeading>Add Money</ModalHeading>}
				subHeading=""
				body={
					<ModalWrapper>
						<p>Transfer money into your Bridger wallet</p>
						<form>
							<h4>{wallet_account_number}</h4> <h3>{wallet_bank_name}</h3>{" "}
						</form>
						<br />
						<Button
							title="Got it!"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={copyToClipboard}
						/>
					</ModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleWithdrawMoney}
				modal={withdrawMoneyModal}
				heading={<ModalHeading>Withdraw Money</ModalHeading>}
				subHeading=""
				body={
					<ModalWrapper>
						<p>Withdraw money from your Bridger wallet to your bank account</p>{" "}
						<h4>{userProfile?.profile?.account_number}</h4>{" "}
						<h3>
							{userProfile?.profile?.bank_name} | {userProfile?.profile?.account_name}
						</h3>{" "}
						<Input
							size="lg"
							title="Withdrawal Amount"
							inputType="number"
							value={withdrawalDetails?.amount}
							name="amount"
							placeHolder="₦"
							onChange={e => handleChange(e)}
						/>{" "}
						<br />
						<Button
							title="Continue"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={handleConfirmTransaction}
						/>
					</ModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleConfirmTransaction}
				modal={confirmTransactionModal}
				heading={<ModalHeading>Confirm Transaction</ModalHeading>}
				subHeading=""
				body={
					<ModalWrapperConfirm>
						<p>Kindly confirm the details of your transaction.</p>{" "}
						<View field="Withdrawal amount" detail={withdrawalDetails?.amount} />{" "}
						<View field="Beneficiary" detail={userProfile?.profile?.account_name} />{" "}
						<View field="Bank name" detail={userProfile?.profile?.bank_name} />{" "}
						<View
							field="Bank account number"
							detail={userProfile?.profile?.account_number}
						/>{" "}
						<br />
						<Button
							title="Withdraw"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={handleConfirmPin}
						/>
					</ModalWrapperConfirm>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleConfirmPin}
				heading="Transaction PIN"
				subHeading="Enter your 4-digit Transaction PIN"
				modal={confirmPinModal}
				body={
					<ModalWrapper>
						<OtpWrapper className="otp-group">
							<OTP value={otp} valueLength={4} onChange={onChange} />
						</OtpWrapper>
						<br />
						<Button
							title="Continue"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={withdrawMoney}
						/>
					</ModalWrapper>
				}
			/>
			<ToastContainer />
		</Wrapper>
	);
}

const ModalHeading = styled.h3`
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 0;
`;
const ModalWrapperConfirm = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ModalWrapper = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p {
		margin-top: 0;
		font-size: 12px;
		color: #484848;
		margin-bottom: 30px;
	}
	h4 {
		font-size: 16px;
		font-weight: 500;
		color: #000000;
	}
	h3 {
		font-size: 16px;
		font-weight: 500;
		color: #6d6d6d;
	}
	@media (max-width: 576px) {
		width: 90%;
		margin: 0 auto;
	}
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;

	@media (max-width: 768px) {
		display: grid;
		grid-template-columns: 50% 1fr 1fr;
	}
	@media (max-width: 576px) {
		display: flex;
		flex-direction: column;
	}
`;
const First = styled.div`
	@media (max-width: 576px) {
		margin-bottom: 20px;
	}
`;

const MainAccount = styled.div`
	background-color: #302361;
	color: #fff;
	display: flex;
	justify-content: space-between;
	padding: 19px 30px;
	width: 80%;
	margin: 0 auto;
	border-radius: 8px;
	span {
		:first-child {
			h3 {
				color: #dddddd;
				font-size: 14px;
				font-weight: 400;
			}

			h4 {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 0;
				@media (max-width: 576px) {
					display: none;
				}
			}
			h5 {
				display: none;
				@media (max-width: 576px) {
					font-size: 16px;
					font-weight: 500;
					margin-bottom: 0;
					margin-top: 5px;
					display: block;
				}
			}
			p {
				font-size: 16px;
				font-weight: 500;
				margin-top: 5px;
			}
			text-align: left;
		}
		:last-child {
			h3 {
				color: #dddddd;
				font-size: 14px;
				font-weight: 400;
			}

			p {
				font-size: 28px;
				font-weight: 500;
				margin-top: 5px;
				@media (max-width: 576px) {
					font-size: 20px;
				}
			}
			text-align: right;
		}
	}
`;
const Quick = styled.div`
	padding-top: 25px;
	max-width: 505px;
	margin: 0 auto;
	h2 {
		text-align: left;
		font-size: 18px;
		font-weight: 500;
		padding-bottom: 10px;
	}
	@media (max-width: 576px) {
		margin: 0 auto;

		width: 90%;
	}
`;
const Actions = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ActionButtons = styled.button`
	height: 105px;
	width: 23%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	border: none;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	img {
		margin-bottom: 8px;
	}
`;

const Second = styled.div`
	div {
		@media (max-width: 576px) {
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
			margin-bottom: 15px;
			max-width: 90%;
		}
	}
	div:nth-child(2) {
		@media (max-width: 425px) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: 0 auto;
			margin-bottom: 15px;
			max-width: 90%;
		}
	}
`;

const Outstanding = styled.div`
	text-align: left;
	background-color: #f2d4d4;
	height: 136px;
	width: 90%;
	padding: 20px 0 0 12px;
	border-radius: 8px;
	@media (max-width: 576px) {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 70px;
		padding: 20px 12px 0 12px;
		h3 {
			margin-top: 0;
		}
	}
	h4 {
		margin-top: 0;
		font-size: 14px;
	}
	h3 {
		color: #cd0a0a;
		font-size: 20px;
	}
	p {
		font-size: 14px;
		@media (max-width: 576px) {
			display: none;
		}
	}
`;

const Total = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	margin-top: 10px;
	@media (max-width: 576px) {
		width: 40%;
		padding-right: 15px;
	}
	@media (max-width: 425px) {
		width: 80%;
		margin: 0 auto;
		padding-right: 15px;
	}
	img {
		@media (max-width: 576px) {
			height: 40px;
		}
	}
	span {
		padding-left: 15px;
		h5 {
			margin-bottom: 0;
		}
		p {
			@media (max-width: 576px) {
				margin-top: 10px;
			}
		}
	}
`;

const Stay = styled.div`
	background-color: #302361;
	color: #fff;
	overflow-y: scroll;
	margin-bottom: 20px;
	padding: 40px 20px 30px 30px;
	max-height: 370px;
	text-align: left;
	border-radius: 5px;
	h4 {
		font-size: 15px;
		font-weight: 500;
		margin-top: 0;
	}
	p {
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		span {
			cursor: pointer;
		}
	}
	@media (max-width: 576px) {
		width: 90%;
		:last-child {
			display: none;
		}
	}
`;

const OtpWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ModalWrapperStay = styled.div`
	max-width: 500px;
	margin: 0 auto;
	p {
		font-weight: 400;
		font-size: 14px;
	}
`;
