import { useEffect, useState } from "react";
import styled from "styled-components";
import { TableColumn } from "react-data-table-component";
// import { toast } from "react-toastify";
// import { httprequest } from "../../Constants/api";
import Table from "../../Constants/Table";
import Status from "../../Constants/Status";
import { v4 as uuidv4 } from "uuid";
import PaginatedCards from "../../Constants/PaginatedCards";
// import TransactionDetailModal from "./TransactionDetail";

const columns: TableColumn<any>[] = [
	{
		name: "Customer",
		selector: row => row?.customer,
		width: "20%",
	},
	{
		name: "Amount",
		selector: row => row?.amount,
		width: "15%",
	},
	{
		name: "Description",
		selector: row => row?.description,
		width: "15%",
	},
	{
		name: "Type",
		selector: row => row?.type,
		width: "10%",
		center: true,
	},
	{
		name: "Date",
		selector: row => row?.date,
		width: "20%",
		sortable: true,
	},
	{
		name: "Status",
		selector: row => row?.status,
		width: "20%",
	},
];
type Props = {
	transactions: any;
	walletUid: string;
};

// Many lines in this files are commented out in

export default function Right({ transactions }: Props) {
	const [transactionList, setTransactionList] = useState<any>([]);
	// const [transDetailsModal, setTransDetailsModal] = useState(false);
	// const [transactionDetails, setTransactionDetails] = useState({
	// 	transaction_status: "",
	// 	transaction_amount: "",
	// 	reference_number: "",
	// 	transaction_date: "",
	// 	sender: "",
	// 	benefiaciary: "",
	// 	benefiaciary_account_number: "",
	// 	narration: "",
	// });

	// const getTransactionDetails = (walletUid: string, transactionUid: string) => {
	// 	httprequest
	// 		.get(`/wallet/${walletUid}/transactions/${transactionUid}`)
	// 		.then((response: any) => {
	// 			setTransactionDetails({
	// 				transaction_status: response?.data?.status,
	// 				transaction_amount: response?.data?.amount,
	// 				reference_number: response?.data?.reference,
	// 				transaction_date: response?.data?.created_at,
	// 				sender: response?.data?.wallet?.account_name,
	// 				benefiaciary: response?.data?.customer,
	// 				benefiaciary_account_number: response?.data?.transfer?.account_number,
	// 				narration: response?.data?.narration,
	// 			});
	// 		})
	// 		.catch((error: any) => {
	// 			toast.error(`${error?.response?.data?.errors[0]?.detail}`);
	// 		});
	// };

	// const handleDetails = (row: any) => {
	// 	getTransactionDetails(walletUid, row?.transaction_uid);
	// 	handleTransDetailModal();
	// };

	// const handleTransDetailModal = () => {
	// 	setTransDetailsModal(!transDetailsModal);
	// };
	useEffect(() => {
		setTransactionList(
			transactions?.map((transaction: any) => {
				return {
					customer: transaction?.customer,
					amount: `₦ ${Number(transaction?.amount)?.toFixed(2)}`,
					description: transaction?.narration,
					date: `${transaction?.created_at?.substring(0, 10)}`,
					status: <Status statusState={transaction?.status} />,
					type: transaction?.transaction_type,
					transaction_uid: transaction?.uid,
				};
			})
		);
	}, [transactions]);
	// const handleSmallCardsDetails = (id: any) => {
	// 	const current = transactionList.filter(
	// 		transact => transact.transaction_uid === id
	// 	);
	// 	console.log(
	// 		"thsgs",
	// 		transactions?.filter(transaction => transaction.uid === id)
	// 	);
	// 	console.log(transactions);

	// 	// getTransactionDetails(walletUid, current);
	// };
	return (
		<>
			<LGTableContainer>
				<Table
					key={uuidv4()}
					title="Transaction History"
					columns={columns}
					data={transactionList}
					pagination
					// onRowClicked={(row: any) => handleDetails(row)}
				/>
			</LGTableContainer>
			<SMTBContainer>
				<PaginatedCards
					key={uuidv4()}
					itemsPerPage={10}
					items={transactionList}
					date="date"
					title="Transaction History"
					valueOne="customer"
					valueFour="amount"
					valueThree="type"
					valueTwo="description"
					id="transaction_uid"
				/>
			</SMTBContainer>

			{/* <TransactionDetailModal
				transDetailModal={transDetailsModal}
				transactionDetail={transactionDetails}
				handleTransDetailModal={handleTransDetailModal}
			/> */}
		</>
	);
}

const LGTableContainer = styled.div`
	@media (max-width: 576px) {
		display: none;
	}
`;

const SMTBContainer = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
`;
