import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface Props {
	modal?: boolean;
	toggleModal?: any;
	heading?: React.ReactNode;
	subHeading?: React.ReactNode;
	body?: React.ReactNode;
	size: string;
	headingDirection?: any;
}

type HeadingProps = {
	headingDirection?: string;
};

const modalDiv = document.getElementById("portal");

const Modal: React.FC<Props> = ({
	modal,
	toggleModal,
	heading,
	headingDirection,
	subHeading,
	body,
	size,
}) => {
	return modalDiv
		? ReactDom.createPortal(
				<>
					{modal ? (
						<ModalContainer>
							<ModalBodyClose onClick={toggleModal}></ModalBodyClose>
							<ModalDiv size={size}>
								<ModalClose onClick={toggleModal}>
									<CloseOutlinedIcon />
								</ModalClose>
								<ModalHeading headingDirection={headingDirection}>
									{/* <ArrowBackOutlinedIcon onClick={toggleModal} /> */}
									{heading}
								</ModalHeading>
								<ModalSubHeading>{subHeading}</ModalSubHeading>
								{body}
							</ModalDiv>
						</ModalContainer>
					) : null}
				</>,
				modalDiv
		  )
		: null;
};

const ModalBodyClose = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	background: rgba(79, 86, 101, 0.2);
	backdrop-filter: blur(12px);
`;
const ModalContainer = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
`;
const ModalDiv = styled.div<Props>`
	z-index: 2;
	background-color: #fff;
	position: absolute;
	top: 25%;
	left: 55%;
	transform: translateX(-50%);
	padding-bottom: 40px;
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	color: #65676c;
	box-sizing: border-box;
	width: ${({ size }) =>
		size === "xlg"
			? "1200px"
			: size === "lg"
			? "600px"
			: size === "md"
			? "540px"
			: size === "sm"
			? "330px"
			: size === "full"
			? "100%"
			: ""};
	margin: 0 auto;
	@media (max-width: 1336px) {
		width: ${({ size }) => size === "xlg" && "1000px"};
	}
	@media (max-width: 1200px) {
		width: ${({ size }) => size === "xlg" && "95%"};
	}
	@media (max-width: 991px) {
		max-width: none;
		top: 0px;
		left: 0px;
		transform: translateX(-50%);
		border-radius: 10px;
		width: ${({ size }) =>
			size === "xlg"
				? "80%"
				: size === "lg"
				? "540px"
				: size === "md"
				? "440px"
				: size === "sm"
				? "300px"
				: size === "full"
				? "100%"
				: ""};
	}
	@media (max-width: 576px) {
		transform: translateX(-50%);
		border-radius: 10px;
		width: 90%;
		margin: 0 auto;
	}
	p {
		margin-top: 25px;
	}
	@media (max-width: 1366px) {
		top: 15%;
		left: 50%;
	}
`;
const ModalClose = styled.button`
	display: grid;
	place-content: center;
	background-color: white;
	width: 32px;
	height: 32px;
	border: none;
	position: fixed;
	right: 30px;
	top: 35px;
	color: black;
	font-weight: 700;
`;

const ModalHeading = styled.div<HeadingProps>`
	color: #171717;
	margin: 0px;
	font-size: 23px;
	font-weight: 700;
	text-align: ${({ headingDirection }) =>
		headingDirection === "left" ? "left" : "center"};
	padding-left: ${({ headingDirection }) =>
		headingDirection === "left" ? "30px" : ""};
	padding-top: 76px;
	svg {
		display: none;
	}

	@media (max-width: 991px) {
		padding-left: 24px;
		font-size: 22px;
		display: flex;
		justify-content: center;
		padding-left: 0px;
		svg {
			margin-right: 25px;
			display: flex;
		}
	}
	@media (max-width: 576px) {
		svg {
			font-size: 14px;
		}
	}
`;
const ModalSubHeading = styled.div`
	color: #484848;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	padding-top: 8px;
`;

export default Modal;
