import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Envelop from "../../assets/emailEnvelop.png";
import OTP from "../Constants/OTP";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../Constants/FormComponents/Button";

interface Props {
	reSendOtp: any;
	password: string;
}
export default function VerifyJoinTeam({ reSendOtp, password }: Props) {
	const navigate = useNavigate();
	const location = useLocation();

	const [otp, setOtp] = useState("");
	const [loading, setLoading] = useState(false);
	const ref = location.pathname.split("/");

	const handleSubmit = (e: any) => {
		const details = {
			password: password,
			otp: otp,
		};
		setLoading(true);
		e.preventDefault();
		constants.httprequest
			.put(`/accounts/team/invite/${ref[2]}/accept/`, details)
			.then(res => {
				if (res?.status === 200) {
					navigate("/");
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const onChange = (value: string) => setOtp(value);

	return (
		<EmailWrapper>
			<span>
				<img src={Envelop} alt="Envelop" />
			</span>

			<EmailTitle>Check your Text Message Inbox!</EmailTitle>

			<EmailText>
				We sent a message with a 6-digit verification code to you.
			</EmailText>

			<form>
				<OTP value={otp} valueLength={6} onChange={onChange} />
				<br />
				<Button
					title="Continue"
					variant="default"
					textColor="normal"
					size="full"
					isLoading={loading}
					onClick={e => handleSubmit(e)}
				/>
			</form>
			<br />
			<ResendButton onClick={reSendOtp}>Resend Verification Code</ResendButton>
			<ToastContainer />
		</EmailWrapper>
	);
}

const EmailWrapper = styled.div`
	background: #ffffff;

	width: 35%;
	margin: 0 auto;
	padding: 50px 40px;
	@media (max-width: 900px) {
		width: 40%;
	}
	@media (max-width: 600px) {
		width: 50%;
	}
	@media (max-width: 425px) {
		width: 70%;
	}
`;

const EmailTitle = styled.h2`
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
`;
const EmailText = styled.p`
	font-size: 13px;
	line-height: 21px;
	max-width: 320px;
	margin: 0 auto;
`;

const ResendButton = styled.div`
	cursor: pointer;
`;
