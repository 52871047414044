import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../Constants/FormComponents/Input";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import Button from "../Constants/FormComponents/Button";
import * as constants from "../Constants/api";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import VerifyJoinTeam from "./VerifyJoinTeam";

export default function JoinTeam() {
	const [teamMemberDetails, setTeamMemberDetails] = useState({
		fullname: "",
		email: "",
		phone: "",
		password: "",
	});
	const [passwordType, setPasswordType] = useState("password");
	const [count, setCount] = useState(1);
	const [loading, setLoading] = useState(false);

	const location = useLocation();

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	const handleChange = (e: any) => {
		setTeamMemberDetails({
			...teamMemberDetails,
			[e.target.name]: e.target.value,
		});
	};

	const ref = location.pathname.split("/");

	const getInvitedDetails = () => {
		constants.httprequest
			.get(`accounts/team/invite/${ref[2]}/`)
			.then(res => {
				setTeamMemberDetails({
					...teamMemberDetails,
					fullname: `${res?.data?.first_name} ${res?.data?.last_name}`,
					email: res.data.email,
				});
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const validatePassword = () => {
		const password = {
			password: teamMemberDetails.password,
		};
		setLoading(true);
		constants.httprequest
			.post("/accounts/password/validity/", password)
			.then(res => {
				if (res.status === 201) {
					setLoading(false);
					requestOtp();
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const requestOtp = () => {
		const phoneNumber = {
			phone: teamMemberDetails.phone,
		};
		setLoading(true);
		constants.httprequest
			.put(`/accounts/team/invite/${ref[2]}/request-otp/`, phoneNumber)
			.then(res => {
				if (res.status === 200) {
					setLoading(false);
					setCount(2);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		validatePassword();
	};

	useEffect(() => {
		getInvitedDetails();
	}, []);

	return (
		<Wrapper>
			{count === 1 && (
				<JoinTeamForm>
					<h2>Join Team</h2>
					<p>Kindly provide the required information to setup your account.</p>

					<Input
						inputType="text"
						title="Full Name"
						required
						value={teamMemberDetails.fullname}
						name="fullname"
						placeHolder="John Doe"
						size="full"
					/>
					<Input
						inputType="email"
						title="Email Address"
						required
						value={teamMemberDetails.email}
						name="email"
						placeHolder="johndoe@gmail.com"
						size="full"
					/>

					<Input
						inputType="text"
						title="Phone Number"
						required
						value={teamMemberDetails.phone}
						name="phone"
						placeHolder="+2347012312123"
						onChange={e => handleChange(e)}
						size="full"
					/>

					<Password>
						<Input
							inputType={passwordType}
							title="Password"
							required
							value={teamMemberDetails.password}
							name="password"
							placeHolder=""
							onChange={e => handleChange(e)}
							size="full"
						/>

						<PasswordIcon>
							{passwordType === "password" ? (
								<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
							) : (
								<FontAwesomeIcon icon={faEye} onClick={togglePassword} />
							)}
						</PasswordIcon>
					</Password>

					<Button
						title="Continue"
						variant="default"
						textColor="normal"
						size="full"
						isLoading={loading}
						onClick={e => handleSubmit(e)}
					/>
				</JoinTeamForm>
			)}
			{count === 2 && (
				<VerifyJoinTeam
					reSendOtp={requestOtp}
					password={teamMemberDetails.password}
				/>
			)}
			<ToastContainer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background: #302361;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const JoinTeamForm = styled.form`
	background: #ffffff;

	width: 35%;
	margin: 0 auto;
	padding: 50px 40px;
	h2 {
		font-size: 22px;
	}
	p {
		font-size: 12px;

		text-align: center;
		margin: 0 auto;
		padding-bottom: 10px;
	}
	@media (max-width: 600px) {
		width: 50%;
	}
	@media (max-width: 425px) {
		width: 70%;
	}
`;

const Password = styled.div``;
const PasswordIcon = styled.div`
	position: relative;
	top: -32px;
	left: 45%;
	@media (max-width: 425px) {
		left: 40%;
	}
`;
