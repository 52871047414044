import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SideNav from "./SideNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import MenuIcon from "@mui/icons-material/Menu";
import animateScrollTo from "animated-scroll-to";
import { AppContext } from "../../Context/index";

interface Props {
	tabName: string;
	rightSubHeader: any;
	leftSubHeader?: any;
	mainBody: React.ReactNode;
}

const Main: React.FC<Props> = props => {
	const [toggle, setToggle] = useState(false);
	const { userProfile } = useContext(AppContext);

	const handleToogle = () => {
		setToggle(!toggle);
	};
	const Initials = userProfile?.first_name?.split("")[0].toUpperCase();
	const closeToggle = () => {
		setToggle(false);
	};
	useEffect(() => {
		animateScrollTo(0);
	}, []);

	return (
		<>
			<Wrapper>
				<SideNav toggle={toggle} />
				<ContentWrapper>
					<HeaderWrapper>
						<HeaderContainer>
							<LeftHeader>
								<div className="tabtitle">{props.tabName}</div>
								<div className="burger" onClick={handleToogle}>
									{/* <FontAwesomeIcon icon={faHamburger} /> */}
									<MenuIcon />
								</div>
							</LeftHeader>

							<RightHeader>
								<Link to="/settings">
									<NameSection>
										<DP>{Initials}</DP>
										<Details>
											<Name>
												{userProfile?.first_name} {userProfile?.last_name}
											</Name>
											<Email>{userProfile?.email}</Email>
										</Details>
									</NameSection>
								</Link>
								<Link to="/settings">
									<Settings>
										<FontAwesomeIcon icon={faGear} />
									</Settings>
								</Link>
							</RightHeader>
						</HeaderContainer>
					</HeaderWrapper>
					<ContentBody
						onClick={closeToggle}
						style={{ height: "100vh" > "100%" ? "100vh" : "100%" }}
					>
						<SubHeading>
							<LeftSubHeading>{props.leftSubHeader}</LeftSubHeading>
							<RightSubHeading>{props.rightSubHeader}</RightSubHeading>
						</SubHeading>
						{props.mainBody}
					</ContentBody>
				</ContentWrapper>
			</Wrapper>
		</>
	);
};

export default Main;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;
const ContentWrapper = styled.div`
	width: calc(100% - 241px);
	@media (max-width: 991px) {
		width: 100%;
	}
`;
const ContentBody = styled.div`
	padding-left: 48px;
	padding-right: 29px;
	padding-top: 100px;
	@media (max-width: 991px) {
		padding-left: 29px;
		padding-right: 29px;
	}
	@media (max-width: 576px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;
const HeaderWrapper = styled.div`
	// border-bottom: 1px solid #c5cae4;
	// border-bottom: 1px solid var(--textAsh);
	// box-shadow: 0px 6px 6px rgba(109, 109, 109, 0.25);
	padding-top: 28px;
	padding-bottom: 28px;
	position: fixed;
	background-color: white;
	width: calc(100% - 241px);
	z-index: 2;
	@media (max-width: 991px) {
		background-color: white;
		border-color: transparent;
		width: 100%;
		position: fixed;
	}
`;
const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-left: 48px;
	padding-right: 19px;
	@media (max-width: 991px) {
		align-items: center;
	}
	@media (max-width: 768px) {
		padding-left: 29px;
		padding-right: 29px;
	}
	@media (max-width: 576px) {
		padding-left: 15px;
		padding-right: 15px;
	}
	a {
		text-decoration: none;
	}
`;
const LeftHeader = styled.div`
	font-weight: 700;
	font-size: 24px;
	.tabtitle {
		@media (max-width: 991px) {
			display: none;
		}
	}
	.burger {
		border: 1px solid white;
		color: black;
		// width:40px;
		// height:40px;
		padding: 10px 15px;
		border-radius: 5px;
		@media (min-width: 991px) {
			display: none;
		}
	}
`;
const RightHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
`;
const NameSection = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	padding-left: 12px;
	padding-right: 12px;
	border-radius: 5px;
`;
const Details = styled.div`
	text-align: left;
	margin-left: 22px;
`;
const DP = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #6892fd;
	color: white;
	font-weight: 600;
	font-size: 16px;
	border-radius: 50%;
`;
const Name = styled.div`
	font-weight: 500;
	color: black;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	// @media(max-width:991px){
	//   color:white;
	// }
`;
const Email = styled.div`
	color: #6d6d6d;
	font-weight: 400;
	font-size: 12px;
	text-align: left;
	// @media(max-width:991px){
	//   color:var(--mainBlue);
	// }
`;
const Settings = styled.div`
	width: 50px;
	height: 50px;
	background-color: #fbfbfb;
	color: var(--mainBlack);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	@media (max-width: 426px) {
		display: none;
	}
`;

const SubHeading = styled.div`
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;
const LeftSubHeading = styled.div`
	// font-size: 12px;
	line-height: 16px;
	// color: #c5cae4;
	// letter-spacing: -1px;
	@media (max-width: 768px) {
		width: 100%;

		font-weight: bold;
		text-align: left;
		border-bottom: 1px solid #c5cae4;
		padding-bottom: 20px;
		padding-top: 20px;
	}
`;
const RightSubHeading = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	justify-content: space-between;
	@media (max-width: 768px) {
		width: 100%;
		padding-bottom: 20px;
		padding-top: 30px;
		flex-wrap: wrap;
		gap: 10px;
	}
`;
