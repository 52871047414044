import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_II = process.env.REACT_APP_API_URL_II

const BASE_URL = process.env.REACT_APP_BASE_URL;

const httprequest = axios.create({
	baseURL: API_URL,
});

const httprequestupdate = axios.create({
	baseURL: API_URL_II,
});

httprequest.interceptors.request.use(config => {
	const token = localStorage.getItem("isAuthenticated");
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

httprequestupdate.interceptors.request.use(config => {
	const token = localStorage.getItem("isAuthenticated");
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});
export const postRequest = async (URI, body) => {
	const res = await axios.post(URI, body);
	return res;
};

export { API_URL, API_URL_II, httprequest, httprequestupdate, BASE_URL };
