import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Overview from "./Components/Overview/Index";
import Invoice from "./Components/Invoice/Index";
import Customer from "./Components/Customer/Index";
import Receive from "./Components/Receive/Index";
import PaymentLink from "./Components/Receive/PaymentLink";
// import PurchaseOrder from "./Components/PurchaseOrder/Index";
import Wallet from "./Components/Wallet/Index";
import Settings from "./Components/Settings/Index";
import Profile from "./Components/Profile/Index";
import Login from "./Components/Auth/Login";
import SignUp from "./Components/Auth/SignUp";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import NewPassword from "./Components/Auth/NewPassword";
import EmailNotification from "./Components/Auth/EmailNotification";
import VerifyEmail from "./Components/Auth/VerifyEmail";
import VerifyPhone from "./Components/Auth/VerifyPhone";
import CreateOrder from "./Components/PurchaseOrder/CreateOrders";
import OrderPreview from "./Components/PurchaseOrder/OrderPreview";
import CreateInvoice from "./Components/Invoice/CreateInvoice";
import InvoicePreview from "./Components/Invoice/InvoicePreview";
// import Accept from "./Components/PurchaseOrder/Accept";
import AcceptInvoice from "./Components/Invoice/Accept";
import LoginOtp from "./Components/Auth/Login-Otp";
import ProtectedRoute from "./Components/Auth/ProtectRoutes";
import CompanyDetailsView from "./Components/Settings/CompanyDetailsView";
import PersonalDetailsEdit from "./Components/Settings/PersonalDetailsEdit";
// import CompanyDetailsEdit from "./Components/Settings/CompanyDetailsEdit";
import Security from "./Components/Settings/Security";
import DocumentView from "./Components/Settings/DocumentView";
import DocumentEdit from "./Components/Settings/DocumentEdit";
import Preferences from "./Components/Settings/preferences/Index";
import MakePayment from "./Components/MakePayment";
import DirectPayment from "./Components/MakePayment/DirectPayment";
import PaymentInvoice from "./Components/Receive/PaymentInvoicePreview";
import AcceptEarly from "./Components/Receive/AcceptForm";
import ExternalPay from "./Components/Receive/ExternalPay";
import ErrorPage from "./Components/Error/Error404";
import TeamMembers from "./Components/Settings/TeamMembers";
import ManageRoles from "./Components/Settings/ManageRoles";

import { Crisp } from "crisp-sdk-web";
import JoinTeam from "./Components/Auth/JoinTeam";
import InvoicePreviewPublic from "./Components/Invoice/InvoicePreviewPublic";

// Purchase Order has been commented out from the App.
// Leave them comented, we can revert it anytime.

function App() {
	if (process.env.REACT_APP_ENVIRONMENT === "production") {
		const CRISP_WEBSITE_ID: any = process.env.REACT_APP_CRISP_WEBSITE_ID;
		useEffect(() => Crisp.configure(CRISP_WEBSITE_ID), []);
	}
	// Protected routes can be added to the routes array as an object
	const routes = [
		{
			element: <Overview />,
			path: "/dashboard",
		},
		{
			element: <Invoice />,
			path: "/invoice",
		},
		{
			element: <CreateInvoice />,
			path: "/invoice/create",
		},
		{
			element: <InvoicePreview />,
			path: "/invoice/preview",
		},
		{
			element: <AcceptInvoice />,
			path: "/invoice/accept",
		},
		{
			element: <Customer />,
			path: "/customer",
		},
		{
			element: <Receive />,
			path: "/receive/early-pay",
		},
		{
			element: <PaymentLink />,
			path: "/receive/payment-link",
		},
		{
			element: <PaymentInvoice />,
			path: "/receive/preview",
		},
		{
			element: <AcceptEarly />,
			path: "/receive/apply",
		},
		// {
		// 	element: <PurchaseOrder />,
		// 	path: "/purchase-order",
		// },
		{
			element: <CreateOrder />,
			path: "/purchase-order/create",
		},
		{
			element: <OrderPreview />,
			path: "/purchase-order/preview",
		},
		// {
		// 	element: <Accept />,
		// 	path: "/purchase-order/accept",
		// },
		{
			element: <Wallet />,
			path: "/wallet",
		},
		{
			element: <Settings />,
			path: "/settings",
		},
		{
			element: <PersonalDetailsEdit />,
			path: "/settings/edit",
		},
		{
			element: <DocumentView />,
			path: "/settings/documents",
		},
		{
			element: <CompanyDetailsView />,
			path: "/settings/companydetailsview",
		},
		{
			element: <DocumentView />,
			path: "/settings/documents",
		},
		{
			element: <TeamMembers />,
			path: "/settings/team-members",
		},
		{
			element: <ManageRoles />,
			path: "/settings/team-members/roles",
		},
		{
			element: <Security />,
			path: "/settings/security/view",
		},
		{
			element: <Preferences />,
			path: "/settings/preferences",
		},
		{
			element: <DocumentEdit />,
			path: "/settings/documents/edit",
		},
		{
			element: <Profile />,
			path: "/profile",
		},
		{
			element: <MakePayment />,
			path: "/makepayment",
		},
		{
			element: <DirectPayment />,
			path: "/makepayment/direct-transfer",
		},
	];
	return (
		<div className="App">
			<Routes>
				{routes.map(route => {
					return (
						<Route
							key={route.path}
							element={<ProtectedRoute>{route.element}</ProtectedRoute>}
							path={route.path}
						/>
					);
				})}
				{/* Unauthenticated Routes */}
				<Route element={<ForgotPassword />} path="/forgot-password" />
				<Route element={<VerifyEmail />} path="/verify-email" />
				<Route element={<VerifyPhone />} path="/verify-phone" />
				<Route element={<EmailNotification />} path="/email-notification" />
				<Route element={<NewPassword />} path="/reset-password" />
				<Route element={<Login />} path="/" />
				<Route element={<LoginOtp />} path="/login-otp" />
				<Route element={<JoinTeam />} path="/join-team/:id" />
				<Route element={<SignUp />} path="/signup" />
				<Route element={<ExternalPay />} path="/pay/:id" />
				<Route element={<InvoicePreviewPublic />} path="/preview/public/:id" />

				<Route element={<ErrorPage />} path="/auth/404" />
				<Route element={<ErrorPage />} path="*" />
			</Routes>
		</div>
	);
}

export default App;
