import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface Props {
	modal: boolean;
	toggleModal: any;
	heading: React.ReactNode;
	subHeading: React.ReactNode;
	body: React.ReactNode;
	headingDirection?: any;
}

type HeadingProps = {
	headingDirection?: string;
};

const modalDiv = document.getElementById("portal");

const Modal: React.FC<Props> = props =>
	modalDiv
		? ReactDom.createPortal(
				<>
					{props.modal ? (
						<ModalContainer>
							<ModalBodyClose onClick={props.toggleModal} />
							<ModalDiv>
								<ModalClose onClick={props.toggleModal}>
									<CloseOutlinedIcon />
								</ModalClose>
								<ModalHeading headingDirection={props.headingDirection}>
									{/* <ArrowBackOutlinedIcon onClick={props.toggleModal} /> */}
									{props.heading}
								</ModalHeading>
								<ModalSubHeading>{props.subHeading}</ModalSubHeading>
								{props.body}
							</ModalDiv>
						</ModalContainer>
					) : null}
				</>,
				modalDiv
		  )
		: null;

const ModalBodyClose = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	background: rgba(79, 86, 101, 0.2);
	backdrop-filter: blur(12px);
	@media (max-width: 991px) {
		display: none;
	}
`;
const ModalContainer = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	@media (max-width: 991px) {
		width: 100%;
		// overflow-y:hidden ;
	}
`;
const ModalDiv = styled.div`
	z-index: 2;
	max-width: 600px;
	width: 100%;
	background-color: #fff;
	position: absolute;
	top: 25%;
	left: 55%;
	transform: translateX(-50%);
	padding-bottom: 40px;
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	color: #65676c;
	p {
		margin-top: 25px;
	}
	@media (max-width: 1366px) {
		top: 15%;
		left: 50%;
	}

	@media (max-width: 991px) {
		max-width: none;
		top: 0px;
		left: 0px;
		transform: translateX(0px);
		border-radius: 0px;
		min-height: 100vh;
	}
`;
const ModalClose = styled.button`
	display: grid;
	place-content: center;
	background-color: white;
	width: 32px;
	height: 32px;
	border: none;
	position: fixed;
	right: 30px;
	top: 35px;
	color: black;
	font-weight: 700;
`;

const ModalHeading = styled.div<HeadingProps>`
	color: #171717;
	font-size: 23px;
	font-weight: 700;
	text-align: ${({ headingDirection }) =>
		headingDirection === "left" ? "left" : "center"};
	padding-top: 76px;
	margin-left: 30px !important;
	svg {
		display: none;
	}

	@media (max-width: 991px) {
		padding-left: 24px;
		font-size: 22px;
		display: flex;
		justify-content: center;
		svg {
			margin-right: 25px;
			display: flex;
		}
	}
	@media (max-width: 576px) {
		svg {
			font-size: 14px;
		}
	}
`;
const ModalSubHeading = styled.div`
	color: #484848;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	padding-top: 8px;
`;

export default Modal;
