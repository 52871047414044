import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import TransferToBank from "../../assets/TransferToBank.png";
import TransferToBridger from "../../assets/TransferToBridger.png";
import Beneficiary from "./Beneficiaries";
import TransferToBankForm from "./TransferToBankForm";
import Modal from "../Constants/Modal";
import View from "../Settings/View";
import OTP from "../Constants/OTP";
import { AppContext } from "../../Context";
import { httprequest, BASE_URL } from "../Constants/api";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Edit from "../Constants/FormComponents/Edit";
import { useCountdown } from "../Receive/Countdown";

export default function DirectPayment() {
	const navigate = useNavigate();

	const { allBankList } = useContext(AppContext);
	const [beneficiaries, setBeneficiary] = useState<any>([]);
	const [toBank, setToBank] = useState(false);
	const [toBridger, setToBridger] = useState(false);
	const [toBridgerWallet, setToBridgerWallet] = useState(false);
	const [toBankTransfer, setToBankTransfer] = useState(false);
	const [toCardPayment, setToCardPayment] = useState(false);
	const [throughBridger, setThroughBridger] = useState(false);
	const [count, setCount] = useState(1);
	const [paymentMethodModal, setPaymentMethodModal] = useState(false);
	const [confirmTransactionModal, setConfirmTransactionModal] = useState(false);
	const [transactionPinModal, setTransactionPinModal] = useState(false);
	const [repaymentMethodModal, setRepaymentMethodModal] = useState(false);
	const [bankAcctModal, setBankAcctModal] = useState<boolean>(false);
	const [repaymentPlan, setRepaymentPlan] = useState("");
	const [virtualBankDetails, setVirtualBankDetails] = useState({
		account_number: "",
		bank_code: "",
		bank_name: "",
		account_name: "",
		expiry: "",
		amount: "",
	});

	// const [customer, setCustomer] = useState("");

	const [otp, setOtp] = useState("");
	const [days, hours, minutes, seconds] = useCountdown(
		virtualBankDetails?.expiry
	);

	const [transferDetails, setTransferDetails] = useState({
		name_enquiry: "",
		wallet: "",
		amount: "",
		narration: "",
		client_reference: uuid(),
		pin: "",
		payment_method: "",
		account_name: "",
		bank_name: "",
		bank_code: "",
		account_number: "",
		save_beneficiary: false,
	});

	const [transferToBridgerDetails, setTransferToBridgerDetails] = useState({
		destination_wallet: "",
		wallet: "",
		amount: "",
		narration: "",
		client_reference: uuid(),
		pin: "",
		payment_method: "",
		// account_name: "",
		name: "",
		// account_number: "",
	});

	const confirmTransactionBank = [
		{ field: "Transaction amount", detail: transferDetails.amount },
		{ field: "Beneficiary", detail: transferDetails.account_name },
		{ field: "Bank name", detail: transferDetails.bank_name },
		{ field: "Bank account number", detail: transferDetails.account_number },
		{ field: "Narration", detail: transferDetails.narration },
	];

	const confirmTransactionBridger = [
		{ field: "Transaction amount", detail: transferToBridgerDetails.amount },
		{ field: "Beneficiary", detail: transferToBridgerDetails.name },
		{ field: "Narration", detail: transferToBridgerDetails.narration },
	];

	useEffect(() => {
		getWalletDetails();
		getBeneficiaryList();
	}, []);

	const TransferToBankAcc = () => {
		setToBridger(false);
		setToBank(true);
	};

	// const TransferToBrigerAcc = () => {
	// 	setToBank(false);
	// 	setToBridger(true);
	// };

	// const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	setCustomer(e.target.value);
	// 	if (e.target.value.length > 0) {
	// 		httprequest
	// 			.get(`/wallet/transfer/internal/name/enquiry/?search=${e.target.value}`)
	// 			.then(response => {
	// 				setTransferToBridgerDetails({
	// 					...transferToBridgerDetails,
	// 					destination_wallet: response.data.results[0]?.reference,
	// 					name: response.data.results[0]?.company?.name,
	// 				});
	// 			})
	// 			.catch(error => {
	// 				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
	// 			});
	// 	}
	// };

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTransferToBridgerDetails({
			...transferToBridgerDetails,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeTransferToBank = (data: any) => {
		setTransferDetails({
			...transferDetails,
			name_enquiry: data.name_enquiry,
			amount: data.transaction_amount,
			narration: data.narration,
			account_name: data.account_name,
			bank_name: data.bank_name,
			account_number: data.account_number,
			save_beneficiary: data.save_beneficiary,
			bank_code: data.bank_code,
		});
	};

	const transferMoney = () => {
		toBank ? handleTransfer() : transferToBridger();
		setCount(1);
	};

	const transferToBridger = () => {
		const repaymentBody = {
			financing_type: "make_payment",
			financing_source_type: "direct_transfer",
			amount_requested: transferToBridgerDetails?.amount,
			repayment_plan: repaymentPlan,
			reason_for_financing: "",
			metadata: {
				wallet: transferToBridgerDetails.destination_wallet,
			},
		};
		if (transferToBridgerDetails.payment_method == "wallet") {
			httprequest
				.post("/wallet/transfer/internal/", transferToBridgerDetails)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						toast.success("Transfer Processing");
						setOtp("");
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setTransactionPinModal(!transactionPinModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else if (transferToBridgerDetails.payment_method === "repayment") {
			httprequest
				.post("/financing/origination/", repaymentBody)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						setRepaymentMethodModal(!repaymentMethodModal);
						toast.success("Financing request sent");
						setTransferDetails({
							...transferDetails,
							client_reference: uuid(),
						});
						setOtp("");
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setRepaymentMethodModal(!repaymentMethodModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else if (
			transferToBridgerDetails.payment_method == "card" ||
			"bank_transfer"
		) {
			httprequest
				.post(
					"/wallet/transfer/payment-channel/internal/",
					transferToBridgerDetails
				)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						toast.success("Transfer Processing");
						setOtp("");
						if (transferDetails.payment_method === "card") {
							window.location.href = res.data.payload.card.payment_url;
						} else {
							setVirtualBankDetails({
								...virtualBankDetails,
								account_number: res.data.payload.bank_transfer.account_number,
								bank_code: res.data.payload.bank_transfer.bank_code,
								bank_name: res.data.payload.bank_transfer.bank_name,
								account_name: res.data.payload.bank_transfer.account_name,
								expiry: res.data.payload.bank_transfer.expiry,
								amount: res.data.payload.bank_transfer.amount,
							});
							setBankAcctModal(!bankAcctModal);
						}
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setTransactionPinModal(!transactionPinModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else {
			toast.error("Pick a payment method");
		}
	};

	const deleteBeneficiary = (uid: any) => {
		httprequest
			.delete(`/wallet/transfer/beneficiary/${uid}`)
			.then(res => {
				if (res.status === 204) {
					toast.success("Beneficiary Deleted Successfully");
					getBeneficiaryList();
				}
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleTransfer = () => {
		const repaymentBody = {
			financing_type: "make_payment",
			financing_source_type: "direct_transfer",
			amount_requested: transferDetails?.amount,
			repayment_plan: repaymentPlan,
			reason_for_financing: transferDetails.narration,
			metadata: {
				bank_name: transferDetails?.bank_name,
				bank_account_number: transferDetails?.account_number,
				bank_account_name: transferDetails?.account_name,
				bank_code: transferDetails?.bank_code,
			},
		};
		if (transferDetails.payment_method === "wallet") {
			httprequest
				.post("/wallet/transfer/bank/", transferDetails)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						toast.success("Transfer Processing");
						setTransferDetails({
							...transferDetails,
							client_reference: uuid(),
						});
						setOtp("");
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setTransactionPinModal(!transactionPinModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else if (transferDetails.payment_method === "repayment") {
			httprequest
				.post("/financing/origination/", repaymentBody)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						setRepaymentMethodModal(!repaymentMethodModal);
						toast.success("Financing request sent");
						setTransferDetails({
							...transferDetails,
							client_reference: uuid(),
						});
						setOtp("");
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setRepaymentMethodModal(!repaymentMethodModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else if (transferDetails.payment_method === "card" || "bank_transfer") {
			httprequest
				.post("/wallet/transfer/payment-channel/bank/", transferDetails)
				.then(res => {
					if (res.status === 201) {
						setConfirmTransactionModal(!confirmTransactionModal);
						setPaymentMethodModal(!paymentMethodModal);
						setTransactionPinModal(!transactionPinModal);
						setConfirmTransactionModal(!confirmTransactionModal);
						toast.success("Transfer Processing");
						setTransferDetails({
							...transferDetails,
							client_reference: uuid(),
						});
						setOtp("");
						if (transferDetails.payment_method === "card") {
							window.location.href = res.data.payload.card.payment_url;
						} else {
							setVirtualBankDetails({
								...virtualBankDetails,
								account_number: res.data.payload.bank_transfer.account_number,
								bank_code: res.data.payload.bank_transfer.bank_code,
								bank_name: res.data.payload.bank_transfer.bank_name,
								account_name: res.data.payload.bank_transfer.account_name,
								expiry: res.data.payload.bank_transfer.expiry,
								amount: res.data.payload.bank_transfer.amount,
							});
							setBankAcctModal(!bankAcctModal);
						}
					}
				})
				.catch(error => {
					setConfirmTransactionModal(!confirmTransactionModal);
					setPaymentMethodModal(!paymentMethodModal);
					setTransactionPinModal(!transactionPinModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		} else {
			toast.error("Pick a payment method");
		}
	};

	const getWalletDetails = () => {
		httprequest
			.get("/wallet/")
			.then(response => {
				setTransferDetails({
					...transferDetails,
					wallet: response.data.results[0]?.uid,
				});
				setTransferToBridgerDetails({
					...transferToBridgerDetails,
					wallet: response.data.results[0]?.uid,
				});
			})
			.catch(error => {
				if (error.response.status === 401) {
					navigate("/");
				}

				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const getBeneficiaryList = () => {
		httprequest
			.get("/wallet/transfer/beneficiary/")
			.then(response => {
				setBeneficiary(response.data.results);
			})
			.catch(error => {
				if (error.response.status === 401) {
					navigate("/");
				}

				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	//  Pick a payment method
	const payWtBridgerWallet = () => {
		setToBridgerWallet(true);
		setTransferDetails({ ...transferDetails, payment_method: "wallet" });
		setTransferToBridgerDetails({
			...transferToBridgerDetails,
			payment_method: "wallet",
		});
		setToBankTransfer(false);
		setToCardPayment(false);
		setThroughBridger(false);
	};

	const payWtBnkTransfer = () => {
		setToBridgerWallet(false);
		setToBankTransfer(true);
		setTransferDetails({ ...transferDetails, payment_method: "bank_transfer" });
		setTransferToBridgerDetails({
			...transferToBridgerDetails,
			payment_method: "bank_transfer",
		});

		setToCardPayment(false);
		setThroughBridger(false);
	};

	const payWtCrdTransfer = () => {
		setToBridgerWallet(false);
		setToBankTransfer(false);
		setToCardPayment(true);
		setTransferDetails({ ...transferDetails, payment_method: "card" });
		setTransferToBridgerDetails({
			...transferToBridgerDetails,
			payment_method: "card",
		});

		setThroughBridger(false);
	};

	const paythrhBridger = () => {
		setToBridgerWallet(false);
		setToBankTransfer(false);
		setToCardPayment(false);
		setThroughBridger(true);
		setTransferDetails({ ...transferDetails, payment_method: "repayment" });
		setTransferToBridgerDetails({
			...transferToBridgerDetails,
			payment_method: "repayment",
		});
		handleRepaymentModal();
	};

	const onChange = (value: string) => {
		setOtp(value);
		setTransferDetails({ ...transferDetails, pin: value });
		setTransferToBridgerDetails({ ...transferToBridgerDetails, pin: value });
	};

	const renderTransferToBridgerAcct = () => {
		return (
			<>
				<Edit
					title="Company name"
					inputType="text"
					value={transferToBridgerDetails.name}
					name="name"
					placeHolder="2000"
				/>
				<Edit
					title="Transaction amount"
					inputType="text"
					value={transferToBridgerDetails.amount}
					name="amount"
					placeHolder="2000"
					onChange={e => handleChange(e)}
				/>

				<Edit
					title="Narration"
					inputType="text"
					value={transferToBridgerDetails.narration}
					name="narration"
					placeHolder="Groceries"
					onChange={e => handleChange(e)}
				/>
			</>
		);
	};

	/* eslint-disable */
	const sliderlabelOne = [
		"O days",
		<br />,
		repaymentPlan === "0_days" || repaymentPlan === "" ? "0%" : null,
	];

	const sliderlabelTwo = [
		"30 days",
		<br />,
		repaymentPlan === "30_days" ? "5%" : null,
	];

	const sliderlabelThree = [
		"60 days",
		<br />,
		repaymentPlan === "60_days" ? "10%" : null,
	];

	const sliderlabelFour = [
		"90 days",
		<br />,
		repaymentPlan === "90_days" ? "15%" : null,
	];

	const marks = [
		{
			value: 0,
			label: sliderlabelOne,
		},
		{
			value: 30,
			label: sliderlabelTwo,
		},
		{
			value: 60,
			label: sliderlabelThree,
		},
		{
			value: 90,
			label: sliderlabelFour,
		},
	];
	/* eslint-enable */

	const getValueText = (value: any) => {
		setRepaymentPlan(`${value}_days`);
		return `${value}`;
	};

	const renderOptions = () => {
		return (
			<DirectOptions>
				{/* <TransferButton
					onClick={() => TransferToBrigerAcc()}
					className={toBridger ? "active" : "inactive"}
				>
					<img src={TransferToBridger} />{" "}
					<Text>
						<h4>Transfer to Bridger account</h4>{" "}
						<p>Send money to an existing Bridger account.</p>
					</Text>
				</TransferButton> */}

				{/* {toBridger ? (
					<>
						<Edit
							title="Transfer money to"
							inputType="text"
							value={customer}
							name="customer"
							placeHolder="Search"
							onChange={e => handleSearch(e)}
						/>
						<p>{transferToBridgerDetails.name}</p>
					</> */}
				{/* ) : (
					""
				)} */}
				<TransferButton
					onClick={() => TransferToBankAcc()}
					className={toBank ? "active" : "inactive"}
				>
					<img src={TransferToBank} />{" "}
					<Text>
						<h4>Transfer to bank account</h4> <p>Send money to a bank account.</p>{" "}
					</Text>
				</TransferButton>
			</DirectOptions>
		);
	};

	const renderEnterBankDetails = () => {
		return (
			<TransferToBankForm
				bankList={allBankList}
				name_enquiry={transferDetails.name_enquiry}
				account_name={transferDetails.account_name}
				bank_name={transferDetails.bank_name}
				transaction_amount={transferDetails.amount}
				narration={transferDetails.narration}
				handleChangeTransferToBank={handleChangeTransferToBank}
			/>
		);
	};
	const handlePaymentModal = () => {
		setPaymentMethodModal(!paymentMethodModal);
	};
	const handleRepaymentModal = () => {
		setRepaymentMethodModal(!repaymentMethodModal);
	};
	const handleConfirmTransactionModal = () => {
		setConfirmTransactionModal(!confirmTransactionModal);
	};
	const handleTransactionPinModal = () => {
		setTransactionPinModal(!transactionPinModal);
		setOtp("");
	};
	const redirectSuccess = () => {
		window.location.href = `${BASE_URL}/dashboard`;
	};

	const ContinueButton = () => {
		setCount(2);

		if (count === 2) {
			setPaymentMethodModal(!paymentMethodModal);
		}
	};
	return (
		<>
			<Main
				tabName="Send Money"
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<Direct>
							<h2>Direct Transfer</h2>
							<p>Transfer money directly to your costomers.</p>

							{count === 1 && renderOptions()}
							{count === 2 && toBank && renderEnterBankDetails()}
							{count === 2 && toBridger && renderTransferToBridgerAcct()}

							<Button onClick={() => ContinueButton()}>Continue</Button>
						</Direct>

						<Beneficiaries>
							{" "}
							<h2>Saved Beneficiaries</h2>{" "}
							<p>Here’s a list of all your saved beneficiaries.</p>{" "}
							{beneficiaries.map((beneficiary: any, index: any) => {
								return (
									<Beneficiary
										key={index}
										name={beneficiary?.account_name}
										account_number={beneficiary.account_number}
										bank_name={beneficiary?.bank_name}
										onDelete={() => deleteBeneficiary(beneficiary?.uid)}
									/>
								);
							})}
						</Beneficiaries>

						<Modal
							toggleModal={handlePaymentModal}
							heading="Payment method"
							subHeading="Please select your preferred method of payment."
							modal={paymentMethodModal}
							body={
								<PaymentModalWrapper>
									<TransferButton
										onClick={() => payWtBridgerWallet()}
										className={toBridgerWallet ? "active" : "inactive"}
									>
										<img src={TransferToBridger} />{" "}
										<Text>
											<h4>Bridger wallet</h4>{" "}
											<p>Make payment using the funds in your Bridger wallet.</p>
										</Text>
									</TransferButton>

									<TransferButton
										onClick={() => payWtBnkTransfer()}
										className={toBankTransfer ? "active" : "inactive"}
									>
										<img src={TransferToBridger} />{" "}
										<Text>
											<h4>Bank Transfer</h4>{" "}
											<p>Transfer money directly from your Bank account.</p>
										</Text>
									</TransferButton>

									<TransferButton
										onClick={() => payWtCrdTransfer()}
										className={toCardPayment ? "active" : "inactive"}
									>
										<img src={TransferToBridger} />{" "}
										<Text>
											<h4>Card Payment</h4>{" "}
											<p>Make payment using your bank card through Flutterwave.</p>
										</Text>
									</TransferButton>

									<TransferButton
										onClick={() => paythrhBridger()}
										className={throughBridger ? "active" : "inactive"}
									>
										<img src={TransferToBridger} />{" "}
										<Text>
											<h4>Pay through Bridger</h4>{" "}
											<p>
												Bridger makes this payment for you and you repay on a set date.
											</p>
										</Text>
									</TransferButton>
									<Button onClick={handleConfirmTransactionModal}>Continue</Button>
								</PaymentModalWrapper>
							}
						/>

						<Modal
							toggleModal={handleConfirmTransactionModal}
							heading="Confirm Transaction"
							subHeading="Kindly confirm the details of your transaction."
							modal={confirmTransactionModal}
							body={
								<ConfirmTransacModalWrapper>
									{toBank
										? confirmTransactionBank.map((detail, index) => {
												return (
													<View key={index} field={detail.field} detail={detail.detail} />
												);
										  })
										: confirmTransactionBridger.map((detail, index) => {
												return (
													<View key={index} field={detail.field} detail={detail.detail} />
												);
										  })}

									<Button onClick={handleTransactionPinModal}>Proceed</Button>
								</ConfirmTransacModalWrapper>
							}
						/>

						<Modal
							toggleModal={handleRepaymentModal}
							heading="Repayment Plan"
							subHeading="When do you intend to repay Bridger?."
							modal={repaymentMethodModal}
							body={
								<ConfirmTransacModalWrapper>
									<PaymentModalWrapper>
										{/* <Percentage>
                                            The percentage of interest for every given period is seennunderneath the period.{" "}
                                        </Percentage> */}
										<SliderContainer style={{ textAlign: "center" }}>
											<Box sx={{ width: 344 }}>
												<Slider
													aria-label="Always visible"
													defaultValue={0}
													getAriaValueText={getValueText}
													step={30}
													marks={marks}
													max={90}
													valueLabelDisplay="on"
												/>
											</Box>
										</SliderContainer>
										<Button onClick={handleConfirmTransactionModal}>Continue</Button>
									</PaymentModalWrapper>
								</ConfirmTransacModalWrapper>
							}
						/>

						<Modal
							toggleModal={redirectSuccess}
							heading="Bank Account Information"
							subHeading=""
							modal={bankAcctModal}
							body={
								<>
									<BankInfoBody>
										<ReceiptBody>
											<div>
												<h4>Account Name</h4>
												<h5>{virtualBankDetails?.account_name}</h5>
											</div>
											<div>
												<h4>Account Number</h4>
												<h5>{virtualBankDetails?.account_number}</h5>
											</div>
											<div>
												<h4>Bank Name</h4>
												<h5>{virtualBankDetails?.bank_name}</h5>
											</div>
											<div>
												<h4>Amount to be paid</h4>
												<h5>{virtualBankDetails?.amount}</h5>
											</div>

											{/* 
                                                <div>
                                                    <h4>Count Down</h4>
                                                    <h5>{transferDetails?.expiry}</h5>
                                                </div> 
                                            */}
										</ReceiptBody>
										<Timer>
											{days + hours + minutes + seconds <= 0 ? (
												"Count Down Expired"
											) : (
												<h2>
													{days} : {hours} : {minutes} : {seconds}
												</h2>
											)}
											<h6>Please Pay within this specified window</h6>
										</Timer>
									</BankInfoBody>
									<SuccessBody>
										<div>
											<Button onClick={redirectSuccess}>Done</Button>
										</div>
									</SuccessBody>
								</>
							}
						/>
						<Modal
							toggleModal={handleTransactionPinModal}
							heading="Transaction PIN"
							subHeading={
								<>
									<ToastContainer />
									"Enter your 4-digit Transaction PIN"
								</>
							}
							modal={transactionPinModal}
							body={
								<ConfirmTransacModalWrapper>
									<OTP value={otp} valueLength={4} onChange={onChange} />
									<Button onClick={transferMoney}>Continue</Button>
								</ConfirmTransacModalWrapper>
							}
						/>
						<ToastContainer />
					</Wrapper>
				}
			/>
		</>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1.3fr 1fr;
	@media (max-width: 578px) {
		display: flex;
		flex-direction: column;
	}
`;

const Direct = styled.div`
	text-align: left;
	h2 {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 0;
	}
	p {
		font-size: 12px;
		font-weight: 400;
	}
`;
const DirectOptions = styled.div``;
const PaymentModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const ConfirmTransacModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 65px;
`;
const TransferButton = styled.div`
	display: flex;
	align-items: center;

	border-radius: 4px;
	width: 500px;
	margin: 20px 0px;
	padding: 3px 0 8px 20px;
	cursor: pointer;
	img {
		margin-top: 4px;
	}
	&.inactive {
		border: 1px solid #d9d9d9;
	}
	&.active {
		border: 1px solid var(--mainBlue);
	}
`;
const Text = styled.div`
	padding-left: 10px;
	h4 {
		margin-bottom: 0px;
		font-size: 14px;
	}
	p {
		margin-top: 5px;
		font-size: 12px;
	}
`;

const Button = styled.button`
	background-color: var(--mainBlue);
	color: white;
	width: 500px;
	height: 48px;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	border: none;
	border-radius: 4px;
	margin-top: 40px;
`;

const Beneficiaries = styled.div`
	h2 {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 0;
	}
	p {
		font-size: 12px;
		font-weight: 400;
	}
`;

const SliderContainer = styled.div`
	padding-top: 50px;
	padding-bottom: 30px;
`;

const BankInfoBody = styled.div`
	padding-left: 30px;
	padding-right: 30px;
`;

export const ReceiptBody = styled.div`
	div {
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #d4d4d4;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h5 {
			font-size: 12px;
			color: var(--mainBlue);
			font-weight: bold;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 65%;
			flex-wrap: wrap;
			text-align: right;
		}
		h4 {
			font-size: 12px;
			color: #484848;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 30%;
			text-align: left;
		}
	}
`;
const Timer = styled.div`
	text-align: center;
	padding-top: 20px;
	h2 {
		font-size: 36px;
		font-weight: bold;
		color: var(--mainBlue);
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;
export const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;
