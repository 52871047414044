import styled from "styled-components";

interface MeterProps {
	creditScore: any;
}

const CreditMeter: React.FC<MeterProps> = ({ creditScore }: MeterProps) => {
	return (
		<MeterContainer>
			<Meter creditScore={creditScore}>
				<div>
					<SubTitle>
						{Number(creditScore) < 580
							? "Poor"
							: Number(creditScore) > 580 && Number(creditScore) < 669
							? "Fair"
							: Number(creditScore) > 669 && Number(creditScore) < 739
							? "Good"
							: Number(creditScore) > 739 && Number(creditScore) < 799
							? "Very Good"
							: Number(creditScore) > 799
							? "Exceptional"
							: ""}
					</SubTitle>
					<MeterTitle>{creditScore}</MeterTitle>
				</div>
			</Meter>
		</MeterContainer>
	);
};

export default CreditMeter;

const MeterContainer = styled.div`
	height: 150px;
	padding-bottom: 10px;
	box-sizing: border-box;
`;

const Meter = styled.div<MeterProps>`
	width: 250px;
	height: 125px;
	border-top-left-radius: 125px;
	border-top-right-radius: 125px;
	border: ${({ creditScore }) =>
		Number(creditScore) < 580
			? "10px solid red"
			: Number(creditScore) > 580 && Number(creditScore) < 669
			? "10px solid #E74C3C"
			: Number(creditScore) > 669 && Number(creditScore) < 739
			? "10px solid #F39C12"
			: Number(creditScore) > 739 && Number(creditScore) < 799
			? "10px solid #4BB543"
			: Number(creditScore) > 799
			? "10px solid #302361"
			: ""};
	border: 10px solid $;
	border-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
`;

const SubTitle = styled.p`
	font-size: 12px;
	font-weight: 400;
	color: black;
`;

const MeterTitle = styled.div`
	font-weight: 700;
	font-size: 32px;
	color: black;
	margin-top: 10px;
`;
