import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CarouselOne from "../../../assets/carouselImgOne.png";
import CarouselTwo from "../../../assets/carouselImgTwo.png";
import CarouselThree from "../../../assets/carouselImgThree.png";
import CarouselFour from "../../../assets/carouselImgFour.png";

import Slider from "./Slider";
import "./index.css";

export default function CarouselSlider() {
	return (
		<Carousel
			showArrows={false}
			autoPlay={true}
			showThumbs={false}
			showStatus={false}
			infiniteLoop={true}
			className="carousel"
		>
			<Slider
				style={{ backgroundColor: "#302361" }}
				imgSrc={CarouselOne}
				title={"Sell your invoices and get paid upfront."}
				paragraph={
					"Receive full value of invoice within 48 hours and gain real time instant access to invoices through your dashboard."
				}
			/>
			<Slider
				style={{ backgroundColor: "#21307B" }}
				imgSrc={CarouselTwo}
				title={"Send and receive payments instantly."}
				paragraph={
					"Make and receive payments to businesses both locally and more than 100 countries instantly. No hidden fees."
				}
			/>
			<Slider
				style={{ backgroundColor: "#33317E" }}
				imgSrc={CarouselThree}
				title={"Create, send and manage invoices effortlessly."}
				paragraph={
					"Accelerate your workflow with our clean and clear invoice creation system. Create invoices in seconds."
				}
			/>
			<Slider
				style={{ backgroundColor: "#19176A" }}
				imgSrc={CarouselFour}
				title={"Manage and pay vendors."}
				paragraph={
					"Make and receive payments to businesses both locally and more than 100 countries instantly. No hidden fees."
				}
			/>
		</Carousel>
	);
}
