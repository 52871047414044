import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../../Context";
import Logo from "../../assets/logoTwo.svg";
import Loader from "../../assets/loader.gif";
import Main from "../Constants/Main";
import Input from "../Constants/FormComponents/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import * as constants from "../Constants/api";
import {
	Gender,
	Industry,
	Treasury,
	CompanyType,
	Country,
	AnnualTurnOver,
	Identification,
	Currency,
} from "../Constants/utils";
import Right from "./Components/RightOverview";
import Left from "./Components/LeftOverview";
import Select, { MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import ModalComponent from "../Constants/ModalComponent";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";

const Index: React.FC = () => {
	const navigate = useNavigate();

	const { userProfile, banks, overviewDetails } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [createModal, setCreateModal] = useState<boolean>(
		!userProfile?.is_profile_set
	);
	const [createPersonalModal, setCreatePersonalModal] = useState<boolean>(false);
	const [createCompanyModal, setCreateCompanyModal] = useState<boolean>(false);
	const [uploadPersonalDocModal, setUploadPersonalDocModal] =
		useState<boolean>(false);
	const [uploadCompanyDocModal, setUploadCompanyDocModal] =
		useState<boolean>(false);

	// Modal Form Object state
	const [userDetails, setUserDetails] = useState({
		nationality: "NG",
		gender: "female",
		birth_date: "",
		bank_code: "",
		account_number: "",

		role_in_company: "",
	});

	const [personalDocuments, setPersonalDocuments] = useState({
		id_image: "",
		id_image_back: "",
		id_type: "INTERNATIONAL_PASSPORT",
		id_number: "",
	});

	const [companyDocuments, setCompanyDocuments] = useState({
		cac_document: "",
		memart_document: "",
		document_type: "BN",
	});
	const [fileUploadName, setFileUploadName] = useState({
		id_image: "",
		id_image_back: "",
		cac_document: "",
		memart_document: "",
	});
	const [profileDetails, setProfileDetails] = useState({
		industry_type: "AGRICULTURE",
		address: "",
		type: "Sole propeitorship/Enterprise",
		name: "",
		incorporation_date: "",
		website: "",
		treasury_needs: "",
		annual_turnover: "",
		ft_employees: "",
		pt_employees: "",
		cac_number: "",
		cac_number_type: "RC",
		city: "",
		state: "",
		country: "Nigeria",
	});

	const [details, setDetails] = useState({
		personalDetail: userProfile.is_profile_set,
		companyDetail: userProfile.is_company_details_set,
	});
	const [bankDetails, setBankDetails] = useState({
		account_number: "",
		bank_code: "",
	});
	const [accountName, setAccountName] = useState("");
	const [bankList, setBankList] = useState<any>([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState("ngn");
	const [selectCurrencySign, setSelectedCurrencySign] = useState("₦");
	const [currencyDisplay, setCurrencyDisplay] = useState("₦ Naira (NGN)");

	const avaliableCurrencies = overviewDetails?.statistics
		? Object.keys(overviewDetails?.statistics)
		: [];

	const filteredCurrencies = Currency?.filter(currency =>
		avaliableCurrencies?.includes(currency.value)
	);

	const chooseCurrency = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const setCurrencyValue = (currency: string, sign: string, label: string) => {
		setSelectedCurrency(currency);
		setSelectedCurrencySign(sign);
		setCurrencyDisplay(`${sign} ${label}`);
		setIsDropdownOpen(false);
	};
	const setCreateModalToogle = () => {
		if (userProfile?.is_profile_set === false) {
			setCreateModal(!userProfile?.is_profile_set);
		} else if (userProfile?.is_profile_set === true) {
			setCreateModal(!userProfile?.is_company_details_set);
		}
	};

	// All handleChange functions
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
		setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });

		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: e.target.value,
		});
		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: e.target.value,
		});
		if (e.target.name === "account_number") {
			setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });

			bankAccountResolver(e.target.value);
		}
	};

	const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
		setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value });
		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: e.target.value,
		});
		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files ?? []);
		const file = files[0];
		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: file,
		});
		setCompanyDocuments({ ...companyDocuments, [e.target.name]: file });
		setFileUploadName({ ...fileUploadName, [e.target.name]: file.name });
	};
	const handleChangeSelectMulti = (
		e: MultiValue<{
			value: string;
			label: string;
		}>
	) => {
		const selectedTreasury = e.map(item => item.value);
		setProfileDetails({
			...profileDetails,
			treasury_needs: selectedTreasury.toString(),
		});
	};
	const handleChangeSelectSearch = (e: any) => {
		const selectedBank = e.value;

		setUserDetails({
			...userDetails,
			bank_code: selectedBank,
		});
		setBankDetails({ ...bankDetails, bank_code: selectedBank });
	};

	const handleCreateModal = () => {
		// setCreateModal(!createModal);
	};

	const handleCreatePersonalModal = () => {
		if (details.personalDetail === false) {
			setCreatePersonalModal(!createPersonalModal);
		} else if (
			details.personalDetail === true &&
			details.companyDetail === false
		) {
			setCreateCompanyModal(true);
		}
	};

	const handleUploadPersonalDoc = (e: any) => {
		e.preventDefault();
		setUploadPersonalDocModal(!uploadPersonalDocModal);
	};
	const handleUploadCompanyDoc = (e: any) => {
		e.preventDefault();
		setUploadCompanyDocModal(!uploadCompanyDocModal);
	};

	const handleCreateCompanyModal = (e: any) => {
		e.preventDefault();
		setCreateCompanyModal(!createCompanyModal);
	};
	useEffect(() => {
		setDetails({
			personalDetail: userProfile.is_profile_set,
			companyDetail: userProfile.is_company_details_set,
		});
		setCreateModalToogle();
		if (userProfile.is_company_details_set && !userProfile?.is_set_pin) {
			navigate("/settings");
		}
	}, [userProfile]);
	useEffect(() => {
		setBankList(
			banks.map((bank: any) => {
				return {
					value: bank.code,
					label: bank.name,
				};
			})
		);
	}, [banks]);

	const bankAccountResolver = (account: any) => {
		if (account.length == 10 && bankDetails.bank_code?.length > 1) {
			constants.httprequest
				.post("/accounts/bank-account/resolve/", {
					bank_code: bankDetails.bank_code,
					account_number: account,
				})
				.then(res => {
					if (res.status === 201) {
						setAccountName(res.data.account_enquiry.account_name);
					}
				})
				.catch(error => {
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		}
	};

	const handleSubmit = (e: any) => {
		setLoading(true);

		const form_data = new FormData();

		Object.entries(userDetails).forEach(data => {
			form_data.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.post("/accounts/user/profile/create/", form_data)
			.then(res => {
				if (res.status === 201) {
					toast.success("Profile successfully Updated");
					setLoading(false);
					setUploadPersonalDocModal(true);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};
	const handleSubmitPersonalDocs = (e: any) => {
		setLoading(true);
		const personalDocFormData = new FormData();
		Object.entries(personalDocuments).forEach(data => {
			personalDocFormData.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.put("/accounts/user/profile/", personalDocFormData)
			.then(res => {
				if (res.status === 200) {
					setLoading(false);
					toast.success("Profile documents successfully uploaded");
					setCreateCompanyModal(true);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleSubmitCompanyDocs = (e: any) => {
		setLoading(true);
		const companyDocFormData = new FormData();
		Object.entries(companyDocuments).forEach(data => {
			companyDocFormData.append(data[0], data[1]);
		});
		e.preventDefault();

		constants.httprequest
			.put("/accounts/company/", companyDocFormData)
			.then(res => {
				if (res.status === 200) {
					setLoading(false);
					toast.success("Company documents successfully uploaded");

					setCreateCompanyModal(false);

					setUploadCompanyDocModal(false);
					navigate("/settings");
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleSubmitCompany = (e: any) => {
		setLoading(true);
		const companyFormData = new FormData();
		Object.entries(profileDetails).forEach(data => {
			companyFormData.append(data[0], data[1]);
		});
		e.preventDefault();

		constants.httprequest
			.post("/accounts/company/", companyFormData)
			.then(res => {
				if (res.status === 201) {
					setLoading(false);
					toast.success("Profile successfully Updated");
					setUploadCompanyDocModal(true);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<>
			<Main
				tabName="Overview"
				rightSubHeader={""}
				mainBody={
					<>
						<CurrencySection>
							<CurrencyDropDown onClick={chooseCurrency}>
								{isDropdownOpen ? "Select Currency" : currencyDisplay}
							</CurrencyDropDown>

							{isDropdownOpen && (
								<DropDown className="dropdown-content">
									{filteredCurrencies?.map((currency, index) => (
										<div
											key={index}
											onClick={() =>
												setCurrencyValue(
													currency.value,
													currency.symbol,

													currency.label
												)
											}
											className="currency-option"
										>
											{currency.symbol} {currency.label}
										</div>
									))}
								</DropDown>
							)}
						</CurrencySection>
						<Wrapper>
							<Right
								selectedCurrency={selectedCurrency}
								selectCurrencySign={selectCurrencySign}
							/>
							<Left />
						</Wrapper>

						{details?.personalDetail || details?.companyDetail === false ? (
							<ModalComponent
								size="lg"
								toggleModal={handleCreateModal}
								heading={
									<ModalHeader>
										{" "}
										<Link to="/">
											<img src={Logo} alt="Bridger's Logo" />{" "}
										</Link>
										"Welcome to bridger! 🥳"
									</ModalHeader>
								}
								subHeading=""
								modal={createModal}
								body={
									<>
										{" "}
										<Text>
											You just joined hundreds of supply-chain businesses who manage their
											businesses online. Before you can fully get onboarded, we need to
											know more about you and your business.
										</Text>{" "}
										<CreateButton onClick={handleCreatePersonalModal}>
											Update Profile
										</CreateButton>{" "}
									</>
								}
							/>
						) : (
							""
						)}
						<ModalComponent
							size="lg"
							toggleModal={handleCreatePersonalModal}
							heading={<ModalHeading>Personal Details</ModalHeading>}
							subHeading={<ToastContainer />}
							modal={createPersonalModal}
							body={
								<ModalWrapper>
									<form>
										<Input
											size="lg"
											title="Role in Company"
											required
											inputType="text"
											value={userDetails.role_in_company}
											name="role_in_company"
											placeHolder="Admin"
											onChange={e => handleChange(e)}
										/>
										<SelectWrapper
											size="lg"
											required
											title="Nationality"
											children={
												<select
													name="nationality"
													value={userDetails.nationality}
													onChange={e => handleChangeSelect(e)}
												>
													{Country.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>

										<SelectWrapper
											title="Gender"
											required
											size="lg"
											children={
												<select
													name="gender"
													value={userDetails.gender}
													onChange={e => handleChangeSelect(e)}
												>
													{Gender.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>
										<Input
											size="lg"
											title="Date of Birth"
											required
											inputType="date"
											value={userDetails.birth_date}
											name="birth_date"
											placeHolder="1/1/1991"
											onChange={e => handleChange(e)}
										/>
										<SelectWrapper
											required
											size="lg"
											title="Bank Name"
											children={
												<Select
													options={bankList}
													isSearchable
													onChange={e => handleChangeSelectSearch(e)}
												/>
											}
										/>
										<Input
											size="lg"
											title="Account Number"
											inputType="number"
											value={userDetails.account_number}
											name="account_number"
											placeHolder="202223233"
											onChange={e => handleChange(e)}
										/>
										<Input
											size="lg"
											title="Account Name"
											inputType="text"
											value={accountName}
											name="accountName"
											placeHolder="Jone Doe"
										/>

										<ButtonGroup>
											<ButtonOutlined>Cancel</ButtonOutlined>{" "}
											{loading ? (
												<ButtonFilled>
													<img src={Loader} alt="loader gif" />
												</ButtonFilled>
											) : (
												<ButtonFilled onClick={e => handleSubmit(e)}>Next</ButtonFilled>
											)}
										</ButtonGroup>
									</form>
								</ModalWrapper>
							}
						/>

						<ModalComponent
							size="lg"
							toggleModal={handleUploadPersonalDoc}
							heading={<ModalHeading>PERSONAL DOCUMENTS</ModalHeading>}
							subHeading={<ToastContainer />}
							modal={uploadPersonalDocModal}
							body={
								<ModalWrapper>
									<form>
										<SelectWrapper
											title="ID Type"
											required
											size="lg"
											children={
												<select
													name="id_type"
													value={personalDocuments.id_type}
													onChange={e => handleChangeSelect(e)}
												>
													{Identification.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>
										<Input
											size="lg"
											title="License Number"
											required
											inputType="text"
											value={personalDocuments.id_number}
											name="id_number"
											placeHolder="5637828474"
											onChange={e => handleChange(e)}
										/>
										<UploadLogoContainer>
											<div>
												<CloudUploadIcon />
												<p>Upload a copy of your ID card here</p>
												<small>(Only JPEG, PNG, PDF formats are accepted)</small>
												<p>{fileUploadName?.id_image}</p>
											</div>
											<input
												type="file"
												name="id_image"
												onChange={e => handleChangeFiles(e)}
											/>
										</UploadLogoContainer>

										<ButtonGroup>
											<ButtonOutlined>Cancel</ButtonOutlined>{" "}
											{loading ? (
												<ButtonFilled>
													<img src={Loader} alt="loader gif" />
												</ButtonFilled>
											) : (
												<ButtonFilled onClick={e => handleSubmitPersonalDocs(e)}>
													Next
												</ButtonFilled>
											)}
										</ButtonGroup>
									</form>
								</ModalWrapper>
							}
						/>
						<ModalComponent
							size="lg"
							toggleModal={handleCreateCompanyModal}
							heading={<ModalHeading>Company Details</ModalHeading>}
							subHeading={<ToastContainer />}
							modal={createCompanyModal}
							body={
								<ModalWrapper>
									<form>
										<Input
											size="lg"
											title="Registered Business Name"
											required
											inputType="text"
											value={profileDetails.name}
											name="name"
											placeHolder="Bridger Africa"
											onChange={e => handleChange(e)}
										/>
										<SelectWrapper
											title="Type of Company"
											required
											size="lg"
											children={
												<select
													name="type"
													value={profileDetails.type}
													onChange={e => handleChangeSelect(e)}
												>
													{CompanyType.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>

										<SelectWrapper
											title="Industry Type"
											required
											size="lg"
											children={
												<select
													name="industry_type"
													value={profileDetails.industry_type}
													onChange={e => handleChangeSelect(e)}
												>
													{Industry.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>

										<Input
											size="lg"
											title="Business Website"
											inputType="text"
											value={profileDetails.website}
											name="website"
											placeHolder="https://www.bridger.africa"
											onChange={e => handleChange(e)}
										/>
										<Small>
											<Input
												size="sm"
												title="Number of Full-time Employees"
												inputType="text"
												value={profileDetails.ft_employees}
												name="ft_employees"
												onChange={e => handleChange(e)}
											/>
											<Input
												size="sm"
												title="Number of Part-time Employees"
												inputType="text"
												value={profileDetails.pt_employees}
												name="pt_employees"
												onChange={e => handleChange(e)}
											/>
										</Small>

										<SelectWrapper
											size="lg"
											title="Treasury Needs"
											required
											children={
												<Select
													options={Treasury}
													isMulti
													onChange={e => handleChangeSelectMulti(e)}
												/>
											}
										/>

										<SelectWrapper
											size="lg"
											title="Annual Turnover"
											required
											children={
												<select
													name="annual_turnover"
													value={profileDetails.annual_turnover}
													onChange={e => handleChangeSelect(e)}
												>
													{AnnualTurnOver.map(item => {
														return (
															<option key={item.value} value={item.value}>
																{item.label}
															</option>
														);
													})}
												</select>
											}
										/>

										<Input
											size="lg"
											title="Date of incorporation*"
											inputType="date"
											value={profileDetails.incorporation_date}
											name="incorporation_date"
											placeHolder="11/11/2011"
											onChange={e => handleChange(e)}
										/>
										<RCSection>
											<label>CAC Details*</label>
											<RcInput>
												<select
													name="cac_number_type"
													onChange={handleChangeSelect}
													value={profileDetails.cac_number_type}
												>
													<option value="RC">RC</option>
													<option value="BN">BN</option>
												</select>

												<input
													type="text"
													value={profileDetails?.cac_number}
													name="cac_number"
													placeholder="12345678"
													onChange={e => handleChange(e)}
												/>
											</RcInput>
										</RCSection>

										<Small>
											<Input
												size="sm"
												required
												title="Street Name"
												inputType="text"
												value={profileDetails.address}
												name="address"
												onChange={e => handleChange(e)}
											/>
											<Input
												size="sm"
												required
												title="City"
												inputType="text"
												value={profileDetails.city}
												name="city"
												onChange={e => handleChange(e)}
											/>
										</Small>
										<Small>
											<Input
												size="sm"
												required
												title="Country"
												inputType="text"
												value={profileDetails.country}
												name="country"
												onChange={e => handleChange(e)}
											/>
											<Input
												size="sm"
												required
												title="State"
												inputType="text"
												value={profileDetails.state}
												name="state"
												onChange={e => handleChange(e)}
											/>
										</Small>
										<ButtonGroup>
											<ButtonOutlined>Cancel</ButtonOutlined>{" "}
											{loading ? (
												<ButtonFilled>
													<img src={Loader} alt="loader gif" />
												</ButtonFilled>
											) : (
												<ButtonFilled onClick={e => handleSubmitCompany(e)}>
													Save Changes
												</ButtonFilled>
											)}
										</ButtonGroup>
									</form>
									<ToastContainer />
								</ModalWrapper>
							}
						/>

						<ModalComponent
							size="lg"
							toggleModal={handleUploadCompanyDoc}
							heading={<ModalHeading>COMPANY DOCUMENTS</ModalHeading>}
							subHeading={<ToastContainer />}
							modal={uploadCompanyDocModal}
							body={
								<ModalWrapper>
									<form>
										<SelectWrapper
											title="Document Type"
											required
											size="lg"
											children={
												<select
													name="document_type"
													onChange={handleChangeSelect}
													value={companyDocuments.document_type}
												>
													<option value="RC">RC</option>
													<option value="BN">BN</option>
												</select>
											}
										/>
										<UploadLogoContainer>
											<div>
												<CloudUploadIcon />
												<p>Upload a copy of your CAC Document here</p>
												<small>(Only JPEG, PNG, PDF formats are accepted)</small>
												<p>{fileUploadName?.cac_document}</p>
											</div>
											<input
												type="file"
												name="cac_document"
												onChange={e => handleChangeFiles(e)}
											/>
										</UploadLogoContainer>

										{companyDocuments.document_type === "RC" && (
											<UploadLogoContainer>
												<div>
													<CloudUploadIcon />
													<p>Upload a copy of your MEMART Document here</p>
													<small>(Only JPEG, PNG, PDF formats are accepted)</small>
													<p>{fileUploadName?.memart_document}</p>
												</div>
												<input
													type="file"
													name="memart_document"
													onChange={e => handleChangeFiles(e)}
												/>
											</UploadLogoContainer>
										)}

										<ButtonGroup>
											<ButtonOutlined>Cancel</ButtonOutlined>{" "}
											{loading ? (
												<ButtonFilled>
													<img src={Loader} alt="loader gif" />
												</ButtonFilled>
											) : (
												<ButtonFilled onClick={e => handleSubmitCompanyDocs(e)}>
													Next
												</ButtonFilled>
											)}
										</ButtonGroup>
									</form>
								</ModalWrapper>
							}
						/>
					</>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-gap: 15px;

	@media (max-width: 500px) {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
`;
const ModalWrapper = styled.div`
	margin: 0 auto;

	@media (min-width: 500px) {
		display: flex;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;
const ModalHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ModalHeading = styled.div`
	text-align: left;
	padding-left: 50px;
	@media (max-width: 500px) {
		text-align: center;
		padding-left: 0px;
	}
`;
const CreateButton = styled.button`
	background-color: var(--mainBlue);
	width: 80%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
	margin: 0 auto;
	margin-top: 30px;
	cursor: pointer;
`;
const Text = styled.p`
	font-size: 14px;
	text-align: center;
	color: #484848;
	font-weight: 400;
	max-width: 430px;
	margin: 0 auto;
`;
const Small = styled.div`
	display: grid;
	grid-template-columns: 235px 235px;
	gap: 30px;
	width: 500px;
	margin: 0 auto;
	@media (max-width: 500px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
`;
const RcInput = styled.div`
	border: 1px solid #d9d9d9;
	height: 45px;
	max-width: 92%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	input {
		border: none;
		height: 90%;
	}
	select {
		border: none;
		height: 90%;
		border-right: 1px solid #d9d9d9;
	}
`;

const RCSection = styled.div`
	label {
		display: flex;
		justify-content: flex-start;
		padding: 25px 0 10px 0;
		font-size: 14px;
	}
`;
const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 47px;
	margin-top: 50px;
	img {
		height: 40px;
	}
`;
const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;
const ButtonFilled = styled.button`
	border: none;
	border-radius: 3px;
	padding: 12px 25px;
	background: #302361;
	color: #fff;
	img {
		height: 30px;
	}
`;
const UploadLogoContainer = styled.div`
	max-width: 600px;
	height: 140px;
	border: 1px solid #d9d9d9;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		color: #454545;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 14px;
		color: #454545;
	}
	small {
		font-size: 12px;
		color: #919191;
	}
	input {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		cursor: pointer;
	}
	div {
	}
`;
const CurrencySection = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 1024px) {
		width: 100vw;
	}
	@media (max-width: 768px) {
		width: 100vw;
	}
`;
const CurrencyDropDown = styled.div`
	position: relative;
`;
const DropDown = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	text-align: left;
	background: #fcfcfc;
	top: 35%;
	font-size: 10px;
	cursor: pointer;
	font-weight: 600;
	background-color: #f8f8f8;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	padding: 10px;
	z-index: 10;
	div {
		padding-top: 6px;
	}
	@media (max-width: 1024px) {
		top: 36%;
	}
	@media (max-width: 768px) {
		top: 60%;
	}
`;

export default Index;
