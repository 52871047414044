import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Main from "../Constants/Main";
import View from "./View";
import { AppContext } from "../../Context/index";
import { useTruncate } from "../../hooks/useTruncate";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../Constants/api";

export default function DocumentsView() {
	const navigate = useNavigate();

	const { userProfile } = useContext(AppContext);

	const [profileDetails, setProfileDetails] = useState({
		type: "",
		name: "",

		cac_document: "",
		memart_document: "",
		cac_details: "",
	});

	const getDetails = () => {
		httprequest
			.get("/accounts/company/")
			.then(response => {
				setProfileDetails({
					type: response.data?.type,
					name: response.data?.name,

					cac_document: response.data?.cac_document,
					memart_document: response.data?.memart_document,
					cac_details: `${response.data?.cac_number_type} - ${response.data?.cac_number}`,
				});
			})
			.catch(error => {
				if (error?.response?.status === 401) {
					navigate("/");
				}
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};
	useEffect(() => {
		getDetails();
	}, []);

	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={<ToastContainer />}
				mainBody={
					<Wrapper>
						<PageHeader>
							<span>
								<PageTitle>Documents</PageTitle>
								<PageInstruction>
									View and manage your company’s documents.
								</PageInstruction>
							</span>
							{/* <Button>
								<Link to="/settings/companydetailsview/edit">Edit</Link>
							</Button> */}
						</PageHeader>

						<PageBody>
							<h2>Personal Documents</h2>

							<View field="ID Type" detail={userProfile?.profile?.id_type} />
							<View field="ID Number" detail={userProfile?.profile?.id_number} />
							<View
								field="ID Upload"
								detail={useTruncate(userProfile?.profile?.id_image, 40, "...")}
							/>
						</PageBody>

						<PageBody>
							<h2>Company Documents</h2>

							<View field="CAC Details" detail={profileDetails.cac_details} />
							<View
								field="CAC Document"
								detail={useTruncate(profileDetails?.cac_document, 40, "...")}
							/>
							<View
								field="MEMART Document"
								detail={useTruncate(profileDetails?.memart_document, 40, "...")}
							/>
						</PageBody>
					</Wrapper>
				}
			/>
		</>
	);
}

const Wrapper = styled.div`
	width: 45vw;
`;

const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;

// const TooglePage = styled.div`
// 	display: flex;
// 	padding-bottom: 30px;
// 	p {
// 		padding-right: 30px;
// 		font-size: 14px;
// 		color: #6d6d6d;
// 	}
// `;

const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

// const Button = styled.button`
// 	align-self: center;
// 	background: none;
// 	padding: 7px 25px;
// 	border: 1px solid #9791b0;
// 	color: #302361;
// 	font-size: 14px;
// 	cursor: pointer;
// `;

const PageBody = styled.div`
	padding-top: 20px;
	h2 {
		text-align: left;
		font-size: 18px;
		font-weight: 500;
	}
`;
