import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ModalComponent from "../Constants/ModalComponent";
import { AppContext } from "../../Context/index";
import { ToastContainer, toast } from "react-toastify";
import * as constants from "../Constants/api";
// import Loader from "../../assets/loader.gif";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Button from "../Constants/FormComponents/Button";
import Input from "../Constants/FormComponents/Input";
import { Identification } from "../Constants/utils";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";
// import { useNavigate } from "react-router-dom";

const StatusConfig = {
	pending: {
		description:
			"Profile Update Pending. You will be granted access to all features when your account is approved.",
		modalTitle: "Profile Update Pending!",
		modalText:
			"Profile Update Pending. You will be granted access to all features when your account is approved.",
		buttonText: "Got that!",
		statusBar: "#FCF2DC",
	},
	incomplete: {
		description:
			"Profile Incomplete. Kindly provide the required details to be granted access to all features.",
		modalText:
			"You have not submitted all required documents. Kindly submit the required details.",
		statusBar: "#FCF2DC",
		buttonText: "Edit Profile",
		modalTitle: "Profile Incomplete!",
	},
	failed: {
		description:
			"Profile update failed. Kindly provide the required details to be granted access to all features.",
		modalText:
			"Unfortunately, we could not activate your account as some of the information you provided were not approved. Kindly resubmit the required details.",
		statusBar: "#F2D4D4",
		buttonText: "Edit Profile",
		modalTitle: "Profile Update Failed!",
	},
	successfull: {
		description:
			"Profile update successfull! You have been granted access to all features on the Bridger platform.",
		statusBar: "#DCF8EF",
		modalText: "",
		buttonText: "",
		modalTitle: "",
	},
};

export default function KYCStatusBar() {
	const { userKYC, fetchUserKYCStatus } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState<boolean>(false);
	const [showBVN, setShowBVN] = useState<boolean>(false);
	const [uploadPersonalDocModal, setUploadPersonalDocModal] =
		useState<boolean>(false);
	const [uploadCompanyDocModal, setUploadCompanyDocModal] =
		useState<boolean>(false);
	const [bvnDetails, setBvnDetails] = useState({
		number: "",
		date_of_birth: "",
	});
	const [personalDocuments, setPersonalDocuments] = useState({
		image: "",
		image_back: "",
		verification_type: "INTERNATIONAL_PASSPORT",
		number: "",
	});

	const [companyDocuments, setCompanyDocuments] = useState({
		number: "",
		company_name: "",
		cac_document: "",
		memart_document: "",
		company_type: "BN",
		incorporation_date: "",
	});
	const [fileUploadName, setFileUploadName] = useState({
		image: "",
		image_back: "",
		cac_document: "",
		memart_document: "",
	});
	const [statusInfo, setStatusInfo] = useState({
		description: "",
		modalTitle: "",
		modalText: "",
		statusBar: "",
		buttonText: "",
	});

	const handleClose = () => {
		setShow(!show);
	};

	const handleCloseBVN = () => {
		setShowBVN(!showBVN);
	};
	const handleUploadPersonalDoc = (e: any) => {
		e.preventDefault();
		setUploadPersonalDocModal(!uploadPersonalDocModal);
	};
	const handleUploadCompanyDoc = (e: any) => {
		e.preventDefault();
		setUploadCompanyDocModal(!uploadCompanyDocModal);
	};
	const handleChange = (e: any) => {
		setBvnDetails({ ...bvnDetails, [e.target.name]: e.target.value });
		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: e.target.value,
		});
		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: e.target.value,
		});
		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files ?? []);
		const file = files[0];
		setPersonalDocuments({
			...personalDocuments,
			[e.target.name]: file,
		});
		setCompanyDocuments({ ...companyDocuments, [e.target.name]: file });
		setFileUploadName({ ...fileUploadName, [e.target.name]: file.name });
	};

	const profileDetails = () => {
		if (userKYC.status === "pending") {
			setStatusInfo({
				...statusInfo,
				description: StatusConfig.pending.description,
				modalTitle: StatusConfig.pending.modalTitle,
				modalText: StatusConfig.pending.modalText,
				statusBar: StatusConfig.pending.statusBar,
				buttonText: StatusConfig.pending.buttonText,
			});
		} else if (userKYC.status === "not_done") {
			setStatusInfo({
				...statusInfo,
				description: StatusConfig.pending.description,
				modalTitle: StatusConfig.pending.modalTitle,
				modalText: StatusConfig.pending.modalText,
				statusBar: StatusConfig.pending.statusBar,
				buttonText: StatusConfig.pending.buttonText,
			});
		} else if (userKYC.status === "failed") {
			setStatusInfo({
				...statusInfo,
				description: StatusConfig.failed.description,
				modalTitle: StatusConfig.failed.modalTitle,
				modalText: StatusConfig.failed.modalText,
				statusBar: StatusConfig.failed.statusBar,
				buttonText: StatusConfig.failed.buttonText,
			});
		} else if (userKYC.status === "incomplete") {
			setStatusInfo({
				...statusInfo,
				description: StatusConfig.incomplete.description,
				modalTitle: StatusConfig.incomplete.modalTitle,
				modalText: StatusConfig.incomplete.modalText,
				statusBar: StatusConfig.incomplete.statusBar,
				buttonText: StatusConfig.incomplete.buttonText,
			});
		} else if (userKYC.status === "successfull") {
			setStatusInfo({
				...statusInfo,
				description: StatusConfig.successfull.description,
				modalTitle: StatusConfig.successfull.modalTitle,
				modalText: StatusConfig.successfull.modalText,
				statusBar: StatusConfig.successfull.statusBar,
				buttonText: StatusConfig.successfull.buttonText,
			});
		}
	};

	const initialLookupVerification = () => {
		if (userKYC?.lookup?.bvn?.status === "FAILED") {
			setShowBVN(!showBVN);
			return false;
		} else if (userKYC?.lookup?.document?.status === "FAILED") {
			setUploadPersonalDocModal(!uploadPersonalDocModal);
			return false;
		} else if (userKYC?.lookup?.company_registration?.status === "FAILED") {
			setUploadCompanyDocModal(!uploadCompanyDocModal);
			return false;
		}
		return true;
	};

	const Verification = () => {
		if (!userKYC?.bvn || userKYC?.bvn?.review_status === "DECLINED") {
			setShowBVN(!showBVN);
		} else if (
			!userKYC?.document ||
			userKYC?.document?.review_status === "DECLINED"
		) {
			setUploadPersonalDocModal(!uploadPersonalDocModal);
		} else if (
			!userKYC?.company_registration ||
			userKYC?.company_registration?.review_status === "DECLINED"
		) {
			setUploadCompanyDocModal(!uploadCompanyDocModal);
		}
	};
	const handleClickModalBtn = () => {
		if (userKYC?.status === "pending") {
			setShow(!show);
		} else if (userKYC?.status === "failed") {
			Verification();
		} else if (userKYC?.status === "incomplete") {
			if (initialLookupVerification()) {
				Verification();
			}
		} else if (userKYC?.status === "not_done") {
			if (initialLookupVerification()) {
				Verification();
			}
		}
	};

	const handleSubmitBVN = (e: any) => {
		setLoading(true);
		e.preventDefault();
		constants.httprequest
			.post("/kyc/bvn/upload/", bvnDetails)
			.then(res => {
				if (res.status === 201) {
					toast.success("BVN successfully updated");
					if (userKYC?.lookup?.document?.status === "FAILED") {
						setUploadPersonalDocModal(!uploadPersonalDocModal);
					} else if (userKYC?.document?.review_status === "DECLINED") {
						setUploadPersonalDocModal(!uploadPersonalDocModal);
					} else if (userKYC?.lookup?.company_registration?.status === "FAILED") {
						setUploadCompanyDocModal(!uploadCompanyDocModal);
					} else {
						setShowBVN(false);
						setUploadPersonalDocModal(false);
						setUploadCompanyDocModal(false);
						fetchUserKYCStatus();
					}
					setLoading(false);
				}
			})
			.catch(error => {
				toast.error(error?.response?.data?.errors[0]?.detail);
				setLoading(false);
			});
	};
	const handleSubmitPersonalDocs = (e: any) => {
		setLoading(true);

		const personalDocFormData = new FormData();
		Object.entries(personalDocuments).forEach(data => {
			personalDocFormData.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.post("/kyc/id/upload/", personalDocFormData)
			.then(res => {
				if (res.status === 201) {
					toast.success("Profile documents successfully uploaded");
					if (userKYC?.lookup?.company_registration?.status === "FAILED") {
						setUploadCompanyDocModal(!uploadCompanyDocModal);
					} else if (userKYC?.company_registration?.review_status === "DECLINED") {
						setUploadCompanyDocModal(!uploadCompanyDocModal);
					} else {
						setShowBVN(false);
						setUploadPersonalDocModal(false);
						setUploadCompanyDocModal(false);
						fetchUserKYCStatus();
					}
					setLoading(false);
				}
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				setLoading(false);
			});
	};

	const handleSubmitCompanyDocs = (e: any) => {
		setLoading(true);
		const companyDocFormData = new FormData();
		Object.entries(companyDocuments).forEach(data => {
			companyDocFormData.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.post("/kyc/company-registration/upload/", companyDocFormData)
			.then(res => {
				if (res.status === 201) {
					toast.success("Company documents successfully uploaded");
					setShowBVN(false);
					setUploadPersonalDocModal(false);
					setUploadCompanyDocModal(false);
					fetchUserKYCStatus();
					setLoading(false);
				}
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				setLoading(false);
			});
	};

	useEffect(() => {
		profileDetails();
	}, [userKYC]);
	const { description, modalTitle, modalText, statusBar, buttonText } =
		statusInfo;
	return (
		<>
			<StatusBar onClick={handleClose} style={{ background: statusBar }}>
				{description}
			</StatusBar>

			<ModalComponent
				size="lg"
				toggleModal={handleClose}
				heading=""
				subHeading=""
				modal={show}
				body={
					<ModalWrapper>
						<ToastContainer />

						<h2>{modalTitle}</h2>
						<p>{modalText}</p>
						<br />

						<Button
							title={buttonText}
							size="sm"
							textColor="normal"
							variant="default"
							isLoading={loading}
							disabled={loading}
							onClick={handleClickModalBtn}
						/>
					</ModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleCloseBVN}
				heading=""
				subHeading=""
				modal={showBVN}
				body={
					<ModalWrapper>
						<ToastContainer />

						<Input
							inputType="text"
							title="BVN"
							required
							value={bvnDetails.number}
							name="number"
							placeHolder="11111111111"
							onChange={handleChange}
							size="full"
						/>
						<Input
							inputType="date"
							title="Date of Birth"
							required
							value={bvnDetails.date_of_birth}
							name="date_of_birth"
							placeHolder="1/1/1990"
							onChange={handleChange}
							size="full"
						/>
						<br />

						<Button
							title={buttonText}
							size="sm"
							textColor="normal"
							variant="default"
							isLoading={loading}
							disabled={loading}
							onClick={handleSubmitBVN}
						/>
					</ModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleUploadPersonalDoc}
				heading={<ModalHeading>PERSONAL DOCUMENTS</ModalHeading>}
				subHeading={<ToastContainer />}
				modal={uploadPersonalDocModal}
				body={
					<ModalWrapper>
						<form>
							<SelectWrapper
								title="ID Type"
								required
								size="lg"
								children={
									<select
										name="verification_type"
										value={personalDocuments.verification_type}
										onChange={e => handleChangeSelect(e)}
									>
										{Identification.map(item => {
											return (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											);
										})}
									</select>
								}
							/>
							<Input
								size="lg"
								title="License Number"
								required
								inputType="text"
								value={personalDocuments.number}
								name="number"
								placeHolder="5637828474"
								onChange={e => handleChange(e)}
							/>
							<UploadLogoContainer>
								<div>
									<CloudUploadIcon />
									<p>Upload a copy of your ID card here</p>
									<small>(Only JPEG, PNG, PDF formats are accepted)</small>
									<p>{fileUploadName?.image}</p>
								</div>
								<input type="file" name="image" onChange={e => handleChangeFiles(e)} />
							</UploadLogoContainer>
							<UploadLogoContainer>
								<div>
									<CloudUploadIcon />
									<p>Upload a copy of your ID card here</p>
									<small>(Only JPEG, PNG, PDF formats are accepted)</small>
									<p>{fileUploadName?.image_back}</p>
								</div>
								<input
									type="file"
									name="image_back"
									onChange={e => handleChangeFiles(e)}
								/>
							</UploadLogoContainer>

							<ButtonGroup>
								<ButtonOutlined>Cancel</ButtonOutlined>{" "}
								<Button
									title="Submit"
									size="sm"
									textColor="normal"
									variant="default"
									isLoading={loading}
									disabled={loading}
									onClick={handleSubmitPersonalDocs}
								/>
							</ButtonGroup>
						</form>
					</ModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleUploadCompanyDoc}
				heading={<ModalHeading>COMPANY DOCUMENTS</ModalHeading>}
				subHeading={<ToastContainer />}
				modal={uploadCompanyDocModal}
				body={
					<ModalWrapper>
						<Input
							inputType="text"
							title="Company Name"
							required
							value={companyDocuments.company_name}
							name="company_name"
							placeHolder="Bridger Africa Limited"
							onChange={handleChange}
							size="full"
						/>
						<Input
							inputType="date"
							title="Incorporation Date"
							required
							value={companyDocuments.incorporation_date}
							name="incorporation_date"
							placeHolder="1/1/1990"
							onChange={handleChange}
							size="full"
						/>
						<SelectWrapper
							title="Document Type"
							required
							size="lg"
							children={
								<select
									name="company_type"
									onChange={handleChangeSelect}
									value={companyDocuments.company_type}
								>
									<option value="RC">RC</option>
									<option value="BN">BN</option>
								</select>
							}
						/>
						<Input
							size="lg"
							title="CAC Number"
							required
							inputType="text"
							value={companyDocuments.number}
							name="number"
							placeHolder="5637828474"
							onChange={e => handleChange(e)}
						/>
						<UploadFileContainerKYC>
							<div>
								<CloudUploadIcon />
								<p>Upload a copy of your CAC Document here</p>
								<small>(Only JPEG, PNG, PDF formats are accepted)</small>
								<p>{fileUploadName?.cac_document}</p>
							</div>
							<input
								type="file"
								name="cac_document"
								onChange={e => handleChangeFiles(e)}
							/>
						</UploadFileContainerKYC>

						{companyDocuments.company_type === "RC" && (
							<UploadFileContainerKYC>
								<div>
									<CloudUploadIcon />
									<p>Upload a copy of your MEMART Document here</p>
									<small>(Only JPEG, PNG, PDF formats are accepted)</small>
									<p>{fileUploadName?.memart_document}</p>
								</div>
								<input
									type="file"
									name="memart_document"
									onChange={e => handleChangeFiles(e)}
								/>
							</UploadFileContainerKYC>
						)}

						<ButtonGroup>
							<ButtonOutlined>Cancel</ButtonOutlined>{" "}
							<Button
								title="Submit"
								size="sm"
								textColor="normal"
								variant="default"
								isLoading={loading}
								disabled={loading}
								onClick={handleSubmitCompanyDocs}
							/>
						</ButtonGroup>
					</ModalWrapper>
				}
			/>
		</>
	);
}

const StatusBar = styled.div`
	padding: 20px 10px 20px 15px;
	color: #000;
	text-align: left;
	font-size: 14px;
	background: #fcf2dc;
	width: 100vw;
	margin-bottom: 20px;
	cursor: pointer;
`;
const ModalWrapper = styled.div`
	width: 90%;
	margin: 0 auto;
	text-align: center;
	h2 {
		text-align: center;
		margin-botton: 0px;
	}
	button {
		margin: 0 auto;
	}
`;

const ModalHeading = styled.div`
	text-align: left;
	padding-left: 50px;
	@media (max-width: 500px) {
		text-align: center;
		padding-left: 0px;
	}
`;
const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 47px;
	margin-top: 50px;
	img {
		height: 40px;
	}
`;
const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;

const UploadFileContainerKYC = styled.div`
	max-width: 600px;
	height: 140px;
	border: 1px solid #d9d9d9;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	svg {
		color: #454545;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 14px;
		color: #454545;
	}
	small {
		font-size: 12px;
		color: #919191;
	}
	input {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		cursor: pointer;
	}
	div {
	}
`;
const UploadLogoContainer = styled.div`
	max-width: 90%;
	height: 140px;
	border: 1px solid #d9d9d9;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		color: #454545;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 14px;
		color: #454545;
	}
	small {
		font-size: 12px;
		color: #919191;
	}
	input {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		cursor: pointer;
	}
	div {
	}
`;
