import React, { useState } from "react";
import Button from "../Constants/FormComponents/Button";
import Main from "../Constants/Main";
import EditIcon from "@mui/icons-material/Edit";
import { TableColumn } from "react-data-table-component";
import PaginatedCards from "../Constants/PaginatedCards";
import { LGTableContainer, SMTBContainer } from "../Invoice/Index";
import Table from "../Constants/Table";
import { v4 as uuidv4 } from "uuid";
import FinanceModal from "./UserRoleModals/FinanceModal";
import AdminModal from "./UserRoleModals/AdminModal";
import SuperAdminModal from "./UserRoleModals/SuperAdminModal";

const columns: TableColumn<any>[] = [
	{
		name: "Role Title",
		selector: row => row?.role_title,
		width: "25%",
		sortable: true,
	},
	{
		name: "Role Creator",
		selector: row => row?.role_creator,
		width: "25%",
		sortable: true,
		center: true,
	},
	{
		name: "Role Type",
		selector: row => row?.role_type,
		center: true,
		width: "25%",
	},
	{
		name: "No. of team members",
		selector: row => row?.no_of_members,
		center: true,
		width: "25%",
	},
];

const roles = [
	{
		role: "super_admin",
		role_title: "Owner",
		role_creator: "Bridger",
		role_type: "Default",
		no_of_members: "1",
	},
	{
		role: "admin",
		role_title: "Operations",
		role_creator: "Bridger",
		role_type: "Default",
		no_of_members: "1",
	},
	{
		role: "finance",
		role_title: "Finance",
		role_creator: "Bridger",
		role_type: "Default",
		no_of_members: "1",
	},
];
export default function TeamMembers() {
	const [financeModal, setFinanceModal] = useState(false);
	const [adminModal, setAdminModal] = useState(false);
	const [superAdminModal, setSuperAdminModal] = useState(false);

	const handleFinanceModal = () => {
		setFinanceModal(!financeModal);
	};
	const handleAdminModal = () => {
		setAdminModal(!adminModal);
	};
	const handleSuperAdminModal = () => {
		setSuperAdminModal(!superAdminModal);
	};

	const viewTeamMember = (row: any) => {
		if (row?.role === "super_admin") {
			handleSuperAdminModal();
		} else if (row?.role === "finance") {
			handleFinanceModal();
		} else if (row?.role === "admin") {
			handleAdminModal();
		}
	};

	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={
					<>
						<Button
							title="Create Custom Role"
							size="md"
							textColor="brand"
							variant="secondary"
							icon={<EditIcon />}
						/>
					</>
				}
				mainBody={
					<>
						<LGTableContainer>
							<Table
								data={roles}
								columns={columns}
								onRowClicked={(row: any) => viewTeamMember(row)}
							/>
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={roles}
								valueOne="role_title"
								valueThree="no_of_members"
								valueFour="role_type"
								valueTwo="role_creator"
								id="role"
							/>
						</SMTBContainer>
					</>
				}
			/>
			<FinanceModal
				handleFinanceModal={handleFinanceModal}
				financeModal={financeModal}
			/>
			<AdminModal handleAdminModal={handleAdminModal} adminModal={adminModal} />

			<SuperAdminModal
				handleSuperAdminModal={handleSuperAdminModal}
				superAdminModal={superAdminModal}
			/>
		</>
	);
}
