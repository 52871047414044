import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

interface Props {
	alertSeverity: any;
	alertTitle: any;
	open: boolean;
	closeModal: any;
}

const AlertComponent: React.FC<Props> = props => {
	/* 
        AlertSeverity could be either success,info, warning,error
	    Closemodal is a function
	    AlertTitle is your message
	    Open is either true or false
    */

	return (
		<Wrapper>
			{props.open && (
				<Collapse in={props.open}>
					<Alert
						severity={props.alertSeverity}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={props.closeModal}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						<AlertTitle>{props.alertTitle}</AlertTitle>
					</Alert>
				</Collapse>
			)}
		</Wrapper>
	);
};

export default AlertComponent;

const Wrapper = styled.div`
	position: fixed;
	z-index: 2;
	top: 0;
	right: 30%;
	@media (min-width: 1200px) {
		width: 300px;
		margin: auto;
	}
	@media (max-width: 576px) {
		top: 50%;
		right: 50%;
		left: 50%;
		z-index: 5;
	}
`;
