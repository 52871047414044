import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/logoTwo.svg";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import axios from "axios";
import Alert from "../Constants/Alert";
import CarouselSlider from "../Constants/Carousel/CarouselSlider";
import Loader from "../../assets/loader.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

export default function NewPassword() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const key = queryParams.get("uid") as string;
	const code = queryParams.get("key") as string;

	const [newPasswords, setNewPasswords] = useState({
		new_password1: "",
		new_password2: "",
	});
	const [modal, setModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [passwordType, setPasswordType] = useState("password");
	const [passwordType2, setPasswordType2] = useState("password");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const details = {
			...newPasswords,
			uid: key,
			token: code,
		};
		setLoading(true);
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/password/reset/confirm/`, details)
			.then(res => {
				if (res.status === 200) {
					setTimeout(() => {
						navigate("/");
					}, 2000);
				}
			})
			.catch(error => {
				setLoading(false);
				setModal(true);
				setErrorMsg(error?.response?.data?.errors[0]?.detail);
			});
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	const togglePassword2 = () => {
		if (passwordType2 === "password") {
			setPasswordType2("text");
		} else {
			setPasswordType2("password");
		}
	};
	const tooglemodal = () => {
		setModal(!modal);
	};
	return (
		<Wrapper>
			<CarouselSlider />
			<SignUpWrapper>
				<SignUpHeader>
					<img src={Logo} alt="Bridger's Logo" />
					<SignUpTitle>Create a new Password</SignUpTitle>
					<SignUpInstruction>
						Please create a new password. Use a password you can easily remember.
					</SignUpInstruction>
				</SignUpHeader>
				<form onSubmit={handleSubmit}>
					<PasswordWrapper>
						Password*
						<Password>
							<PasswordInput
								type={passwordType}
								required
								value={newPasswords.new_password1}
								name="new_password1"
								onChange={e => handleChange(e)}
							/>

							{passwordType === "password" ? (
								<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
							) : (
								<FontAwesomeIcon icon={faEye} onClick={togglePassword} />
							)}
						</Password>
					</PasswordWrapper>

					<PasswordWrapper>
						Confirm Password*
						<Password>
							<PasswordInput
								type={passwordType2}
								required
								value={newPasswords.new_password2}
								name="new_password2"
								onChange={e => handleChange(e)}
							/>

							{passwordType2 === "password" ? (
								<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword2} />
							) : (
								<FontAwesomeIcon icon={faEye} onClick={togglePassword2} />
							)}
						</Password>
					</PasswordWrapper>

					<PasswordStrengthCheckbox>
						<p>Password must:</p>
						<Checkbox>
							<input
								readOnly
								name="upperCase"
								id="upperCase"
								type="checkbox"
								checked={/[A-Z]/.test(newPasswords.new_password1)}
							/>{" "}
							<label>Contain an uppercase letter.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="lowerCase"
								id="lowerCase"
								type="checkbox"
								checked={/[a-z]/.test(newPasswords.new_password1)}
							/>{" "}
							<label>Contain a lowercase letter.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="numberCha"
								id="numberCha"
								type="checkbox"
								checked={/[0-9]/.test(newPasswords.new_password1)}
							/>{" "}
							<label>Contain a number.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="specialCha"
								id="specialCha"
								type="checkbox"
								checked={/[!@#$%^&*]/.test(newPasswords.new_password1)}
							/>{" "}
							<label>Contain a special character.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="eightCha"
								id="eightCha"
								type="checkbox"
								checked={newPasswords.new_password1.length >= 8}
							/>{" "}
							<label>Contains more than 8 characters.</label>
						</Checkbox>
					</PasswordStrengthCheckbox>

					{loading ? (
						<Button>
							<img src={Loader} alt="loader gif" />
						</Button>
					) : (
						<Button>Continue</Button>
					)}
				</form>
			</SignUpWrapper>
			<Alert
				alertSeverity={"error"}
				open={modal}
				alertTitle={errorMsg || "Email address provided not valid"}
				closeModal={tooglemodal}
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	overflow: hidden;
	@media (max-width: 600px) {
		display: flex;
		justify-content: center;
		padding-top: 100px;
		gap: 0px;
	}
`;
const SignUpWrapper = styled.div`
	padding-left: 58vw;
	padding-top: 100px;
	span {
		display: flex;
		justify-content: space-around;
		padding-top: 15px;
	}
	@media (max-width: 600px) {
		width: 90vw;
		padding: 50px 0 20px 0;
	}
`;
const SignUpHeader = styled.div``;
const SignUpTitle = styled.h2`
	font-size: 24px;
`;
const SignUpInstruction = styled.p`
	font-size: 14px;
	max-width: 340px;
	text-align: center;
	margin: 0 auto;
	padding-bottom: 20px;
`;

const Button = styled.button`
	width: 34vw;
	height: 40px;
	background: #302361;
	color: #fff;
	border: none;
	border-radius: 2px;
	margin-top: 30px;
	margin-bottom: 20px;
	cursor: pointer;
	img {
		height: 40px;
	}
	@media (max-width: 600px) {
		width: 319px;
		margin: 0 auto;
	}
`;
const PasswordWrapper = styled.label`
	display: flex;
	flex-direction: column;
	jusify-content: center;
	max-width: 476px;
	text-align: left;
	font-size: 14px;
	padding-top: 25px;
	margin: 0 auto;
	@media (max-width: 600px) {
		max-width: 319px;
	}
`;
const Password = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	padding: 0 10px;
	height: 40px;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const PasswordInput = styled.input`
	top: 26.146087646484375px;
	background: none;
	border: none;
	:focus {
		outline: none;
	}
`;
const PasswordStrengthCheckbox = styled.div`
	padding: 30px 0;
	p {
		text-align: left;
		font-size: 12px;
	}
`;

const Checkbox = styled.div`
	text-align: left;
	font-weight: 400;
	font-size: 12px;
	padding: 3px 0;
	position: relative;

	input {
		margin-right: 10px;
		height: 12px;
		width: 12px;
	}
`;
