import React, { useState } from "react";
import styled from "styled-components";
import Input from "../Constants/FormComponents/Input";
import Logo from "../../assets/logoTwo.svg";
import { Link, useNavigate } from "react-router-dom";
import CarouselSlider from "../Constants/Carousel/CarouselSlider";
import axios from "axios";
import * as constants from "../Constants/api";
import Loader from "../../assets/loader.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

export default function SignUp() {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const getkey = queryParams.get("invite") as string;
	const invitedBy = {
		invite: getkey || "",
	};
	const getReferral = queryParams.get("referral") as string;

	const [signUpDetails, setSignUpDetails] = useState({
		first_name: "",
		last_name: "",
		company_name: "",
		email: "",
		phone: "",
		referral_code: getReferral,
		password1: "",
	});
	const [loading, setLoading] = useState(false);
	const [passwordType, setPasswordType] = useState("password");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSignUpDetails({ ...signUpDetails, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
		localStorage.setItem("email", signUpDetails.email);
		const details = {
			...signUpDetails,
			password2: signUpDetails.password1,
			invited_by: invitedBy.invite,
		};
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/register/`, details)
			.then(res => {
				if (res.status === 201) {
					navigate("/email-notification");
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	return (
		<Wrapper>
			<CarouselSlider />
			<SignUpWrapper>
				<SignUpHeader>
					<img src={Logo} alt="Bridger's Logo" />
					<SignUpTitle>Sign up to Bridger!</SignUpTitle>
					<SignUpInstruction>
						Join hundreds of supply-chain businesses who manage their businesses
						online.
					</SignUpInstruction>
				</SignUpHeader>
				<form onSubmit={handleSubmit}>
					<Input
						inputType="text"
						value={signUpDetails.first_name}
						title="First Name"
						required
						name="first_name"
						placeHolder="John"
						onChange={handleChange}
						size="full"
					/>
					<Input
						inputType="text"
						title="Last Name"
						required
						value={signUpDetails.last_name}
						name="last_name"
						placeHolder="Doe"
						onChange={handleChange}
						size="full"
					/>
					<Input
						inputType="text"
						title="Company Name"
						required
						value={signUpDetails.company_name}
						name="company_name"
						placeHolder="Bridger Africa"
						onChange={handleChange}
						size="full"
					/>
					<Input
						inputType="email"
						title="Email Address"
						required
						value={signUpDetails.email}
						name="email"
						placeHolder="abc@gmail.com"
						onChange={handleChange}
						size="full"
					/>
					<Input
						inputType="tel"
						title="Phone Number"
						required
						value={signUpDetails.phone}
						name="phone"
						placeHolder="+2348012345678"
						onChange={handleChange}
						size="full"
					/>

					<Input
						inputType="text"
						title="Do you have a referral code? (Optional)"
						required={false}
						value={signUpDetails.referral_code}
						name="referral_code"
						placeHolder="qwe123"
						onChange={handleChange}
						size="full"
					/>
					<Password>
						<Input
							inputType={passwordType}
							title="Password"
							required
							value={signUpDetails.password1}
							name="password1"
							placeHolder=""
							onChange={e => handleChange(e)}
							size="full"
						/>

						<PasswordIcon>
							{passwordType === "password" ? (
								<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
							) : (
								<FontAwesomeIcon icon={faEye} onClick={togglePassword} />
							)}
						</PasswordIcon>
					</Password>

					<PasswordStrengthCheckbox>
						<p>Password must:</p>
						<Checkbox>
							<input
								readOnly
								name="upperCase"
								id="upperCase"
								type="checkbox"
								checked={/[A-Z]/.test(signUpDetails.password1)}
							/>{" "}
							<label>Contain an uppercase letter.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="lowerCase"
								id="lowerCase"
								type="checkbox"
								checked={/[a-z]/.test(signUpDetails.password1)}
							/>{" "}
							<label>Contain a lowercase letter.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="numberCha"
								id="numberCha"
								type="checkbox"
								checked={/[0-9]/.test(signUpDetails.password1)}
							/>{" "}
							<label>Contain a number.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="specialCha"
								id="specialCha"
								type="checkbox"
								checked={/[!@#$%^&*]/.test(signUpDetails.password1)}
							/>{" "}
							<label>Contain a special character.</label>
						</Checkbox>
						<Checkbox>
							<input
								readOnly
								name="eightCha"
								id="eightCha"
								type="checkbox"
								checked={signUpDetails.password1.length >= 8}
							/>{" "}
							<label>Contains more than 8 characters.</label>
						</Checkbox>
					</PasswordStrengthCheckbox>

					<Remember>
						<input type="checkbox" id="remember_me" name="remember_me" />
						<label htmlFor="remember_me">Remember Me</label>
					</Remember>

					<Click>
						By clicking the “Sign Up” button, you agree to Bridger’s Terms of Use and
						Privacy Policy.
					</Click>

					{loading ? (
						<Button>
							<img src={Loader} alt="loader gif" />
						</Button>
					) : (
						<Button>Sign Up</Button>
					)}
				</form>

				<Login>
					Already have an account? <Link to="/">Log In</Link>
				</Login>
			</SignUpWrapper>
			<ToastContainer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 70px;
	overflow: hidden;
	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding-top: 100px;
		gap: 0px;
	}
`;

const SignUpWrapper = styled.div`
	padding-left: 58vw;
	padding-top: 100px;
	span {
		display: flex;
		justify-content: space-around;
		padding-top: 15px;
	}
	@media (max-width: 768px) {
		width: 90vw;
		padding: 20px 0;
	}
`;

const SignUpHeader = styled.div``;

const SignUpTitle = styled.h2`
	font-size: 24px;
`;

const SignUpInstruction = styled.p`
	font-size: 14px;
	max-width: 340px;
	text-align: center;
	margin: 0 auto;
	padding-bottom: 20px;
`;

const Remember = styled.div`
	display: flex;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 12px;
	input {
		margin-right: 10px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
	}
`;

const Button = styled.button`
	width: 34vw;
	height: 40px;
	background: #302361;
	color: #fff;
	border: none;
	border-radius: 2px;
	margin-top: 30px;
	margin-bottom: 20px;
	cursor: pointer;
	img {
		height: 40px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
	}
`;

const Login = styled.div`
	padding-top: 15px;
	text-align: center;
	font-size: 14px;
	padding-bottom: 50px;
	a {
		color: #302361;
	}
`;

const PasswordStrengthCheckbox = styled.div`
	width: 32vw;

	padding: 30px 0;
	p {
		text-align: left;
		font-size: 12px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
	}
`;

const Checkbox = styled.div`
	text-align: left;
	font-weight: 400;
	font-size: 12px;
	padding: 3px 0;
	position: relative;

	input {
		margin-right: 10px;
		height: 12px;
		width: 12px;
	}
`;
const Click = styled.div`
	padding-top: 30px;
	max-width: 100%;
	text-align: left;
	line-height: 20px;
	font-size: 12px;
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
		padding-bottom: 10px;
	}
`;

const Password = styled.div``;

const PasswordIcon = styled.div`
	position: relative;
	top: -32px;
	left: 45%;
`;
