import React, { useState } from "react";
import styled from "styled-components";
import Input from "../Constants/FormComponents/Input";
import Logo from "../../assets/logoTwo.svg";
import axios from "axios";
import * as constants from "../Constants/api";
import { useNavigate } from "react-router-dom";
import CarouselSlider from "../Constants/Carousel/CarouselSlider";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../assets/loader.gif";

export default function ForgotPassword() {
	const navigate = useNavigate();
	const [emailAddress, setEmailAddress] = useState({ email: "" });
	const [loading, setLoading] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmailAddress({ email: e.target.value });
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/password/reset/`, emailAddress)
			.then(res => {
				if (res.status === 200) {
					navigate("/");
					toast.success("A reset password link has been sent to your mail");
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<Wrapper>
			<CarouselSlider />
			<LoginWrapper>
				<LoginHeader>
					<img src={Logo} alt="Bridger's Logo" />
					<Title>Forgot Password</Title>
					<LoginInstruction>
						Kindly enter your email address to reset your password.
					</LoginInstruction>
				</LoginHeader>
				<form onSubmit={handleSubmit}>
					<Input
						inputType="email"
						title="Email Address"
						required={true}
						value={emailAddress.email}
						name="email"
						placeHolder="abc@gmail.com"
						onChange={e => handleChange(e)}
						size="lg"
					/>

					{loading ? (
						<Button>
							<img src={Loader} alt="loader gif" />
						</Button>
					) : (
						<Button>Continue</Button>
					)}
				</form>
			</LoginWrapper>
			<ToastContainer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 70px;
	overflow: hidden;
	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding-top: 100px;
		gap: 0px;
	}
`;
const LoginWrapper = styled.div`
	padding-left: 58vw;
	padding-top: 100px;
	span {
		display: flex;
		justify-content: space-between;
		padding-top: 15px;
	}
	@media (max-width: 768px) {
		width: 90vw;
		padding: 20px 0;
	}
`;
const LoginHeader = styled.div``;
const Title = styled.h2`
	font-size: 24px;
	padding-bottom: 10px;
`;
const LoginInstruction = styled.p`
	font-size: 14px;
	padding-bottom: 15px;
`;

const Button = styled.button`
	width: 34vw;
	height: 40px;
	background: #302361;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border: none;
	border-radius: 2px;
	margin-top: 50px;
	margin-bottom: 20px;
	cursor: pointer;
	img {
		height: 40px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
		margin-top: 30px;
	}
`;
