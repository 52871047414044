import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import Input from "../../Constants/FormComponents/Input";
import OTP from "../../Constants/OTP";
import { ToastContainer } from "react-toastify";
import {
	PageBody,
	PageHeader,
	PageInstruction,
	PageTitle,
	PageWord,
	PageToggle,
	PreferencesSection,
} from "./Index";
import Button from "../../Constants/FormComponents/Button";
import Modal from "../../Constants/ModalComponent";
import { CreateOptionsContainer } from "../../Invoice/Index";

const Notifications = () => {
	const [secModal, setSecModal] = useState(false);
	const [transactionPinModal, setTransactionPinModal] = useState(false);
	const [otp, setOtp] = useState("");
	const handleSecModal = () => {
		setSecModal(!secModal);
	};

	const toggleOtpModal = () => {
		setTransactionPinModal(!transactionPinModal);
	};

	const onChange = (value: string) => {
		setOtp(value);
	};
	return (
		<>
			<PageHeader>
				<span>
					<PageTitle>Notifications</PageTitle>
					<PageInstruction>Receive the latest news and updates.</PageInstruction>
				</span>
			</PageHeader>

			<PageBody>
				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>News and Updates</h4>{" "}
						<p>
							Get business and financial news with deep research and intelligance
							reports.
						</p>
					</PageWord>
					<PageToggle>
						<span>
							<FontAwesomeIcon className="icon" icon={faToggleOff} size="lg" /> Email
							Notifications{" "}
						</span>{" "}
						<span>
							{" "}
							<FontAwesomeIcon className="icon" icon={faToggleOn} size="lg" /> Push
							(In-App) Notifications{" "}
						</span>
					</PageToggle>
				</PreferencesSection>

				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>Product Updates</h4>{" "}
						<p>Be the first to know about updates on the Bridger platform.</p>
					</PageWord>
					<PageToggle>
						<span>
							<FontAwesomeIcon className="icon" icon={faToggleOff} size="lg" /> Email
							Notifications{" "}
						</span>{" "}
						<span>
							{" "}
							<FontAwesomeIcon className="icon" icon={faToggleOn} size="lg" /> Push
							(In-App) Notifications{" "}
						</span>
					</PageToggle>
				</PreferencesSection>

				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>Reminders</h4>{" "}
						<p>Notifications to remind you of updates you might have missed.</p>
					</PageWord>
					<PageToggle>
						<span>
							<FontAwesomeIcon className="icon" icon={faToggleOff} size="lg" /> Email
							Notifications{" "}
						</span>{" "}
						<span>
							{" "}
							<FontAwesomeIcon className="icon" icon={faToggleOn} size="lg" /> Push
							(In-App) Notifications{" "}
						</span>
					</PageToggle>
				</PreferencesSection>
				<SecMailContainer>
					<Button
						variant="secondary"
						textColor="primary"
						size="md"
						title="Add Secondary Email"
						icon={<AddIcon />}
						onClick={handleSecModal}
					/>
					<p>
						Having a secondary email means that you can receive news, updates and
						other email notifications in your Primary and Secondary Email Addresses
					</p>
				</SecMailContainer>
				{/* 
				<ButtonGroup>
					<ButtonOutlined>Cancel</ButtonOutlined>{" "}
					<ButtonFilled>Save Changes</ButtonFilled>
				</ButtonGroup> */}
			</PageBody>
			<Modal
				toggleModal={handleSecModal}
				heading="Add Secondary Email"
				modal={secModal}
				subHeading={
					<>
						<ToastContainer />
					</>
				}
				size="md"
				body={
					<>
						<CreateOptionsContainer>
							<Input
								size="full"
								inputType="email"
								name="secondary_email"
								title="Email Address"
								required
							/>
							<div style={{ marginTop: "15px" }}>
								<Button
									size="full"
									title="Continue"
									variant="default"
									textColor="normal"
									onClick={toggleOtpModal}
								/>
							</div>
						</CreateOptionsContainer>
					</>
				}
			/>
			<Modal
				toggleModal={toggleOtpModal}
				heading="Transaction PIN"
				size="md"
				subHeading={
					<>
						<ToastContainer />
						"Enter your 4-digit Transaction PIN"
					</>
				}
				modal={transactionPinModal}
				body={
					<CreateOptionsContainer>
						<OTP value={otp} valueLength={4} onChange={onChange} />
						<div style={{ marginTop: "15px" }}>
							<Button
								size="full"
								title="Continue"
								variant="default"
								textColor="normal"
							/>
						</div>
					</CreateOptionsContainer>
				}
			/>
		</>
	);
};

const SecMailContainer = styled.div`
	margin-top: 15px;
	width: 352px;
	p {
		margin-top: 24px;
		text-align: left;
		font-weight: 400;
		font-size: 14px;
	}
`;

export default Notifications;
