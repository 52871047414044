import React from "react";
import Main from "../Constants/Main";

const Index: React.FC = () => {
	return (
		<>
			<Main
				tabName="Profile"
				rightSubHeader={<>Hello World</>}
				mainBody={<>This is the main Body of each Page</>}
			/>
		</>
	);
};

export default Index;
