import { useState, useEffect } from "react";
import Button from "../Constants/FormComponents/Button";
import Main from "../Constants/Main";
import EditIcon from "@mui/icons-material/Edit";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableColumn } from "react-data-table-component";
import Status from "../Constants/Status";
import EmptyImg from "../../assets/empty.png";
import PaginatedCards from "../Constants/PaginatedCards";
import {
	EmptyData,
	LGTableContainer,
	LeftTableToggle,
	RightTableToggle,
	SMTBContainer,
	TableToggleContainer,
} from "../Invoice/Index";
import Table from "../Constants/Table";
import { httprequest } from "../Constants/api";
import { v4 as uuidv4 } from "uuid";
import FinanceModal from "./UserRoleModals/FinanceModal";
import AdminModal from "./UserRoleModals/AdminModal";
import SuperAdminModal from "./UserRoleModals/SuperAdminModal";
import InviteModal from "./UserRoleModals/InviteModal";
import { useNavigate } from "react-router-dom";
import Actions from "./UserRoleModals/Actions";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";

export enum ActionType {
	"Resend" = "resend",
	"Activate" = "activate",
	"Deactivate" = "deactivate",
	"Delete" = "delete",
}

const columns: TableColumn<any>[] = [
	{
		name: "Name",
		selector: row => row?.name,
		width: "17%",
		sortable: true,
	},
	{
		name: "Email Address",
		selector: row => row?.email,
		width: "18%",
		sortable: true,
		center: true,
	},
	{
		name: "Role",
		selector: row => row?.role,
		width: "10%",
	},

	{
		name: "Status",
		cell: (row: { status: string }) => <Status statusState={row?.status} />,
		center: true,
		width: "20%",
	},

	{
		name: "Action",
		selector: row => row?.action,
		width: "20%",
	},
	{
		name: " ",
		selector: row => row?.icon,
		width: "20%",
	},
];

interface ActionState {
	activeTeam: any;
	actionType: ActionType | null;
	show: boolean;
	loading: boolean;
}

export default function TeamMembers() {
	const [teamList, setTeamList] = useState<any>([]);
	const [allTeamList, setAllTeamList] = useState<any>([]);
	const [invitedTeamList, setInvitedTeamList] = useState<any>([]);

	const [toggleState, setToggleState] = useState("all");
	const [allTeamMemberCount, setAllTeamMemberCount] = useState<Number>(0);
	const [invitedTeamMemberCount, setInvitedTeamMemberCount] =
		useState<Number>(0);

	const [financeModal, setFinanceModal] = useState(false);
	const [adminModal, setAdminModal] = useState(false);
	const [inviteModal, setInviteModal] = useState(false);

	const [superAdminModal, setSuperAdminModal] = useState(false);

	const [actionState, setActionState] = useState<ActionState>({
		activeTeam: null,
		actionType: null,
		show: false,
		loading: false,
	});

	const handleChangeActionState = (partialState: Partial<ActionState>) => {
		setActionState({ ...actionState, ...partialState });
	};

	const navigate = useNavigate();

	const manageRole = () => {
		navigate("/settings/team-members/roles");
	};
	const getTeammembersList = () => {
		httprequest
			.get("accounts/team/member/")
			.then(response =>
				setAllTeamList(
					response?.data?.results?.map((team: any) => {
						return {
							name: `${team?.first_name} ${team?.last_name}`,
							email: team?.email,
							role: team?.role,
							status: <Status statusState={team?.status} />,
							action: (
								<div
									onClick={() =>
										handleChangeActionState({
											activeTeam: team,
											show: true,
											actionType:
												team?.status === "invite_sent"
													? ActionType.Resend
													: team?.status === "deactivated"
													? ActionType.Activate
													: team?.status === "active"
													? ActionType.Deactivate
													: null,
										})
									}
								>
									{team?.status === "invite_sent"
										? "Resend Invite"
										: team?.status === "deactivated"
										? "Reactivate"
										: team?.status === "active"
										? "Deactivate"
										: ""}
								</div>
							),
							icon: (
								<DeleteIcon
									onClick={() => {
										handleChangeActionState({
											activeTeam: team,
											show: true,
											actionType: ActionType.Delete,
										});
									}}
									style={{ opacity: 0.5 }}
								/>
							),
							uid: team?.uid,
							toggleStatus: team?.status,
						};
					})
				)
			)
			.catch(error => {
				if (error?.response?.status === 401) {
					navigate("/");
				}
				if (error?.response?.status === 403) {
					toast.error(
						"You do not have permission to view the information on this page"
					);
				} else {
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				}
			});
		httprequest
			.get("accounts/team/member/invite/")
			.then(response =>
				setInvitedTeamList(
					response?.data?.results?.map((team: any) => {
						return {
							name: `${team?.first_name} ${team?.last_name}`,
							email: team?.email,
							role: team?.role,
							status: <Status statusState={team?.status} />,
							action: (
								<div
									onClick={() =>
										handleChangeActionState({
											activeTeam: team,
											show: true,
											actionType:
												team?.status === "invite_sent"
													? ActionType.Resend
													: team?.status === "deactivated"
													? ActionType.Activate
													: team?.status === "active"
													? ActionType.Deactivate
													: null,
										})
									}
								>
									{team?.status === "invite_sent"
										? "Resend Invite"
										: team?.status === "deactivated"
										? "Reactivate"
										: team?.status === "active"
										? "Deactivate"
										: ""}
								</div>
							),
							icon: (
								<DeleteIcon
									onClick={() => {
										handleChangeActionState({
											activeTeam: team,
											show: true,
											actionType: ActionType.Delete,
										});
									}}
									style={{ opacity: 0.5 }}
								/>
							),
							uid: team?.uid,
							toggleStatus: team?.status,
						};
					})
				)
			)
			.catch(error => {
				if (error?.response?.status === 401) {
					navigate("/");
				}
				if (error?.response?.status === 403) {
					toast.error(
						"You do not have permission to view the information on this page"
					);
				} else {
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				}
			});
	};

	const handleFinanceModal = () => {
		setFinanceModal(!financeModal);
	};
	const handleAdminModal = () => {
		setAdminModal(!adminModal);
	};
	const handleSuperAdminModal = () => {
		setSuperAdminModal(!superAdminModal);
	};
	const handleInviteModal = () => {
		setInviteModal(!inviteModal);
	};

	const viewTeamMember = (row: any) => {
		if (row?.role === "super_admin") {
			handleSuperAdminModal();
		} else if (row?.role === "finance") {
			handleFinanceModal();
		} else if (row?.role === "admin") {
			handleAdminModal();
		}
	};

	const handleResend = (team: any) => {
		handleChangeActionState({ loading: true });
		constants.httprequest
			.put(`/accounts/team/member/${team.uid}/resend-invite/`)
			.then(() => {
				toast.success("Team member reinvited successfully");
				setTimeout(() => {
					handleChangeActionState({
						loading: false,
						show: false,
						actionType: null,
						activeTeam: null,
					});
					getTeammembersList();
				}, 2000);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);

				handleChangeActionState({ loading: false });
			});
	};

	const handleActivate = (team: any) => {
		const detail = {
			is_active: true,
			role: team.role,
		};
		handleChangeActionState({ loading: true });
		constants.httprequest
			.put(`/accounts/team/member/${team.uid}/`, detail)
			.then(() => {
				toast.success("Team member activated successfully");
				setTimeout(() => {
					handleChangeActionState({
						loading: false,
						show: false,
						actionType: null,
						activeTeam: null,
					});
					getTeammembersList();
				}, 2000);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				handleChangeActionState({ loading: false });
			});
	};

	const handleDeactivate = (team: any) => {
		const detail = {
			is_active: false,
			role: team.role,
		};
		handleChangeActionState({ loading: true });
		constants.httprequest
			.put(`/accounts/team/member/${team.uid}/`, detail)
			.then(() => {
				toast.success("Team member deactivated successfully");
				setTimeout(() => {
					handleChangeActionState({
						loading: false,
						show: false,
						actionType: null,
						activeTeam: null,
					});
					getTeammembersList();
				}, 2000);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				handleChangeActionState({ loading: false });
			});
	};

	const handleDelete = (team: any) => {
		handleChangeActionState({ loading: true });
		constants.httprequest
			.delete(`/accounts/team/member/${team.uid}/`)
			.then(() => {
				toast.success("Team member deleted successfully");
				setTimeout(() => {
					handleChangeActionState({
						loading: false,
						show: false,
						actionType: null,
						activeTeam: null,
					});
					getTeammembersList();
				}, 2000);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				handleChangeActionState({ loading: false });
			});
	};

	useEffect(() => {
		getTeammembersList();
	}, []);

	useEffect(() => {
		setTeamList(allTeamList);

		setAllTeamMemberCount(allTeamList?.length);

		setInvitedTeamMemberCount(invitedTeamList?.length);
	}, [allTeamList]);

	const handleAllTeamMembers = () => {
		setTeamList(allTeamList);
		setAllTeamMemberCount(allTeamList?.length);
		setToggleState("all");
	};
	const handleInvited = () => {
		setTeamList(invitedTeamList);
		setInvitedTeamMemberCount(invitedTeamList?.length);
		setToggleState("invited");
	};
	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={
					<>
						<Button
							title="Sort"
							size="bt"
							textColor="brand"
							variant="secondary"
							icon={<UnfoldMoreIcon />}
						/>

						<Button
							title="Manage Roles"
							size="md"
							textColor="brand"
							variant="secondary"
							icon={<EditIcon />}
							onClick={manageRole}
						/>
						<Button
							title="Invite Teammate"
							size="md"
							textColor="normal"
							variant="default"
							icon={<PersonAddAlt1Icon />}
							onClick={handleInviteModal}
						/>
					</>
				}
				mainBody={
					<>
						<TableToggleContainer>
							<LeftTableToggle>
								<div
									onClick={handleAllTeamMembers}
									style={{
										borderBottom:
											toggleState === "all" ? "1px solid #302361" : "transparent",
										color: toggleState === "all" ? "#302361" : "#6D6D6D",
									}}
								>
									{`All Team Members (${allTeamMemberCount})`}
								</div>
								<div
									onClick={handleInvited}
									style={{
										borderBottom:
											toggleState === "invited" ? "1px solid #302361" : "transparent",
										color: toggleState === "invited" ? "#302361" : "#6D6D6D",
									}}
								>
									{`Invited Team Members (${invitedTeamMemberCount})`}
								</div>
							</LeftTableToggle>
							<RightTableToggle></RightTableToggle>
						</TableToggleContainer>
						<LGTableContainer>
							<Table
								data={teamList}
								columns={columns}
								pagination
								paginationPerPage={10}
								onRowClicked={(row: any) => viewTeamMember(row)}
							/>
							{!teamList?.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no team members. Click on the button below to
										invite one.
									</p>
									<Button
										title="Invite Teammate"
										size="md"
										textColor="normal"
										variant="default"
										icon={<PersonAddAlt1Icon />}
										onClick={handleInviteModal}
									/>
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={teamList}
								valueOne="name"
								valueThree="action"
								valueFour="role"
								valueTwo="email"
								id="uid"
							/>
						</SMTBContainer>
					</>
				}
			/>
			<FinanceModal
				handleFinanceModal={handleFinanceModal}
				financeModal={financeModal}
			/>
			<AdminModal handleAdminModal={handleAdminModal} adminModal={adminModal} />

			<SuperAdminModal
				handleSuperAdminModal={handleSuperAdminModal}
				superAdminModal={superAdminModal}
			/>
			<InviteModal
				handleInviteModal={handleInviteModal}
				inviteModal={inviteModal}
				setInviteModal={setInviteModal}
				getTeammembersList={getTeammembersList}
			/>
			{actionState.show && (
				<Actions
					loading={actionState.loading}
					actionType={actionState.actionType}
					team={actionState.activeTeam}
					handleClose={() =>
						handleChangeActionState({
							show: false,
							actionType: null,
							activeTeam: null,
						})
					}
					show={actionState.show}
					action={{
						resend: handleResend,
						activate: handleActivate,
						deactivate: handleDeactivate,
						delete: handleDelete,
					}}
				/>
			)}

			<ToastContainer />
		</>
	);
}
