import styled from "styled-components";

// Pass props(inputType,onChange,required,placeHolder,title,size,name,value)
// required should be either true or false
// input Sizing is lg, md, sm, full

interface Props {
	inputType?: string;
	title?: any;
	required?: boolean;
	value?: any;
	name?: string;
	placeHolder?: string;
	size: any;
	min?: any;
	max?: any;
	pattern?: any;
	onInput?: any;
	onKeyPress?: any;
	isDisabled?: boolean;
	children?: any;
	/* eslint-disable-next-line no-unused-vars */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputPrepend: React.FC<Props> = ({
	inputType,
	title,
	required,
	value,
	name,
	placeHolder,
	size,
	min,
	max,
	pattern,
	onKeyPress,
	onInput,
	children,
	onChange,
	isDisabled,
}: Props) => {
	return (
		<InputWrapper size={size}>
			<label>
				{title} {required ? <span>*</span> : null}{" "}
			</label>
			<PrependWrapper size={size}>
				<div>{children}</div>
				<StyledInput
					size={size}
					min={min}
					max={max}
					type={inputType}
					required={required}
					value={value}
					name={name}
					onChange={onChange}
					placeholder={placeHolder}
					pattern={pattern}
					onKeyPress={onKeyPress}
					onInput={onInput}
					disabled={isDisabled}
				/>
			</PrependWrapper>
		</InputWrapper>
	);
};

export const PrependWrapper = styled.div<Props>`
	display: flex;
	align-items: center;
	wrap: no-wrap;
	width: 100%;
	div {
		width: 35%;
		height: ${({ size }) =>
			size === "lg"
				? "49px"
				: size === "md"
				? "45px"
				: size === "sm"
				? "45px"
				: size === "full"
				? "47px"
				: ""};
	}
	select {
		width: 100%;
		height: 100%;
		margin-top: 5px !important;
		margin-bottom: 0px !important;
		border: 1px solid #d9d9d9;
		border-right: transparent;
		padding-left: 3px;
		padding-right: 3px;
		box-sizing: border-box;
		img {
			width: 15px;
			height: 15px;
		}
	}
`;

const InputWrapper = styled.div<Props>`
	display: flex;
	flex-direction: column;
	width: ${({ size }) =>
		size === "lg"
			? "460px"
			: size === "md"
			? "360px"
			: size === "sm"
			? "230px"
			: size === "full"
			? "100%"
			: ""};

	text-align: left;
	font-size: 14px;
	padding-top: 25px;

	@media (max-width: 576px) {
		width: ${({ size }) =>
			size === "lg" || size === "md" || size === "sm"
				? "320px"
				: size === "full"
				? "100%"
				: ""};
	}
	@media (max-width: 426px) {
		width: 90%;
		padding-top: 15px;
		margin: 0 auto;
	}
	span {
		color: tomato;
	}
	label {
		display: flex;
		align-items: center;
		gap: 5px;
	}
`;

const StyledInput = styled.input<Props>`
	top: 26px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	background: none;
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	align-items: center;
	box-sizing: border-box;
	width: 65%;
	height: ${({ size }) =>
		size === "lg"
			? "50px"
			: size === "md"
			? "46px"
			: size === "sm"
			? "46px"
			: size === "full"
			? "48px"
			: ""};
	@media (max-width: 768px) {
		height: 40px;
		width: 75%;
		margin: 0 auto;
		margin-top: 10px;
	}
`;

export default InputPrepend;
