import { useState, useContext } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context/index";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Button from "../Constants/FormComponents/Button";
import {
	CreateOptionsContainer,
	CreateOptionsCard,
	CheckedCard,
} from "./Index";

export default function CreateModal() {
	const { handleCreateInvoiceModal, createInvoiceModal } =
		useContext(AppContext);
	const [addState, setAddState] = useState("");
	const navigate = useNavigate();
	const handleManualAdd = () => {
		setAddState("manual");
	};
	// const handleImportAdd = () => {
	// 	setAddState("import");
	// };
	const handleCreateButton = () => {
		if (addState === "manual") {
			navigate("/invoice/create");
			handleCreateInvoiceModal();
		}
	};
	return (
		<Modal
			toggleModal={handleCreateInvoiceModal}
			heading="Create Invoice"
			subHeading="Please select your preferred method"
			modal={createInvoiceModal}
			size="lg"
			body={
				<>
					<CreateOptionsContainer>
						<CreateOptionsCard onClick={handleManualAdd}>
							<div>
								<CreateOutlinedIcon />
							</div>
							<div>
								<h4>Add Manually</h4>
								<p>Manually create an invoice by inputing the details.</p>
							</div>
							<CheckedCard>
								{addState === "manual" ? <CheckCircleIcon /> : <CircleOutlined />}
							</CheckedCard>
						</CreateOptionsCard>
						{/* Comment out import invoice option */}
						{/* <CreateOptionsCard onClick={handleImportAdd}>
							<div>
								<FileDownloadOutlinedIcon />
							</div>
							<div>
								<h4>Import Invoice</h4>
								<p>Import an already created invoice</p>
							</div>
							<CheckedCard>
								{addState === "import" ? <CheckCircleIcon /> : <CircleOutlined />}
							</CheckedCard>
						</CreateOptionsCard> */}
						{addState === "manual" ? (
							<Button
								title="Continue"
								onClick={handleCreateButton}
								size="full"
								textColor="normal"
								variant="default"
							/>
						) : null}
					</CreateOptionsContainer>
				</>
			}
		/>
	);
}
