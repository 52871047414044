import styled from "styled-components";
type Props = {
	name: string;
	account_number: string;
	bank_name: string;

	onDelete: any;
};
export default function Beneficiary({
	name,
	account_number,
	bank_name,

	onDelete,
}: Props) {
	return (
		<BeneficiaryDetail>
			<Initials>{name?.slice(0, 2)?.toUpperCase()}</Initials>{" "}
			<Detail>
				<h4>{name}</h4>{" "}
				<p>
					{account_number} | {bank_name}
				</p>
			</Detail>{" "}
			<Delete onClick={onDelete}>Delete</Delete>
		</BeneficiaryDetail>
	);
}

const BeneficiaryDetail = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
`;

const Initials = styled.h3`
	background-color: var(--mainBlue);
	color: #fff;
	padding: 10px;
	border-radius: 50%;
	margin: 5px 10px 0 0;
`;

const Detail = styled.div`
	text-align: left;
	margin-right: 25px;
	min-width: 220px;
	h4 {
		margin-bottom: 0;
		font-weight: 500;
		font-size: 14px;
	}
	p {
		margin-top: 6px;
		font-weight: 400;
		font-size: 12px;
		color: #b5b5b5;
	}
`;
const Delete = styled.div`
	color: #cd0a0a;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #cd0a0a;
	cursor: pointer;
`;
