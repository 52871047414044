import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Main from "../Constants/Main";
import View from "./View";
import KYCStatusBar from "./KYCStatusBar";
import BVNVerification from "./BVNVerification";
import { httprequest } from "../Constants/api";
import { useTruncate } from "../../hooks/useTruncate";
import { ToastContainer, toast } from "react-toastify";
import { AppContext } from "../../Context";
import ModalComponent from "../Constants/ModalComponent";
import Button from "../Constants/FormComponents/Button";
import OTP from "../Constants/OTP";

const Index: React.FC = () => {
	// const navigate = useNavigate();

	const { wallet, userProfile } = useContext(AppContext);
	const [walletInfoModal, setWalletInfoModal] = useState(false);
	const [transactionPinModal, setTransactionPinModal] = useState(false);
	const [otp, setOtp] = useState("");

	const [userDetails, setUserDetails] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		phone_number_verified: false,
		selfie_image: "",
		profile_image: "",
		role: "",
		is_set_pin: false,
	});

	const [profileDetails, setProfileDetails] = useState({
		nationality: "",
		gender: "",
		birth_date: "",
		bvn: " ",
		bvn_verified: false,
		bvn_verification_failed: false,
		bank_name: "",
		account_number: "",
		id_type: "",
		id_image: "",
		id_number: "",
	});

	const handleWalletInfoModal = () => {
		setWalletInfoModal(!walletInfoModal);
	};
	const handleTransactionPinModal = () => {
		setTransactionPinModal(!transactionPinModal);
	};
	const onChange = (value: string) => setOtp(value);

	const getDetails = () => {
		httprequest
			.get("/accounts/user")
			.then(response => {
				setUserDetails({
					...userDetails,
					first_name: response?.data?.first_name,
					last_name: response?.data?.last_name,
					email: response?.data?.email,
					phone: response?.data?.phone,
					phone_number_verified: response.data?.phone_number_verified,
					role: response?.data?.role,
					is_set_pin: response?.data?.is_set_pin,
				});
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});

		httprequest
			.get("/accounts/user/profile")
			.then(response => {
				setProfileDetails({
					nationality: response.data?.nationality,
					gender: response.data?.gender,
					birth_date: response.data?.birth_date,
					bvn: response.data?.bvn,
					bvn_verified: response.data?.bvn_verified,
					bank_name: response.data?.bank_name,
					account_number: response.data?.account_number,
					bvn_verification_failed: response.data?.bvn_verification_failed,
					id_type: response.data?.id_type,
					id_image: response.data?.id_image,
					id_number: response.data?.id_number,
				});
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};
	const setTransactionPin = () => {
		if (wallet?.length > 0 && userProfile?.is_set_pin === false) {
			handleWalletInfoModal();
		}
	};

	const decideAction = (bvnInfo: any) => {
		//this checks through the array of multiple wallets to see if bvn consent is needed and if not it displays the Toast notification
		if (bvnInfo.every((item: any) => item.required !== true)) {
			toast.info("Consent is not required for any item.");
		} else {
			bvnInfo.forEach((item: any) => {
				if (item.required === true && item.url) {
					window.open(item.url, "_blank");
				}
			});
		}
	};

	const bvnVerify = () => {
		httprequest
			.get("/wallet/bvn/consent/status/")
			.then((response: any) => {
				decideAction(response?.data?.data);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const createTransactionPin = (e: any) => {
		e.preventDefault();
		const details = {
			pin1: otp,
			pin2: otp,
		};
		httprequest
			.post("/accounts/user/pin/", details)
			.then(res => {
				if (res.status === 201) {
					toast.success("Transaction Pin Created");
					setWalletInfoModal(false);
					setTransactionPinModal(false);
					bvnVerify();
				}
			})
			.catch(err => {
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};
	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		setTransactionPin();
	}, [wallet, userProfile]);

	return (
		<>
			<Main
				tabName="Settings"
				leftSubHeader={<KYCStatusBar />}
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						{!profileDetails.bvn_verified && (
							<BVNVerification bvnVerify={bvnVerify} />
						)}

						<PageHeader>
							<span>
								<PageTitle>Personal Details</PageTitle>
								<PageInstruction>
									View and manage your personal details.
								</PageInstruction>
							</span>
							{/* <Button>
								{" "}
								<Link to="/settings/edit">Edit</Link>
							</Button> */}
						</PageHeader>

						<PageToggle>
							<Link to="/settings">Personal Details</Link>
							<Link to="/settings/companydetailsview">Company Details</Link>
						</PageToggle>

						<PageBody>
							<View field="First Name" detail={userDetails?.first_name} />
							<View field="Last Name" detail={userDetails?.last_name} />
							<View field="Email Address" detail={userDetails?.email} />
							<View field="Phone Number" detail={userDetails?.phone} />
							<View field="Role in Company" detail={userDetails?.role} />
							<View field="Nationality" detail={profileDetails?.nationality} />
							<View field="Gender" detail={profileDetails?.gender} />
							<View field="Date of Birth" detail={profileDetails?.birth_date} />
							<View field="Bank Name" detail={profileDetails?.bank_name} />
							<View field="Account Number" detail={profileDetails?.account_number} />
							<View
								field="Bank Verification Number (BVN)"
								detail={profileDetails?.bvn}
							/>
							<View field="ID Type" detail={profileDetails?.id_type} />
							<View field="ID Number" detail={profileDetails?.id_number} />
							<View
								field="ID Upload"
								detail={useTruncate(profileDetails?.id_image, 35, "...")}
							/>
						</PageBody>
						<ModalComponent
							size="lg"
							toggleModal={handleWalletInfoModal}
							heading={"Hello Abel 👋🏾"}
							subHeading={<ToastContainer />}
							modal={walletInfoModal}
							body={
								<ModalWrapper>
									<p>
										You have completed your onboarding process and an account has been
										created for you! Here’s your account details 👇🏾
									</p>
									<br />
									<MainAccount>
										<span>
											<h3>Main Account</h3> <h4>{wallet[0]?.bank_name}</h4>{" "}
											{/* <h5>{wallet[0]?.bank_name}</h5> <p>{wallet.account_number}</p> */}
										</span>{" "}
										<span>
											<h3>Account Number</h3> <h4>{wallet[0]?.account_number}</h4>
										</span>
									</MainAccount>
									<br />

									<p>
										To protect your account, you need to create a Transaction PIN. It
										takes less than 2 minutes.
									</p>
									<br />

									<Button
										title={"Create Transaction PIN"}
										size="full"
										textColor="normal"
										variant="default"
										onClick={handleTransactionPinModal}
									/>
								</ModalWrapper>
							}
						/>
						<ModalComponent
							size="lg"
							toggleModal={handleTransactionPinModal}
							heading="Create Transaction PIN"
							subHeading="Please crete a PIN that will be used to authorize your payments."
							modal={transactionPinModal}
							body={
								<ModalWrapper>
									<OtpWrapper className="otp-group">
										<OTP value={otp} valueLength={4} onChange={onChange} />
									</OtpWrapper>
									<br />
									<Button
										title={"Continue"}
										size="full"
										textColor="normal"
										variant="default"
										onClick={createTransactionPin}
									/>
									<ToastContainer />
								</ModalWrapper>
							}
						/>
						<ToastContainer />
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 45vw;
	@media (max-width: 768px) {
		width: 60vw;
	}
	@media (max-width: 600px) {
		width: 70vw;
	}
	@media (max-width: 425px) {
		width: 95vw;
		margin: 0 auto;
	}
`;

const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;

const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

// const Button = styled.button`
// 	align-self: center;
// 	background: none;
// 	padding: 7px 25px;
// 	border: 1px solid #9791b0;
// 	color: #302361;
// 	font-size: 14px;
// 	cursor: pointer;
// `;

const PageBody = styled.div`
	padding-top: 20px;
`;
const PageToggle = styled.div`
	display: flex;
	padding-top: 30px;
	font-size: 14px;
	color: #6d6d6d;
	gap: 30px;
	Link {
		cursor: pointer;
		color: #6d6d6d;
		text-decoration: none;
	}
`;
const ModalWrapper = styled.div`
	width: 85%;
	margin: 0 auto;
`;
const MainAccount = styled.div`
	background-color: #302361;
	color: #fff;
	display: flex;
	justify-content: space-between;
	padding: 19px 30px;
	width: 80%;
	margin: 0 auto;
	border-radius: 8px;
	span {
		h3 {
			color: #dddddd;
			font-size: 14px;
			font-weight: 400;
		}

		h4 {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 0;
			@media (max-width: 576px) {
				display: none;
			}
		}
		h5 {
			display: none;
			@media (max-width: 576px) {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 0;
				margin-top: 5px;
				display: block;
			}
		}
		p {
			font-size: 16px;
			font-weight: 500;
			margin-top: 5px;
		}
		text-align: left;

		:last-child {
			text-align: right;
		}
	}
`;
const OtpWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
export default Index;
