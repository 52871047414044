import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Envelop from "../../assets/emailEnvelop.png";
import OTP from "../Constants/OTP";
import Loader from "../../assets/loader.gif";
import axios from "axios";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";

export default function VerifyPhone() {
	const navigate = useNavigate();
	const [emailAddress, setEmailAddress] = useState({
		email: "",
	});

	const [otp, setOtp] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const signUpEmail = localStorage.getItem("email") as string;
		setEmailAddress({ email: signUpEmail });
	}, []);

	const sendPhoneVerification = async () => {
		await axios
			.post(`${constants.API_URL}/accounts/phone/verify/`, emailAddress)
			.then(() => {
				toast.success("Verification Code Sent");
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	useEffect(() => {
		sendPhoneVerification();
	}, [emailAddress]);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const details = {
			...emailAddress,
			code: otp,
		};
		setLoading(true);
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/phone/verify/confirm/`, details)
			.then(res => {
				if (res?.status === 200) {
					navigate("/");
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const onChange = (value: string) => setOtp(value);

	return (
		<EmailWrapper>
			<span>
				<img src={Envelop} alt="Envelop" />
			</span>

			<EmailTitle>Check your Text Message Inbox!</EmailTitle>

			<EmailText>
				We sent a message with a 6-digit verification code to you.
			</EmailText>

			<form onSubmit={handleSubmit}>
				<OTP value={otp} valueLength={6} onChange={onChange} />

				{loading ? (
					<Button>
						<img src={Loader} alt="loader gif" />
					</Button>
				) : (
					<Button>Log In</Button>
				)}
			</form>

			<ResendButton onClick={sendPhoneVerification}>
				Resend Verification Code
			</ResendButton>
			<ToastContainer />
		</EmailWrapper>
	);
}

const EmailWrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
`;

const EmailTitle = styled.h2`
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
`;
const EmailText = styled.p`
	font-size: 13px;
	line-height: 21px;
	max-width: 320px;
`;
const Button = styled.button`
	width: 34vw;
	height: 40px;
	background: #302361;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border: none;
	border-radius: 2px;
	margin: 0 auto;
	margin: 30px 0;
	cursor: pointer;
	img {
		height: 40px;
	}
	@media (max-width: 600px) {
		width: 319px;
		margin: 0 auto;
		margin-top: 30px;
	}
`;

const ResendButton = styled.div`
	cursor: pointer;
`;
