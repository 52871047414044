import styled from "styled-components";
import {
	PageHeader,
	PageBody,
	PreferencesSection,
	PageWord,
	PageToggle,
	PreferenceSubSection,
} from "./Index";
import Button from "../../Constants/FormComponents/Button";
import { useState } from "react";
import Modal from "../../Constants/ModalComponent";
import Input from "../../Constants/FormComponents/Input";

const TransactionLimits = () => {
	const [companyLimitModal, setCompanyLimitModal] = useState(false);
	const [companyLimitForm, setCompanyLimitForm] = useState({});

	const [memberLimitModal, setMemberLimitModal] = useState(false);
	const [memberLimitForm, setMemberLimitForm] = useState({});

	const handleCompanyLimitModal = () => setCompanyLimitModal(!companyLimitModal);
	const handleMemberLimitModal = () => setMemberLimitModal(!memberLimitModal);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCompanyLimitForm({ ...companyLimitForm, [e.target.name]: e.target.value });
	};
	const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMemberLimitForm({ ...memberLimitForm, [e.target.name]: e.target.value });
	};
	return (
		<>
			<PageHeader>
				{/* <span>
					<PageTitle>Invoices</PageTitle>
					<PageInstruction>
						Make changes to your invoice preferences.
					</PageInstruction>
				</span> */}
			</PageHeader>
			<PageBody>
				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>Company’s Transactions Limit</h4>{" "}
						<p>Manage your company’s transactions limit.</p>
					</PageWord>
					<PageToggle>
						<Button
							size="sm"
							variant="secondary"
							textColor="default"
							title="Edit Limit"
							onClick={handleCompanyLimitModal}
						/>
					</PageToggle>
				</PreferencesSection>
				<PreferenceSubSection>
					<PageWord>
						<h2>BRIDGER TO BRIDGER</h2>
						<h3>Maximum amount per transaction</h3>
					</PageWord>
					<PageToggle>
						<h3>NGN 10,000,000</h3>
					</PageToggle>
				</PreferenceSubSection>
				<PreferenceSubSection>
					<PageWord>
						<h3>Maximum transactions daily (Daily Frequency)</h3>
					</PageWord>
					<PageToggle>
						<h3>30</h3>
					</PageToggle>
				</PreferenceSubSection>
				<PreferenceSubSection>
					<PageWord>
						<h2>BRIDGER TO OTHER BANKS</h2>
						<h3>Maximum amount per transaction</h3>
					</PageWord>
					<PageToggle>
						<h3>NGN 1,000,000</h3>
					</PageToggle>
				</PreferenceSubSection>
				<PreferenceSubSection>
					<PageWord>
						<h3>Maximum transactions daily (Daily Frequency)</h3>
					</PageWord>
					<PageToggle>
						<h3>30</h3>
					</PageToggle>
				</PreferenceSubSection>
				<PreferenceSubSection>
					<PageWord>
						<h3>Daily transaction limit</h3>
					</PageWord>
					<PageToggle>
						<h3>NGN 10,000,000</h3>
					</PageToggle>
				</PreferenceSubSection>
				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>Team members Transactions Limit</h4>{" "}
						<p>
							This allows you to have total control over the transactions limit of all
							your team members.
						</p>
					</PageWord>
					<PageToggle>
						<Button
							size="sm"
							variant="secondary"
							textColor="default"
							title="Edit Limit"
							onClick={handleMemberLimitModal}
						/>
					</PageToggle>
				</PreferencesSection>
			</PageBody>

			<Modal
				toggleModal={handleCompanyLimitModal}
				modal={companyLimitModal}
				heading="Company’s Transactions Limit"
				subHeading="Manage your company’s transactions limit."
				size="md"
				body={
					<>
						<Wrapper>
							<SubHeader>BRIDGER TO BRIDGER</SubHeader>
							<Form>
								<FormContainer>
									<Input
										inputType="text"
										title="Maximum amount per transaction (₦)"
										name="business_name"
										size="full"
										onChange={handleChange}
										required
									/>
									<Input
										inputType="text"
										title="Daily transaction limit"
										name="business_name"
										size="full"
										onChange={handleChange}
										required
									/>
									<SubHeader>BRIDGER TO OTHER BANKS</SubHeader>
									<Input
										inputType="text"
										title="Maximum amount per transaction"
										name="business_name"
										size="full"
										onChange={handleChange}
										required
									/>
									<Input
										inputType="text"
										title="Daily transaction limit"
										name="business_name"
										size="full"
										onChange={handleChange}
										required
									/>
								</FormContainer>
							</Form>
						</Wrapper>
					</>
				}
			/>
			<Modal
				toggleModal={handleMemberLimitModal}
				modal={memberLimitModal}
				heading="Divine Amarachi"
				subHeading="Manage your company’s transactions limit."
				size="md"
				body={
					<>
						<Wrapper>
							<SubHeader>BRIDGER TO BRIDGER</SubHeader>
							<Form>
								<FormContainer>
									<Input
										inputType="text"
										title="Single Transaction limit"
										name="business_name"
										size="full"
										onChange={handleMemberChange}
										required
									/>
									<Input
										inputType="text"
										title="Daily Frequency"
										name="business_name"
										size="full"
										onChange={handleMemberChange}
										required
									/>
									<Input
										inputType="text"
										title="Daily transaction limit"
										name="business_name"
										size="full"
										onChange={handleMemberChange}
										required
									/>
								</FormContainer>
							</Form>
						</Wrapper>
					</>
				}
			/>
		</>
	);
};

export default TransactionLimits;

const Form = styled.form`
	width: 100%;
`;
const FormContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const SubHeader = styled.h5`
	font-size: 12px;
	font-weight: 700;
	color: black;
	text-align: left;
	margin-top: 22px;
	margin-bottom: 0px;
`;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	@media (max-width: 426px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;
