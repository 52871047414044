import { createContext, useState, useEffect } from "react";
import * as constants from "../Components/Constants/api";
import { useNavigate } from "react-router-dom";

export type CustomerProps = {
	uid: string;
	orderNo: string;
	date: string;
	phoneNumber: string;
	active: false;
	address: string;
	bank_account_name: string;
	bank_account_no: string;
	bank_name: string;
	bank_account_number: string;
	business: Number;
	business_name: string;
	category: string;
	company_code: string;
	company_email: string;
	country: string;
	industry: string;
	name: string;
	phone_number: string;
	rc_number: string;
	tier: string;
	transaction_limit: string;
	user: string;
};

export type ProfileProp = {
	nationality: String;
	gender: String;
	birth_date: String;
	bvn: String;
	bvn_verified: Boolean;
	selfie_image: String;
	profile_image: String;
	bvn_verification_failed: String;
	role_in_company: String;
	id_number: String;
	id_verified: Boolean;
	id_verification_failed: Boolean;
	id_image: String;
	id_image_back: String;
	id_type: String;
	id_expiry_date: String;
	id_issue_date: String;
	id_verification_message: String;
};

export type OrderItemProp = {
	description: String;
	quantity: Number | String;
	total: Number | String;
	unit_price: Number | String;
};

export type InvoiceItemProp = {
	total_display: number | string;
	description: String;
	unit_price: Number | String;
	quantity: Number | String;
	total: Number | String;
};

export type InstallmentProp = {
	percentage_split: Number;
	due_date: String;
	amount_due: String;
};

export interface PurchaseOrderProp {
	created_at: String;
	created_by: Number;
	currency: String;
	customer: CustomerProps;
	due_date: String;
	items: Array<OrderItemProp>;
	misc: String;
	order_date: String;
	order_number: String;
	payment_terms: String;
	pdf: any;
	publish_status: String;
	status: string;
	sub_total: Number;
	summary: String;
	supply_date: String;
	title: String;
	total: Number;
	uid: String;
}

export interface UserProp {
	email: String;
	first_name: String;
	first_time_login: Boolean;
	is_company_details_set: Boolean;
	is_profile_set: Boolean;
	is_set_pin: Boolean;
	last_name: String;
	phone: String;
	address: String;
	phone_number_verified: Boolean;
	profile: ProfileProp;
	public_id: String;
	role: String;
	uid: String;
}

export interface InvoiceProp {
	created_at: string;
	created_by: Number;
	currency: string;
	customer: CustomerProps;
	payment_due_date: string;
	items: Array<OrderItemProp>;
	misc: string;
	order_date: string;
	order_number: string;
	payment_terms: string;
	pdf: any;
	publish_status: string;
	status: string;
	sub_total: Number;
	summary: string;
	supply_date: string;
	title: string;
	total: Number;
	tax: string;
	discount: string;
	uid: string;
	instalments: Array<InstallmentProp>;
	dynamic_discount_value: String;
	dynamic_discount_due_date: any;
	payment_link: any;
}

export interface PaylinkProps {
	amount: string;
	available_payment_channels: string;
	category: string;
	creator: any;
	created_date: string;
	currency: string;
	description: string;
	dispute_reason: string;
	due_date: string;
	is_disputed: Boolean;
	name: string;
	recipient_company_name: string;
	recipient_customer: string;
	recipient_email: string;
	recipient_name: string;
	recipient_phone: string;
	reference: string;
	status: string;
	uid: string;
}

interface InitContextProps {
	customerBuyerModal: boolean;
	customerSellerModal: boolean;
	createInvoiceModal: boolean;
	handleCustomerBuyerModal: any;
	handleCustomerSellerModal: any;
	handleCreateInvoiceModal: any;
	handleSelectPayInvoice: any;
	handleSelectPayOrder: any;
	fetchAllCustomerData: Function;
	fetchAllPurchaseOrderData: Function;
	fetchAllInvoiceData: Function;
	fetchAllBanks: Function;
	fetchUserProfile: Function;
	fetchUserKYCStatus: Function;
	fetchOrigination: Function;
	getWalletDetails: Function;
	getOverview: Function;
	allCustomerData: Array<CustomerProps>;
	Industries: Array<String>;
	Countries: Array<String>;
	allPurchaseOrderData: Array<PurchaseOrderProp>;
	allInvoiceData: Array<InvoiceProp>;
	userProfile: any;
	userKYC: any;
	selectPayInv: any;
	selectPayOrder: any;
	banks: any;
	allBankList: any;
	wallet: any;
	origination: any;
	overviewDetails: any;
}

const AppContext = createContext({} as InitContextProps);

const AppProvider = ({ children }: any) => {
	const [customerBuyerModal, setCustomerBuyerModal] = useState(false);
	const [customerSellerModal, setCustomerSellerModal] = useState(false);
	const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
	const [allCustomerData, setAllCustomerData] = useState([]);
	const [allInvoiceData, setAllInvoiceData] = useState([]);
	const [allPurchaseOrderData, setAllPurchaseOrderData] = useState([]);
	const [userProfile, setUserProfile] = useState([]);
	const [userKYC, setUserKYC] = useState({});
	const [banks, setAllBanks] = useState([]);
	const [allBankList, setAllBankList] = useState<any>([]);
	const [wallet, setWallet] = useState([]);
	const [origination, setAllOrigination] = useState<any>([]);
	const [selectPayInv, setSelectPayInv] = useState({});
	const [selectPayOrder, setSelectPayOrder] = useState({});
	const [overviewDetails, setOverviewDetails] = useState();
	const navigate = useNavigate();
	const handleCustomerBuyerModal = () => {
		setCustomerBuyerModal(!customerBuyerModal);
	};

	const handleCustomerSellerModal = () => {
		setCustomerSellerModal(!customerSellerModal);
	};

	const handleCreateInvoiceModal = () => {
		setCreateInvoiceModal(!createInvoiceModal);
	};

	const fetchUserProfile = () => {
		constants.httprequest
			.get("/accounts/user/detailed/")
			.then(res => {
				setUserProfile(res?.data);
			})
			.catch(error => {
				if (error?.res?.status === 401) {
					navigate("/");
				}
			});
	};

	const fetchUserKYCStatus = () => {
		constants.httprequest
			.get("/kyc/status/")
			.then(res => {
				setUserKYC(res?.data);
			})
			.catch(() => {});
	};

	const fetchAllCustomerData = () => {
		constants.httprequest
			.get("/customers")
			.then(res => {
				setAllCustomerData(res?.data?.results);
			})
			.catch(error => {
				if (error?.res?.status === 401) {
					navigate("/");
				}
			});
	};
	const getWalletDetails = () => {
		constants.httprequest
			.get("/wallet/")
			.then(res => {
				setWallet(res?.data?.results);
			})
			.catch(error => {
				if (error?.res?.status === 401) {
					navigate("/");
				}
			});
	};

	const fetchAllPurchaseOrderData = () => {
		constants.httprequest
			.get("/inventory/purchase_orders/")
			.then(res => {
				setAllPurchaseOrderData(res?.data?.results);
			})
			.catch(() => {});
	};

	const fetchAllInvoiceData = () => {
		constants.httprequest
			.get("/inventory/invoices/")
			.then(res => {
				setAllInvoiceData(res?.data?.results);
			})
			.catch(() => {});
	};

	const fetchAllBanks = () => {
		constants.httprequest
			.get("/wallet/banks/")
			.then(res => {
				setAllBanks(res?.data?.results);
				setAllBankList(
					res?.data?.results?.map((bank: any) => {
						return {
							value: bank.code,
							label: bank.name,
						};
					})
				);
			})
			.catch(() => {});
	};

	const fetchOrigination = () => {
		constants.httprequest
			.get("/financing/origination/")
			.then(res => {
				setAllOrigination(res?.data?.results);
			})
			.catch(() => {});
	};
	const getOverview = () => {
		constants.httprequest.get("/dashboard/overview/").then(res => {
			setOverviewDetails(res.data.data);
		});
	};

	const handleSelectPayInvoice = (param: InvoiceProp) => {
		setSelectPayInv(param);
	};

	const handleSelectPayOrder = (param: InvoiceProp) => {
		setSelectPayOrder(param);
	};

	useEffect(() => {
		fetchAllCustomerData();
		fetchAllPurchaseOrderData();
		fetchAllInvoiceData();
		fetchUserProfile();
		fetchUserKYCStatus();
		fetchAllBanks();
		getWalletDetails();
		getOverview();
	}, []);

	const Industries: Array<String> = [
		"Agriculture",
		"Construction",
		"Education",
		"Finance and Insurance",
		"Health Services",
		"Hospitality",
		"Logistics",
		"Manufacturing",
		"Media, Advertising and Publishing",
		"Real Estate",
		"Retail",
		"Software and Technology",
		"Other",
	];

	const Countries: Array<String> = ["Nigeria"];

	return (
		<>
			<AppContext.Provider
				value={{
					customerBuyerModal,
					customerSellerModal,
					allCustomerData,
					allPurchaseOrderData,
					allInvoiceData,
					userProfile,
					userKYC,
					createInvoiceModal,
					selectPayInv,
					selectPayOrder,
					banks,
					origination,
					allBankList,
					wallet,
					overviewDetails,
					handleCustomerBuyerModal,
					handleCustomerSellerModal,
					handleCreateInvoiceModal,
					handleSelectPayInvoice,
					handleSelectPayOrder,
					getWalletDetails,
					fetchAllCustomerData,
					fetchAllPurchaseOrderData,
					fetchUserProfile,
					fetchUserKYCStatus,
					fetchAllInvoiceData,
					fetchAllBanks,
					fetchOrigination,
					getOverview,
					Countries,
					Industries,
				}}
			>
				{children}
			</AppContext.Provider>
		</>
	);
};

const AppConsumer = AppContext.Consumer;
export { AppContext, AppProvider, AppConsumer };
