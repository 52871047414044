import React from "react";
import styled from "styled-components";

// Pass props( onChange, required, placeHolder, title, size, name, value)
// required should be either true or false
// text area Sizing is lg, md, sm

interface Props {
	title?: string;
	required?: boolean;
	value?: string;
	name?: string;
	placeHolder?: string;
	size: any;
	isDisabled?: boolean;
	/* eslint-disable-next-line no-unused-vars */
	onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
}
const TextArea: React.FC<Props> = ({
	title,
	required,
	value,
	name,
	placeHolder,
	size,
	onChange,
	isDisabled,
}: Props) => {
	return (
		<TextAreaWrapper size={size}>
			<label>
				{title} {required ? <span>*</span> : null}{" "}
			</label>
			<StyledTextArea
				size={size}
				required={required}
				value={value}
				name={name}
				onChange={onChange}
				placeholder={placeHolder}
				disabled={isDisabled}
			/>
		</TextAreaWrapper>
	);
};

const TextAreaWrapper = styled.div<Props>`
	display: flex;
	flex-direction: column;
	width: ${({ size }) =>
		size === "lg"
			? "460px"
			: size === "md"
			? "360px"
			: size === "sm"
			? "230px"
			: size === "full"
			? "100%"
			: ""};

	text-align: left;
	font-size: 14px;
	padding-top: 25px;
	@media (max-width: 768px) {
		max-width: 320px;
		margin: 0 auto;
	}
	span {
		color: tomato;
	}
	label {
		display: flex;
		align-items: center;
		gap: 5px;
	}
`;

const StyledTextArea = styled.textarea<Props>`
	height: 46px;
	top: 26px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	background: none;
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	align-items: center;
	box-sizing: border-box;
	height: ${({ size }) =>
		size === "lg"
			? "120px"
			: size === "md"
			? "100px"
			: size === "sm"
			? "100px"
			: ""};
	@media (max-width: 768px) {
		height: 40px;
		width: 100%;
		margin: 0 auto;
		margin-top: 10px;
	}
`;

export default TextArea;
