import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import Coins from "../../../assets/coins.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import {
	CartesianGrid,
	XAxis,
	YAxis,
	BarChart,
	Bar,
	ResponsiveContainer,
	Tooltip,
	Legend,
} from "recharts";
import * as constants from "../../Constants/api";
import { useNavigate } from "react-router-dom";
import { FormatCurrency } from "../../Constants/utils";
import { AppContext } from "../../../Context/index";

export default function Right({ selectedCurrency, selectCurrencySign }: any) {
	const navigate = useNavigate();
	const { overviewDetails } = useContext(AppContext);

	const [GraphData, setGraphData] = useState([]);

	useEffect(() => {
		getGraphData();
	}, [selectedCurrency]);

	const recoverPayment = () => {
		navigate("/invoice");
	};

	const getGraphData = async () => {
		const res = await constants.httprequest.get(
			`/dashboard/account-receivables/?period=${"monthly"}`
		);
		const GraphData = res.data.data;
		setGraphData(GraphData?.[selectedCurrency]);
	};

	return (
		<RightWrapper>
			<Financing>
				<div>
					<span>
						<h3>Overdue Amount</h3>
						<p>
							{selectCurrencySign}
							{overviewDetails?.statistics?.[selectedCurrency]?.overdue_invoices_total
								? `${FormatCurrency(
										overviewDetails?.statistics?.[selectedCurrency]
											?.overdue_invoices_total
								  )}`
								: 0}
						</p>
					</span>

					<span>
						<h3>Overdue Invoices</h3>
						<p>
							{overviewDetails?.statistics?.[selectedCurrency]?.overdue_invoices_count
								? overviewDetails?.statistics?.[selectedCurrency]
										?.overdue_invoices_count
								: 0}
						</p>
					</span>
				</div>

				<button onClick={recoverPayment}>Recover Payment</button>

				{/* <img src={Coins} alt="Coins image" /> */}
			</Financing>

			<CreditSpan>
				<CreditIndividual>
					<span>
						<h3>
							{overviewDetails?.statistics?.[selectedCurrency]?.paid_invoices_count}
						</h3>
						<CallReceivedIcon
							style={{
								fontSize: "medium",
								backgroundColor: "#DAD6FF",
								padding: "7px",
								borderRadius: "50%",
							}}
						/>
					</span>
					<h4>PAID INVOICES</h4>
					<h3>
						{selectCurrencySign}
						{overviewDetails?.statistics?.[selectedCurrency]?.paid_invoices_total
							? `${FormatCurrency(
									overviewDetails?.statistics?.[selectedCurrency]?.paid_invoices_total
							  )}`
							: 0}
					</h3>
				</CreditIndividual>
				<CreditIndividual>
					<span>
						<h3>
							{overviewDetails?.statistics?.[selectedCurrency]?.sent_invoices_count
								? overviewDetails?.statistics?.[selectedCurrency]?.sent_invoices_count
								: 0}
						</h3>
						<ArrowOutwardIcon
							style={{
								fontSize: "medium",
								backgroundColor: "#DAD6FF",
								padding: "7px",
								borderRadius: "50%",
							}}
						/>
					</span>
					<h4>SENT INVOICES</h4>
					<h3>
						{selectCurrencySign}
						{overviewDetails?.statistics?.[selectedCurrency]?.sent_invoices_total
							? `${FormatCurrency(
									overviewDetails?.statistics?.[selectedCurrency]?.sent_invoices_total
							  )}`
							: 0}
					</h3>
				</CreditIndividual>
				<CreditIndividual>
					<span>
						<h3>
							{overviewDetails?.statistics?.[selectedCurrency]?.soon_due_invoices_count
								? overviewDetails?.statistics?.[selectedCurrency]
										?.soon_due_invoices_count
								: 0}
						</h3>
						<HourglassBottomIcon
							style={{
								fontSize: "medium",
								backgroundColor: "#DAD6FF",
								padding: "7px",
								borderRadius: "50%",
							}}
						/>
					</span>
					<h4>DUE SOON INVOICES</h4>
					<h3>
						{selectCurrencySign}
						{overviewDetails?.statistics?.[selectedCurrency]?.soon_due_invoices_total
							? `${FormatCurrency(
									overviewDetails?.statistics?.[selectedCurrency]
										?.soon_due_invoices_total
							  )}`
							: 0}
					</h3>
				</CreditIndividual>
			</CreditSpan>

			<GraphContainer>
				<GraphInfo>
					{" "}
					<h4>Transactions Chart</h4>{" "}
				</GraphInfo>

				<ResponsiveContainer width="100%" height="80%">
					<BarChart
						width={750}
						height={300}
						data={GraphData}
						margin={{
							top: 5,
							right: 20,
							left: 20,
							bottom: 5,
						}}
					>
						<Bar type="monotone" dataKey="paid" fill="#5B5187" />

						<Bar type="monotone" dataKey="overdue" fill="#FF7700" />
						<Bar type="monotone" dataKey="expected" fill="#DAD6FF" />

						<CartesianGrid stroke="#ccc" strokeDasharray="6 6" vertical={false} />
						<XAxis
							dataKey="period"
							style={{
								fontSize: "0.8rem",
							}}
						/>
						<YAxis
							style={{
								fontSize: "0.8rem",
								fontVariant: "#000",
							}}
						/>
						<Tooltip />
						<Legend />
					</BarChart>
				</ResponsiveContainer>
			</GraphContainer>
		</RightWrapper>
	);
}

const RightWrapper = styled.div`
	max-width: 100%;
`;

const Financing = styled.div`
	display: flex;
	text-align: left;
	justify-content: space-between;
	background-color: #302361;
	color: #fff;
	padding: 20px 15px 10px 30px;
	border-radius: 7px;
	align-items: center;
	position: relative;
	div {
		display: flex;
		width: 35%;
		justify-content: space-between;
	}
	h3 {
		font-size: 12px;
		font-weight: 400;
		margin-bottom: none;
	}
	p {
		font-size: 20px;
		font-weight: 500;
	}
	button {
		border-radius: 3px;
		border: none;
		cursor: pointer;
		padding: 5px 25px;
		color: #302361;
		font-weight: 500;
		font-size: 12px;
		height: 35px;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;

const GraphContainer = styled.div`
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	margin-top: 40px;
	padding-bottom: 30px;
	height: 400px;

	@media (max-width: 500px) {
		padding-bottom: 30px;
		margin-top: 30px;
	}
`;
const GraphInfo = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 10px;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		justifcontent: flex-start;
		padding: 0px 10px;
		h4 {
			margin-top: 0;
		}
	}
`;

const CreditSpan = styled.div`
	display: flex;
	justify-content: space-between;
	height: 170px;
	margin-top: 30px;
	@media (max-width: 500px) {
		justify-content: center;
		height: 140px;
		width: 100%%;
		margin: 0 auto;
	}
`;
const CreditIndividual = styled.div`
	width: 200px;
	padding: 22px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	align-text: left;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	@media (max-width: 500px) {
		width: 25vw;
		padding: 10px 10px;
		margin: 10px 10px;
		height: 80px;
		padding: 15px 10px;
	}
	span {
		width: 95%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h3 {
			font-size: 24px;
		}
	}
	img {
		height: 40px;
		width: 40px;
		@media (max-width: 500px) {
			height: 30px;
			width: 30px;
		}
		@media (max-width: 340px) {
			height: 20px;
			width: 20px;
		}
	}
	h4 {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		padding-top: 14px;
		@media (max-width: 500px) {
			font-size: 10px;
		}
		@media (max-width: 340px) {
			font-size: 8px;
		}
	}
	p {
		margin: 0;
		color: #b5b5b5;
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		padding-top: 5px;
		@media (max-width: 500px) {
			display: none;
		}
	}
	h3 {
		margin: 0;
		color: #302361;
		padding-top: 10px;
		font-size: 18px;
		@media (max-width: 500px) {
			font-size: 12px;
		}
		@media (max-width: 340px) {
			font-size: 10px;
		}
	}
`;
