import React from "react";
import styled from "styled-components";

interface Props {
	size: string;
	children: any;
	title: string;
	required?: boolean;
}

interface WrapperProps {
	size: string;
}

// Pass props(size, children ,title, required)
// Children is a select tag
// required should be either true or false
// input Sizing is lg, md, sm, full

const SelectWrapper: React.FC<Props> = ({
	size,
	title,
	children,
	required,
}) => (
	<Wrapper size={size}>
		<label>
			{title} {required ? <span>*</span> : null}
		</label>
		{children}
	</Wrapper>
);

export const Wrapper = styled.div<WrapperProps>`
    font-size: 14px;
    padding-top: 25px;
    width: ${({ size }) =>
					size === "lg"
						? "460px"
						: size === "md"
						? "360px"
						: size === "sm"
						? "230px"
						: size === "full"
						? "100%"
						: ""};
    }
    span {
        color: tomato;
    }
    label{
        display: flex;
        align-items: center;
        gap: 5px;
        width: 100%;
    }
	div {
		margin-top: 10px;
	}
	select {
		width: 100%;
        height: ${({ size }) =>
									size === "lg"
										? "50px"
										: size === "md"
										? "48px"
										: size === "sm"
										? "48px"
										: size === "full"
										? "48px"
										: ""};
		border: 1px solid #d9d9d9;
		padding-right: 5px;
		padding-left: 5px;
		margin-top: 10px;
    }
    @media (max-width: 768px) {
		select{
			height: 40px;
			width: 100%;
			margin: 0 auto;
		}
	
	}
	@media(max-width: 576px){
		width: ${({ size }) =>
			size === "lg" || size === "md" || size === "sm"
				? "320px"
				: size === "full"
				? "100%"
				: ""};
	}

    @media(max-width: 426px){
        width: 90%;
        max-width: 90%;
        padding-top: 15px;
        margin: 0 auto;
    }
`;

export default SelectWrapper;
