import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AppContext, InvoiceProp, PurchaseOrderProp } from "../../Context";
import * as constants from "../../Components/Constants/api";
import EmptyImg from "../../assets/empty.png";
import Main from "../Constants/Main";
import AcceptModal from "./AcceptModal";
import { FormatCurrency } from "../Constants/utils";
import Modal from "../Constants/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { v4 as uuidv4 } from "uuid";
import Table from "../Constants/Table";
import { TableColumn } from "react-data-table-component";
import Status from "../Constants/Status";
import PaginatedCards from "../Constants/PaginatedCards";

import {
	// faFileInvoiceDollar,
	faFileInvoice,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
	SubHeaderButton,
	SubHeaderSearch,
	InputSearch,
	TableToggleContainer,
	RightTableToggle,
	LGTableContainer,
	SMTBContainer,
	CreateOptionsCard,
	CreateButton,
	CheckedCard,
	EmptyData,
} from "../Invoice/Index";
import { useNavigate } from "react-router-dom";

const Index = () => {
	const [allInvoice, setAllInvoice] = useState<any>([]);
	const [isInvaccepted, setInvAccepted] = useState<any>([]);
	const [isPOaccepted, setPoAccepted] = useState<any>([]);
	const [financedData, setFinanceData] = useState<any>({});
	const {
		allInvoiceData,
		allPurchaseOrderData,
		userProfile,
		handleCreateInvoiceModal,
		handleSelectPayInvoice,
		fetchOrigination,
		origination,
	} = useContext(AppContext);
	const [toggleState, setToggleState] = useState("inv");
	const [acceptPayment, setAcceptPayment] = useState(false);
	const [searchInv, setSearchInv] = useState(false);
	const [searchPO, setSearchPO] = useState(false);
	const [earlyState, setEarlyState] = useState("");
	const [smallOriginationList, setSmallOriginationList] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const Invoice = allInvoiceData?.filter(
			invoice =>
				invoice.status === "created" ||
				invoice?.status === "invoice accepted" ||
				invoice?.status === "sent"
		);
		setAllInvoice(Invoice);
		setToggleState("inv");

		constants.httprequest
			.get("/inventory/summary")
			.then(res => {
				setFinanceData(res?.data?.data);
			})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});

		fetchOrigination();
		setSmallOriginationList(
			origination?.map((origin: any) => {
				return {
					date: `${origin?.financing_source?.created_at?.substring(0, 10)}`,
					amount: `₦ ${Number(origin?.amount_requested)?.toFixed(2)}`,
					invoiceTo: `Invoice/PO To: ${origin?.counterparty_company?.name}`,
					invoiceFrom: `Invoic/PO From: ${origin?.initiator_company?.name}`,
					invoiceNo: origin?.financing_source?.order_number,
					status: <Status statusState={origin?.status} />,
				};
			})
		);
	}, [allInvoiceData, allPurchaseOrderData, navigate]);

	const handleInvoiceModalDetails = (id: String) => {
		const current = allInvoiceData.filter(invoice => invoice.uid === id);
		handleSelectPayInvoice(current);
		navigate("/receive/apply");
	};

	const handlePOModalDetails = (id: String) => {
		const current = allPurchaseOrderData.filter(order => order.uid === id);
		handleSelectPayInvoice(current);
		navigate("/receive/apply");
	};

	const handleEarlyInv = () => {
		setEarlyState("invoice");
		localStorage.setItem("financing_source_type", "invoice");
	};

	// const handleEarlyPO = () => {
	// 	setEarlyState("purchase_order");
	// 	localStorage.setItem("financing_source_type", "purchase_order");
	// };

	const handleSearchInv = () => {
		setSearchInv(!searchInv);
		const Invoice = allInvoiceData?.filter(
			invoice =>
				invoice.status === "created" ||
				invoice?.status === "invoice accepted" ||
				invoice?.status === "sent"
		);
		setInvAccepted(Invoice);
	};

	const handleSearchPO = () => {
		setSearchPO(!searchPO);
		const PurchaseOrder = allPurchaseOrderData?.filter(
			order => order.status === "new" || order?.status === "accepted"
		);
		setPoAccepted(PurchaseOrder);
	};

	const handleAcceptModal = () => {
		setAcceptPayment(!acceptPayment);
	};

	const CloseAcceptPaymentModal = () => {
		setAcceptPayment(false);
		setEarlyState("");
	};

	const columns: TableColumn<any>[] = [
		{
			name: "Date",
			selector: row => row?.financing_source?.created_at.substring(0, 10),
			width: "10%",
			sortable: true,
		},
		{
			name: "Invoice No",
			selector: row => row?.financing_source?.order_number,
			width: "15%",
			sortable: true,
			center: true,
		},
		{
			name: "Invoice From",
			selector: row => row?.counterparty_company?.name,
			width: "20%",
		},
		{
			name: "Invoice to",
			selector: row => row?.initiator_company?.name,
			width: "20%",
			sortable: true,
		},
		{
			name: "Amount",
			selector: row => FormatCurrency(row?.amount_requested),
			width: "15%",
			sortable: true,
		},
		{
			name: "Status",
			cell: (row: { status: string }) => <Status statusState={row?.status} />,
			center: true,
			width: "20%",
		},
	];

	return (
		<>
			<Main
				tabName="Receive Payments"
				rightSubHeader={
					<>
						<SubHeaderSearch>
							<SearchOutlinedIcon />
							<InputSearch placeholder="Enter Keyword to search" />
						</SubHeaderSearch>
						<SubHeaderButton onClick={handleAcceptModal}>
							<FlashOnOutlinedIcon />
							Request Financing
						</SubHeaderButton>
					</>
				}
				mainBody={
					<>
						<HeadingContainer>
							<h2>Request Financing</h2>
							<p>Receive financing for your unpaid invoices.</p>
						</HeadingContainer>
						<CardFlex>
							{/* Comment our finance purchase order card */}
							{/* <Card>
								<h1>{financedData?.purchase_orders?.count || 0}</h1>
								<h5>FINANCED PURCHASE ORDER</h5>
								<h3>
									₦ {financedData?.purchase_orders?.total || 0} <span></span>
								</h3>
							</Card> */}
							<Card>
								<h1>{financedData?.invoices?.count || 0}</h1>
								<h5>FINANCED INVOICES</h5>
								<h3>
									₦ {financedData?.invoices?.total || 0} <span></span>
								</h3>
							</Card>
						</CardFlex>
						<TableToggleContainer>
							<RightTableToggle></RightTableToggle>
						</TableToggleContainer>
						<LGTableContainer>
							<Table
								data={origination}
								columns={columns}
								pagination
								paginationPerPage={10}
							/>
							{!allInvoice.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no{" "}
										{toggleState === "all"
											? "invoice,"
											: toggleState === "due"
											? "due invoice,"
											: toggleState === "accepted"
											? "accepted invoice"
											: toggleState === "rejected"
											? "rejected invoice,"
											: toggleState === "created"
											? "created invoice,"
											: "new invoice,"}{" "}
										Click on the button below to create one
									</p>
									<button onClick={handleCreateInvoiceModal}>
										<AddIcon /> Create Invoice
									</button>
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={smallOriginationList}
								date="date"
								valueOne="invoiceFrom"
								valueThree="invoiceNo"
								valueFour="amount"
								valueTwo="invoiceTo"
							/>
						</SMTBContainer>
						<Modal
							toggleModal={CloseAcceptPaymentModal}
							heading="Request Financing"
							subHeading="Kindly select what you want to request financing for."
							modal={acceptPayment}
							body={
								<>
									<CreateOptionsContainer>
										<CreateOptionsCard onClick={handleEarlyInv}>
											<div>
												<FontAwesomeIcon icon={faFileInvoice} />
											</div>
											<div>
												<h4>Invoice</h4>
												<p>Request financing for your invoices.</p>
											</div>
											<CheckedCard>
												{earlyState === "invoice" ? (
													<CheckCircleIcon />
												) : (
													<CircleOutlined />
												)}
											</CheckedCard>
										</CreateOptionsCard>
										{/* Comment out the ability to request financing for a purchase order */}
										{/* <CreateOptionsCard onClick={handleEarlyPO}>
											<div>
												<FontAwesomeIcon icon={faFileInvoiceDollar} />
											</div>
											<div>
												<h4>Purchase Order</h4>
												<p>Request financing for your purchase orders.</p>
											</div>
											<CheckedCard>
												{earlyState === "purchase_order" ? (
													<CheckCircleIcon />
												) : (
													<CircleOutlined />
												)}
											</CheckedCard>
										</CreateOptionsCard> */}
										{earlyState === "invoice" ? (
											<CreateButton onClick={handleSearchInv}>Continue</CreateButton>
										) : (
											<CreateButton onClick={handleSearchPO}>Continue</CreateButton>
										)}
									</CreateOptionsContainer>
								</>
							}
						/>
						<Modal
							toggleModal={handleSearchPO}
							heading="Select Purchase Order"
							subHeading="Here’s a list on purchase orders you can request financing for."
							modal={searchPO}
							body={
								<>
									<CreateOptionsContainer>
										<SearchInvoiceContainer>
											<input type="search" />
										</SearchInvoiceContainer>
										{isPOaccepted?.map((order: PurchaseOrderProp) => {
											return (
												<ModalInvoice
													key={uuidv4()}
													onClick={() => {
														handlePOModalDetails(order?.uid);
													}}
												>
													<div>
														<p>{order?.created_at?.substring(0, 10)}</p>
														<Status statusState={order?.status} />
													</div>
													<div>
														<h6>Purchase Order from</h6>
														<h4>
															{userProfile?.first_name} {userProfile?.last_name}{" "}
														</h4>
													</div>
													<div>
														<h6>Purchase Order to</h6> <h4>{order?.customer?.name}</h4>
													</div>
													<div>
														<h5>{order?.order_number}</h5>{" "}
														<h4>
															<span>₦</span>
															{`${FormatCurrency(order?.total)}`}
														</h4>
													</div>
												</ModalInvoice>
											);
										})}
									</CreateOptionsContainer>
								</>
							}
						/>

						<Modal
							toggleModal={handleSearchInv}
							heading="Select Invoice"
							subHeading={
								<>
									Here’s a list of Invoices you can request financing for.
									<SearchInvoiceContainer>
										<div>
											<FontAwesomeIcon icon={faSearch} />
											<input type="search" />
										</div>
									</SearchInvoiceContainer>
								</>
							}
							modal={searchInv}
							body={
								<>
									<CreateOptionsContainer>
										{isInvaccepted.map((invoice: InvoiceProp) => {
											return (
												<ModalInvoice
													key={uuidv4()}
													onClick={() => {
														handleInvoiceModalDetails(invoice?.uid);
													}}
												>
													<div>
														<p>{invoice?.created_at?.substring(0, 10)}</p>
														<Status statusState={invoice?.status} />
													</div>
													<div>
														<h6>Invoice from</h6>
														<h4>
															{userProfile?.first_name} {userProfile?.last_name}{" "}
														</h4>
													</div>
													<div>
														<h6>Invoice to</h6> <h4>{invoice?.customer?.name}</h4>
													</div>
													<div>
														<h5>{invoice?.order_number}</h5>{" "}
														<h3>
															<span>₦</span>
															{`${FormatCurrency(invoice?.total)}`}
														</h3>
													</div>
												</ModalInvoice>
											);
										})}
									</CreateOptionsContainer>
								</>
							}
						/>
						<AcceptModal />
					</>
				}
			/>
		</>
	);
};

export default Index;

export const HeadingContainer = styled.div`
	margin-top: 32px;

	h2 {
		font-size: 22px;
		color: var(--mainBlue);
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 5px;
		text-align: left;
	}
	h3 {
		font-size: 18px;
		color: var(--mainBlue);
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 5px;
		text-align: center;
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		color: var(--textAsh);
		font-size: 12px;
		font-weight: 400;
		text-align: left;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: var(--textAsh);
		font-size: 12px;
		font-weight: 400;
		text-align: center;
	}
`;

const CreateOptionsContainer = styled.div`
	margin: 50px;
	margin-top: 35px;
`;

const CardFlex = styled.div`
	display: flex;
	gap: 26px;
	margin-top: 15px;
	flex-wrap: wrap;
	@media (max-width: 426px) {
		flex-wrap: no-wrap;
		justify-content: space-between;
		gap: 0px;
	}
`;
const Card = styled.div`
	width: 250px;
	height: 128px;
	box-sizing: border-box;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	padding: 20px;
	text-align: left;

	h1 {
		font-size: 30px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 7px;
		color: var(--mainBlue);
	}
	h3 {
		margin-top: 0px;
		margin-bottom: 7px;
		color: var(--mainBlue);
		font-weight: 500;
		font-size: 20px;
	}
	h5 {
		font-size: 12px;
		margin-top: 0px;
		margin-bottom: 7px;
		color: var(--textAsh);
	}
	span {
		color: var(--mainGreen);
		font-size: 14px;
	}
	@media (max-width: 991px) {
		width: 200px;
		padding: 15px;
	}
	@media (max-width: 426px) {
		width: 150px;
		height: 140px;
		h5 {
			height: 30px;
			margin-bottom: 0px;
		}
	}
`;
export const ModalInvoice = styled.div`
	width: 95%;
	margin: auto;
	padding-top: 23px;
	padding-bottom: 23px;
	padding-left: 16px;
	padding-right: 16px;
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 10px;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	div:hover {
		cursor: pointer;
	}
	span {
		padding-right: 5px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-weight: 400;
		font-size: 12px;
	}
	h5 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-weight: 400;
		font-size: 12px;
		font-weight: bold;
		color: black;
	}
	p {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0px;
		margin-bottom: 0px;
		color: black;
	}
	h4 {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0px;
		margin-bottom: 0px;
		text-align: right;
		color: black;
	}
	h3 {
		font-size: 14px;
		font-weight: 400;
		margin-top: 0px;
		margin-bottom: 0px;
		text-align: right;
		color: #302361;
	}
`;
const SearchInvoiceContainer = styled.div`
	width: 100%;
	input {
		width: 100%;
		margin: auto;
		margin-top: 20px;
		height: 40px;
		background-color: var(--mainAsh);
		border-color: transparent;
		padding-left: 35px;
		box-sizing: border-box;
	}
	svg {
		position: absolute;
		left: 15px;
		top: 35px;
	}
	div {
		width: 70%;
		height: 40px;
		margin: auto;
		position: relative;
	}
`;
