import React from "react";
import Modal from "../../Constants/ModalComponent";
import styled from "styled-components";

interface Props {
	handleFinanceModal: any;
	financeModal: boolean;
}
export default function FinanceModal({
	handleFinanceModal,
	financeModal,
}: Props) {
	return (
		<>
			<Modal
				size="lg"
				toggleModal={handleFinanceModal}
				heading="Finance"
				subHeading="View features that are accessible and inaccessible to this role."
				modal={financeModal}
				body={
					<ModalWrapper>
						<CanAccess>
							<h2>CAN ACCESS</h2>
							<List>
								<ListTitle>Overview</ListTitle>

								<ul>
									<li>View credit information</li>
									<li>Can view top buyers</li>
									<li>Can view top payments</li>
									<li>Can view transaction charts</li>
								</ul>
							</List>
							<List>
								<ListTitle>Customers</ListTitle>

								<ul>
									<li>Add customer (Buyer & Supplier)</li>
									<li>Edit customer info</li>
									<li>Delete buyer/supplier</li>
									<li>View customer transaction</li>
								</ul>
							</List>
							<List>
								<ListTitle>Invoice and POs</ListTitle>

								<ul>
									<li>view user invoice/POs</li>
									<li>accept or reject invoice/POs for user</li>
									<li>delete invoice/POs</li>
								</ul>
							</List>
							<List>
								<ListTitle>Wallet</ListTitle>

								<ul>
									<li>View account information</li>
									<li>View credit informations</li>
									<li>download & share transaction receipt</li>
								</ul>
							</List>
							<List>
								<ListTitle>Make payment</ListTitle>

								<ul>
									<li>view accepted invoices</li>
									<li>view received invoices</li>
									<li>accept invoices</li>
									<li>pay for an invoice</li>
									<li>transfer to existing customer</li>
								</ul>
							</List>
							<List>
								<ListTitle>Receive payment</ListTitle>

								<ul>
									<li>create a payment link</li>
									<li>view/copy payment links</li>
									<li>delete payment links</li>
									<li>view all financing</li>
									<li>request financing for PO/invoice</li>
								</ul>
							</List>
							<List>
								<ListTitle>Settings</ListTitle>

								<ul>
									<li>view and edit personal details</li>
									<li>change/update password</li>
									<li>create/change transaction PIN</li>
								</ul>
							</List>
						</CanAccess>
						<CannotAccess>
							<h2>CANNOT ACCESS</h2>

							<List>
								<ListTitle>Settings</ListTitle>

								<ul>
									<li>View and edit company details</li>
									<li>View all team members</li>
									<li>Edit all team members</li>
									<li>Invite teammates</li>
									<li>Manage role</li>
									<li>Manage teammates</li>
								</ul>
							</List>
						</CannotAccess>
					</ModalWrapper>
				}
			/>
		</>
	);
}
const ModalWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 30px;
	@media (max-width: 425px) {
		width: 90%;
		display: flex;
		flex-direction: column;
	}
`;
const CanAccess = styled.div`
	width: 48%;
	h2 {
		padding: 8px 0;
		color: #4bb543;
		background-color: #dcf8ef;
		font-size: 14px;
		min-width: 40%;
		text-align: center;
	}
	@media (max-width: 425px) {
		width: 90%;
	}
`;
const ListTitle = styled.h3`
	font-size: 14px;
`;

const List = styled.div`
	li {
		font-size: 12px;
		font-weight: 400;
		padding: 4px 0;
	}
`;

const CannotAccess = styled.div`
	width: 45%;
	h2 {
		padding: 8px 0;
		color: #cd0a0a;
		background-color: #f2d4d4;
		font-size: 14px;
		min-width: 60%;
		text-align: center;
	}
	@media (max-width: 425px) {
		width: 90%;
	}
`;
