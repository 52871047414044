import { VerticalTimelineElement } from "react-vertical-timeline-component";
import styled from "styled-components";

interface Props {
	date?: string;
	message: string;
	time?: string;
	status?: string;
}

const Timeline: React.FC<Props> = ({ date, time, message, status }: Props) => (
	<StyledTimeline
		className="vertical-timeline-element vertical-element"
		date={time}
		iconStyle={{
			background:
				status === "success"
					? "var(--mainGreen)"
					: status === "info"
					? "#6D6D6D"
					: "var(--textRed)",
			width: "10px",
			height: "10px",
			color: "#fff",
			marginLeft: "13px",
		}}
	>
		<h4 className="vertical-timeline-element-subtitle">{date}</h4>
		<p>{message}</p>
	</StyledTimeline>
);

export default Timeline;

const StyledTimeline = styled(VerticalTimelineElement)`
	margin-bottom: 0px;
	margin-top: 20px;
	:first-child {
		margin-top: 0px;
	}
	h4 {
		font-weight: 500;
		font-size: 12px;
		margin-top: 3px;
		margin-bottom: 4px;
		color: #6d6d6d;
	}
	p {
		font-size: 14px;
		font-weight: 500;
		color: black;
		margin-top: 4px;
	}
	.vertical-timeline-element-date {
		padding-top: 4px;
		padding-bottom: 2px;
	}
	.vertical-timeline-element-content {
		box-shadow: none;
		padding-top: 0px;
	}
`;
