import React, { useState } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import Modal from "../Constants/Modal";
import { httprequest } from "../Constants/api";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import OTP from "../Constants/OTP";
import { ToastContainer, toast } from "react-toastify";

const Security: React.FC = () => {
	const navigate = useNavigate();

	const [newPasswords, setNewPasswords] = useState({
		old_password: "",
		new_password1: "",
		new_password2: "",
	});
	const [otp, setOtp] = useState("");

	const [newPin, setNewPin] = useState({
		pin1: "",
		pin2: "",
		old_pin: "",
	});

	const [passwordType, setPasswordType] = useState("password");
	const [passwordType2, setPasswordType2] = useState("password");
	const [oldPassword, setOldPassword] = useState("password");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value });
	};

	const handleChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNewPin({ ...newPin, [e.target.name]: e.target.value });
	};

	const handleSubmitPassword = (e: React.FormEvent<HTMLFormElement>) => {
		const details = {
			...newPasswords,
		};
		e.preventDefault();
		httprequest
			.post("/accounts/password/change/", details)
			.then(res => {
				if (res.status === 200) {
					toast.success(`${res?.data?.detail}`);
				}
			})
			.catch(err => {
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
				if (err?.response?.data?.errors[0]?.detail == "Invalid token.") {
					navigate("/");
				}
			});
	};
	const onChange = (value: string) => setOtp(value);

	const handleSubmitPin = (e: React.FormEvent<HTMLFormElement>) => {
		const details = {
			...newPin,
		};
		e.preventDefault();
		httprequest
			.put("/accounts/user/pin/", details)
			.then(res => {
				if (res.status === 200) {
					toast.success(`${res?.data?.detail}`);
				}
			})
			.catch(err => {
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);

				if (err?.response?.data?.errors[0]?.detail == "Invalid token.") {
					navigate("/");
				}
			});
	};

	const createTransactionPin = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const details = {
			pin1: otp,
			pin2: otp,
		};
		httprequest
			.post("/accounts/user/pin/", details)
			.then(res => {
				if (res.status === 201) {
					toast.success("Transaction Pin Created");
				}
			})
			.catch(err => {
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	const togglePassword2 = () => {
		if (passwordType2 === "password") {
			setPasswordType2("text");
		} else {
			setPasswordType2("password");
		}
	};

	const toggleOldPassword = () => {
		if (oldPassword === "password") {
			setOldPassword("text");
		} else {
			setOldPassword("password");
		}
	};

	const [createModal, setCreateModal] = useState<boolean>(false);

	const handleCreateModal = () => {
		setCreateModal(!createModal);
	};
	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<PageHeader>
							<span>
								<PageTitle>Password Change</PageTitle>
								<PageInstruction>Change your login password.</PageInstruction>
							</span>
						</PageHeader>

						<PageBody>
							<form onSubmit={handleSubmitPassword}>
								<PasswordWrapper>
									Old Password
									<Password>
										<PasswordInput
											type={oldPassword}
											required
											value={newPasswords.old_password}
											name="old_password"
											onChange={e => handleChange(e)}
										/>

										{oldPassword === "password" ? (
											<FontAwesomeIcon icon={faEyeSlash} onClick={toggleOldPassword} />
										) : (
											<FontAwesomeIcon icon={faEye} onClick={toggleOldPassword} />
										)}
									</Password>
								</PasswordWrapper>
								<PasswordWrapper>
									New Password
									<Password>
										<PasswordInput
											type={passwordType}
											required
											value={newPasswords.new_password1}
											name="new_password1"
											onChange={e => handleChange(e)}
										/>

										{passwordType === "password" ? (
											<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
										) : (
											<FontAwesomeIcon icon={faEye} onClick={togglePassword} />
										)}
									</Password>
								</PasswordWrapper>

								<PasswordStrengthCheckbox>
									<p>Password must:</p>
									<Checkbox>
										<input
											readOnly
											name="upperCase"
											id="upperCase"
											type="checkbox"
											checked={/[A-Z]/.test(newPasswords.new_password1)}
										/>{" "}
										<label>Contain an uppercase letter.</label>
									</Checkbox>
									<Checkbox>
										<input
											readOnly
											name="lowerCase"
											id="lowerCase"
											type="checkbox"
											checked={/[a-z]/.test(newPasswords.new_password1)}
										/>{" "}
										<label>Contain a lowercase letter.</label>
									</Checkbox>
									<Checkbox>
										<input
											readOnly
											name="numberCha"
											id="numberCha"
											type="checkbox"
											checked={/[0-9]/.test(newPasswords.new_password1)}
										/>{" "}
										<label>Contain a number.</label>
									</Checkbox>
									<Checkbox>
										<input
											readOnly
											name="specialCha"
											id="specialCha"
											type="checkbox"
											checked={/[!@#$%^&*]/.test(newPasswords.new_password1)}
										/>{" "}
										<label>Contain a special character.</label>
									</Checkbox>
									<Checkbox>
										<input
											readOnly
											name="eightCha"
											id="eightCha"
											type="checkbox"
											checked={newPasswords.new_password1.length >= 8}
										/>{" "}
										<label>Contains more than 8 characters.</label>
									</Checkbox>
								</PasswordStrengthCheckbox>

								<PasswordWrapper>
									Confirm New Password
									<Password>
										<PasswordInput
											type={passwordType2}
											required
											value={newPasswords.new_password2}
											name="new_password2"
											onChange={e => handleChange(e)}
										/>

										{passwordType2 === "password" ? (
											<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword2} />
										) : (
											<FontAwesomeIcon icon={faEye} onClick={togglePassword2} />
										)}
									</Password>
								</PasswordWrapper>

								<ButtonGroup>
									<ButtonOutlined>Cancel</ButtonOutlined>{" "}
									<ButtonFilled>Save Changes</ButtonFilled>
								</ButtonGroup>
							</form>

							<br />
							<br />

							<PageHeader>
								<span>
									<PageTitle>Transaction PIN</PageTitle>
									<PageInstruction>Change your payments transaction PIN</PageInstruction>
								</span>
								<Button onClick={handleCreateModal}>Create PIN</Button>
							</PageHeader>
							<form onSubmit={handleSubmitPin}>
								<PasswordWrapper>
									Old Transaction PIN
									<Password>
										<PasswordInput
											type="password"
											required
											value={newPin.old_pin}
											name="old_pin"
											onChange={e => handleChangePin(e)}
										/>
									</Password>
								</PasswordWrapper>
								<PasswordWrapper>
									New Pin
									<Password>
										<PasswordInput
											type="password"
											required
											value={newPin.pin1}
											name="pin1"
											onChange={e => handleChangePin(e)}
										/>
									</Password>
								</PasswordWrapper>

								<PasswordWrapper>
									Confirm New Pin
									<Password>
										<PasswordInput
											type="password"
											required
											value={newPin.pin2}
											name="pin2"
											onChange={e => handleChangePin(e)}
										/>
									</Password>
								</PasswordWrapper>
								<ToastContainer />

								<ButtonGroup>
									<ButtonOutlined>Cancel</ButtonOutlined>{" "}
									<ButtonFilled>Save Changes</ButtonFilled>
								</ButtonGroup>
							</form>
						</PageBody>

						<Modal
							toggleModal={handleCreateModal}
							heading="Create Transaction PIN"
							subHeading="Please crete a PIN that will be used to authorize your payments."
							modal={createModal}
							body={
								<form onSubmit={e => createTransactionPin(e)}>
									<OtpWrapper className="otp-group">
										<OTP value={otp} valueLength={4} onChange={onChange} />
									</OtpWrapper>

									<CreateButton>Continue</CreateButton>
									<ToastContainer />
								</form>
							}
						/>
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 45vw;
	padding-bottom: 50px;
	@media (max-width: 768px) {
		width: 60vw;
	}
	@media (max-width: 600px) {
		width: 70vw;
	}
	@media (max-width: 425px) {
		width: 95vw;
		margin: 0 auto;
	}
`;

const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 600px;
`;

const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;

const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

const Button = styled.button`
	align-self: center;
	background: none;
	padding: 7px 25px;
	border: 1px solid #9791b0;
	color: #302361;
	font-size: 14px;
	cursor: pointer;
`;

const PageBody = styled.div`
	padding-top: 20px;
`;

const PasswordWrapper = styled.label`
	display: flex;
	flex-direction: column;
	jusify-content: center;
	max-width: 600px;
	text-align: left;
	font-size: 14px;
	padding-top: 25px;

	@media (max-width: 600px) {
		max-width: 319px;
	}
`;

const Password = styled.div`
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	padding: 0 10px;
	height: 40px;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const PasswordInput = styled.input`
	top: 26.146087646484375px;
	background: none;
	border: none;
	:focus {
		outline: none;
	}
`;
const PasswordStrengthCheckbox = styled.div`
	padding: 30px 0;
	p {
		text-align: left;
		font-size: 12px;
	}
`;

const Checkbox = styled.div`
	text-align: left;
	font-weight: 400;
	font-size: 12px;
	padding: 3px 0;
	position: relative;

	input {
		margin-right: 10px;
		height: 12px;
		width: 12px;
	}
`;

const OtpWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CreateButton = styled.button`
	background-color: var(--mainBlue);
	width: 80%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
	margin: 0 auto;
	margin-top: 60px;
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
	@media (max-width: 500px) {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: 0px;
		margin-top: 30px;
	}
`;

const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;

const ButtonFilled = styled.button`
	border: none;
	border-radius: 3px;
	padding: 12px 25px;
	background: #302361;
	color: #fff;
`;

export default Security;
