import { useState, useContext, useEffect } from "react";
import Main from "../Constants/Main";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "../Constants/FormComponents/Button";
import * as constants from "../Constants/api";
import { FormatCurrency, Currency } from "../Constants/utils";
import Input from "../Constants/FormComponents/Input";
import TextArea from "../Constants/FormComponents/TextArea";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";

// All these are styling imported from create invoice
import {
	AmountGroup,
	QuantityGroup,
	DeleteBtn,
	PaymentTerms,
	Form,
	FormGroupContainer,
	Misc,
	ItemsContainer,
	DetailsForm,
	OrderCalc,
	SubTotal,
	FormBottom,
	FormBottomPagination,
	PageButtons,
} from "../PurchaseOrder/CreateOrders";

// All these are styling imported from purchase order preview
import {
	DetailsHeader,
	InvoiceNumber,
	SmallPayableAmount,
	AddressContainer,
	InvoiceAddress,
	PayableAmount,
	InvoiceTable,
	TableHead,
	SmallDesc,
	Desc,
	SmallQty,
	Qty,
	Rate,
	Amount,
	RightCalc,
	ActionButtons,
	TableCalc,
	TableBody,
	DetailsContainer,
} from "../PurchaseOrder/OrderPreview";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AppContext, CustomerProps } from "../../Context";
import AddBuyerModal from "../Customer/AddBuyerModal";
import Alert from "../Constants/Alert";

// type ItemProps = {
// 	description: string;
// 	unit_price: number;
// 	quantity: number;
// 	amount: number;
// };

// type InstallmentProp = {
// 	index: number;
// 	percentage_split: number;
// 	due_date: string;
// 	amount: number;
// };

const CreateInvoice = () => {
	const navigate = useNavigate();
	const [invoicePage, setInvoicePage] = useState(1);
	const {
		handleCustomerBuyerModal,
		allCustomerData,
		fetchAllInvoiceData,
		userProfile,
	} = useContext(AppContext);

	const [detailsState, setDetailsState] = useState(false);
	const [activate, setActivate] = useState({
		discount: false,
		reminder_status: false,
		installment: false,
	});

	const [isLoading, setIsLoading] = useState(false);
	const [errCreate, setErrCreate] = useState("");
	const [errCreateModal, setErrCreateModal] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<Array<CustomerProps>>(
		[]
	);
	const [selectedCurrency, setSelectedCurrency] = useState<any>([]);
	const [itemList, setItemList] = useState<any>([
		{
			description: "",
			unit_price: 0,
			quantity: 0,
			amount: 0,
		},
	]);

	const [installmentList, setInstallmentList] = useState<any>([
		{
			index: 0,
			percentage_split: 0,
			due_date: "0000-00-00",
			amount: 0,
		},
	]);

	const [reminderList, setReminderList] = useState<any>([]);

	const [invoiceForm, setInvoiceForm] = useState({
		items: itemList,
		sub_total: 0,
		tax: 0,
		tax_percentage: 7.5,
		discount: 0,
		order_number: "",
		title: "",
		summary: "",
		supply_date: "",
		order_date: "",
		payment_due_date: "",
		currency: "",
		misc: 0,
		payment_terms: "",
		attachment: "",
		customer: "",
		customer_name: "",
		total: 0,
		how_to_pay: "",
		instalments: installmentList,
		dynamic_discount_calc: 0,
		dynamic_discount_value: "",
		discount_percentage: 0,
		dynamic_discount_due_date: null,
		installmentPercTotal: 0,
		visible_add_installment: false,
		reminder_status: false,
		reminder_channels: reminderList,
	});

	const handleItemChange = (
		event: React.FormEvent<HTMLInputElement>,
		index: number
	) => {
		const { name, value } = event.currentTarget;
		const list = [...itemList];
		list[index][name] = value;
		list[index]["amount"] = Math.abs(
			list[index]["unit_price"] * list[index]["quantity"]
		);
		setItemList(list);
		let subtotal = 0;
		for (let i = 0; i < itemList.length; i++) {
			subtotal += itemList[i].amount;
		}
		const tax = Math.round((invoiceForm.tax_percentage / 100) * subtotal);
		const discount = Math.round(
			(invoiceForm.discount_percentage / 100) * subtotal
		);
		setInvoiceForm({
			...invoiceForm,
			sub_total: subtotal,
			tax: tax,
			discount: discount,
			total: Number(subtotal) + Number(invoiceForm.misc) + Number(tax) - discount,
		});
	};

	const handleInstPercentChange = (
		event: React.FormEvent<HTMLInputElement>,
		index: number
	) => {
		const { name, value } = event.currentTarget;
		const list = [...installmentList];

		if (index >= 1) {
			if (Number(value) + Number(list[index - 1]["percentage_split"]) > 100) {
				list[index][name] = 100 - list[index - 1]["percentage_split"];
				const newPercTotal =
					Number(invoiceForm.installmentPercTotal) +
					Number(100 - list[index - 1]["percentage_split"]);
				setInvoiceForm({
					...invoiceForm,
					visible_add_installment: true,
					installmentPercTotal: newPercTotal,
				});
			} else {
				list[index][name] = value;
				const newPercTotal = invoiceForm.installmentPercTotal + Number(value);
				setInvoiceForm({
					...invoiceForm,
					visible_add_installment: false,
					installmentPercTotal: newPercTotal,
				});
			}
		} else {
			if (Number(value) <= 100) {
				list[index][name] = value;
				const newValue = Number(value);
				setInvoiceForm({
					...invoiceForm,
					visible_add_installment: false,
					installmentPercTotal: +newValue,
				});
			} else {
				list[index][name] = 100;
				setInvoiceForm({
					...invoiceForm,
					visible_add_installment: true,
					installmentPercTotal: 100,
				});
			}
		}
		list[index]["amount"] =
			Math.abs(Number(list[index]["percentage_split"]) / 100) * invoiceForm.total;
		setInstallmentList(list);
		setInvoiceForm({
			...invoiceForm,
			instalments: list,
		});
	};

	const handleInstallmentDue = (
		event: React.FormEvent<HTMLInputElement>,
		index: number
	) => {
		const { name, value } = event.currentTarget;
		const list = [...installmentList];
		list[index][name] = value;
		setInstallmentList(list);
		setInvoiceForm({
			...invoiceForm,
			instalments: list,
		});
	};

	const handleReminderChannel = (event: React.FormEvent<HTMLInputElement>) => {
		const { value, checked } = event.currentTarget;
		if (checked) {
			setReminderList([...reminderList, value]);
		} else {
			const filtered = reminderList.filter((list: string) => list !== value);
			setReminderList(filtered);
		}
	};

	useEffect(() => {
		// Use this as a callback to update reminder channels immediately based on state change
		setInvoiceForm({ ...invoiceForm, reminder_channels: reminderList });
	}, [reminderList]);

	const handleDetailToggle = () => {
		setDetailsState(!detailsState);
	};

	const closeErrCreateModal = () => {
		setErrCreateModal(false);
	};

	useEffect(() => {
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
	}, []);

	const handleCreateInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/inventory/invoices/", invoiceForm)
			.then(() => {
				setIsLoading(false);
				fetchAllInvoiceData();
				navigate("/invoice");
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrCreateModal(true);
				setErrCreate(err?.response?.data?.errors[0]?.detail);
			});
	};

	const AddInvoiceItem = () => {
		const newItemlist = [
			...itemList,
			{
				description: "",
				unit_price: 0,
				quantity: 0,
				amount: 0,
			},
		];
		setItemList(newItemlist);
		setInvoiceForm({ ...invoiceForm, items: newItemlist });
	};

	const handleNextPage = () => {
		setInvoicePage(2);
		setDetailsState(false);
	};

	const handlePrevPage = () => {
		setInvoicePage(1);
	};

	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.type === "number" && e.currentTarget.value?.length > 0) {
			setInvoiceForm({
				...invoiceForm,
				[e.target.name]: Math.abs(Number(e.target.value)),
			});
		} else {
			setInvoiceForm({ ...invoiceForm, [e.target.name]: e.target.value });
		}
	};

	const handleMisc = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
			total:
				Number(invoiceForm.sub_total) +
				Number(event.currentTarget.value) +
				Number(invoiceForm.tax) -
				Number(invoiceForm.discount),
		});
	};

	const handleActivate = (event: React.FormEvent<HTMLInputElement>) => {
		setActivate({
			...activate,
			[event.currentTarget.name]: event.currentTarget.checked,
		});

		if (event.currentTarget.name === "installment") {
			setInstallmentList([
				{
					index: 0,
					percentage_split: 100,
					due_date: "0000-00-00",
					amount: invoiceForm.total,
				},
			]);
		}
	};

	const handleInvoiceReminder = (event: React.FormEvent<HTMLInputElement>) => {
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.checked,
		});
	};

	const handleTax = (event: React.ChangeEvent<HTMLInputElement>) => {
		const tax =
			Math.abs(Number(event.currentTarget.value) / 100) *
			Number(invoiceForm.sub_total);
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
			total:
				Number(invoiceForm.sub_total) +
				tax +
				Number(invoiceForm.misc) -
				Number(invoiceForm.discount),
			tax: Number(tax?.toFixed(2)),
		});
	};

	const handleDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
		const discount =
			(Number(event.currentTarget.value) / 100) * Number(invoiceForm.sub_total);
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
			total:
				Number(invoiceForm.sub_total) +
				Number(invoiceForm.tax) +
				Number(invoiceForm.misc) -
				discount,
			discount: Number(discount?.toFixed(2)),
		});
	};

	const handleDynamicDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
		const discount =
			(Number(event.currentTarget.value) / 100) * Number(invoiceForm.total);
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
			dynamic_discount_calc: Number(discount?.toFixed(2)),
		});
	};

	const {
		title,
		order_number,
		order_date,
		supply_date,
		payment_due_date,
		customer,
		currency,
		payment_terms,
		sub_total,
		discount,
		misc,
		how_to_pay,
		total,
		tax,
		items,
		dynamic_discount_value,
		dynamic_discount_calc,
		reminder_status,
	} = invoiceForm;

	const DisableBtnOne = () => {
		if (
			title &&
			order_number &&
			order_date &&
			supply_date &&
			payment_due_date &&
			customer &&
			currency
		) {
			return false;
		} else {
			return true;
		}
	};

	const DisableSwitchBtn = () => {
		if (total) {
			return false;
		} else {
			return true;
		}
	};

	const DisableButtonTwo = () => {
		if (items[0]?.quantity && items[0]?.description && items[0]?.unit_price) {
			return false;
		} else {
			return true;
		}
	};

	const DeleteInvItem = (id: any) => {
		const newItemList = itemList.filter(
			(item: any) => id !== itemList.indexOf(item)
		);
		DisableButtonTwo();
		let subtotal = 0;
		for (let i = 0; i < newItemList.length; i++) {
			subtotal += newItemList[i].amount;
		}
		const taxes = Math.round(0.07 * subtotal);
		setItemList(newItemList);
		setInvoiceForm({
			...invoiceForm,
			sub_total: subtotal,
			total:
				Number(subtotal) +
				Number(invoiceForm.misc) +
				Number(taxes) -
				Number(invoiceForm.discount),
			items: newItemList,
		});
	};

	const handleSelectedCustomer = (target: string) => {
		const selectCustomer = allCustomerData?.filter(
			onecustomer => onecustomer?.uid === target
		);
		setSelectedCustomer(selectCustomer);
	};

	const handleSelectedCurrency = (target: string) => {
		const selectCurrency = Currency?.filter(
			eachcurrency => eachcurrency?.value === target
		);
		setSelectedCurrency(selectCurrency);
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setInvoiceForm({
			...invoiceForm,
			[event.currentTarget.name]: event.currentTarget.value,
		});
		if (event.currentTarget.name === "customer") {
			handleSelectedCustomer(event.currentTarget.value);
		}
		if (event.currentTarget.name === "currency") {
			handleSelectedCurrency(event.currentTarget.value);
		}
	};

	return (
		<>
			<Main
				tabName="Create Invoice"
				rightSubHeader={<></>}
				mainBody={
					<>
						{detailsState ? (
							<DetailsContainer>
								<StepsDiv>
									<Steps></Steps>
									{invoicePage === 2 ? (
										<div onClick={handleNextPage}>
											<KeyboardBackspaceIcon /> Go Back
										</div>
									) : null}
								</StepsDiv>
								<DetailsHeader>
									<InvoiceNumber>
										<div></div>
										<h4>Invoice #{order_number}</h4>
									</InvoiceNumber>
									<SmallPayableAmount>
										<p>Total Payable</p>
										<h1>
											{" "}
											{selectedCurrency?.[0]?.symbol} {total}
										</h1>
									</SmallPayableAmount>
								</DetailsHeader>
								<AddressContainer>
									<InvoiceAddress>
										<div>
											<div>
												<h6>Invoice from</h6>
												<h5>
													{userProfile?.first_name} {userProfile?.last_name}
												</h5>
												<p>{userProfile?.address}</p>
											</div>
											<div>
												<h6>Sent to</h6>
												<h5>{selectedCustomer[0]?.name}</h5>
												<p>{selectedCustomer[0]?.address}</p>
											</div>
										</div>
										<div>
											<div>
												<h6>Invoice Number</h6>
												<h5>{order_number}</h5>
											</div>

											<div>
												<h6>Creation Date</h6>
												<h5>{order_date}</h5>
											</div>
											<div>
												<h6>Due Data</h6>
												<h5>{payment_due_date}</h5>
											</div>
										</div>
									</InvoiceAddress>
									<PayableAmount>
										<p>Total Payable</p>
										<h1>
											{" "}
											{selectedCurrency[0]?.symbol} {FormatCurrency(total)}
										</h1>
									</PayableAmount>
								</AddressContainer>
								<InvoiceTable>
									<TableHead>
										<SmallDesc>Item</SmallDesc>
										<Desc>Item Description</Desc>
										<SmallQty>Qty</SmallQty>
										<Qty>Quantity</Qty>
										<Rate>Rate</Rate>
										<Amount>Amount</Amount>
									</TableHead>

									{items?.map((item: any, i: any) => {
										return (
											<TableBody key={i}>
												<Desc>{item?.description}</Desc>
												<SmallDesc>{item?.description}</SmallDesc>
												<Qty>{item?.quantity}</Qty>
												<SmallQty>{item?.quantity}</SmallQty>
												<Rate>
													{selectedCurrency[0]?.symbol} {FormatCurrency(item?.unit_price)}
												</Rate>
												<Amount>
													{selectedCurrency[0]?.symbol}{" "}
													{FormatCurrency(Number(item?.unit_price) * Number(item?.quantity))}
												</Amount>
											</TableBody>
										);
									})}
								</InvoiceTable>
								<TableCalc>
									<LeftCalc>
										{how_to_pay ? (
											<div>
												<p>How to Pay</p>
												<h6>{how_to_pay}</h6>
											</div>
										) : null}
										{payment_terms ? (
											<div>
												<p>Payment Terms</p>
												<h6>{payment_terms}</h6>
											</div>
										) : null}
									</LeftCalc>
									<RightCalc>
										<div>
											<p>Sub Total</p>
											<h6>
												{selectedCurrency[0]?.symbol} {FormatCurrency(sub_total)}
											</h6>
										</div>

										<div>
											<p>Miscellenous</p>
											<h6>
												{selectedCurrency[0]?.symbol} {FormatCurrency(misc)}
											</h6>
										</div>

										<div>
											<p>Tax</p>
											<h6>
												{selectedCurrency[0]?.symbol} {FormatCurrency(tax)}
											</h6>
										</div>

										<div>
											<p>Discount</p>
											<h6>
												{selectedCurrency[0]?.symbol} {FormatCurrency(discount)}
											</h6>
										</div>

										<div>
											<p>Total</p>
											<h6>
												{selectedCurrency[0]?.symbol} {FormatCurrency(total)}
											</h6>
										</div>
									</RightCalc>
								</TableCalc>
								<ActionButtons>
									<div></div>
									<div>
										<Button
											title="Edit Invoice"
											onClick={handleNextPage}
											size="md"
											textColor="brand"
											variant="secondary"
										/>
										<Button
											title="Save"
											onClick={handleCreateInvoice}
											size="md"
											textColor="normal"
											variant="default"
											isLoading={isLoading}
										/>
									</div>
								</ActionButtons>
							</DetailsContainer>
						) : (
							<div className="container">
								<StepsDiv>
									<Steps>STEP {invoicePage} OF 2 : Add Invoice Details</Steps>
									{invoicePage === 2 ? (
										<div onClick={handlePrevPage}>
											<KeyboardBackspaceIcon /> Go Back
										</div>
									) : null}
								</StepsDiv>

								{invoicePage === 1 ? (
									<>
										<Form>
											<FormGroupContainer>
												<Input
													inputType="text"
													title="Invoice Order Title"
													name="title"
													value={title}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="number"
													title="Invoice Order Number"
													name="order_number"
													value={order_number}
													size="md"
													min="0"
													pattern="^\d]+/g"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Invoice Order Creation Date"
													max={supply_date}
													name="order_date"
													value={order_date}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Supply Date"
													min={order_date}
													name="supply_date"
													value={supply_date}
													size="md"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="date"
													title="Payment Due Date"
													min={order_date}
													name="payment_due_date"
													value={payment_due_date}
													size="md"
													onChange={handleChange}
													required
												/>
												{/* <Input
													inputType="text"
													title="Currency"
													name="currency"
													value={currency}
													size="md"
													isDisabled
													onChange={handleChange}
													required
												/> */}
												<SelectWrapper
													size="md"
													required
													title={`Currency ${
														selectedCurrency[0] ? `(${selectedCurrency[0]?.symbol})` : ""
													}`}
													children={
														<>
															<select
																onChange={handleSelectChange}
																name="currency"
																value={currency}
															>
																<option value="">--- Select Currency ---</option>
																{Currency?.map(currency => {
																	return (
																		<option value={currency?.value} key={currency?.value}>
																			{currency?.label}
																		</option>
																	);
																})}
															</select>
														</>
													}
												/>
												<SelectWrapper
													size="md"
													required
													title="Customer Name"
													children={
														<>
															<select onChange={handleSelectChange} name="customer">
																<option value="">--- Select Customer ---</option>
																{allCustomerData?.map((eachcustomer, i) => {
																	return (
																		<option value={`${eachcustomer?.uid}`} key={i}>
																			{eachcustomer?.name}
																		</option>
																	);
																})}
															</select>
															<div>
																<Button
																	title="Add Customer"
																	onClick={handleCustomerBuyerModal}
																	size="md"
																	textColor="brand"
																	variant="secondary"
																	icon={<PersonAddAlt1Icon />}
																/>
															</div>
														</>
													}
												/>
												<TextArea
													name="summary"
													size="md"
													onChange={handleAreaChange}
													title="Invoice Summary"
												/>
											</FormGroupContainer>
										</Form>
									</>
								) : (
									<>
										<ItemsContainer>
											{itemList.map((item: any, index: any) => {
												return (
													<DetailsForm key={index}>
														<Input
															inputType="text"
															title="Item Description"
															name="description"
															value={item.description}
															size="sm"
															onChange={event => handleItemChange(event, index)}
															required
														/>
														<Input
															inputType="number"
															title="Price per unit"
															name="unit_price"
															value={item.unit_price}
															size="sm"
															onChange={event => handleItemChange(event, index)}
															required
														/>
														<Input
															inputType="number"
															title="Quantity"
															name="quantity"
															value={item.quantity}
															size="sm"
															onChange={event => handleItemChange(event, index)}
															required
														/>
														<Input
															inputType="number"
															title="Amount"
															name="amount"
															value={item?.amount}
															size="sm"
															isDisabled
														/>
														{itemList?.length > 1 ? (
															<DeleteBtn
																onClick={() => {
																	DeleteInvItem(index);
																}}
															>
																Delete
															</DeleteBtn>
														) : null}
													</DetailsForm>
												);
											})}

											<PaymentTerms>
												<div>
													<TextArea
														name="payment_terms"
														title="Payment Terms"
														value={payment_terms}
														size="lg"
														onChange={handleAreaChange}
													/>
													<section>
														<FormControlLabel
															control={
																<Switch
																	checked={reminder_status}
																	onChange={handleInvoiceReminder}
																	color="primary"
																	name="reminder_status"
																	size="small"
																/>
															}
															labelPlacement="start"
															label="Activate Invoice Reminders"
														/>
														{reminder_status ? (
															<>
																<p>
																	These are automated notifications that will be sent by Bridger
																	to your customers via email on three specific dates: the 15th,
																	7th and the last day before the invoice is due. They serve as a
																	way to prompt your customers to pay on time.
																</p>
																<ReminderContainer>
																	<div>
																		<input
																			type="checkbox"
																			id="reminder_channel"
																			value="email"
																			onChange={handleReminderChannel}
																		/>
																		<label>
																			Email Reminder - {selectedCustomer["0"]?.company_email}
																		</label>
																	</div>
																	<div>
																		<input
																			type="checkbox"
																			id="reminder_channel"
																			value="sms"
																			onChange={handleReminderChannel}
																		/>
																		<label>
																			SMS Reminder - {selectedCustomer["0"]?.phone_number}
																		</label>
																	</div>
																	{/* <div>
																		<input
																			type="checkbox"
																			id="reminder_channel"
																			value="whatsapp"
																			onChange={handleReminderChannel}
																		/>
																		<label>
																			Whatsapp Reminder - {selectedCustomer["0"]?.phone_number}
																		</label>
																	</div> */}
																</ReminderContainer>
															</>
														) : null}
													</section>
												</div>
												<div>
													<Button
														title="Add another item"
														onClick={AddInvoiceItem}
														size="md"
														textColor="brand"
														variant="secondary"
													/>
												</div>
											</PaymentTerms>
											<OrderCalc>
												<SubTotal>
													<p>Sub Total</p>
													<h6>
														{selectedCurrency[0]?.symbol} {FormatCurrency(sub_total)}
													</h6>
												</SubTotal>
												<Misc>
													<p>
														Miscellanous{" "}
														{selectedCurrency[0] ? `(${selectedCurrency[0]?.symbol})` : ""}
													</p>
													<input
														type="number"
														onChange={handleMisc}
														name="misc"
														value={misc}
													/>
												</Misc>

												<MiscGroup>
													<label>Tax (%)</label>
													<InputFlex>
														<input
															type="number"
															name="tax_percentage"
															placeholder="7.5%"
															min={0}
															value={invoiceForm?.tax_percentage}
															onChange={handleTax}
														/>
														<input type="number" name="tax" value={tax} disabled />
													</InputFlex>
												</MiscGroup>
												<MiscGroup>
													<label>Discount (%)</label>
													<InputFlex>
														<input
															type="number"
															name="discount_percentage"
															placeholder="7.5%"
															min={0}
															value={invoiceForm?.discount_percentage}
															onChange={handleDiscount}
														/>
														<input type="number" name="discount" value={discount} disabled />
													</InputFlex>
												</MiscGroup>

												<SubTotal>
													<p>Total</p>
													<h6>
														{" "}
														{selectedCurrency[0]?.symbol}
														{FormatCurrency(total)}
													</h6>
												</SubTotal>
											</OrderCalc>
											<SwitchSection>
												<FormControlLabel
													control={
														<Switch
															checked={activate.discount}
															onChange={handleActivate}
															disabled={DisableSwitchBtn()}
															color="primary"
															name="discount"
															size="small"
														/>
													}
													labelPlacement="start"
													label="Offer Dynamic Discounting"
												/>
												<ActivateInfo>
													This gives your customer the option of making payments earlier, in
													exchange for a discount.
												</ActivateInfo>
												{activate.discount ? (
													<GroupFlex>
														<MiscGroup style={{ marginTop: "0px", marginBottom: "0px" }}>
															<label>Discount Value (%)</label>
															<InputFlex>
																<input
																	type="number"
																	name="dynamic_discount_value"
																	placeholder="7.5%"
																	value={dynamic_discount_value}
																	onChange={handleDynamicDiscount}
																/>
																<input
																	type="number"
																	name="dynamic_discount_calc"
																	value={dynamic_discount_calc}
																	disabled
																/>
															</InputFlex>
														</MiscGroup>
														<QuantityGroup>
															<label>Due Date</label>
															<input
																type="date"
																name="dynamic_discount_due_date"
																min={order_date}
																max={payment_due_date}
																onChange={handleChange}
															/>
														</QuantityGroup>
													</GroupFlex>
												) : null}

												{/* Needed for installment payment feature when ready */}

												{/* <FormControlLabel control={
                            				<Switch
                              					checked={activate.installment}
                              					onChange={handleActivate}
                              					disabled={DisableSwitchBtn()}
                              					color="primary"
                              					name="installment"
                              					size="small"
                            					/>
                          					}
                          						labelPlacement="start"
                          						label="Offer Installment Payment"
                        					/> */}
												{/* <ActivateInfo>
                          					This enables your customer make payment in bits over agreed terms.
                        					</ActivateInfo> */}
												{activate.installment ? (
													<>
														{installmentList.map((item: any, index: any) => {
															return (
																<GroupFlex key={index}>
																	<QuantityGroup>
																		<label>No of Installments</label>
																		<input type="number" disabled value={index + 1} />
																	</QuantityGroup>
																	<QuantityGroup>
																		<label>Due Date</label>
																		<input
																			type="date"
																			value={item.due_date}
																			onChange={event => handleInstallmentDue(event, index)}
																			name="due_date"
																		/>
																	</QuantityGroup>
																	<QuantityGroup>
																		<label>Percentage Split</label>
																		<input
																			type="number"
																			onChange={event => handleInstPercentChange(event, index)}
																			value={item.percentage_split}
																			name="percentage_split"
																		/>
																	</QuantityGroup>
																	<AmountGroup>
																		<label>Amount Due</label>
																		<input
																			type="number"
																			disabled
																			name="amount"
																			value={item.amount}
																		/>
																	</AmountGroup>
																</GroupFlex>
															);
														})}
														{/* Needed for installment payment feature when ready */}

														{/* <InstallmentButton
                              onClick={AddInstallmentItem}
                              disabled={visible_add_installment}
                            >
                              <AddIcon /> Add Installment
                            </InstallmentButton> */}
													</>
												) : null}
											</SwitchSection>
										</ItemsContainer>
									</>
								)}
								<FormBottom>
									<FormBottomPagination>
										<PageButtons>
											<button
												style={{
													backgroundColor: invoicePage === 1 ? "#302361" : "white",
													color: invoicePage === 1 ? "white" : "#302361",
												}}
											>
												1
											</button>
											<button
												style={{
													backgroundColor: invoicePage === 2 ? "#302361" : "white",
													color: invoicePage === 2 ? "white" : "#302361",
												}}
											>
												2
											</button>
										</PageButtons>
										{invoicePage === 1 && (
											<Button
												title="Continue"
												onClick={handleNextPage}
												size="md"
												textColor="normal"
												variant="default"
												disabled={DisableBtnOne()}
											/>
										)}
										{invoicePage === 2 && (
											<Button
												title="Continue"
												onClick={handleDetailToggle}
												size="md"
												textColor="normal"
												variant="default"
												disabled={DisableButtonTwo()}
											/>
										)}
									</FormBottomPagination>
								</FormBottom>
							</div>
						)}
					</>
				}
			/>
			<AddBuyerModal />
			<Alert
				alertSeverity={"error"}
				open={errCreateModal}
				alertTitle={errCreate || "Error"}
				closeModal={closeErrCreateModal}
			/>
		</>
	);
};
export default CreateInvoice;

const Steps = styled.p`
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 15px;
`;

export const DateGroup = styled.div`
	text-align: left;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 140px;
	position: relative;
	label {
		color: #484848;
	}
	input {
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		padding-left: 10px;
		padding-right: 5px;
		box-sizing: border-box;
		width: 120px;
	}
	@media (max-width: 1200px) {
		width: 18%;
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
		input {
			width: 100%;
			height: 40px;
		}
	}
`;

export const MiscGroup = styled.div`
	text-align: left;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 310px;
	position: relative;
	label {
		color: #484848;
	}
	input {
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		padding-left: 10px;
		padding-right: 5px;
		:first-child {
			width: 70px;
		}
		:last-child {
			width: 230px;
		}
	}
	@media (max-width: 1200px) {
		width: 30%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 426px) {
		width: 100%;
	}
`;

const StepsDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	div {
		color: black;
		font-weight: 500;
		font-size: 14px;
		display: flex;
		align-items: center;
		gap: 4px;
		:hover {
			cursor: pointer;
		}
	}
`;
const SwitchSection = styled.div`
	text-align: left;
	label {
		font-weight: 500 !important;
		font-size: 14px !important;
		color: var(--mainBlue);
		margin-left: 0px;
		margin-top: 20px;
	}
`;

const ReminderContainer = styled.div`
	width: 350px !important;
	div {
		display: flex;
		gap: 5px;
		align-items: center;
		width: 100% !important;
		margin-top: 5px !important;
	}
	label {
		margin-top: 0px !important;
		color: black !important;
	}
`;

const ActivateInfo = styled.div`
	width: 100%;
	height: 45px;
	color: black;
	font-size: 12px;
	box-sizing: border-box;
	background-color: #e9e9e9;
	display: flex;
	align-items: center;
	padding-left: 20px;
	margin-top: 16px;
`;

const InputFlex = styled.div`
	display: flex;
	align-items: center;
	select {
		padding-left: 15px;
		padding-right: 15px;
		margin-top: 5px;
		height: 52px;
		border: 1px solid #d9d9d9;
		border-right: transparent;
	}
`;

const GroupFlex = styled.div`
	display: flex;
	width: 450px;
	justify-content: space-between;
	gap: 20px;
	margin-top: 20px;
	label {
		color: #484848;
	}
	@media (max-width: 525px) {
		flex-direction: column;
	}
`;

export const LeftCalc = styled.div`
	width: 400px;
	text-align: left;
	p {
		font-size: 12px;
		font-weight: 500;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	div {
		width: 188px;
		// margin-bottom: 15px;
	}
	@media (max-width: 576px) {
		width: 188px;
		p {
			margin-bottom: 5px;
		}
		h6 {
			font-size: 12px;
		}
		a {
			font-size: 12px;
			margin-top: 0px;
		}
	}
`;
