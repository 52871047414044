const useTruncate = (str: any, length: any, ending: any) => {
	if (length == null) {
		length = 15;
	}
	if (ending == null) {
		ending = "...";
	}
	if (str?.length > 0) {
		if (str?.length > length) {
			return (
				<div
					style={{ cursor: "pointer" }}
					onClick={() => window.open(`${str}`, "_blank")}
				>
					{str.substring(0, length - ending?.length) + ending}
				</div>
			);
		} else {
			return (
				<div
					style={{ cursor: "pointer" }}
					onClick={() => window.open(`${str}`, "_blank")}
				>
					str
				</div>
			);
		}
	} else {
		return "";
	}
};

// eslint-disable-next-line import/prefer-default-export
export { useTruncate };
