import React, { useMemo } from "react";
import styled from "styled-components";
import { RE_DIGIT } from "../Constants/utils";

// ValueLength prop is the number of digits required for the otp

type Props = {
	value: string;
	valueLength: number;
	/* eslint-disable-next-line no-unused-vars */
	onChange: (value: string) => void;
};
const Otp = ({ value, valueLength, onChange }: Props) => {
	const valueItems = useMemo(() => {
		const valueArray = value.split("");
		const items: Array<string> = [];

		for (let i = 0; i < valueLength; i++) {
			const char = valueArray[i];

			if (RE_DIGIT.test(char)) {
				items.push(char);
			} else {
				items.push("");
			}
		}

		return items;
	}, [value, valueLength]);

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;

		if (e.key !== "Backspace" || target.value !== "") {
			return;
		}

		const previousElementSibling =
			target.previousElementSibling as HTMLInputElement | null;

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	const inputOnChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number
	) => {
		const target = e.target;
		let targetValue = target.value.trim();
		const isTargetValueDigit = RE_DIGIT.test(targetValue);

		if (!isTargetValueDigit && targetValue !== "") {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : " ";
		const targetValueLength = targetValue.length;
		if (targetValueLength === 1) {
			const newValue =
				value.substring(0, idx) + targetValue + value.substring(idx + 1);

			onChange(newValue);
			if (!isTargetValueDigit) {
				return;
			}

			const nextElementSibling =
				target.nextElementSibling as HTMLInputElement | null;

			if (nextElementSibling) {
				nextElementSibling.focus();
			}
		} else if (targetValueLength === valueLength) {
			onChange(targetValue);

			target.blur();
		}
		if (!isTargetValueDigit) {
			return;
		}
	};

	return (
		<OtpWrapper className="otp-group">
			{valueItems.map((digit, idx) => (
				<OtpInput
					key={idx}
					type="text"
					inputMode="numeric"
					autoComplete="one-time-code"
					pattern="\d{1}"
					maxLength={valueLength}
					className="otp-input"
					value={digit}
					onKeyDown={inputOnKeyDown}
					onChange={e => inputOnChange(e, idx)}
				/>
			))}
		</OtpWrapper>
	);
};

const OtpWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const OtpInput = styled.input`
	width: 40px;
	height: 40px;
	border: 1px solid #d9d9d9;
	margin: 30px 10px 0 10px;
	text-align: center;
	font-weight: 600;

	@media (max-width: 765px) {
		width: 30px;
		height: 30px;
	}
	@media (max-width: 325px) {
		width: 25px;
		height: 25px;
	}
`;

export default Otp;
