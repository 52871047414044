import React, { useState, useContext } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../Constants/FormComponents/Button";

const EditCustomerModal = (props: any) => {
	const { fetchAllCustomerData } = useContext(AppContext);
	const { deleteModal, handleDeleteModal, currentCustomer } = props;
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleDeleteCustomer = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.delete(`/customers/${currentCustomer?.uid}/`)
			.then(() => {
				setIsLoading(false);
				fetchAllCustomerData();
				handleDeleteModal();
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<>
			<Modal
				toggleModal={handleDeleteModal}
				modal={deleteModal}
				heading=""
				subHeading=""
				size="lg"
				body={
					<>
						<Wrapper>
							<>
								<FormTitle>Are you sure?</FormTitle>
								<p>
									You are about to delete {currentCustomer?.name} from your customers
									list. All your transaction records with this customer alongside their
									details will be cleared. This action is irreversible.
								</p>
								<p>Do you want to go ahead ?</p>

								<ActionButtons>
									<Button
										title="Delete"
										onClick={handleDeleteCustomer}
										size="full"
										textColor="normal"
										variant="reject"
										isLoading={isLoading}
									/>
								</ActionButtons>
							</>
							<ToastContainer />
						</Wrapper>
					</>
				}
			/>
		</>
	);
};

export default EditCustomerModal;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	p {
		font-size: 13px;
		line-height: 27px;
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;

const FormTitle = styled.h2`
	color: black;
	font-weight: 500;
	font-size: 18px;
	marginn-bottom: 3px;
`;

export const DeleteButton = styled.button`
	background-color: var(--textRed);
	width: 100%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
	margin-top: 20px;
	img {
		height: 40px;
	}
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;
