import React from "react";
import styled from "styled-components";

type Props = {
	field: string;
	detail: string;
};

export default function View({ field, detail }: Props) {
	return (
		<ViewBody>
			<ViewField>{field}</ViewField>
			<ViewDetail>{detail}</ViewDetail>
		</ViewBody>
	);
}

const ViewBody = styled.div`
	display: flex;
	width: 80%;
	margin: 0 auto;
	justify-content: space-between;
	border-bottom: 1px solid #ececec;
`;
const ViewField = styled.p`
	color: #484848;
	font-size: 14px;
	margin-bottom: 10px;
`;

const ViewDetail = styled.p`
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
`;
