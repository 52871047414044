import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as constants from "../Constants/api";
import { AppContext, InvoiceItemProp } from "../../Context";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../Constants/Alert";
import { useNavigate, useLocation } from "react-router-dom";
// import { FormatCurrency } from "../Constants/utils";
import { v4 as uuidv4 } from "uuid";
import { useTruncate } from "../../hooks/useTruncate";
import Button from "../Constants/FormComponents/Button";
import { Tooltip } from "react-tooltip";
import {
	CreateOptionsCard,
	CreateOptionsContainer,
	CheckedCard,
} from "./Index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { VerticalTimeline } from "react-vertical-timeline-component";
import Timeline from "../../Components/Constants/Timeline";
import LinkIcon from "@mui/icons-material/Link";
import Modal from "../Constants/ModalComponent";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindoSize";

export default function InvoicePreview() {
	const location = useLocation();
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [handleSend, setHandleSend] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingRemind, setIsLoadingRemind] = useState(false);
	const [isLoadingMark, setIsLoadingMark] = useState(false);
	const [isLoadingAccept, setIsLoadingAccept] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const [errSendModal, setErrSendModal] = useState(false);
	const [errSend, setErrSend] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const [acceptModal, setAcceptModal] = useState<boolean>(false);
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const [errAcceptModal, setErrAcceptModal] = useState(false);
	const [errAccept, setErrAccept] = useState("");
	const [errRejectModal, setErrRejectModal] = useState(false);
	const [errReject, setErrReject] = useState("");
	const [copied, setCopied] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");
	const [expandState, setExpandState] = useState(false);
	const [smallTab, setSmallTab] = useState("default");

	const currentInvoice = location.state ? location.state.currentInvoice : {};
	const screenSize = useWindowSize();

	const navigate = useNavigate();

	const handleAcceptModal = () => {
		setAcceptModal(!acceptModal);
	};
	const handleRejectModal = () => {
		setRejectModal(!rejectModal);
	};

	const handleExpand = () => {
		setExpandState(!expandState);
	};

	const handleTimeLineSwitch = () => {
		setSmallTab("activitytimeline");
	};
	const handleSummarySwitch = () => {
		setSmallTab("summarytab");
	};

	const { fetchAllInvoiceData } = useContext(AppContext);

	const handleCreateModal = () => {
		setCreateModal(!createModal);
	};

	const closeCreateModal = () => {
		setCreateModal(false);
		setHandleSend("");
		setCopied(false);
		setErrSend("");
		setErrSendModal(false);
	};
	const handleSendCard = () => {
		setHandleSend("sendInvoice");
	};
	const handleShareLink = () => {
		setHandleSend("copylink");
		setCopied(!copied);
	};
	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
		setCreateModal(false);
	};
	const handleBack = () => {
		navigate("/invoice");
	};

	const closeCopiedModal = () => {
		setCopied(false);
	};

	const closeErrSend = () => {
		setErrSendModal(false);
	};
	const closeErrAccept = () => {
		setErrAcceptModal(false);
	};
	const closeErrReject = () => {
		setErrRejectModal(false);
	};
	const handleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};
	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setRejectMessage(event.currentTarget.value);
	};

	useEffect(() => {
		if (screenSize < 768) {
			setSmallTab("summarytab");
		} else {
			setSmallTab("default");
		}
	}, [screenSize]);

	// If invoice has been deleted and a user goes back, redirect to invoices
	useEffect(() => {
		if (!location.state) {
			navigate("/invoice");
		}
	}, [currentInvoice, navigate]);

	const ShareInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.get(`/inventory/invoices/${currentInvoice?.uid}/send/`)
			.then(() => {
				setIsLoading(false);
				fetchAllInvoiceData();
				handleSuccessModal();
			})
			.catch(err => {
				setIsLoading(false);
				handleCreateModal();
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrSendModal(true);
				setErrSend(err?.response?.data?.errors[0]?.detail);
			});
	};

	const SendInvoiceReminder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingRemind(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/send_reminder/`)
			.then(() => {
				setIsLoadingRemind(false);
				toast.success("Invoice Reminder Sent");
			})
			.catch(_err => {
				setIsLoadingRemind(false);
				toast.error("Unable to send invoice reminder");
			});
	};

	const handleContactAccountOfficer = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		toast.loading("Contacting your Account Receivables Expert");
		constants.httprequest
			.post(
				`inventory/invoices/${currentInvoice?.uid}/request_for_account_receivables/`
			)
			.then(() => {
				toast.success("Account Receivables Expert Contacted, wait for a response");
				// Needed to add a dismiss to clear the loading option, this will also clear the success toast hence the set time out
				setTimeout(() => {
					toast.dismiss();
				}, 2000);
			})
			.catch(_err => {
				setIsLoadingMark(false);
				toast.error("Unable to Contact your Account Receivables Expert");
				// Needed to add a dismiss to clear the loading option, this will also clear the success toast hence the set time out
				setTimeout(() => {
					toast.dismiss();
				}, 2000);
			});
	};

	const MarkAsPaid = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingMark(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/mark_paid/`)
			.then(() => {
				toast.success("Successfully marked as paid");
				setIsLoadingMark(false);
				fetchAllInvoiceData();
				navigate("/invoice");
			})
			.catch(_err => {
				setIsLoadingMark(false);
				toast.error("Error marking as paid");
			});
	};

	const AcceptInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingAccept(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/options/`, {
				option: "accept",
			})
			.then(() => {
				fetchAllInvoiceData();
				navigate("/invoice");
			})
			.catch(err => {
				setIsLoadingAccept(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrAcceptModal(true);
				setErrAccept(err?.response?.data?.errors[0]?.detail);
			});
	};

	const RejectInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingReject(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/options/`, {
				option: "reject",
				message: rejectMessage,
			})
			.then(() => {
				fetchAllInvoiceData();
				navigate("/invoice");
			})
			.catch(err => {
				setIsLoadingReject(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrRejectModal(true);
				setErrReject(err?.response?.data?.errors[0]?.detail);
			});
	};

	const handleDownloadInvoice = () => {
		if (currentInvoice?.pdf === null) {
			toast.error("Your invoice PDF is processing, please try again");
			navigate("/invoice");
			fetchAllInvoiceData();
		} else {
			window.open(currentInvoice?.pdf, "_blank");
		}
	};

	// const discountedAmount =
	// 	(Number(currentInvoice?.dynamic_discount_value) / 100) *
	// 	Number(currentInvoice?.total);

	return (
		<>
			<Main
				tabName="Invoice Preview"
				rightSubHeader={
					<>
						<BackDiv onClick={handleBack}>
							<KeyboardBackspaceIcon /> Go Back
						</BackDiv>
					</>
				}
				mainBody={
					<DetailsContainer>
						<DetailsHeader>
							<InvoiceNumber>
								<div
									style={{
										backgroundColor:
											currentInvoice?.status === "rejected" ? "#cd0a0a" : "#302361",
									}}
								></div>
								<h4>{`Invoice # ${currentInvoice?.order_number}`}</h4>{" "}
								{expandState ? (
									<StyledCollapseBtn onClick={handleExpand}>
										Collapse invoice
									</StyledCollapseBtn>
								) : (
									<StyledCollapseBtn onClick={handleExpand}>
										Expand invoice
									</StyledCollapseBtn>
								)}
							</InvoiceNumber>
							<>
								{/* If Collapse invoice is enabled 
									If Invoice is created, sent or its invoice accepted, show option for mark as paid and invoice reminder 1.e recipient of invoices shouldnt be able to see mark as paid and send invoice reminder
									If An Invoice is not paid, show option for mark as paid else show an status for paid*/}
								{!expandState ? (
									currentInvoice?.status === "created" ||
									currentInvoice?.status === "sent" ||
									currentInvoice?.status === "invoice-accepted" ? (
										!currentInvoice?.is_paid ? (
											<div>
												<Button
													title="Mark as Paid"
													onClick={MarkAsPaid}
													size="md"
													textColor="normal"
													variant="accept"
													icon={<DoneOutlinedIcon />}
													isLoading={isLoadingMark}
												/>
												<Button
													title="Send Invoice Reminder"
													onClick={SendInvoiceReminder}
													size="md"
													textColor="normal"
													variant="default"
													isLoading={isLoadingRemind}
												/>
											</div>
										) : (
											<Button
												title="Paid"
												size="md"
												textColor="normal"
												variant="accept"
												disabled
											/>
										)
									) : null
								) : null}
							</>
							{currentInvoice?.status === "new" ? (
								<div>
									<Button
										title="Accept"
										onClick={handleAcceptModal}
										size="md"
										textColor="normal"
										variant="accept"
										icon={<DoneOutlinedIcon />}
									/>
									<Button
										title="Reject"
										onClick={handleRejectModal}
										size="md"
										textColor="normal"
										variant="reject"
										icon={<CloseIcon />}
									/>
								</div>
							) : null}
						</DetailsHeader>
						{!expandState ? (
							<ToggleContainer>
								<div
									onClick={handleSummarySwitch}
									style={{
										borderBottom:
											smallTab === "summarytab" ? "1px solid #302361" : "transparent",
										color: smallTab === "summarytab" ? "#302361" : "#6D6D6D",
									}}
								>
									Invoice Summary
								</div>
								<div
									onClick={handleTimeLineSwitch}
									style={{
										borderBottom:
											smallTab === "activitytimeline"
												? "1px solid #302361"
												: "transparent",
										color: smallTab === "activitytimeline" ? "#302361" : "#6D6D6D",
									}}
								>
									Activity Timeline
								</div>
							</ToggleContainer>
						) : null}

						{expandState ? (
							<>
								{" "}
								<AddressContainer>
									<InvoiceAddress>
										<div>
											<div>
												<h6>Invoice from</h6>
												<h5>{currentInvoice?.payment_link?.creator?.company_name}</h5>
												<p>{currentInvoice?.payment_link?.creator?.company_address}</p>
											</div>
											<div>
												<h6>Sent to</h6>
												<h5>{currentInvoice?.customer?.business_name}</h5>
												<p>{currentInvoice?.customer?.address}</p>
											</div>
										</div>
										<div>
											<div>
												<h6>Invoice Number</h6>
												<h5>{currentInvoice?.order_number}</h5>
											</div>

											<div>
												<h6>Creation Date</h6>
												<h5>{currentInvoice?.created_at?.substring(0, 10)}</h5>
											</div>
											<div>
												<h6>Due Date</h6>
												<h5>{currentInvoice?.payment_due_date?.substring(0, 10)}</h5>
											</div>
										</div>
									</InvoiceAddress>
									<PayableAmount>
										<p>Total Payable</p>
										<h1> {currentInvoice?.total_display}</h1>
									</PayableAmount>
								</AddressContainer>
								<InvoiceTable>
									<TableHead>
										<SmallDesc>Item</SmallDesc>
										<Desc>Item Description</Desc>
										<SmallQty>Qty</SmallQty>
										<Qty>Quantity</Qty>
										<Rate>Rate</Rate>
										<Amount>Amount</Amount>
									</TableHead>
									{currentInvoice?.items?.map((invoice: InvoiceItemProp) => {
										return (
											<TableBody key={uuidv4()}>
												<Desc>{invoice?.description}</Desc>
												<SmallDesc>{invoice?.description}</SmallDesc>
												<Qty>{`${invoice?.quantity}`}</Qty>
												<SmallQty>{`${invoice?.quantity}`}</SmallQty>
												<Rate>{`${invoice?.unit_price}`}</Rate>
												<Amount>{invoice?.total_display}</Amount>
											</TableBody>
										);
									})}
								</InvoiceTable>
								<TableCalc>
									<LeftCalc>
										<div>
											<p>Payment Terms</p>
											<h6>{currentInvoice?.payment_terms}</h6>
										</div>
										{currentInvoice?.currency === "ngn" ? (
											<>
												<p>Invoice link</p>
												<a
													href={`${constants.BASE_URL}pay/${currentInvoice?.payment_link?.reference}`}
													target="_blank"
													rel="noreferrer"
												>
													{useTruncate(
														`${constants.BASE_URL}pay/${currentInvoice?.payment_link?.reference}`,
														30,
														"..."
													)}
												</a>
											</>
										) : null}
									</LeftCalc>
									<RightCalc>
										<div>
											<p>Sub Total</p>
											<h6>{currentInvoice?.sub_total_display}</h6>
										</div>
										<div>
											<p>Miscellenous</p>
											<h6>{currentInvoice?.misc_display}</h6>
										</div>
										<div>
											<p>Tax</p>
											<h6>{currentInvoice?.tax_display}</h6>
										</div>
										<div>
											<p>Discount</p>
											<h6>{currentInvoice?.discount_display}</h6>
										</div>
										<div>
											<p>Total</p>
											<h6>{currentInvoice?.total_display}</h6>
										</div>
									</RightCalc>
								</TableCalc>
								{currentInvoice?.status === "new" ||
								currentInvoice?.status === "accepted" ||
								currentInvoice?.status === "rejected" ? (
									<DiscountInfo>
										<span>
											<FontAwesomeIcon icon={faInfoCircle} />
										</span>{" "}
										You have been offered dynamic discounting. This means that you enjoy a
										discount of {currentInvoice?.dynamic_discount_value} if you pay before{" "}
										{currentInvoice?.dynamic_discount_due_date}
									</DiscountInfo>
								) : null}
								<ActionButtons>
									<div>
										<Button
											title="Share"
											onClick={handleCreateModal}
											size="md"
											textColor="normal"
											variant="default"
										/>
										<Button
											title="Delete"
											onClick={handleDeleteModal}
											size="md"
											textColor="normal"
											variant="reject"
										/>
									</div>
									<Button
										title="Download Pdf"
										onClick={handleDownloadInvoice}
										size="md"
										textColor="brand"
										variant="secondary"
										icon={<CloudDownloadIcon />}
									/>
								</ActionButtons>
							</>
						) : (
							<>
								<CollapsedSection>
									{smallTab === "summarytab" || smallTab === "default" ? (
										<LeftCollapsed>
											<h3>Invoice Summary</h3>

											<InvoiceAddress>
												<div>
													<p>Invoice to</p>
													<h5>{currentInvoice?.customer?.business_name}</h5>
													<p>{currentInvoice?.customer?.address}</p>
													<div className="flex">
														<div>
															<h6>Creation Date</h6>
															<h5>{currentInvoice?.created_at?.substring(0, 10)}</h5>
														</div>
														<div>
															<h6>Due Date</h6>
															<h5>{currentInvoice?.payment_due_date?.substring(0, 10)}</h5>
														</div>
													</div>
													{currentInvoice?.currency === "ngn" ? (
														<div>
															<p>Invoice link</p>
															<a
																href={`${constants.BASE_URL}pay/${currentInvoice?.payment_link?.reference}`}
																target="_blank"
																rel="noreferrer"
															>
																{useTruncate(
																	`${constants.BASE_URL}pay/${currentInvoice?.payment_link?.reference}`,
																	30,
																	"..."
																)}
															</a>
														</div>
													) : null}

													<PayableAmount>
														<p>Total Payable</p>
														<h1> {currentInvoice?.total_display}</h1>
													</PayableAmount>
												</div>
											</InvoiceAddress>
										</LeftCollapsed>
									) : null}

									{smallTab === "activitytimeline" || smallTab === "default" ? (
										<RightCollapsed>
											<h3>Activity Timeline</h3>
											<StyledVerticalTimeline layout="1-column-left" lineColor="#DDDDDD">
												{currentInvoice?.timelines?.map((line: any, i: number) => {
													return (
														<Timeline
															key={i}
															date={new Date(line?.created_at).toDateString()}
															time={new Date(line?.created_at).toTimeString()}
															message={line?.description}
															status="success"
														/>
													);
												})}
											</StyledVerticalTimeline>
										</RightCollapsed>
									) : null}
								</CollapsedSection>
								<TooltopContainer>
									<Button
										size="md"
										variant="secondary"
										textColor="primary"
										title="Account Receivables Expert"
										onClick={handleContactAccountOfficer}
									/>
									<FontAwesomeIcon icon={faInfoCircle} className="acct-offic-hover" />
									<StyledToolTip anchorSelect=".acct-offic-hover" place="bottom">
										Send Invoice details to your skilled accounts receivables experts for
										followup
									</StyledToolTip>
								</TooltopContainer>
							</>
						)}
						<ToastContainer />
					</DetailsContainer>
				}
			/>

			{/* Modal that handles share invoice */}
			<Modal
				toggleModal={closeCreateModal}
				heading="Share Invoice"
				size="lg"
				subHeading={
					<>
						<p>Please select your preferred method</p>
						<Alert
							open={copied}
							alertSeverity="success"
							alertTitle=" Payment link Copied"
							closeModal={closeCopiedModal}
						/>
					</>
				}
				modal={createModal}
				body={
					<>
						<CreateOptionsContainer>
							<CreateOptionsCard onClick={handleSendCard}>
								<div>
									<ScreenShareOutlinedIcon />
								</div>
								<div>
									<h4>Send to Customer</h4>
									<p>Send invoice to your already created customer.</p>
								</div>
								<CheckedCard>
									{handleSend === "sendInvoice" ? <CheckCircleIcon /> : null}
								</CheckedCard>
							</CreateOptionsCard>
							<CopyToClipboard
								text={`${constants.BASE_URL}pay/${currentInvoice?.payment_link?.reference}`}
								onCopy={() => setCopied(true)}
							>
								<CreateOptionsCard onClick={handleShareLink}>
									<div>
										<LinkIcon />
									</div>
									<div>
										<h4>Copy Link</h4>
										<p>Copy and send invoice link to customer.</p>
									</div>
									<CheckedCard>
										{handleSend === "copylink" ? <CheckCircleIcon /> : null}
									</CheckedCard>
								</CreateOptionsCard>
							</CopyToClipboard>
							{handleSend === "sendInvoice" ? (
								<Button
									title="Continue"
									onClick={ShareInvoice}
									size="full"
									textColor="normal"
									variant="default"
									isLoading={isLoading}
									disabled={isLoading}
								/>
							) : null}
						</CreateOptionsContainer>
					</>
				}
			/>

			{/* This Modal ia triggered after an invoice is successfully shared or sent */}
			<Modal
				toggleModal={handleSuccessModal}
				heading=""
				subHeading=""
				size="lg"
				modal={successModal}
				body={
					<>
						<SuccessTitle>Invoice Sent !</SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully created and sent an Invoice to{" "}
								{currentInvoice?.customer?.name}. Your customer will receive an email or
								notification containing the Invoice details.
							</p>
							<h6>You also have the option of saving this Invoice as a PDF.</h6>
							<div>
								<Button
									title="Download PDF"
									onClick={handleDownloadInvoice}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Continue"
									onClick={handleSuccessModal}
									size="sm"
									textColor="normal"
									variant="default"
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Alert
				alertSeverity={"error"}
				open={errSendModal}
				alertTitle={errSend || "Unable to Send Invoice"}
				closeModal={closeErrSend}
			/>
			<Alert
				alertSeverity={"error"}
				open={errAcceptModal}
				alertTitle={errAccept || "Unable to Accept Invoice"}
				closeModal={closeErrAccept}
			/>
			<Alert
				alertSeverity={"error"}
				open={errRejectModal}
				alertTitle={errReject || "Unable to Reject Invoice"}
				closeModal={closeErrReject}
			/>
			{deleteModal ? (
				<DeleteInvoiceModal
					currentOrder={currentInvoice}
					deleteModal={deleteModal}
					handleDeleteModal={handleDeleteModal}
				/>
			) : null}
			<Modal
				toggleModal={handleRejectModal}
				heading=""
				subHeading=""
				size="lg"
				modal={rejectModal}
				body={
					<>
						<AcceptContainer>
							<h5>Reject Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentInvoice?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentInvoice?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentInvoice?.total_display}</h4>
								</div>
							</AcceptDetails>
							<div>
								<label>Reason for rejection</label>
								<textarea onChange={handleAreaChange}></textarea>
							</div>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleRejectModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Reject"
									onClick={RejectInvoice}
									size="sm"
									textColor="normal"
									variant="reject"
									isLoading={isLoadingReject}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleAcceptModal}
				heading=""
				subHeading=""
				size="lg"
				modal={acceptModal}
				body={
					<>
						<AcceptContainer>
							<h5>Accept Purchase Order</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentInvoice?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentInvoice?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentInvoice?.total_display}</h4>
								</div>
							</AcceptDetails>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleAcceptModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Accept"
									onClick={AcceptInvoice}
									size="sm"
									textColor="normal"
									variant="accept"
									isLoading={isLoadingAccept}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
		</>
	);
}

export const DetailsContainer = styled.div`
	padding-bottom: 60px;
`;

const BackDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	:hover {
		cursor: pointer;
	}
`;

export const InvoiceNumber = styled.div`
	gap: 15px;
	display: flex;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 25px;
	div {
		width: 48px;
		height: 48px;
	}
	h4 {
		color: var(--mainBlue);
		font-size: 25px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	@media (max-width: 768px) {
		gap: 8px;
		div {
			width: 32px;
			height: 32px;
		}
		h4 {
			font-size: 16px;
		}
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const InvoiceAddress = styled.div`
	width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 300px;
		text-align: left;
		margin-top: 20px;
		margin-bottom: 20px;
		:first-child {
			margin-bottom: 30px;
		}
	}
	h6 {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	p {
		color: #6d6d6d;
		font-size: 12px;
		margin-top: 2px;
		margin-bottom: 2px;
	}
	h5 {
		font-size: 14px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	@media (max-width: 1200px) {
		width: 450px;
	}
	@media (max-width: 768px) {
		width: 100%;
		h5 {
			font-size: 12px;
		}
	}
`;

export const PayableAmount = styled.div`
	margin-left: auto;
	text-align: right;
	p {
		font-size: 12px;
		color: #919191;
		margin-top: 20px;
		margin-bottom: 2px;
	}
	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 29px;
		color: var(--mainBlue);
	}
	@media (max-width: 1200px) {
		h1 {
			font-size: 24px;
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const AcceptContainer = styled.div`
	width: 90%;
	margin: auto;
	h5 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 23px;
		text-align: left;
		color: black;
	}

	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
		font-weight: 400;
	}
	h4 {
		font-size: 14px;
		color: black;
		font-size: 500;
		margin-top: 10px;
		margin-bottom: 0px;
	}
	textarea {
		height: 120px;
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-top: 5px;
	}
`;
const AcceptDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AcceptButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 40px;
`;

const DetailsHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	div {
		display: flex;
		gap: 10px;
	}

	@media (max-width: 991px) {
		flex-direction: column;
		gap: 20px;
		text-align: left;
		justify-content: start;
		align-items: flex-start;
	}
`;

export const InvoiceTable = styled.div`
	width: 100%;
	margin-top: 40px;
`;

export const TableHead = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e9e9e9;
	font-size: 14px;
	font-weight: 500;
`;

export const TableBody = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const Desc = styled.div`
	width: 35%;
	font-size: 14px;
	text-align: left;
	padding-left: 20px;
	@media (max-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const SmallDesc = styled.div`
	width: 30%;
	font-size: 12px;
	text-align: left;
	padding-left: 10px;
	@media (min-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const Qty = styled.div`
	width: 10%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		display: none;
	}
`;

export const SmallQty = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
	width: 6%;
	font-size: 12px;
	text-align: left;
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Rate = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Amount = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: right;
	padding-right: 20px;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
		padding-right: 10px;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const TableCalc = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	@media (max-width: 576px) {
		flex-direction: column-reverse;
	}
`;

export const LeftCalc = styled.div`
	width: 221px;
	text-align: left;
	p {
		font-size: 12px;
		font-weight: 500;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	div {
		margin-bottom: 30px;
	}
	@media (max-width: 576px) {
		width: 188px;
		p {
			margin-bottom: 5px;
		}
		h6 {
			font-size: 12px;
		}
		a {
			font-size: 12px;
			margin-top: 0px;
		}
	}
`;

export const RightCalc = styled.div`
	width: 45%;
	div {
		padding-right: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
		font-weight: bold;
	}
	@media (max-width: 576px) {
		width: 60%;
		margin-left: auto;
		p {
			font-size: 12px;
		}
		h6 {
			font-size: 14px;
		}
		div {
			padding-right: 10px;
		}
	}
	@media (max-width: 426px) {
		width: 70%;
	}
`;

const DiscountInfo = styled.div`
	display: flex;
	gap: 5px;
	background-color: #fcf2dc;
	color: #7c5f06;
	font-size: 11px;
	font-weight: 400;
	width: 430px;
	padding: 14px;
	box-sizing: border-box;
	text-align: left;
	@media (max-width: 576px) {
		width: 100%;
	}
`;

export const ActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	flex-wrap: wrap;
	gap: 10px;
	div {
		display: flex;
		gap: 10px;
	}
	@media (max-width: 576px) {
		flex-direction: column;
		gap: 10px;
		button {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
		div {
			flex-direction: column;
			justify-content: center;
		}
	}
`;

export const SendButton = styled.button`
	background-color: var(--mainBlue);
	color: white !important;
	justify-content: center;
	height: 48px;
	:hover {
		background-color: #9791b0;
	}
`;

export const Delete = styled.button`
	background-color: var(--textRed);
	color: white !important;
	height: 48px;
	border-color: transparent !important;
	font-weight: 500;
`;

export const SuccessTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 23px;
	text-align: left;
	color: black;
	width: 90%;
	margin: auto;
`;

export const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;

export const Continue = styled.button`
	background-color: var(--mainBlue);
	font-size: 14px;
	border: transparent;
	color: white;
	font-weight: 500;
	padding: 15px 30px;
`;

const CollapsedSection = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 8px;
`;

const LeftCollapsed = styled.div`
	width: 46%;
	h3 {
		border-bottom: 1px solid #e5e5ef;
		text-align: left;
		padding-bottom: 15px;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const RightCollapsed = styled.div`
	width: 46%;
	height: 400px;
	position: relative;
	overflow: scroll;
	text-align: left;
	h3 {
		border-bottom: 1px solid #e5e5ef;
		text-align: left;
		padding-bottom: 15px;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;

const StyledVerticalTimeline = styled(VerticalTimeline)`
	width: 300px;
	margin: 0px !important;
	&:before {
		width: 1px !important;
		color: #dddddd;
	}
`;

const StyledCollapseBtn = styled.span`
	border: 1px solid var(--mainBlue);
	color: var(--mainBlue);
	font-size: 14px;
	font-weight: 500;
	padding: 4px 14px;
	border-radius: 3px;

	@media (max-width: 576px) {
		width: 150px;
		box-sizing: border-box;
		height: 43px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const ToggleContainer = styled.div`
	display: flex;
	gap: 30px;
	padding-top: 25px;
	padding-bottom: 15px;
	font-size: 18px;
	font-weight: 500;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 576px) {
		font-size: 16px;
		div {
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
	@media (max-width: 426px) {
		font-size: 12px;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;

const TooltopContainer = styled.div`
	width: 300px;
	display: flex;
	gap: 10px;
	align-items: center;

	@media (max-width: 426px) {
		position: relative;
		svg {
			position: absolute;
			right: 15px;
		}
	}
`;
const StyledToolTip = styled(Tooltip)`
	width: 250px;
	text-align: left;
`;
