import styled from "styled-components";

type Props = {
	field: string;
	detail: any;
};

export default function View({ field, detail }: Props) {
	return (
		<ViewBody>
			<ViewField>{field}</ViewField>
			<ViewDetail>{detail}</ViewDetail>
		</ViewBody>
	);
}

const ViewBody = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #ececec;
	margin-top: 10px;
`;
const ViewField = styled.p`
	color: #484848;
	font-size: 14px;
	margin-bottom: 8px;
`;

const ViewDetail = styled.p`
	margin-bottom: 8px;
`;
