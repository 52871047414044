import React from "react";
import styled from "styled-components";
import DataTable, { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

// Ensure to set width for the respective columns in your columns array depending on the data represented and need
// pagination defaults to 10per page and it is an optional prop
// if you need more than 10 rows per page,pass a number into Pagination per page
// onRow clicked prop is needed when you need the row to be clickable
//
// Pass sortable to each column that needs sorting(e.g Date columns)

type TableProps = {
	columns: TableColumn<any>[];
	data: any[];
	title?: string;
	pagination?: boolean;
	paginationPerPage?: number;
	onRowClicked?: any;
	persistTableHead?: boolean;
};
function Table({
	columns,
	data,
	title,
	pagination,
	paginationPerPage,
	onRowClicked,
}: TableProps) {
	return (
		<>
			<TableTitle>{title}</TableTitle>

			<DataTable
				columns={columns}
				data={data}
				customStyles={customStyles}
				pagination={pagination}
				paginationPerPage={paginationPerPage}
				onRowClicked={onRowClicked}
				sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
			/>
		</>
	);
}

const customStyles = {
	columns: {
		style: {
			"&:last-child": {
				width: "30px",
				textAlign: "center",
				fontWeight: "500",
			},
		},
	},
	headCells: {
		style: {
			backgroundColor: "#e9e9e9",
			paddingLeft: "25px",
			paddingRight: "25px",
		},
	},
	rows: {
		style: {
			borderBottom: "none",
			paddingTop: "5px",
			paddingBottom: "5px",
			"&:hover": {
				backgroundColor: "#f7f7f7",
				cursor: "pointer",
			},
		},
	},
};
const TableTitle = styled.h2`
	font-size: 18px;
	font-weight: 500;
	text-align: left;
	margin-bottom: 20px;
	margin-top: 40px;
`;
export default Table;
