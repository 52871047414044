import styled from "styled-components";
import { SvgIconProps } from "@material-ui/core";
import Loader from "../../../assets/loader.gif";

// Pass props(onClick,isLoading,isActive,title,size,variant,textColor,icon, disabled)
// Variant could be either of inactive, default, secondary, accept, reject
// Text color could either be normal (white) or brand color(default company blue)
// Button Sizing is lg, md, sm, bt or full (fill width)
// The Icon prop is a Material UI icon passed only when an icon is needed

interface Props {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	isLoading?: boolean;
	title?: string;
	icon?: SvgIconProps;
	size: string;
	variant: string;
	textColor: string;
	disabled?: boolean;
}

const Button: React.FC<Props> = ({
	onClick,
	isLoading,
	title,
	size,
	variant,
	textColor,
	icon,
	disabled,
}: Props) => {
	return (
		<ButtonWrapper
			size={size}
			variant={variant}
			textColor={textColor}
			onClick={onClick}
			disabled={disabled}
		>
			{isLoading ? (
				<img src={Loader} alt="Bridger Loader" />
			) : (
				<>
					{icon as SvgIconProps} {title}{" "}
				</>
			)}
		</ButtonWrapper>
	);
};

const ButtonWrapper = styled.button<Props>`
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	border: ${({ variant }) =>
		variant === "inactive"
			? "1px solid #9791b0;"
			: variant === "default"
			? "1px solid #302361"
			: variant === "secondary"
			? "1px solid #302361"
			: variant === "accept"
			? "1px solid #4BB543"
			: variant === "reject"
			? "1px solid #CD0A0A"
			: ""};
	background: ${({ variant }) =>
		variant === "inactive"
			? "#9791B0"
			: variant === "default"
			? "#302361"
			: variant === "secondary"
			? "#FFFFFF"
			: variant === "accept"
			? "#4BB543"
			: variant === "reject"
			? "#CD0A0A"
			: ""};
	color: ${({ textColor }) =>
		textColor === "normal" ? "#fff" : textColor === "brand" ? "#302361" : ""};
	width: ${({ size }) =>
		size === "lg"
			? "460px"
			: size === "md"
			? "210px"
			: size === "sm"
			? "130px"
			: size === "bt"
			? "130px"
			: size === "full"
			? "100%"
			: ""};
	height: ${({ size }) =>
		size === "lg"
			? "55px"
			: size === "md"
			? "46px"
			: size === "sm"
			? "40px"
			: size === "bt"
			? "46px"
			: size === "full"
			? "48px"
			: ""};
	font-size: ${({ size }) =>
		size === "lg"
			? "18px"
			: size === "md"
			? "14px"
			: size === "sm"
			? "12px"
			: size === "full"
			? "16px"
			: ""};
	@media (max-width: 500px) {
		width: ${({ size }) =>
			size === "lg"
				? "320px"
				: size === "md"
				? "180px"
				: size === "sm"
				? "100px"
				: size === "bt"
				? "100px"
				: size === "full"
				? "100%"
				: ""};

		height: ${({ size }) =>
			size === "lg"
				? "46px"
				: size === "md"
				? "40px"
				: size === "sm"
				? "34px"
				: size === "bt"
				? "40px"
				: size === "full"
				? "44px"
				: ""};
	}
	@media (max-width: 426px) {
		width: 100%;
		margin: 0 auto;
	}
	svg {
		height: ${({ size }) =>
			size === "lg"
				? "16px"
				: size === "md"
				? "14px"
				: size === "sm"
				? "12px"
				: size === "full"
				? "16px"
				: ""};
	}
	img {
		height: ${({ size }) =>
			size === "lg"
				? "24px"
				: size === "md"
				? "20px"
				: size === "sm"
				? "16px"
				: size === "full"
				? "20px"
				: ""};
	}
	:hover {
		cursor: pointer;
	}

	:disabled {
		background: ${({ variant }) =>
			variant === "inactive"
				? "#9791B0"
				: variant === "default"
				? "#9791B0"
				: variant === "secondary"
				? "#FFFFFF"
				: variant === "accept"
				? "#4BB543"
				: variant === "reject"
				? "#CD0A0A"
				: ""};
		border: ${({ variant }) =>
			variant === "inactive"
				? "1px solid #9791B0"
				: variant === "default"
				? " 1px solid #9791B0"
				: variant === "secondary"
				? "1px solid #FFFFFF"
				: variant === "accept"
				? "1px solid #4BB543"
				: variant === "reject"
				? "1px solid #CD0A0A"
				: ""};
	}
`;
export default Button;
