import React, { useState, useEffect, useContext } from "react";
import Main from "../Constants/Main";
import styled from "styled-components";
import { AppContext } from "../../Context";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { HeadingContainer } from "./Index";
import Modal from "../Constants/ModalComponent";
import { SuccessTitle, SuccessBody } from "../Invoice/InvoicePreview";
import * as constants from "../Constants/api";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Alert from "../Constants/Alert";
import Button from "../Constants/FormComponents/Button";

export default function AcceptForm() {
	const {
		selectPayInv,
		handleSelectPayInvoice,
		fetchAllInvoiceData,
		fetchOrigination,
	} = useContext(AppContext);
	const [previewState, setPreviewState] = useState(false);
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [repaymentAmount, setRepaymentAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [errApply, setErrApply] = useState("");
	const [errApplyModal, setErrApplyModal] = useState(false);
	const [applyFinance, setApplyFinance] = useState({
		reference: selectPayInv[0]?.uid,
		financing_type: "receive_payment",
		financing_source_type: localStorage.getItem("financing_source_type"),
		amount_requested: "",
		repayment_plan: "",
		reason_for_financing: "",
	});

	const navigate = useNavigate();

	const handleBack = () => {
		navigate(-1);
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setApplyFinance({ ...applyFinance, amount_requested: e.target.value });
		setRepaymentAmount(0.8 * Number(e.target.value));
	};

	// If invoice has been deleted and a user goes bcak, redirect to purchase order
	useEffect(() => {
		if (!selectPayInv.length) {
			navigate("/receive/early-pay");
		}
	}, [selectPayInv, navigate]);

	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setApplyFinance({
			...applyFinance,
			reason_for_financing: event.currentTarget.value,
		});
	};

	const handlePreviewToggle = () => {
		setPreviewState(!previewState);
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const { repayment_plan, reason_for_financing, amount_requested } =
		applyFinance;

	/* eslint-disable */
	// Render array of string to add a line break
	const percentOne = [
		"O days",
		<br />,
		repayment_plan === "0_days" || repayment_plan === "" ? "0%" : null,
	];

	const percentTwo = [
		"30 days",
		<br />,
		repayment_plan === "30_days" ? "5%" : null,
	];

	const percentThree = [
		"60 days",
		<br />,
		repayment_plan === "60_days" ? "10%" : null,
	];

	const percentFour = [
		"90 days",
		<br />,
		repayment_plan === "90_days" ? "15%" : null,
	];

	const marks = {
		"0": percentOne,
		30: percentTwo,
		60: percentThree,
		90: percentFour,
	};
	/* eslint-enable */

	const handleSlider = (value: any) => {
		setApplyFinance({
			...applyFinance,
			repayment_plan: `${value}_days`,
		});
	};

	const closeErrApply = () => {
		setErrApplyModal(false);
	};

	const handleApplyFinancing = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/financing/origination/", applyFinance)
			.then(() => {
				setIsLoading(false);
				handleSelectPayInvoice({});
				fetchOrigination();
				fetchAllInvoiceData();
				navigate("/receive/early-pay");
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrApplyModal(true);
				setErrApply(err?.response?.data?.errors[0]?.detail);
			});
	};

	return (
		<>
			<Main
				tabName="Receive Payments"
				rightSubHeader={
					<>
						<BackDiv onClick={handleBack}>
							<KeyboardBackspaceIcon /> Go Back
						</BackDiv>
					</>
				}
				mainBody={
					<>
						<HeadingContainer>
							<h2>Request Financing - Apply for receivable finance</h2>
							<p>Kindly complete the form below.</p>
						</HeadingContainer>
						<FormContainer>
							{previewState ? (
								<>
									<PreviewItem>
										<p>Invoice number</p>
										<h6>{selectPayInv[0]?.order_number}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Invoice total receivable</p>
										<h6>{selectPayInv[0]?.total}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Supply date</p>
										<h6>{selectPayInv[0]?.supply_date}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Due date</p>
										<h6>{selectPayInv[0]?.payment_due_date}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Repayment time</p>
										<h6>{applyFinance?.repayment_plan}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Amount needed from Bridger</p>
										<h6>{applyFinance?.amount_requested}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>You receive</p>
										<h6>{repaymentAmount}</h6>
									</PreviewItem>
									<PreviewItem>
										<p>Reason for Invoice Financing</p>
										<h6>{applyFinance?.reason_for_financing}</h6>
									</PreviewItem>
									<ButtonSection>
										<Button
											title="Cancel"
											variant="reject"
											textColor="normal"
											size="md"
											onClick={handlePreviewToggle}
										/>
										<Button
											title="Continue"
											variant="default"
											textColor="normal"
											size="md"
											onClick={handleApplyFinancing}
											isLoading={isLoading}
										/>
									</ButtonSection>
								</>
							) : (
								<>
									<FormSubHeading>Order Information</FormSubHeading>
									<FormGroup>
										<label>Invoice number</label>
										<input type="number" disabled value={selectPayInv[0]?.order_number} />
									</FormGroup>
									<FormGroup>
										<label>Invoice total receivables</label>
										<input type="number" disabled value={selectPayInv[0]?.total} />
									</FormGroup>
									<FlexGroup>
										<div>
											<label>Supply date</label>
											<input type="date" disabled value={selectPayInv[0]?.supply_date} />
										</div>
										<div>
											<label>Due date</label>
											<input
												type="date"
												disabled
												value={selectPayInv[0]?.payment_due_date}
											/>
										</div>
									</FlexGroup>
									<FormSubHeading>Application Details</FormSubHeading>
									<FormGroup>
										<label>Repayment time</label>
										<StyledSlider
											min={0}
											max={90}
											marks={marks}
											step={30}
											onChange={handleSlider}
											defaultValue={0}
										/>
									</FormGroup>
									<FormGroup>
										<label>Amount needed from bridger</label>
										<input
											type="number"
											value={amount_requested}
											name="amount_requested"
											onChange={handleAmountChange}
										/>
									</FormGroup>
									<FormGroup>
										<label>Reason for invoice financing</label>
										<textarea
											onChange={handleAreaChange}
											value={reason_for_financing}
											name="reason_for_financing"
										></textarea>
									</FormGroup>
									<ButtonSection>
										<Button
											title="Cancel"
											variant="reject"
											textColor="normal"
											size="md"
											onClick={handleBack}
										/>
										<Button
											title="Continue"
											variant="default"
											textColor="normal"
											size="md"
											onClick={handlePreviewToggle}
										/>
									</ButtonSection>
								</>
							)}
						</FormContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleSuccessModal}
				heading=""
				subHeading=""
				modal={successModal}
				size="lg"
				body={
					<>
						<SuccessTitle>Application Submitted! </SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully applied to receive early payment. Once approved, a
								notification will be sent to the email of both parties.
							</p>
							<h6>You also have the option of saving this Invoice as a PDF.</h6>
							<div>
								<Button
									title="Continue"
									variant="default"
									textColor="normal"
									size="full"
									onClick={handleSuccessModal}
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Alert
				alertSeverity={"error"}
				open={errApplyModal}
				alertTitle={errApply || "Unable to Apply for Financing"}
				closeModal={closeErrApply}
			/>
		</>
	);
}

const BackDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	:hover {
		cursor: pointer;
	}
`;

export const FormContainer = styled.div`
	width: 492px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: white;
	padding-bottom: 60px;
	@media (max-width: 1366px) {
		width: 100%;
		display: flex;
		overflow-y: scroll;
		flex-wrap: wrap;
	}
`;

export const FlexGroup = styled.div`
	display: flex;
	justify-content: space-between;
	width: 492px;
	text-align: left;
	label {
		font-size: 14px;
		font-weight: 400;
		margin: 0px;
		color: #484848;
		text-align: left;
		width: 100%;
	}

	div {
		width: 236px;
		margin-top: 12px;
		margin-bottom: 12px;
	}
	input {
		width: 100%;
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: 16px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
	}
	@media (max-width: 576px) {
		flex-wrap: wrap;
		width: 400px;
		margin: auto;
		div {
			width: 100%;
		}
	}
	@media (max-width: 426px) {
		width: 360px;
	}
`;

export const FormSubHeading = styled.h2`
	font-weight: 500;
	font-size: 24px;
	color: black;
	margin-top: 46px;
	margin-bottom: 16px;
`;

export const FormGroup = styled.div`
	text-align: left;
	width: 492px;
	position: relative;
	margin-top: 12px;
	margin-bottom: 12px;
	label {
		font-size: 14px;
		font-weight: 400;
		margin: 0px;
		color: #484848;
		text-align: left;
		width: 100%;
	}
	input {
		width: 492px;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
	}
	select {
		width: 100%;
		height: 48px;
		border: 1px solid #d9d9d9;
		padding-right: 5px;
		padding-left: 5px;
	}
	textarea {
		width: 492px;
		height: 100px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		box-sizing: border-box;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
	}

	@media (max-width: 576px) {
		width: 100%;
		input {
			width: 100%;
			box-sizing: border-box;
		}
		textarea {
			width: 100%;
			box-sizing: border-box;
		}
	}
`;

const StyledSlider = styled(Slider)`
	margin-top: 15px;
	margin-bottom: 25px;
	@media (max-width: 991px) {
		width: 85%;
		height: 50px;
		margin: auto;
		margin-top: 15px;
	}
`;

const ButtonSection = styled.div`
	margin-top: 30px;
	margin-bottom: 30px;
	margin-left: auto;
	display: flex;
	gap: 16px;
	img {
		width: 40px;
	}
`;

const PreviewItem = styled.div`
	padding-top: 20px;
	padding-bottom: 17px;
	border-bottom: 1px solid #d4d4d4;
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	width: 100%;

	p {
		font-size: 16px;
		color: #484848;
		margin-top: 0px;
		margin-bottom: 0px;
		width: 65%;
		text-align: left;
	}
	h6 {
		font-size: 14px;
		color: var(--mainBlue);
		font-weight: bold;
		margin-top: 0px;
		margin-bottom: 0px;
		width: 30%;
		flex-wrap: wrap;
		text-align: right;
	}
`;
