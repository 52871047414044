import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import * as constants from "../../Components/Constants/api";
import EmptyImg from "../../assets/empty.png";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import Main from "../Constants/Main";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Button from "../Constants/FormComponents/Button";
import { FormatCurrency } from "../Constants/utils";
import Table from "../Constants/Table";
import { TableColumn } from "react-data-table-component";
import Status from "../Constants/Status";
import PaginatedCards from "../Constants/PaginatedCards";

const Index: React.FC = () => {
	const [purchaseOrder, setPurchaseOrder] = useState<any>([]);
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [addState, setAddState] = useState("");
	const [toggleState, setToggleState] = useState("all");
	const { allPurchaseOrderData } = useContext(AppContext);
	const [allOrdersCount, setAllOrdersCount] = useState<Number>(0);
	const [allNewCount, setAllNewCount] = useState<Number>(0);
	const [allCreatedCount, setAllCreatedCount] = useState<Number>(0);
	const [smallPOList, setSmallPOList] = useState<any>([]);

	const navigate = useNavigate();
	const handleCreateModal = () => {
		setCreateModal(!createModal);
	};

	const handleManualAdd = () => {
		setAddState("manual");
	};

	const handleImportAdd = () => {
		setAddState("import");
	};

	const handleCreateButton = () => {
		if (addState === "manual") {
			navigate("/purchase-order/create");
		}
	};

	useEffect(() => {
		const AllNew = allPurchaseOrderData?.filter(
			order =>
				order.status === "new" ||
				order.status === "accepted" ||
				order.status === "rejected"
		);
		const AllCreated = allPurchaseOrderData?.filter(
			order =>
				order.status === "created" ||
				order.status === "po accepted" ||
				order.status === "po rejected" ||
				order.status === "sent"
		);

		setPurchaseOrder(allPurchaseOrderData);
		setAddState("");
		setToggleState("all");
		setAllOrdersCount(allPurchaseOrderData?.length);
		setAllNewCount(AllNew?.length);
		setAllCreatedCount(AllCreated?.length);
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
		setSmallPOList(
			allPurchaseOrderData?.map(purchase => {
				return {
					date: `${purchase?.created_at?.substring(0, 10)}`,
					amount: `₦ ${Number(purchase?.total)?.toFixed(2)}`,
					invoiceTo: `PO To: ${purchase?.customer?.business_name}`,
					invoiceFrom: "PO From:",
					invoiceNo: purchase?.order_number,
					status: <Status statusState={purchase?.status} />,
					uid: purchase?.uid,
				};
			})
		);
	}, [allPurchaseOrderData, navigate]);

	const handleDetails = (row: any) => {
		if (row) {
			navigate("/purchase-order/preview", {
				state: { currentOrder: row },
			});
		}
	};

	const handleSmallDetails = (id: any) => {
		const current = allPurchaseOrderData?.filter(
			purchaseorder => purchaseorder?.uid === id
		);
		if (current) {
			navigate("/purchase-order/preview", {
				state: { currentOrder: current[0] },
			});
		}
	};

	const handleAllPurchase = () => {
		setPurchaseOrder(allPurchaseOrderData);
		setToggleState("all");
	};

	const handleNew = () => {
		const allNew = allPurchaseOrderData?.filter(
			order =>
				order.status === "new" ||
				order.status === "accepted" ||
				order.status === "rejected"
		);
		setPurchaseOrder(allNew);
		setToggleState("new");
	};

	const handleCreated = () => {
		const allCreated = allPurchaseOrderData?.filter(
			order =>
				order.status === "created" ||
				order.status === "po accepted" ||
				order.status === "po rejected" ||
				order.status === "sent"
		);
		setPurchaseOrder(allCreated);
		setToggleState("created");
	};

	const handleSearchPO = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value;
		const result = allPurchaseOrderData?.filter((order: any) => {
			return order?.customer?.name.toLowerCase().indexOf(input.toLowerCase()) > -1;
		});
		if (result?.length > 0 && input?.length) {
			setPurchaseOrder(result);
			setAllOrdersCount(result?.length);
		} else {
			const AllNew = allPurchaseOrderData?.filter(
				order =>
					order.status === "new" ||
					order.status === "accepted" ||
					order.status === "rejected"
			);
			const AllCreated = allPurchaseOrderData?.filter(
				order =>
					order.status === "created" ||
					order.status === "po accepted" ||
					order.status === "po rejected" ||
					order.status === "sent"
			);
			setPurchaseOrder(allPurchaseOrderData);
			setToggleState("all");
			setAllOrdersCount(allPurchaseOrderData?.length);
			setAllNewCount(AllNew?.length);
			setAllCreatedCount(AllCreated?.length);
		}
	};

	const AcceptOrder = () => {
		handleNew();
	};

	const columns: TableColumn<any>[] = [
		{
			name: "Date",
			selector: row => row?.created_at?.substring(0, 10),
			width: "10%",
			sortable: true,
		},
		{
			name: "Purchase Order No",
			selector: row => row?.order_number,
			width: "15%",
			sortable: true,
			center: true,
		},
		{
			name: "Purchase Order From",
			selector: row => row?.payment_link?.creator?.user_full_name,
			width: "20%",
		},
		{
			name: "Purchase Order to",
			selector: row => row?.customer?.business_name,
			width: "20%",
			sortable: true,
		},
		{
			name: "Amount",
			selector: row => FormatCurrency(row?.total),
			width: "15%",
			sortable: true,
		},
		{
			name: "Status",
			cell: (row: { status: string }) => <Status statusState={row?.status} />,
			center: true,
			width: "20%",
		},
	];
	return (
		<>
			<Main
				tabName="Purchase Order"
				rightSubHeader={
					<>
						<SubHeaderSearch>
							<SearchOutlinedIcon />
							<InputSearch
								placeholder="Search by customer name"
								onChange={handleSearchPO}
							/>
						</SubHeaderSearch>
						<Button
							title="Accept Purchase Order"
							onClick={AcceptOrder}
							size="md"
							textColor="brand"
							variant="secondary"
							icon={<DoneOutlinedIcon />}
						/>
						<Button
							title="Create Purchase Order"
							onClick={handleCreateModal}
							size="md"
							textColor="normal"
							variant="default"
							icon={<AddIcon />}
						/>
					</>
				}
				mainBody={
					<>
						<TableToggleContainer>
							<LeftTableToggle>
								<div
									onClick={handleAllPurchase}
									style={{
										borderBottom:
											toggleState === "all" ? "1px solid #302361" : "transparent",
										color: toggleState === "all" ? "#302361" : "#6D6D6D",
									}}
								>
									{`All Purchase Orders (${allOrdersCount})`}
								</div>
								<div
									onClick={handleNew}
									style={{
										borderBottom:
											toggleState === "new" ? "1px solid #302361" : "transparent",
										color: toggleState === "new" ? "#302361" : "#6D6D6D",
									}}
								>
									{`Received (${allNewCount})`}
								</div>
								<div
									style={{
										borderBottom:
											toggleState === "created" ? "1px solid #302361" : "transparent",
										color: toggleState === "created" ? "#302361" : "#6D6D6D",
									}}
									onClick={handleCreated}
								>
									{`Created (${allCreatedCount})`}
								</div>
							</LeftTableToggle>
							<RightTableToggle></RightTableToggle>
						</TableToggleContainer>
						<LGTableContainer>
							<Table
								data={purchaseOrder}
								columns={columns}
								pagination
								paginationPerPage={10}
								onRowClicked={(row: any) => handleDetails(row)}
							/>
							{!purchaseOrder.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no{" "}
										{toggleState === "all"
											? "purchase order,"
											: toggleState === "due"
											? "due purchase order,"
											: toggleState === "accepted,"
											? "accepted purchase order,"
											: toggleState === "rejected,"
											? "rejected purchase order,"
											: toggleState === "created,"
											? "created purchase order,"
											: "new purchase order,"}{" "}
										Click on the button below to create one
									</p>
									<Button
										title="Create Purchase Order"
										onClick={handleCreateModal}
										size="md"
										textColor="normal"
										variant="default"
										icon={<AddIcon />}
									/>
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={smallPOList}
								date="date"
								valueOne="invoiceFrom"
								valueThree="invoiceNumber"
								valueFour="amount"
								valueTwo="invoiceTo"
								id="uid"
								onClick={handleSmallDetails}
							/>
						</SMTBContainer>

						<Modal
							toggleModal={handleCreateModal}
							heading="Create Purchase Order"
							subHeading="Please select your preferred method"
							size="lg"
							modal={createModal}
							body={
								<>
									<CreateOptionsContainer>
										<CreateOptionsCard onClick={handleManualAdd}>
											<div>
												<CreateOutlinedIcon />
											</div>
											<div>
												<h4>Add Manually</h4>
												<p>Manually create an purchase order by inputing the details.</p>
											</div>
											<CheckedCard>
												{addState === "manual" ? (
													<CheckCircleIcon />
												) : (
													<CircleOutlined style={{ color: "#dfdfdf" }} />
												)}
											</CheckedCard>
										</CreateOptionsCard>
										<CreateOptionsCard onClick={handleImportAdd}>
											<div>
												<FileDownloadOutlinedIcon />
											</div>
											<div>
												<h4>Import purchase order</h4>
												<p>Import an already created purchase order</p>
											</div>
											<CheckedCard>
												{addState === "import" ? (
													<CheckCircleIcon />
												) : (
													<CircleOutlined style={{ color: "#dfdfdf" }} />
												)}
											</CheckedCard>
										</CreateOptionsCard>
										{addState === "manual" ? (
											<Button
												title="Continue"
												onClick={handleCreateButton}
												size="full"
												textColor="normal"
												variant="default"
											/>
										) : null}
									</CreateOptionsContainer>
								</>
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default Index;

const SubHeaderSearch = styled.div`
	width: 252px;
	font-size: 12px;
	color: #b5b5b5;
	display: flex;
	align-items: center;
	position: relative;
	svg {
		position: absolute;
		left: 16px;
	}
	@media (max-width: 1200px) {
		display: none;
	}
`;

const InputSearch = styled.input`
	width: 100%;
	height: 40px;
	padding-left: 42px;
	border-radius: 4px;
	border-color: transparent;
	color: #b5b5b5;
	background-color: #f7f7f7;
`;

const TableToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: 50px;
	overflow: scroll;
	@media (max-width: 576px) {
		padding-top: 30px;
	}
`;

const LeftTableToggle = styled.div`
	display: flex;
	gap: 30px;
	padding-bottom: 3px;
	font-size: 14px;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 1200px) {
		font-size: 12px;
	}
	@media (max-width: 576px) {
		div {
			padding-bottom: 10px;
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
`;

const RightTableToggle = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

const LGTableContainer = styled.div`
	@media (max-width: 576px) {
		display: none;
	}
`;

const SMTBContainer = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
`;

export const CreateOptionsContainer = styled.div`
	margin: 50px;
`;

export const CreateOptionsCard = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-top: 30px;
	margin-bottom: 30px;
	border: 1px solid #d9d9d9;
	padding: 25px;
	svg {
		color: black;
	}
	div {
		:first-child {
			width: 36px;
		}
	}
	h4 {
		margin-bottom: 6px;
		margin-top: 0px;
		font-size: 14px;
		color: black;
	}
	p {
		margin-top: 0px !important;
		margin-bottom: 0px;
		font-size: 12px;
		color: #6d6d6d;
	}
	:hover {
		cursor: pointer;
	}
`;

export const CheckedCard = styled.div`
	margin-left: auto;
	color: var(--mainBlue);
	svg {
		font-size: 13px;
	}
`;

export const EmptyData = styled.div`
	width: 100%;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	p {
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
		margin-bottom: 30px;
		color: black;
	}
	img {
		width: 180px;
		height: 140px;
	}
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: var(--mainBlue);
		width: 200px;
		height: 48px;
		margin: auto;
		border-color: transparent;
		color: white;
	}
`;
