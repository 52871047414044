import React, { useState, useContext, useMemo, useCallback } from "react";
import styled from "styled-components";
import EmptyImg from "../../assets/empty.png";
import Logo from "../../assets/logo.svg";
import Main from "../Constants/Main";
import { AppContext, CustomerProps, PaylinkProps } from "../../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FlexGroup } from "./AcceptForm";
import { HeadingContainer } from "./Index";
import { EmptyData } from "../Invoice/Index";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import { FormatCurrency } from "../Constants/utils";
import Modal from "../Constants/ModalComponent";
import { ToastContainer, toast } from "react-toastify";
import {
	ReceiptWrapper,
	ReceiptHeader,
	ReceiptBody,
	Center,
} from "./ExternalPay";
import { SuccessTitle, SuccessBody, Continue } from "../Invoice/InvoicePreview";
import { useTruncate } from "../../hooks/useTruncate";
import { faArrowLeft, faWallet } from "@fortawesome/free-solid-svg-icons";
import Button from "../Constants/FormComponents/Button";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";
import Input from "../Constants/FormComponents/Input";

const PaymentLink: React.FC = () => {
	const navigate = useNavigate();
	const [toggleState, setToggleState] = useState("created_link");
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [preview, setPreview] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selected, setSelected] = useState<Array<CustomerProps>>([]);
	const [referenceID, setReferenceID] = useState<string>("");
	const [allPaymentLinks, setAllPaymentLinks] = useState([]);
	const [filteredLinks, setFilteredLinks] = useState([]);
	const [paylinksCount, setPayLinksCount] = useState(0);
	const [createdLinksCount, setCreatedLinksCount] = useState(0);
	const [view, setView] = useState(false);
	const [togglePage, setTogglePage] = useState(false);

	const [current, setCurrent] = useState<Partial<PaylinkProps>>({
		amount: "",
		category: "",
		created_date: "",
		currency: "",
		description: "",
		dispute_reason: "",
		due_date: "",
		name: "",
		recipient_customer: "",
		recipient_email: "",
		recipient_name: "",
		recipient_phone: "",
		reference: "",
		status: "",
	});

	const [paymentForm, setPaymentForm] = useState({
		amount: "",
		name: "",
		description: "",
		category: "collection",
		recipient_customer: "",
		created_date: "",
		due_date: "",
	});

	const handlePageSwitch = () => setTogglePage(!togglePage);

	const { allCustomerData, userProfile } = useContext(AppContext);

	const handleEmpty = () => {
		setPaymentForm({
			...paymentForm,
			amount: "",
			name: "",
			description: "",
			category: "collection",
			recipient_customer: "",
			created_date: "",
			due_date: "",
		});
	};

	const handleToggleInvLink = () => {
		setToggleState("invoice_link");
		const Invoice = allPaymentLinks?.filter(
			(link: any) => link?.category === "invoice"
		);
		setFilteredLinks(Invoice);
	};

	const handleToggleCreLink = () => {
		setToggleState("created_link");
		const Created = allPaymentLinks?.filter(
			(link: any) => link?.category === "collection"
		);
		setFilteredLinks(Created);
	};

	const handlePreviewModal = () => {
		setPreview(!preview);
	};

	const handleViewModal = (id: number) => {
		setView(!view);
		setCurrent(filteredLinks[id]);
	};

	const closePreviewModal = () => {
		setPreview(false);
		handleEmpty();
	};

	const closeViewModal = () => {
		setView(false);
		handleEmpty();
		setCurrent({
			amount: "",
			category: "",
			created_date: "",
			currency: "",
			description: "",
			dispute_reason: "",
			due_date: "",
			name: "",
			recipient_customer: "",
			recipient_email: "",
			recipient_name: "",
			recipient_phone: "",
			reference: "",
			status: "",
		});
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPaymentForm({ ...paymentForm, [e.target.name]: e.target.value });
	};

	const handleSelectedCustomer = (target: String) => {
		const selectCustomer = allCustomerData.filter(
			customer => customer.uid === target
		);
		setSelected(selectCustomer);
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setPaymentForm({
			...paymentForm,
			[event.currentTarget.name]: event.currentTarget.value,
		});
		handleSelectedCustomer(event.currentTarget.value);
	};

	const fetchAllPaymentLink = useCallback(() => {
		constants.httprequest
			.get("/payments/payment-link/")
			.then(res => {
				setAllPaymentLinks(res?.data?.results);
				const Collection = res?.data?.results?.filter(
					(link: any) => link?.category === "collection"
				);
				const Invoice = res?.data?.results?.filter(
					(link: any) =>
						link?.category === "invoice" && link?.currency?.toLowerCase() === "ngn"
				);
				setFilteredLinks(Collection);
				setPayLinksCount(Collection?.length);
				setCreatedLinksCount(Invoice?.length);
			})
			.catch(err => {
				if (err?.response?.status === 401) {
					navigate("/");
				}
			});
	}, [navigate]);

	useMemo(() => {
		fetchAllPaymentLink();
	}, [fetchAllPaymentLink]);

	const handleCreatePayLink = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/payments/payment-link/", paymentForm)
			.then(res => {
				setIsLoading(false);
				setReferenceID(res?.data?.reference);
				fetchAllPaymentLink();
				handleSuccessModal();
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}

				toast.error(err?.response?.data?.errors[0]?.detail);
			});
	};

	const { amount, description, recipient_customer, created_date, due_date } =
		paymentForm;

	const disablePreview = () => {
		if (amount && description && recipient_customer && created_date && due_date) {
			return false;
		} else {
			return true;
		}
	};
	return (
		<>
			<Main
				tabName="Receive Payments"
				rightSubHeader={
					<>
						{togglePage ? (
							<div onClick={handlePageSwitch}>
								<FontAwesomeIcon icon={faArrowLeft} /> Go Back
							</div>
						) : (
							<SmallPaymentBtn onClick={handlePageSwitch}>
								View Payment Links
							</SmallPaymentBtn>
						)}
					</>
				}
				mainBody={
					<>
						<Container>
							{togglePage ? (
								<SmallRightContainer>
									<HeadingContainer>
										<h3>Payment Links</h3>
										<h6>Here’s a list of all your payment links.</h6>
									</HeadingContainer>
									<ToggleContainer>
										<div
											onClick={handleToggleCreLink}
											style={{
												borderBottom:
													toggleState === "created_link"
														? "1px solid #302361"
														: "transparent",
												color: toggleState === "created_link" ? "#302361" : "#6D6D6D",
											}}
										>
											{`Created Payment Link (${paylinksCount})`}
										</div>
										<div
											onClick={handleToggleInvLink}
											style={{
												borderBottom:
													toggleState === "invoice_link"
														? "1px solid #302361"
														: "transparent",
												color: toggleState === "invoice_link" ? "#302361" : "#6D6D6D",
											}}
										>
											{`Invoice links (${createdLinksCount})`}
										</div>
									</ToggleContainer>
									{filteredLinks?.map((eachLink: any, i) => {
										return eachLink?.currency?.toLowerCase() === "ngn" ? (
											<PaymentLinkFlex
												key={i}
												onClick={() => {
													handleViewModal(i);
												}}
											>
												<LeftLink>
													<div>
														<FontAwesomeIcon icon={faWallet} />{" "}
													</div>
													<div>
														<h3>{eachLink?.name}</h3>
														<p>{eachLink?.description}</p>
													</div>
												</LeftLink>
												<RightLink>
													<CopyToClipboard
														text={`${eachLink?.reference}`}
														onCopy={() => toast.success("Payment link Copied")}
													>
														<button>Copy</button>
													</CopyToClipboard>
													<button>Delete</button>
												</RightLink>
											</PaymentLinkFlex>
										) : null;
									})}
									{!filteredLinks.length && (
										<EmptyData>
											<img src={EmptyImg} alt="Empty " />
											<p>You have not created any Invoice link.</p>
										</EmptyData>
									)}
								</SmallRightContainer>
							) : (
								<>
									<FormContainer>
										<HeadingContainer>
											<h2>Payment Link</h2>
											<p>
												Create and send payment requests to your customers using links.
											</p>
										</HeadingContainer>
										<SelectWrapper
											size="full"
											title="Payment Request to"
											required
											children={
												<select
													onChange={handleSelectChange}
													name="recipient_customer"
													value={recipient_customer}
												>
													<option value="">--- Select Customer ---</option>
													{allCustomerData?.map((customer, i) => {
														return (
															<option value={`${customer.uid}`} key={i}>
																{customer.name}
															</option>
														);
													})}
												</select>
											}
										/>

										<Input
											inputType="text"
											title="Payment Description"
											name="description"
											value={description}
											size="full"
											onChange={handleChange}
											required
										/>
										<Input
											inputType="number"
											title="Payment Amount"
											name="amount"
											value={amount}
											size="full"
											onChange={handleChange}
											required
										/>

										<FlexGroup>
											<div>
												<label>Supply date</label>
												<input
													type="date"
													name="created_date"
													onChange={handleChange}
													value={created_date}
												/>
											</div>
											<div>
												<label>Due date</label>
												<input
													type="date"
													name="due_date"
													onChange={handleChange}
													value={due_date}
												/>
											</div>
										</FlexGroup>
										<Button
											variant="default"
											textColor="normal"
											title="Preview"
											size="full"
											disabled={disablePreview()}
											onClick={handlePreviewModal}
										/>
									</FormContainer>
									<RightContainer>
										<HeadingContainer>
											<h3>Payment Links</h3>
											<h6>Here’s a list of all your payment links.</h6>
										</HeadingContainer>
										<ToggleContainer>
											<div
												onClick={handleToggleCreLink}
												style={{
													borderBottom:
														toggleState === "created_link"
															? "1px solid #302361"
															: "transparent",
													color: toggleState === "created_link" ? "#302361" : "#6D6D6D",
												}}
											>
												{`Created Payment Link (${paylinksCount})`}
											</div>
											<div
												onClick={handleToggleInvLink}
												style={{
													borderBottom:
														toggleState === "invoice_link"
															? "1px solid #302361"
															: "transparent",
													color: toggleState === "invoice_link" ? "#302361" : "#6D6D6D",
												}}
											>
												{`Invoice links (${createdLinksCount})`}
											</div>
										</ToggleContainer>
										{filteredLinks?.map((eachLink: any, i) => {
											return eachLink?.currency?.toLowerCase() === "ngn" ? (
												<PaymentLinkFlex
													key={i}
													onClick={() => {
														handleViewModal(i);
													}}
												>
													<LeftLink>
														<div>
															<FontAwesomeIcon icon={faWallet} />{" "}
														</div>
														<div>
															<h3>{eachLink?.name}</h3>
															<p>{eachLink?.description}</p>
														</div>
													</LeftLink>
													<RightLink>
														<CopyToClipboard
															text={`${eachLink?.reference}`}
															onCopy={() => toast.success("Payment link Copied")}
														>
															<button>Copy</button>
														</CopyToClipboard>
														<button>Delete</button>
													</RightLink>
												</PaymentLinkFlex>
											) : null;
										})}
										{!filteredLinks.length && (
											<EmptyData>
												<img src={EmptyImg} alt="Empty " />
												<p>You have not created any Invoice link.</p>
											</EmptyData>
										)}
									</RightContainer>
								</>
							)}
						</Container>
					</>
				}
			/>
			<Modal
				toggleModal={closePreviewModal}
				size="lg"
				heading={
					<>
						<ToastContainer />
						<Center>
							<img src={Logo} alt="Bridger Africa" />
						</Center>
					</>
				}
				subHeading=""
				modal={preview}
				body={
					<>
						<ReceiptWrapper>
							<ReceiptHeader>
								<div>
									<h6>Billed from</h6>
									<h6>Billed to</h6>
								</div>
								<div>
									<h6>
										{" "}
										{userProfile?.first_name} {userProfile?.last_name}
									</h6>
									<h6>{selected[0]?.name}</h6>
								</div>
								<div>
									<h6>{userProfile?.email}</h6>
									<h6>{selected[0]?.company_email}</h6>
								</div>
							</ReceiptHeader>
							<Center>
								<h4>REQUEST PAYMENT</h4>
							</Center>
							{referenceID ? (
								<CopyContainer>
									<CopyToClipboard
										text={`${constants.BASE_URL}pay/${referenceID}`}
										onCopy={() => toast.success("Payment link Copied")}
									>
										<button>
											<InsertLinkIcon />
										</button>
									</CopyToClipboard>
									<div>
										{useTruncate(`${constants.BASE_URL}pay/${referenceID}`, 45, "...")}
									</div>
									<CopyToClipboard
										text={`${constants.BASE_URL}pay/${referenceID}`}
										onCopy={() => toast.success("Payment link Copied")}
									>
										<button>
											Copy link
											<ContentCopyIcon />
										</button>
									</CopyToClipboard>
								</CopyContainer>
							) : null}

							<ReceiptBody>
								<div>
									<h4>Payment Description</h4>
									<h5>{description}</h5>
								</div>
								<div>
									<h4>Payment amount</h4>
									<h5>NGN {FormatCurrency(amount)} </h5>
								</div>
								<div>
									<h4>Date Created</h4>
									<h5>{created_date}</h5>
								</div>
								<div>
									<h4>Date Due</h4>
									<h5>{due_date}</h5>
								</div>
							</ReceiptBody>
							{!referenceID ? (
								<Button
									title="Create Payment Link"
									variant="default"
									textColor="normal"
									size="full"
									isLoading={isLoading}
									onClick={handleCreatePayLink}
								/>
							) : null}
						</ReceiptWrapper>
					</>
				}
			/>
			<Modal
				toggleModal={closeViewModal}
				size="lg"
				heading={
					<>
						<ToastContainer />
						<Center>
							<img src={Logo} alt="Bridger Africa" />
						</Center>
					</>
				}
				subHeading=""
				modal={view}
				body={
					<>
						<ReceiptWrapper>
							<ReceiptHeader>
								<div>
									<h6>Billed from</h6>
									<h6>Billed to</h6>
								</div>
								<div>
									<h6>
										{" "}
										{userProfile?.first_name} {userProfile?.last_name}
									</h6>
									<h6>{current?.recipient_name}</h6>
								</div>
								<div>
									<h6>{userProfile?.email}</h6>
									<h6>{current?.recipient_email}</h6>
								</div>
							</ReceiptHeader>
							<Center>
								<h4>REQUEST PAYMENT</h4>
							</Center>
							{current?.reference ? (
								<CopyContainer>
									<CopyToClipboard
										text={`${constants.BASE_URL}pay/${current?.reference}`}
										onCopy={() => toast.success("Payment link Copied")}
									>
										<button>
											<InsertLinkIcon />
										</button>
									</CopyToClipboard>
									<div>
										{useTruncate(
											`${constants.BASE_URL}pay/${current?.reference}`,
											45,
											"..."
										)}
									</div>
									<CopyToClipboard
										text={`${constants.BASE_URL}pay/${current?.reference}`}
										onCopy={() => toast.success("Payment link Copied")}
									>
										<button>
											Copy link
											<ContentCopyIcon />
										</button>
									</CopyToClipboard>
								</CopyContainer>
							) : null}

							<ReceiptBody>
								<div>
									<h4>Payment Description</h4>
									<h5>{current?.description}</h5>
								</div>
								<div>
									<h4>Payment amount</h4>
									<h5>NGN {FormatCurrency(current?.amount)} </h5>
								</div>
								<div>
									<h4>Date Created</h4>
									<h5>{current?.created_date}</h5>
								</div>
								<div>
									<h4>Date Due</h4>
									<h5>{current?.due_date}</h5>
								</div>
							</ReceiptBody>
						</ReceiptWrapper>
					</>
				}
			/>
			<Modal
				size="lg"
				toggleModal={handleSuccessModal}
				heading={
					<>
						<ToastContainer />
					</>
				}
				subHeading=""
				modal={successModal}
				body={
					<>
						<SuccessTitle>Payment Request Sent! </SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully created a payment request to
								{selected[0]?.name}. Your customer will receive an email or notification
								containing the payment request details.
							</p>
							<div>
								<CopyToClipboard
									text={`${constants.BASE_URL}${referenceID}`}
									onCopy={() => toast.success("Payment link Copied")}
								>
									<Continue>Copy Link</Continue>
								</CopyToClipboard>
								<Continue onClick={handleSuccessModal}>Continue</Continue>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<ToastContainer />
		</>
	);
};

export default PaymentLink;

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 50px;
	align-items: start;
	@media (max-width: 1366px) {
		flex-wrap: wrap;
	}
	@media (max-width: 991px) {
		justify-content: center;
		margin: auto;
	}
`;

const RightContainer = styled.div`
	width: 468px;
	text-align: center;
	@media (max-width: 1336px) {
		display: none;
	}
	@media (max-width: 576px) {
		width: 400px;
		margin: auto;
	}
	@media (max-width: 426px) {
		width: 360px;
	}
`;

const SmallPaymentBtn = styled.button`
	width: 162px;
	height: 43px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border: 1px solid var(--mainBlue);
	color: var(--mainBlue);
	border-radius: 3px;
	@media (min-width: 1336px) {
		display: none;
	}
`;

const SmallRightContainer = styled.div`
	width: 468px;
	text-align: center;
	@media (min-width: 1336px) {
		display: none;
	}
	@media (max-width: 576px) {
		width: 400px;
		margin: auto;
	}
	@media (max-width: 426px) {
		width: 360px;
	}
`;
export const ToggleContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 30px;
	margin-top: 30px;
	padding-bottom: 3px;
	font-size: 14px;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 1200px) {
		font-size: 12px;
	}
	@media (max-width: 576px) {
		div {
			padding-bottom: 10px;
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
`;

const FormContainer = styled.div`
	width: 492px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: white;
	padding-bottom: 60px;

	@media (max-width: 576px) {
		width: 400px;
		margin: auto;
	}
	@media (max-width: 426px) {
		width: 320px;
	}
`;

const PaymentLinkFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #ececec;
	padding-bottom: 15px;
	padding-top: 15px;
`;

const LeftLink = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	div {
		:first-child {
			background-color: var(--mainBlue);
			color: white;
			width: 48px;
			height: 48px;
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
		}
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #b5b5b5;
		font-size: 14px;
		font-weight: 500;
	}
	h3 {
		font-weight: 500;
		font-size: 14px;
		color: black;
		margin-top: 0px;
		margin-bottom: 0px;
		text-align: left;
	}
`;

const RightLink = styled.div`
	display: flex;
	gap: 8px;
	:hover {
		text-decoration: underline;
	}
	button {
		background-color: transparent;
		border-color: transparent;
		font-size: 14px;
		font-weight: 500;
		:first-child {
			color: var(--mainBlue);
		}
		:last-child {
			color: var(--textRed);
		}
	}
`;

const CopyContainer = styled.div`
height: 48px;
width: 480px;
margin: auto;
margin-top: 30px;
margin-bottom: 30px;
display: flex;
justify-content: center;
font-size: 12px;
button{
  height: 48px;
  display: flex;
  :first-child{
    width:: 64px;
    border-color: transparent;
    border-right: 1px solid #eaeaea;
    background-color: #fafafb;
    color: black;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    align-items:center;
  }
  :last-child{
    background-color: var(--mainBlue);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: white;
    width: 120px;
    gap:10px;
    font-weight: 500;
    display: flex;
  align-items:center;
  border-color: transparent;
  }
}
div{
  background-color: #fafafb;
  padding-left: 15px;
  color: black;
  box-sizing: border-box;
  // width: calc(100% - 168px);
  display: flex;
  align-items:center;
}
@media(max-width: 576px){
  width: 90%;
}
`;
