import styled from "styled-components";

interface PrependProps {
	size: string;
}

export const Container = styled.div`
	width: 100%;
	margin: auto;
`;

export const PrependSelectWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: no-wrap;

	select {
		width: 80% !important;
	}

	input{
		width: 80% !important;
	}
`;

export const PrependSelect = styled.div<PrependProps>`
	width: 20%;
	margin-top: 0px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ size }) =>
		size === "lg"
			? "48px"
			: size === "md"
			? "46px"
			: size === "sm"
			? "46px"
			: size === "full"
			? "46px"
			: ""};
	border: 1px solid #d9d9d9;
	border-right: transparent;
	img {
		width: 25px;
		height: 25px;
	}
`;
export const TransparentBlueBtn = styled.button``;
export const BlueBtn = styled.button``;
export const RedButton = styled.button``;
