import React, { useState, useContext } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import Alert from "../Constants/Alert";
import Button from "../Constants/FormComponents/Button";

const DeleteOrderModal = (props: any) => {
	const { fetchAllInvoiceData } = useContext(AppContext);
	const { deleteModal, handleDeleteModal, currentOrder } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [errorModal, setErrorModal] = useState(false);
	const navigate = useNavigate();

	const closeErrModal = () => {
		setErrorModal(false);
	};

	const handleDeleteOrder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.delete(`/inventory/invoices/${currentOrder?.uid}/`)
			.then(() => {
				setIsLoading(false);
				fetchAllInvoiceData();
				handleDeleteModal();
				navigate("/invoice");
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrorMsg(err?.response?.data?.errors);
			});
	};
	return (
		<>
			<Modal
				toggleModal={handleDeleteModal}
				modal={deleteModal}
				heading=""
				subHeading=""
				size="lg"
				body={
					<>
						<Wrapper>
							<>
								<FormTitle>Are you sure?</FormTitle>
								<p>
									You are about to delete Invoice #{currentOrder?.order_number} from your
									purchase order list. All your transaction records with this purchase
									order alongside their details will be cleared. This action is
									irreversible.
								</p>
								<p>Do you want to go ahead ?</p>

								<ActionButtons>
									<Button
										title="Delete"
										onClick={handleDeleteOrder}
										size="full"
										textColor="normal"
										variant="reject"
										isLoading={isLoading}
									/>
								</ActionButtons>
							</>

							<Alert
								alertSeverity={"error"}
								open={errorModal}
								alertTitle={errorMsg || "Unable to Delete Modal"}
								closeModal={closeErrModal}
							/>
						</Wrapper>
					</>
				}
			/>
		</>
	);
};

export default DeleteOrderModal;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	p {
		font-size: 13px;
		line-height: 27px;
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;

const FormTitle = styled.h2`
	color: black;
	font-weight: 500;
	font-size: 18px;
	marginn-bottom: 3px;
`;
export const DeleteButton = styled.button`
	background-color: var(--textRed);
	width: 100%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
	margin-top: 20px;
	img {
		height: 40px;
	}
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;
