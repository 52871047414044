import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import Edit from "../Constants/FormComponents/Edit";
import Select from "react-select";
import * as constants from "../Constants/api";
import Input from "../Constants/FormComponents/Input";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";

type Props = {
	name_enquiry: string;
	transaction_amount: string;
	narration: string;
	handleChangeTransferToBank: any;
	bank_name: string;
	account_name: string;
	bankList: [];
};

export default function TransferToBankForm({
	bankList,
	name_enquiry,
	bank_name,
	account_name,

	handleChangeTransferToBank,
}: Props) {
	const [checked, setChecked] = useState(false);

	const [transferToBankDetails, setTransferToBankDetails] = useState({
		bank_code: "",
		account_number: "",
		transaction_amount: "",
		narration: "",
		name_enquiry,
		bank_name,
		account_name,
		save_beneficiary: false,
	});

	const [account, setAccount] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [bank, setBank] = useState({
		bank_code: "",
		account_number: "",
	});

	useEffect(() => {
		handleChangeTransferToBank(transferToBankDetails);
	}, [transferToBankDetails]);

	const handleChangeSelectMulti = (e: any) => {
		const selectedBank = e.value;
		setTransferToBankDetails({
			...transferToBankDetails,
			bank_code: selectedBank,
			bank_name: e.label,
		});
		setBank({ ...bank, bank_code: selectedBank });
		resolveBank(bank.account_number);
	};
	// This function fetches the bank details based on account number and bank code
	const resolveBank = (account: any) => {
		if (account.length == 10 && bank.bank_code?.length > 1) {
			constants.httprequest
				.post("/accounts/bank-account/resolve/", {
					bank_code: bank.bank_code,
					account_number: account,
				})
				.then(res => {
					if (res.status === 201) {
						setTransferToBankDetails({
							...transferToBankDetails,
							name_enquiry: res.data.account_enquiry.uid,
							account_name: res.data.account_enquiry.account_name,
							account_number: res.data.account_enquiry.account_number,
						});
						setAccount(res.data.account_enquiry.account_name);
					}
				})
				.catch(() => {
					setErrorMessage("Account number could not be validated");
				});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTransferToBankDetails({
			...transferToBankDetails,
			[e.target.name]: e.target.value,
		});
		if (e.target.name === "account_number") {
			setBank({ ...bank, [e.target.name]: e.target.value });
			setTransferToBankDetails({
				...transferToBankDetails,
				[e.target.name]: e.target.value,
			});
			resolveBank(e.target.value);
		}
	};

	const handleCheck = () => {
		setChecked(!checked);

		setTransferToBankDetails({
			...transferToBankDetails,
			save_beneficiary: !transferToBankDetails.save_beneficiary,
		});
	};

	return (
		<div>
			<SelectWrapper
				size="lg"
				title="Bank Name"
				required
				children={
					<Select
						options={bankList}
						isSearchable
						onChange={e => handleChangeSelectMulti(e)}
					/>
				}
			/>

			<Input
				size="lg"
				title="Bank account number"
				inputType="number"
				value={bank.account_number}
				name="account_number"
				placeHolder="201122222"
				onChange={e => handleChange(e)}
			/>

			{account}
			<SpanError>{errorMessage}</SpanError>

			<Input
				size="lg"
				title="Transaction amount"
				inputType="text"
				value={transferToBankDetails.transaction_amount}
				name="transaction_amount"
				placeHolder="2000"
				onChange={e => handleChange(e)}
			/>

			<Input
				size="lg"
				title="Narration"
				inputType="text"
				value={transferToBankDetails.narration}
				name="narration"
				placeHolder="Groceries"
				onChange={e => handleChange(e)}
			/>

			<SaveBeneficiary>
				<input
					type="checkbox"
					id="save_beneficiary"
					name="checked"
					onChange={() => handleCheck()}
					checked={transferToBankDetails.save_beneficiary}
				/>
				<label htmlFor="save_beneficiary">Save Beneficiary</label>
			</SaveBeneficiary>
		</div>
	);
}

const SpanError = styled.span`
	font-size: 12px;
	color: red;
`;

const SaveBeneficiary = styled.div`
	display: flex;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 12px;
	padding-top: 15px;
	input {
		margin-right: 10px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
	}
`;
