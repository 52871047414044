import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Status from "./Status";

// The small cards design is in this format
// ValueOne and valueTwo are preferably used for informations concerning the sender and receiver of purchase Orders or Invoices
/*
	Date(2021-04-07)				Status

	ValueOne							
	ValueTwo	

	ValueThree						ValueFour
	ValueFive						ValueSix
*/

interface Props {
	currentItems: any;
	title?: string;
	onClick?: any;
	date?: any;
	cardStatus?: boolean;
	valueOne?: any;
	valueTwo?: any;
	valueThree?: any;
	valueFive?: any;
	valueFour?: any;
	valueSix?: any;
	id?: string;
}

function TableCard({
	currentItems,
	title,
	onClick,
	date,
	cardStatus,
	valueOne,
	valueTwo,
	valueThree,
	valueFour,
	valueFive,
	valueSix,
}: Props) {
	return (
		<>
			<TableTitle>{title}</TableTitle>
			{currentItems &&
				currentItems.map((item: any) => {
					return (
						<SmallTableContent onClick={() => onClick(item?.uid)} key={uuidv4()}>
							<div>
								<div>{date && item[date]}</div>
								<div>
									{cardStatus ? <Status statusState={item?.status} /> : item?.status}
								</div>
							</div>

							<div>
								<h4>{valueOne && item[valueOne]}</h4>
								<h4>{valueTwo && item[valueTwo]}</h4>
							</div>

							<div>
								<h4>{valueThree && item[valueThree]}</h4>
								<h4>{valueFour && item[valueFour]}</h4>
							</div>

							<div>
								<h4>{valueFive && item[valueFive]}</h4>
								<h4>{valueSix && item[valueSix]}</h4>
							</div>
						</SmallTableContent>
					);
				})}
		</>
	);
}

const TableTitle = styled.h2`
	font-size: 18px;
	font-weight: 500;
	text-align: left;
	margin-bottom: 20px;
	margin-top: 40px;
`;
const SmallTableContent = styled.div`
	width: 90%;
	margin: auto;
	padding: 10px;
	margin: 15px 0px;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	font-weight: 400;
	box-sizing: border-box;
	background-color: white;

	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 5px;
		font-size: 12px;
		padding-bottom: 5px;
	}

	div:nth-child(2) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		padding-top: 5px;
		font-size: 12px;
		padding-bottom: 5px;
	}
	span {
		padding-right: 5px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-size: 12px;
	}
	h5 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-size: 12px;
	}
	p {
		font-size: 12px;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	h4 {
		font-size: 12px;
		font-weight: 400;
		padding: 5px 0;
		margin: 0;
	}
	@media (max-width: 576px) {
		width: 100%;
	}
`;
export default TableCard;
