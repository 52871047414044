import React from "react";
import styled from "styled-components";

type Props = {
	inputType: string;
	title: string;
	value: string;
	name: string;
	placeHolder: string;
	/* eslint-disable-next-line no-unused-vars */
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Edit({
	inputType,
	title,
	value,
	name,
	onChange,
	placeHolder,
}: Props) {
	return (
		<InputWrapper>
			{title}
			<StyledInput
				type={inputType}
				value={value}
				name={name}
				onChange={onChange}
				placeholder={placeHolder}
			/>
		</InputWrapper>
	);
}

const InputWrapper = styled.label`
	display: flex;
	flex-direction: column;
	jusify-content: center;
	max-width: 600px;
	text-align: left;
	font-size: 14px;
	padding-top: 25px;

	@media (max-width: 600px) {
		max-width: 319px;
	}
`;

const StyledInput = styled.input`
	height: 40px;
	top: 26.146087646484375px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	background: none;
	margin-top: 10px;
	padding-left: 5px;
`;
