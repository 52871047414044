import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import referralBag from "../../../assets/referralBag.png";
import { ToastContainer, toast } from "react-toastify";
import { AppContext } from "../../../Context";

export default function Right() {
	const { userProfile, overviewDetails } = useContext(AppContext);

	const [displayCreditScore, setDisplayCreditScore] = useState(false);

	const copyToClipboard = () => {
		copy(`${window.location.origin}/signup?referral=${userProfile.public_id}`);
		toast.success("Invite link copied");
	};

	const creditScores = () => {
		if (overviewDetails?.top_credit_scorers.length) {
			setDisplayCreditScore(true);
		}
	};
	useEffect(() => {
		creditScores();
	}, [overviewDetails]);

	return (
		<div>
			<div>
				<TopBuyer>
					<h3>TOP BUYERS</h3>

					<TopBuyerTitle>
						<h4>Rank</h4> <h4>Customer</h4> <h4>Invoices</h4>
					</TopBuyerTitle>
					{overviewDetails?.top_buyers?.map((item: any, index: any) => {
						return (
							<TopBuyerBody key={index}>
								<p>
									<FontAwesomeIcon
										icon={faCaretUp}
										style={{ color: "#4BB543", margin: "4px 4px 0 0" }}
									/>
									{index + 1}
								</p>{" "}
								<p>{item.name}</p> <p>{item.invoices_count}</p>
							</TopBuyerBody>
						);
					})}
				</TopBuyer>

				<TopPayment>
					<h3>CREDIT RATING</h3>

					<TopBuyerTitle>
						<h4>S/N</h4> <h4>Customer</h4> <h4>Credit Score</h4>
					</TopBuyerTitle>

					{displayCreditScore ? (
						overviewDetails?.top_credit_scorers?.map((item: any, index: any) => {
							return (
								<TopPaymentBody key={index}>
									<p>
										<FontAwesomeIcon
											icon={faCaretUp}
											style={{ color: "#4BB543", margin: "4px 2px 0 0" }}
										/>
										{index + 1}
									</p>
									<p>{item?.name}</p> <p>{item?.credit_score}</p>
								</TopPaymentBody>
							);
						})
					) : (
						<p>
							You haven't checked any customer’s credit rating yet. Begin by selecting
							customers to view their data
						</p>
					)}
				</TopPayment>
				<Reminders>
					<h3>INVOICE REMINDERS</h3>
					<h4>{overviewDetails?.invoice_reminders}</h4>
					<p>
						Last sent on{" "}
						{new Date(overviewDetails?.last_reminder_sent).toDateString()}
					</p>
				</Reminders>

				<Referral>
					<h3>Get bonus when you invite your friends to Bridger!</h3>

					<div>
						<ReferralDetails>
							<button onClick={copyToClipboard}>
								Invite Friend <ArrowOutwardIcon style={{ fontSize: "small" }} />
							</button>

							<p>Referral code: {userProfile.public_id}</p>
						</ReferralDetails>

						<img src={referralBag} alt="bag image" />
					</div>
				</Referral>
			</div>
			<ToastContainer />
		</div>
	);
}

const TopBuyer = styled.div`
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.3);
	background: #fff;
	padding: 15px 15px;
	max-width: 230px;
	margin: 0 auto;
	h3 {
		text-align: left;
		font-size: 14px;
		font-weight: 500;
	}
	@media (max-width: 500px) {
		max-width: 90%;
	}
`;

const TopBuyerTitle = styled.span`
	display: grid;
	grid-template-columns: 40px 2fr 2fr;
	text-align: left;
	grid-gap: 20px;
	font-size: 12px;
	font-weight: 400;
	color: #b5b5b5;
	h4 {
		margin-bottom: 0;
	}
`;
const TopPayment = styled.div`
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.3);
	background: #fff;
	padding: 15px 15px;
	max-width: 230px;
	margin: 0 auto;
	margin-top: 20px;
	h3 {
		text-align: left;
		font-size: 14px;
		font-weight: 500;
	}
	p {
		text-align: left;
		font-size: 12px;
		font-weight: 400;
	}
	@media (max-width: 500px) {
		max-width: 90%;
	}
`;
const Reminders = styled.div`
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.3);
	background: #fff;
	padding: 15px 15px;
	max-width: 230px;
	margin: 0 auto;
	margin-top: 20px;
	text-align: left;
	h3 {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 0;
	}
	h4 {
		margin: 5px 0 6px 0;
		font-size: 28px;
		color: #302361;
		font-weight: 500;
	}
	p {
		font-size: 12px;
		color: #b5b5b5;
	}
`;

// const TopPaymentTitle = styled.span`
// 	display: flex;
// 	justify-content: space-between;
// 	text-align: left;

// 	font-size: 12px;
// 	font-weight: 400;
// 	color: #b5b5b5;
// 	h4 {
// 		margin-bottom: 0;
// 	}
// `;
const TopBuyerBody = styled.span`
	display: grid;
	grid-template-columns: 40px 3fr 1fr;
	text-align: left;
	grid-gap: 20px;
	font-size: 12px;
	font-weight: 400;
`;
const TopPaymentBody = styled.span`
	display: flex;
	justify-content: space-between;
	text-align: left;
	grid-gap: 20px;
	font-size: 12px;
	font-weight: 400;
`;

const Referral = styled.div`
	background-color: #302361;
	color: #fff;
	padding: 10px 10px 0px 15px;
	border-radius: 7px;
	text-align: left;
	margin-top: 15px;
	h3 {
		font-size: 17px;
		font-weight: 500;
	}
	p {
		font-size: 12px;
	}
	button {
		border-radius: 3px;
		border: none;
		cursor: pointer;
		padding: 5px 8px;
		color: #302361;
		font-weight: 500;
		font-size: 12px;
	}
	div {
		display: flex;
		justify-content: space-between;
	}
	@media (max-width: 500px) {
		max-width: 90%;
	}
`;
const ReferralDetails = styled.span``;
