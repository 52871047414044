import styled from "styled-components";

interface Props {
	statusState: string;
}

export default function Status({ statusState }: Props) {
	return <StatusWrapper statusState={statusState}>{statusState}</StatusWrapper>;
}

const StatusWrapper = styled.div<Props>`
	width: 150px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
	border: none;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	color: ${({ statusState }) =>
		statusState === "unpaid" ||
		statusState === "pending" ||
		statusState === "draft" ||
		statusState === "invite_sent"
			? "#F3BE4F"
			: statusState === "accepted" ||
			  statusState === "invoice accepted" ||
			  statusState === "po accepted" ||
			  statusState === "new" ||
			  statusState === "approved" ||
			  statusState === "active"
			? "#4BB543"
			: statusState === "created" ||
			  statusState === "paid" ||
			  statusState === "successful" ||
			  statusState === "sent"
			? "#FFFFFF"
			: statusState === "due" ||
			  statusState === "rejected" ||
			  statusState === "failed" ||
			  statusState === "po rejected" ||
			  statusState === "invoice rejected"
			? "#CD0A0A"
			: "#FFFFFF"};
	background-color: ${({ statusState }) =>
		statusState === "accepted" ||
		statusState === "invoice accepted" ||
		statusState === "po accepted" ||
		statusState === "new" ||
		statusState === "approved" ||
		statusState === "active"
			? "#DCF8EF"
			: statusState === "unpaid" ||
			  statusState === "pending" ||
			  statusState === "draft" ||
			  statusState === "invite_sent"
			? "#FCF2DC"
			: statusState === "created" ||
			  statusState === "paid" ||
			  statusState === "successful" ||
			  statusState === "sent"
			? "#4BB543"
			: statusState === "due" ||
			  statusState === "rejected" ||
			  statusState === "failed" ||
			  statusState === "po rejected" ||
			  statusState === "invoice rejected"
			? "#F2D4D4"
			: "#E6E6E6"};
	@media (max-width: 576px) {
		width: 100%;
		box-sizing: border-box;
		text-align: center;
		padding-left: 12px;
		padding-right: 12px;
	}
`;
