import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as constants from "../Constants/api";
import { AppContext, InvoiceItemProp } from "../../Context";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../Constants/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import { FormatCurrency } from "../Constants/utils";
import { v4 as uuidv4 } from "uuid";
import Button from "../Constants/FormComponents/Button";

import Modal from "../Constants/ModalComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

export default function InvoicePreviewPublic() {
	const location = useLocation();
	const [invoiceDetails, setInvoiceDetails] = useState<any>();
	const [isLoadingAccept, setIsLoadingAccept] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [acceptModal, setAcceptModal] = useState<boolean>(false);
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const [errAcceptModal, setErrAcceptModal] = useState(false);
	const [errAccept, setErrAccept] = useState("");
	const [errRejectModal, setErrRejectModal] = useState(false);
	const [errReject, setErrReject] = useState("");
	// const [copied, setCopied] = useState(false);
	const [rejectMessage, setRejectMessage] = useState("");

	const navigate = useNavigate();

	const handleAcceptModal = () => {
		setAcceptModal(!acceptModal);
	};
	const handleRejectModal = () => {
		setRejectModal(!rejectModal);
	};

	const handlePay = () => {
		navigate(`/pay/${invoiceDetails?.payment_link}`);
	};

	const { fetchAllInvoiceData } = useContext(AppContext);

	const closeErrAccept = () => {
		setErrAcceptModal(false);
	};
	const closeErrReject = () => {
		setErrRejectModal(false);
	};
	const handleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};
	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setRejectMessage(event.currentTarget.value);
	};

	const ref = location.pathname.split("/");

	useEffect(() => {
		axios
			.get(`${constants.API_URL}/inventory/public-invoices/${ref[3]}/`)
			.then(res => {
				setInvoiceDetails(res?.data);
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	}, []);

	useEffect(() => {
		axios
			.post(
				`${constants.API_URL}/inventory/public-invoices/${ref[3]}/record_view/`
			)
			.then()
			.catch(() => {});
	}, [invoiceDetails]);

	const AcceptInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingAccept(true);
		constants.httprequest
			.post(`/inventory/public-invoices/${invoiceDetails?.uid}/options/`, {
				option: "accept",
			})
			.then(() => {
				toast.success("Invoice Accepted, proceed to make payment");
				setIsLoadingAccept(false);
				setAcceptModal(false);
				window.location.reload();
			})
			.catch(err => {
				setIsLoadingAccept(false);
				setErrAcceptModal(true);
				setErrAccept(err?.response?.data?.errors[0]?.detail);
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const RejectInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingReject(true);
		constants.httprequest
			.post(`/inventory/public-invoices/${invoiceDetails?.uid}/options/`, {
				option: "reject",
				message: rejectMessage,
			})
			.then(() => {
				setIsLoadingReject(false);
				toast.success("Invoice Rejected");
				setRejectModal(false);
				navigate("/");
			})
			.catch(err => {
				setIsLoadingReject(false);

				setErrRejectModal(true);
				setErrReject(err?.response?.data?.errors[0]?.detail);
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleDownloadInvoice = () => {
		if (invoiceDetails?.pdf === null) {
			toast.error("Your invoice PDF is processing, please try again");
			navigate("/invoice");
			fetchAllInvoiceData();
		} else {
			window.open(invoiceDetails?.pdf, "_blank");
		}
	};

	const discountedAmount =
		(Number(invoiceDetails?.dynamic_discount_value) / 100) *
		Number(invoiceDetails?.total);

	return (
		<Wrapper>
			<>
				<DetailsContainer>
					<DetailsHeader>
						<InvoiceNumber>
							<div
								style={{
									backgroundColor:
										invoiceDetails?.status === "rejected" ? "#cd0a0a" : "#302361",
								}}
							></div>
							<h4>{`Invoice # ${invoiceDetails?.order_number}`}</h4>{" "}
							{invoiceDetails?.status === "invoice accepted" && (
								<StyledCollapseBtn onClick={handlePay}>Make Payment</StyledCollapseBtn>
							)}
						</InvoiceNumber>

						<div>
							<Button
								title="Accept"
								onClick={handleAcceptModal}
								size="md"
								textColor="normal"
								variant="accept"
								icon={<DoneOutlinedIcon />}
							/>
							<Button
								title="Reject"
								onClick={handleRejectModal}
								size="md"
								textColor="normal"
								variant="reject"
								icon={<CloseIcon />}
							/>
						</div>
					</DetailsHeader>

					<AddressContainer>
						<InvoiceAddress>
							<div>
								<div>
									<h6>Invoice from</h6>
									<h5>
										{invoiceDetails?.created_by?.first_name}{" "}
										{invoiceDetails?.created_by?.last_name}
									</h5>
									<p>{invoiceDetails?.company_address}</p>
								</div>
								<div>
									<h6>Sent to</h6>
									<h5>{invoiceDetails?.customer_company}</h5>
									<p>{invoiceDetails?.customer_name}</p>
								</div>
							</div>
							<div>
								<div>
									<h6>Invoice Number</h6>
									<h5>{invoiceDetails?.order_number}</h5>
								</div>

								<div>
									<h6>Creation Date</h6>
									<h5>{invoiceDetails?.created_at?.substring(0, 10)}</h5>
								</div>
								<div>
									<h6>Due Date</h6>
									<h5>{invoiceDetails?.payment_due_date?.substring(0, 10)}</h5>
								</div>
							</div>
						</InvoiceAddress>
						<PayableAmount>
							<p>Total Payable</p>
							<h1> ₦ {FormatCurrency(invoiceDetails?.total)}</h1>
						</PayableAmount>
					</AddressContainer>
					<InvoiceTable>
						<TableHead>
							<SmallDesc>Item</SmallDesc>
							<Desc>Item Description</Desc>
							<SmallQty>Qty</SmallQty>
							<Qty>Quantity</Qty>
							<Rate>Rate</Rate>
							<Amount>Amount</Amount>
						</TableHead>
						{invoiceDetails?.items?.map((invoice: InvoiceItemProp) => {
							return (
								<TableBody key={uuidv4()}>
									<Desc>{invoice?.description}</Desc>
									<SmallDesc>{invoice?.description}</SmallDesc>
									<Qty>{`${invoice?.quantity}`}</Qty>
									<SmallQty>{`${invoice?.quantity}`}</SmallQty>
									<Rate>{`₦ ${FormatCurrency(invoice?.unit_price)}`}</Rate>
									<Amount>{`₦ ${FormatCurrency(invoice?.total)}`}</Amount>
								</TableBody>
							);
						})}
					</InvoiceTable>
					<TableCalc>
						<LeftCalc></LeftCalc>
						<RightCalc>
							<div>
								<p>Sub Total</p>
								<h6>₦{FormatCurrency(invoiceDetails?.sub_total)}</h6>
							</div>
							<div>
								<p>Miscellenous</p>
								<h6>₦{FormatCurrency(invoiceDetails?.misc)}</h6>
							</div>
							<div>
								<p>Tax</p>
								<h6>₦{FormatCurrency(invoiceDetails?.tax)}</h6>
							</div>
							<div>
								<p>Discount</p>
								<h6>₦{FormatCurrency(invoiceDetails?.discount)}</h6>
							</div>
							<div>
								<p>Total</p>
								<h6>₦{FormatCurrency(invoiceDetails?.total)}</h6>
							</div>
						</RightCalc>
					</TableCalc>
					{invoiceDetails?.status === "new" ||
					invoiceDetails?.status === "accepted" ||
					invoiceDetails?.status === "rejected" ? (
						<DiscountInfo>
							<span>
								<FontAwesomeIcon icon={faInfoCircle} />
							</span>{" "}
							You have been offered dynamic discounting. This means that you enjoy a
							discount of ₦{discountedAmount} if you pay before{" "}
							{invoiceDetails?.dynamic_discount_due_date}
						</DiscountInfo>
					) : null}
					<ActionButtons>
						<Button
							title="Download Pdf"
							onClick={handleDownloadInvoice}
							size="md"
							textColor="brand"
							variant="secondary"
							icon={<CloudDownloadIcon />}
						/>
					</ActionButtons>

					<ToastContainer />
				</DetailsContainer>
			</>

			<Alert
				alertSeverity={"error"}
				open={errAcceptModal}
				alertTitle={errAccept || "Unable to Accept Invoice"}
				closeModal={closeErrAccept}
			/>
			<Alert
				alertSeverity={"error"}
				open={errRejectModal}
				alertTitle={errReject || "Unable to Reject Invoice"}
				closeModal={closeErrReject}
			/>
			{deleteModal ? (
				<DeleteInvoiceModal
					currentOrder={invoiceDetails}
					deleteModal={deleteModal}
					handleDeleteModal={handleDeleteModal}
				/>
			) : null}
			<Modal
				toggleModal={handleRejectModal}
				heading=""
				subHeading={<ToastContainer />}
				size="lg"
				modal={rejectModal}
				body={
					<>
						<AcceptContainer>
							<h5>Reject Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{invoiceDetails?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{invoiceDetails?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{invoiceDetails?.total}</h4>
								</div>
							</AcceptDetails>
							<div>
								<label>Reason for rejection</label>
								<textarea onChange={handleAreaChange}></textarea>
							</div>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleRejectModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Reject"
									onClick={RejectInvoice}
									size="sm"
									textColor="normal"
									variant="reject"
									isLoading={isLoadingReject}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleAcceptModal}
				heading=""
				subHeading={<ToastContainer />}
				size="lg"
				modal={acceptModal}
				body={
					<>
						<AcceptContainer>
							<h5>Accept Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{invoiceDetails?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{invoiceDetails?.customer_name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{invoiceDetails?.total}</h4>
								</div>
							</AcceptDetails>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleAcceptModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Accept"
									onClick={AcceptInvoice}
									size="sm"
									textColor="normal"
									variant="accept"
									isLoading={isLoadingAccept}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 90%;
    margin 0 auto;
`;
export const DetailsContainer = styled.div`
	padding-bottom: 60px;
`;

export const InvoiceNumber = styled.div`
	gap: 15px;
	display: flex;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 25px;
	div {
		width: 48px;
		height: 48px;
	}
	h4 {
		color: var(--mainBlue);
		font-size: 25px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	@media (max-width: 768px) {
		gap: 8px;
		div {
			width: 32px;
			height: 32px;
		}
		h4 {
			font-size: 16px;
		}
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const InvoiceAddress = styled.div`
	width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 300px;
		text-align: left;
		margin-top: 20px;
		margin-bottom: 20px;
		:first-child {
			margin-bottom: 30px;
		}
	}
	h6 {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	p {
		color: #6d6d6d;
		font-size: 12px;
		margin-top: 2px;
		margin-bottom: 2px;
	}
	h5 {
		font-size: 14px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	@media (max-width: 1200px) {
		width: 450px;
	}
	@media (max-width: 768px) {
		width: 100%;
		h5 {
			font-size: 12px;
		}
	}
`;

export const PayableAmount = styled.div`
	margin-left: auto;
	text-align: right;
	p {
		font-size: 12px;
		color: #919191;
		margin-top: 20px;
		margin-bottom: 2px;
	}
	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 29px;
		color: var(--mainBlue);
	}
	@media (max-width: 1200px) {
		h1 {
			font-size: 24px;
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const AcceptContainer = styled.div`
	width: 90%;
	margin: auto;
	h5 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 23px;
		text-align: left;
		color: black;
	}

	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
		font-weight: 400;
	}
	h4 {
		font-size: 14px;
		color: black;
		font-size: 500;
		margin-top: 10px;
		margin-bottom: 0px;
	}
	textarea {
		height: 120px;
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-top: 5px;
	}
`;
const AcceptDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AcceptButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 40px;
`;

const DetailsHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	div {
		display: flex;
		gap: 10px;
	}

	@media (max-width: 991px) {
		flex-direction: column;
		gap: 20px;
		text-align: left;
		justify-content: start;
		align-items: flex-start;
	}
`;

export const InvoiceTable = styled.div`
	width: 100%;
	margin-top: 40px;
`;

export const TableHead = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e9e9e9;
	font-size: 14px;
	font-weight: 500;
`;

export const TableBody = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const Desc = styled.div`
	width: 35%;
	font-size: 14px;
	text-align: left;
	padding-left: 20px;
	@media (max-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const SmallDesc = styled.div`
	width: 30%;
	font-size: 12px;
	text-align: left;
	padding-left: 10px;
	@media (min-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const Qty = styled.div`
	width: 10%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		display: none;
	}
`;

export const SmallQty = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
	width: 6%;
	font-size: 12px;
	text-align: left;
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Rate = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Amount = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: right;
	padding-right: 20px;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
		padding-right: 10px;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const TableCalc = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	@media (max-width: 576px) {
		flex-direction: column-reverse;
	}
`;

export const LeftCalc = styled.div`
	width: 221px;
	text-align: left;
	p {
		font-size: 12px;
		font-weight: 500;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	div {
		margin-bottom: 30px;
	}
	@media (max-width: 576px) {
		width: 188px;
		p {
			margin-bottom: 5px;
		}
		h6 {
			font-size: 12px;
		}
		a {
			font-size: 12px;
			margin-top: 0px;
		}
	}
`;

export const RightCalc = styled.div`
	width: 45%;
	div {
		padding-right: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
		font-weight: bold;
	}
	@media (max-width: 576px) {
		width: 60%;
		margin-left: auto;
		p {
			font-size: 12px;
		}
		h6 {
			font-size: 14px;
		}
		div {
			padding-right: 10px;
		}
	}
	@media (max-width: 426px) {
		width: 70%;
	}
`;

const DiscountInfo = styled.div`
	display: flex;
	gap: 5px;
	background-color: #fcf2dc;
	color: #7c5f06;
	font-size: 11px;
	font-weight: 400;
	width: 430px;
	padding: 14px;
	box-sizing: border-box;
	text-align: left;
	@media (max-width: 576px) {
		width: 100%;
	}
`;

export const ActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	flex-wrap: wrap;
	gap: 10px;
	div {
		display: flex;
		gap: 10px;
	}
	@media (max-width: 576px) {
		flex-direction: column;
		gap: 10px;
		button {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
		div {
			flex-direction: column;
			justify-content: center;
		}
	}
`;

export const SendButton = styled.button`
	background-color: var(--mainBlue);
	color: white !important;
	justify-content: center;
	height: 48px;
	:hover {
		background-color: #9791b0;
	}
`;

export const Delete = styled.button`
	background-color: var(--textRed);
	color: white !important;
	height: 48px;
	border-color: transparent !important;
	font-weight: 500;
`;

export const SuccessTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 23px;
	text-align: left;
	color: black;
	width: 90%;
	margin: auto;
`;

export const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;

export const Continue = styled.button`
	background-color: var(--mainBlue);
	font-size: 14px;
	border: transparent;
	color: white;
	font-weight: 500;
	padding: 15px 30px;
`;

const StyledCollapseBtn = styled.span`
	border: 1px solid var(--mainBlue);
	color: var(--mainBlue);
	font-size: 14px;
	font-weight: 500;
	padding: 4px 14px;
	border-radius: 3px;
	cursor: pointer;
	@media (max-width: 576px) {
		width: 150px;
		box-sizing: border-box;
		height: 43px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export const ToggleContainer = styled.div`
	display: flex;
	gap: 30px;
	padding-top: 25px;
	padding-bottom: 15px;
	font-size: 18px;
	font-weight: 500;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 576px) {
		font-size: 16px;
		div {
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
	@media (max-width: 426px) {
		font-size: 12px;
	}
	@media (min-width: 768px) {
		display: none;
	}
`;
