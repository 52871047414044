import React, { useState, useContext } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../Constants/FormComponents/Button";
import Input from "../Constants/FormComponents/Input";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CountryList from "country-list-with-dial-code-and-flag";
import { PrependSelectWrapper, PrependSelect } from "../Constants/CustomStyles";
import InputPrepend from "../Constants/FormComponents/InputPrepend";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";

const AddBuyerModal = () => {
	const {
		fetchAllCustomerData,
		customerBuyerModal,
		handleCustomerBuyerModal,
		Industries,
	} = useContext(AppContext);

	const [previewState, setPreviewState] = useState(false);
	const [buyerFormState, setBuyerFormState] = useState({
		category: "buyer",
		name: "",
		business_name: "",
		industry: "",
		rc_number: "",
		company_code: `RAND${uuidv4()}`,
		company_email: "",
		country: "NG",
		bank_name: "",
		bank_account_no: "",
		bank_account_name: "",
		transaction_limit: "",
		dial_code: "+234",
		phone: "",
		phone_number: "",
		address: "",
		zip_code: "",
		tier: "",
		user: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [countryFlag, setCountryFlag] = useState(CountryFlagSvg["NG"]);

	const Countries = CountryList.getAll();

	const handleCustomerPreview = () => {
		setPreviewState(!previewState);
	};

	const {
		business_name,
		rc_number,
		name,
		industry,
		company_code,
		company_email,
		address,
		phone_number,
		country,
		zip_code,
		phone,
		dial_code,
	} = buyerFormState;

	const navigate = useNavigate();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setBuyerFormState({
			...buyerFormState,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		if (e.currentTarget.name === "phone") {
			setBuyerFormState({
				...buyerFormState,
				phone: e.currentTarget.value,
				phone_number: dial_code + e.currentTarget.value,
			});
		}
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const { value } = event.currentTarget;
		setBuyerFormState({
			...buyerFormState,
			[event.currentTarget.name]: event.currentTarget.value,
		});
		if (event.currentTarget.name === "country") {
			setCountryFlag(CountryFlagSvg[event.currentTarget.value]);
			setBuyerFormState({
				...buyerFormState,
				country: value,
			});
		}
		if (event.currentTarget.name === "dial_code") {
			setBuyerFormState({
				...buyerFormState,
				dial_code: value,
				phone_number: value + phone,
			});
		}
	};

	const closeFormCreateModal = () => {
		handleCustomerBuyerModal();
		setBuyerFormState({
			...buyerFormState,
			rc_number: "",
			business_name: "",
			industry: "",
			company_code: `RAND${uuidv4()}`,
			company_email: "",
			phone_number: "",
			phone: "",
			zip_code: "",
			dial_code: "+234",
			country: "NG",
			name: "",
			address: "",
		});
		setCountryFlag(CountryFlagSvg["NG"]);
		setPreviewState(false);
		setIsLoading(false);
	};

	const createNewBuyer = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/customers/new/", buyerFormState)
			.then(res => {
				toast.success(`${res?.data?.data?.message}`);
				setIsLoading(false);
				fetchAllCustomerData();
				closeFormCreateModal();
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const isDisabled = () => {
		if (
			business_name &&
			name &&
			company_code &&
			company_email &&
			phone_number &&
			country
		) {
			return false;
		} else {
			return true;
		}
	};

	if (previewState && !rc_number) {
		setBuyerFormState({ ...buyerFormState, rc_number: `RANDRC${uuidv4()}` });
	}

	return (
		<>
			{customerBuyerModal ? (
				<Modal
					toggleModal={closeFormCreateModal}
					modal={customerBuyerModal}
					heading=""
					subHeading=""
					size="lg"
					body={
						<>
							{/* Both the Preview of the buyer details and the buyer form are rendered in the same container */}
							{/* When the continue button is pressed, the form closes and the preview opens */}
							{/* There is only one modal close, it closes the whole modal */}
							{/* Edit button only closes the Preview and opens the pre-filled form */}
							<Wrapper>
								{previewState ? (
									<>
										<FormTitle>Buyer Preview</FormTitle>
										<SubHeader>BASIC INFORMATION</SubHeader>
										<PreviewItem>
											<h6>RC Number</h6>
											<h5>{buyerFormState.rc_number}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Company Name</h6>
											<h5>{buyerFormState?.business_name}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Industry</h6>
											<h5>{buyerFormState?.industry}</h5>
										</PreviewItem>
										{/* <PreviewItem>
											<h6>Company Code</h6>
											<h5>{buyerFormState?.company_code}</h5>
										</PreviewItem> */}
										<PreviewItem>
											<h6>Buyer"s Name</h6>
											<h5>{buyerFormState?.name}</h5>
										</PreviewItem>
										<SubHeader>CONTACT INFORMATION</SubHeader>
										<PreviewItem>
											<h6>Company Email</h6>
											<h5>{buyerFormState?.company_email}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Phone Number</h6>
											<h5>{buyerFormState?.phone_number}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Country</h6>
											<h5>{buyerFormState?.country}</h5>
										</PreviewItem>
										<ButtonsContent>
											<div>
												<Button
													title="Edit"
													onClick={handleCustomerPreview}
													size="sm"
													textColor="brand"
													variant="secondary"
												/>
											</div>
											<div>
												<Button
													title="Cancel"
													onClick={closeFormCreateModal}
													size="sm"
													textColor="normal"
													variant="reject"
												/>
												<Button
													title="Add Customer"
													onClick={createNewBuyer}
													size="sm"
													textColor="normal"
													variant="default"
													isLoading={isLoading}
												/>
											</div>
										</ButtonsContent>
										<ToastContainer />
									</>
								) : (
									<>
										<Info>
											Search for your customer RC{" "}
											<a
												href="https://search.cac.gov.ng/"
												target="_blank"
												rel="noreferrer"
											>
												{" "}
												here
											</a>
										</Info>
										<FormTitle id="rc-hover">Add new buyer</FormTitle>
										<FormSubtitle>BASIC INFORMATION</FormSubtitle>
										<Form>
											<FormContainer>
												<Input
													inputType="number"
													title={
														<div>
															<a className="rc-hover">
																RC/BN Number (Optional){" "}
																<FontAwesomeIcon icon={faCircleExclamation} />
															</a>
														</div>
													}
													name="rc_number"
													value={buyerFormState?.rc_number}
													size="sm"
													onChange={handleChange}
												/>
												<StyledToolTip anchorSelect=".rc-hover" place="bottom">
													We use RC/BN number to provide credit insights about your customer
													to you, if you wish. Reduce the risk of selling to customers who
													has a history of defaulting payments, and prevent bad debt losses
												</StyledToolTip>

												<Input
													inputType="text"
													title="Company Name"
													name="business_name"
													value={business_name}
													size="sm"
													onChange={handleChange}
													required
												/>

												<>
													<SelectWrapper
														size="sm"
														title="Industry"
														required
														children={
															<div>
																<select
																	onChange={handleSelectChange}
																	name="industry"
																	defaultValue={industry}
																>
																	<option value=""> --- Select Industry --- </option>
																	{Industries.map((ind: String, index) => {
																		return (
																			<option value={`${ind}`} key={index}>
																				{ind}
																			</option>
																		);
																	})}
																</select>
															</div>
														}
													/>

													{/* <Input
														inputType="text"
														title="Company Code"
														name="company_code"
														value={company_code}
														size="sm"
														isDisabled
														required
													/> */}
													<Input
														inputType="text"
														title="Buyer's name"
														name="name"
														value={name}
														size="sm"
														onChange={handleChange}
														required
													/>
												</>
											</FormContainer>

											<>
												<FormSubtitle>CONTACT INFORMATION</FormSubtitle>
												<FormContainer>
													<Input
														inputType="email"
														title="Company Email"
														name="company_email"
														value={company_email}
														size="sm"
														onChange={handleChange}
														required
													/>

													<InputPrepend
														children={
															<>
																<select
																	onChange={handleSelectChange}
																	name="dial_code"
																	value={dial_code}
																	defaultValue={dial_code}
																>
																	{Countries.map((eachcountry: any, index) => {
																		return (
																			<option
																				value={`${eachcountry?.data?.dial_code}`}
																				key={index}
																			>
																				{eachcountry?.data?.flag} {eachcountry?.data?.dial_code}
																			</option>
																		);
																	})}
																</select>
															</>
														}
														title="Phone Number"
														inputType="tel"
														name="phone"
														value={phone}
														size="sm"
														onChange={handleChange}
														required
													/>
													<Input
														inputType="address"
														title="Address"
														name="address"
														value={address}
														size="sm"
														onChange={handleChange}
														required
													/>
													<Input
														inputType="tel"
														title="Zip Code"
														name="zip_code"
														value={zip_code}
														size="sm"
														onChange={handleChange}
													/>
													<SelectWrapper
														size="sm"
														required
														title="Country"
														children={
															<PrependSelectWrapper>
																<PrependSelect size="sm">
																	<img
																		src={`data:image/svg+xml;utf8,${encodeURIComponent(
																			countryFlag
																		)}`}
																	/>
																</PrependSelect>
																<select
																	onChange={handleSelectChange}
																	name="country"
																	value={country}
																	defaultValue={country}
																>
																	{Countries.map((eachcountry: any, index) => {
																		return (
																			<option value={`${eachcountry?.data?.code}`} key={index}>
																				{eachcountry?.data?.name}
																			</option>
																		);
																	})}
																</select>
															</PrependSelectWrapper>
														}
													/>
												</FormContainer>
											</>

											<ActionButtons>
												<Button
													title="Continue"
													onClick={handleCustomerPreview}
													size="full"
													textColor="normal"
													variant="default"
													disabled={isDisabled()}
												/>
											</ActionButtons>
										</Form>
										<ToastContainer />
									</>
								)}
							</Wrapper>
						</>
					}
				/>
			) : null}
		</>
	);
};

export default AddBuyerModal;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	@media (max-width: 426px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

const Form = styled.form`
	width: 100%;
`;
const FormContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const Info = styled.div`
	position: absolute;
	top: 0;
	z-index: 0;
	width: 300px;
	height: 43px;
	padding: 17px;
	box-sizing: border-box;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	border-radius: 4px;
	display: flex;
	align-items: center;
	background-color: #dcf8ef;
	color: #4bb543;
	font-weight: 500;
	a {
		padding-left: 4px;
	}
`;

const FormTitle = styled.h2`
	color: black;
	font-weight: 500;
	font-size: 18px;
`;

const FormSubtitle = styled.h5`
	font-size: 12px;
	font-weight: 500;
	color: #919191;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 0px;
`;

const SubHeader = styled.h5`
	font-size: 12px;
	font-weight: 500;
	color: #919191;
	text-align: left;
	margin-top: 24px;
	margin-bottom: 24px;
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;

const PreviewItem = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	text-align: left;
	h5 {
		font-size: 14px;
		font-weight: 500;
		color: black;
		margin-top: 0px;
		margin-bottom: 0px;
		width: 180px;
	}
	h6 {
		font-size: 14px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
		color: #484848;
		width: 150px;
	}
`;

const ButtonsContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	div {
		display: flex;
		gap: 20px;
	}
	@media (max-width: 426px) {
		flex-wrap: wrap;
		width: 100%;
		gap: 10px;
		div {
			width: 100%;
			gap: 10px;
			flex-wrap: wrap;
		}
	}
`;

const StyledToolTip = styled(Tooltip)`
	width: 250px;
`;
