import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../Constants/FormComponents/Button";
import Input from "../../Constants/FormComponents/Input";
import SelectWrapper from "../../Constants/FormComponents/SelectWrapper";
import ModalComponent from "../../Constants/ModalComponent";
import * as constants from "../../Constants/api";
import { ToastContainer, toast } from "react-toastify";

import { Roles } from "../../Constants/utils";

interface Props {
	handleInviteModal: any;
	inviteModal: boolean;
	setInviteModal: any;
	getTeammembersList: any;
}
export default function Invite({
	handleInviteModal,
	inviteModal,
	setInviteModal,
	getTeammembersList,
}: Props) {
	const [loading, setLoading] = useState(false);
	const [teamMateDetail, setTeamMateDetail] = useState({
		first_name: "",
		last_name: "",
		email: "",
		role: "operations",
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTeamMateDetail({
			...teamMateDetail,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setTeamMateDetail({ ...teamMateDetail, [e.target.name]: e.target.value });
	};

	const inviteTeamMate = (e: any) => {
		e.preventDefault();
		setLoading(true);
		constants.httprequest
			.post("/accounts/team/member/invite/create/", teamMateDetail)
			.then(res => {
				if (res.status === 201) {
					toast.success("Team member successfully created");
					setLoading(false);

					setTimeout(() => {
						setInviteModal(!inviteModal);
						getTeammembersList();
					}, 2000);
				}
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<ModalComponent
			size="lg"
			toggleModal={handleInviteModal}
			heading="Invite Teammate"
			subHeading="Invite teammates and assign administrative functions to them."
			modal={inviteModal}
			body={
				<ModalWrapper>
					<ToastContainer />
					<Small>
						<Input
							size="sm"
							required
							title="First Name"
							inputType="text"
							value={teamMateDetail.first_name}
							name="first_name"
							onChange={e => handleChange(e)}
						/>
						<Input
							size="sm"
							required
							title="Last Name"
							inputType="text"
							value={teamMateDetail.last_name}
							name="last_name"
							onChange={e => handleChange(e)}
						/>
					</Small>

					<Input
						size="full"
						required
						title="Email Address"
						inputType="text"
						value={teamMateDetail.email}
						name="email"
						onChange={e => handleChange(e)}
					/>

					<SelectWrapper
						title="Role"
						required
						size="full"
						children={
							<select
								name="role"
								value={teamMateDetail.role}
								onChange={e => handleChangeSelect(e)}
							>
								{Roles.map(item => {
									return (
										<option key={item.value} value={item.value}>
											{item.label}
										</option>
									);
								})}
							</select>
						}
					/>
					<br />
					<Button
						title="Invite Teammate"
						size="full"
						textColor="normal"
						variant="default"
						isLoading={loading}
						onClick={e => inviteTeamMate(e)}
					/>
				</ModalWrapper>
			}
		/>
	);
}
const ModalWrapper = styled.div`
	width: 82%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Small = styled.div`
	display: grid;
	grid-template-columns: 235px 235px;
	gap: 30px;
	width: 500px;
	margin: 0 auto;
	@media (max-width: 500px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
`;
