import { useEffect, useState } from "react";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import {
	PageHeader,
	PageTitle,
	PageInstruction,
	PageBody,
	PreferencesSection,
	PageWord,
} from "./Index";
import * as constants from "../../../Components/Constants/api";
import Button from "../../Constants/FormComponents/Button";
import { ToastContainer, toast } from "react-toastify";

const Invoices = () => {
	const [emailTemplates, setEmailTemplates] = useState<any>([]);
	const [templateOne, setTemplatesOne] = useState({
		id: "",
		category: "",
		is_default: false,
		plain_text_template: "",
		communication_channel: "",
	});
	const [templateTwo, setTemplatesTwo] = useState({
		id: "",
		category: "",
		is_default: false,
		plain_text_template: "",
		communication_channel: "",
	});

	// const [isDefault, setisDefault] = useState(false)

	const getTemplates = () => {
		constants.httprequest
			.get("/reminders/reminder-templates/")
			.then(res => {
				setEmailTemplates(
					res?.data?.results?.filter(
						(result: any) => result.communication_channel === "email"
					)
				);
			})
			.catch(err => {
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const setTemplateOneDefault = () => {
		setTemplatesOne({
			...templateOne,
			is_default: true,
		});

		setTemplatesTwo({
			...templateTwo,
			is_default: false,
		});
	};

	const setTemplateTwoDefault = () => {
		setTemplatesOne({
			...templateOne,
			is_default: false,
		});

		setTemplatesTwo({
			...templateTwo,
			is_default: true,
		});
	};

	useEffect(() => {
		getTemplates();
	}, []);
	useEffect(() => {
		setTemplatesOne({
			...templateOne,
			id: emailTemplates[0]?.uid,
			category: emailTemplates[0]?.category,
			is_default: emailTemplates[0]?.is_default,
			plain_text_template: emailTemplates[0]?.plain_text_template,
			communication_channel: emailTemplates[0]?.communication_channel,
		});
		setTemplatesTwo({
			id: emailTemplates[1]?.uid,
			category: emailTemplates[1]?.category,
			is_default: emailTemplates[1]?.is_default,
			plain_text_template: emailTemplates[1]?.plain_text_template,
			communication_channel: emailTemplates[1]?.communication_channel,
		});
	}, [emailTemplates]);

	const setDefaultTemplate = () => {
		try {
			if (templateOne?.is_default) {
				constants.httprequest
					.put(`/reminders/reminder-templates/${templateOne?.id}`)
					.then(res => toast.success(res.data.detail));
			} else if (templateTwo?.is_default) {
				constants.httprequest
					.put(`/reminders/reminder-templates/${templateTwo?.id}`)
					.then(res => toast.success(res.data.detail));
			} else {
				toast.error("Pick a Default Template");
			}
		} catch (error) {
			toast.error("An error occured while trying to update template");
		}
	};
	return (
		<>
			<PageHeader>
				<span>
					<PageTitle>Invoices</PageTitle>
					<PageInstruction>
						Make changes to your invoice preferences.
					</PageInstruction>
				</span>
			</PageHeader>
			<PageBody>
				<PreferencesSection>
					<PageWord>
						{" "}
						<h4>Invoice Reminders</h4>{" "}
						<p>
							These are automated notifications that will be sent by Bridger to your
							customers via email on three specific dates: the 15th, 7th and the last
							day before the invoice is due. They serve as a way to prompt your
							customers to pay on time.
						</p>
					</PageWord>
				</PreferencesSection>

				<Selection>
					<h2>Select Preferred Template</h2>

					<SelectionBoxSection>
						<SelectionBox key={templateOne?.id}>
							<p>{templateOne?.plain_text_template}</p>

							<SelectionBtn>
								<label>Template 1</label>
								<button onClick={setTemplateOneDefault}>
									{templateOne?.is_default ? <CheckCircleIcon /> : <CircleOutlined />}
								</button>
							</SelectionBtn>
						</SelectionBox>

						<SelectionBox>
							<p>{templateTwo?.plain_text_template}</p>

							<SelectionBtn>
								<label>Template 2</label>
								<button onClick={setTemplateTwoDefault}>
									{templateTwo?.is_default ? <CheckCircleIcon /> : <CircleOutlined />}
								</button>
							</SelectionBtn>
						</SelectionBox>
					</SelectionBoxSection>
					<br />
					<Button
						variant="default"
						title="Save"
						size="sm"
						textColor="normal"
						onClick={setDefaultTemplate}
					/>
				</Selection>
			</PageBody>
			<ToastContainer />
		</>
	);
};
const Selection = styled.div`
	h2 {
		text-align: left;
		font-weight: 500;
		font-size: 14px;
	}
`;
const SelectionBoxSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const SelectionBox = styled.div`
	border: 1px solid #d9d9d9;
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px;
	margin-top: 20px;
	text-align: left;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	@media (max-width: 900px) {
		width: 60%;
	}
	@media (max-width: 500px) {
		width: 90%;
	}
`;
const SelectionBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		background: transparent;
		border: none;
	}
`;

export default Invoices;
