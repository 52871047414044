import React, { useState, useContext } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../Constants/FormComponents/Button";
import Input from "../Constants/FormComponents/Input";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import CountryList from "country-list-with-dial-code-and-flag";
import { PrependSelectWrapper, PrependSelect } from "../Constants/CustomStyles";
import InputPrepend from "../Constants/FormComponents/InputPrepend";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";

const AddSellerModal = () => {
	const {
		fetchAllCustomerData,
		customerSellerModal,
		handleCustomerSellerModal,
		Industries,
		banks,
	} = useContext(AppContext);

	const [previewState, setPreviewState] = useState(false);
	const [sellerFormState, setSellerFormState] = useState({
		category: "supplier",
		name: "",
		business_name: "",
		industry: "",
		rc_number: "",
		company_code: `RAND${uuidv4()}`,
		company_email: "",
		phone_number: "",
		country: "NG",
		name_enquiry: "",
		bank_name: "",
		bank_code: "",
		bank_account_no: "",
		bank_account_name: "",
		transaction_limit: "",
		dial_code: "+234",
		phone: "",
		address: "",
		zip_code: "",
		tier: "",
		user: "",
	});

	const [isLoading, setIsLoading] = useState(false);
	const [countryFlag, setCountryFlag] = useState(CountryFlagSvg["NG"]);

	const Countries = CountryList.getAll();

	const handleCustomerPreview = () => {
		setPreviewState(!previewState);
	};

	const {
		rc_number,
		business_name,
		name,
		industry,
		company_code,
		company_email,
		phone_number,
		country,
		bank_account_name,
		bank_name,
		bank_account_no,
		address,
		zip_code,
		phone,
		dial_code,
	} = sellerFormState;

	const navigate = useNavigate();
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSellerFormState({
			...sellerFormState,
			[e.currentTarget.name]: e.currentTarget.value,
		});
		if (
			e.target.name === "bank_account_no" &&
			e.currentTarget.value?.length === 10
		) {
			resolveBank(e.currentTarget.value);
		}
		if (e.target.name === "phone") {
			setSellerFormState({
				...sellerFormState,
				phone: e.currentTarget.value,
				phone_number: dial_code + e.currentTarget.value,
			});
		}
	};
	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		const { value } = event.currentTarget;
		setSellerFormState({
			...sellerFormState,
			[event.currentTarget.name]: event.currentTarget.value,
		});
		if (sellerFormState.bank_account_no?.length === 10) {
			resolveBank(sellerFormState.bank_account_no);
		}
		if (event.currentTarget.name === "country") {
			setCountryFlag(CountryFlagSvg[event.currentTarget.value]);
			setSellerFormState({
				...sellerFormState,
				country: value,
			});
		}
		if (event.currentTarget.name === "dial_code") {
			setSellerFormState({
				...sellerFormState,
				dial_code: value,
				phone_number: value + phone,
			});
		}
	};

	const createNewSeller = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.post("/customers/new/", sellerFormState)
			.then(res => {
				toast.success(`${res?.data?.data?.message}`);
				setIsLoading(false);
				fetchAllCustomerData();
				closeFormCreateModal();
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
			});
	};

	const resolveBank = (account: any) => {
		if (account.length == 10 && sellerFormState.bank_code?.length > 1) {
			constants.httprequest
				.post("/accounts/bank-account/resolve/", {
					bank_code: sellerFormState.bank_code,
					account_number: account,
				})
				.then(res => {
					if (res.status === 201) {
						setSellerFormState({
							...sellerFormState,
							name_enquiry: res?.data?.account_enquiry?.uid,
							bank_account_name: res?.data?.account_enquiry?.account_name,
							bank_name: res?.data?.account_enquiry?.bank?.name,
							bank_account_no: res?.data?.account_enquiry?.account_number,
						});
					}
				})
				.catch(error => {
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				});
		}
	};

	const closeFormCreateModal = () => {
		handleCustomerSellerModal();
		setSellerFormState({
			...sellerFormState,
			category: "supplier",
			name: "",
			business_name: "",
			industry: "",
			rc_number: "",
			company_code: `RAND${uuidv4()}`,
			company_email: "",
			phone_number: "",
			phone: "",
			country: "NG",
			name_enquiry: "",
			bank_name: "",
			bank_code: "",
			bank_account_no: "",
			bank_account_name: "",
			transaction_limit: "",
			dial_code: "+234",
			address: "",
			zip_code: "",
			tier: "",
			user: "",
		});
		setCountryFlag(CountryFlagSvg["NG"]);
		setPreviewState(false);
		setIsLoading(false);
	};

	const isDisabled = () => {
		if (
			business_name &&
			name &&
			industry &&
			company_code &&
			company_email &&
			phone_number &&
			bank_account_name &&
			country
		) {
			return false;
		} else {
			return true;
		}
	};

	if (previewState && !rc_number) {
		setSellerFormState({ ...sellerFormState, rc_number: `RANDRC${uuidv4()}` });
	}
	return (
		<>
			{customerSellerModal ? (
				<Modal
					toggleModal={closeFormCreateModal}
					modal={customerSellerModal}
					heading=""
					subHeading=""
					size="lg"
					body={
						<>
							{/* Both the Preview of the buyer details and the buyer form are rendered in the same container */}
							{/* When the continue button is pressed, the form closes and the preview opens */}
							{/* There is only one modal close, it closes the whole modal */}
							{/* Edit button only closes the Preview and opens the pre-filled form */}
							<Wrapper>
								{previewState ? (
									<>
										<FormTitle>Seller Preview</FormTitle>
										<SubHeader>BASIC INFORMATION</SubHeader>
										<PreviewItem>
											<h6>RC Number</h6>
											<h5>{rc_number}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Company Name</h6>
											<h5>{business_name}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Industry</h6>
											<h5>{industry}</h5>
										</PreviewItem>
										{/* <PreviewItem>
											<h6>Company Code</h6>
											<h5>{company_code}</h5>
										</PreviewItem> */}
										<PreviewItem>
											<h6>Buyer's Name</h6>
											<h5>{name}</h5>
										</PreviewItem>
										<SubHeader>CONTACT INFORMATION</SubHeader>
										<PreviewItem>
											<h6>Company Email</h6>
											<h5>{company_email}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Phone Number</h6>
											<h5>{phone_number}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Country</h6>
											<h5>{country}</h5>
										</PreviewItem>
										<SubHeader>OTHER INFORMATION</SubHeader>
										<PreviewItem>
											<h6>Bank Name</h6>
											<h5>{bank_name}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Bank Account Name</h6>
											<h5>{sellerFormState?.bank_account_name}</h5>
										</PreviewItem>
										<PreviewItem>
											<h6>Bank Account Number</h6>
											<h5>{bank_account_no}</h5>
										</PreviewItem>
										<ButtonsContent>
											<div>
												<Button
													title="Edit"
													onClick={handleCustomerPreview}
													size="sm"
													textColor="brand"
													variant="secondary"
												/>
											</div>
											<div>
												<Button
													title="Cancel"
													onClick={closeFormCreateModal}
													size="sm"
													textColor="normal"
													variant="reject"
												/>
												<Button
													title="Add Customer"
													onClick={createNewSeller}
													size="sm"
													textColor="normal"
													variant="default"
													isLoading={isLoading}
												/>
											</div>
										</ButtonsContent>
										<ToastContainer />
									</>
								) : (
									<>
										<Info>
											Search for your customer RC{" "}
											<a
												href="https://search.cac.gov.ng/"
												target="_blank"
												rel="noreferrer"
											>
												{" "}
												here
											</a>
										</Info>
										<FormTitle>Add new supplier</FormTitle>
										<FormSubtitle>BASIC INFORMATION</FormSubtitle>
										<Form>
											<FormContainer>
												<Input
													inputType="number"
													title={
														<div>
															<a className="rc-hover">
																RC/BN Number (Optional){" "}
																<FontAwesomeIcon icon={faCircleExclamation} />
															</a>
														</div>
													}
													value={rc_number}
													name="rc_number"
													size="sm"
													onChange={handleChange}
													required
												/>
												<StyledToolTip anchorSelect=".rc-hover" place="bottom">
													We use RC/BN number to provide credit insights about your customer
													to you, if you wish. Reduce the risk of selling to customers who
													has a history of defaulting payments, and prevent bad debt losses
												</StyledToolTip>

												<Input
													inputType="text"
													title="Company Name"
													name="business_name"
													value={business_name}
													size="sm"
													onChange={handleChange}
													required
												/>

												<>
													<SelectWrapper
														size="sm"
														title="Industry"
														required
														children={
															<select
																onChange={handleSelectChange}
																name="industry"
																defaultValue={industry}
															>
																<option value=""> --- Select Industry --- </option>
																{Industries.map((eachindustry: String, index) => {
																	return (
																		<option value={`${eachindustry}`} key={index}>
																			{eachindustry}
																		</option>
																	);
																})}
															</select>
														}
													/>
													<Input
														inputType="text"
														title="Supplier's name"
														name="name"
														value={name}
														size="sm"
														onChange={handleChange}
														required
													/>
												</>
											</FormContainer>

											<>
												<FormSubtitle>CONTACT INFORMATION</FormSubtitle>
												<FormContainer>
													<Input
														inputType="email"
														title="Company Email"
														name="company_email"
														value={company_email}
														size="sm"
														onChange={handleChange}
														required
													/>
													<InputPrepend
														children={
															<>
																<select
																	onChange={handleSelectChange}
																	name="dial_code"
																	value={dial_code}
																	defaultValue={dial_code}
																>
																	{Countries.map((eachcountry: any, index) => {
																		return (
																			<option
																				value={`${eachcountry?.data?.dial_code}`}
																				key={index}
																			>
																				{eachcountry?.data?.flag} {eachcountry?.data?.dial_code}
																			</option>
																		);
																	})}
																</select>
															</>
														}
														title="Phone Number"
														inputType="tel"
														name="phone"
														value={phone}
														size="sm"
														onChange={handleChange}
														required
													/>
													<Input
														inputType="address"
														title="Address"
														name="address"
														value={address}
														size="sm"
														onChange={handleChange}
														required
													/>
													<Input
														inputType="tel"
														title="Zip Code"
														name="zip_code"
														value={zip_code}
														size="sm"
														onChange={handleChange}
													/>

													<SelectWrapper
														size="sm"
														title="Country"
														required
														children={
															<PrependSelectWrapper>
																<PrependSelect size="sm">
																	<img
																		src={`data:image/svg+xml;utf8,${encodeURIComponent(
																			countryFlag
																		)}`}
																	/>
																</PrependSelect>
																<select
																	onChange={handleSelectChange}
																	name="country"
																	value={country}
																	defaultValue={country}
																>
																	{Countries.map((eachcountry: any, index) => {
																		return (
																			<option value={`${eachcountry?.data?.code}`} key={index}>
																				{eachcountry?.data?.name}
																			</option>
																		);
																	})}
																</select>
															</PrependSelectWrapper>
														}
													/>
												</FormContainer>
												<FormSubtitle>ACCOUNT INFORMATION</FormSubtitle>
												<FormContainer>
													<SelectWrapper
														size="sm"
														title="Bank name"
														required
														children={
															<select onChange={handleSelectChange} name="bank_code">
																{banks.map((bank: any, index: any) => {
																	return (
																		<option value={bank?.code} key={index}>
																			{bank?.name}
																		</option>
																	);
																})}
															</select>
														}
													/>

													<Input
														inputType="number"
														title="Bank account number"
														name="bank_account_no"
														value={bank_account_no}
														size="sm"
														onChange={handleChange}
														required
													/>
													<Input
														inputType="text"
														title="Bank account name"
														name="bank_account_name"
														value={bank_account_name}
														size="sm"
														isDisabled
														onChange={handleChange}
													/>
												</FormContainer>
											</>

											<ActionButtons>
												<Button
													title="Continue"
													onClick={handleCustomerPreview}
													size="full"
													textColor="normal"
													variant="default"
													disabled={isDisabled()}
												/>
											</ActionButtons>
										</Form>
										<ToastContainer />
									</>
								)}
							</Wrapper>
						</>
					}
				/>
			) : null}
		</>
	);
};

export default AddSellerModal;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
`;

const Form = styled.form`
	width: 100%;
`;
const FormContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const FormTitle = styled.h2`
	color: black;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 3px;
`;

const Info = styled.div`
	position: absolute;
	top: 0;
	width: 300px;
	height: 43px;
	padding: 17px;
	box-sizing: border-box;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	border-radius: 4px;
	display: flex;
	align-items: center;
	background-color: #dcf8ef;
	color: #4bb543;
	font-weight: 500;
	a {
		padding-left: 4px;
	}
`;

const FormSubtitle = styled.h5`
	font-size: 12px;
	font-weight: 500;
	color: #919191;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 0px;
`;

export const SubHeader = styled.h4`
	font-size: 12px;
	font-weight: 500;
	color: #919191;
	text-align: left;
	margin-top: 24px;
	margin-bottom: 24px;
`;

const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;

const ButtonsContent = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	div {
		display: flex;
		gap: 20px;
	}
	@media (max-width: 426px) {
		flex-wrap: wrap;
		width: 100%;
		gap: 10px;
		div {
			width: 100%;
			gap: 10px;
			flex-wrap: wrap;
		}
	}
`;

export const PreviewItem = styled.div`
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	text-align: left;
	h5 {
		font-size: 14px;
		font-weight: 500;
		color: black;
		margin-top: 0px;
		margin-bottom: 0px;
		width: 180px;
	}
	h6 {
		font-size: 14px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
		color: #484848;
		width: 150px;
	}
	@media (max-width: 426px) {
		h5 {
			width: 140px;
			font-size: 12px;
		}
		h4 {
			font-size: 12px;
		}
	}
`;

const StyledToolTip = styled(Tooltip)`
	width: 250px;
`;
