import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../Constants/FormComponents/Input";
import Logo from "../../assets/logoTwo.svg";
import Button from "../Constants/FormComponents/Button";
import CarouselSlider from "../Constants/Carousel/CarouselSlider";
import * as constants from "../Constants/api";

export default function Login() {
	const navigate = useNavigate();
	const [loginDetails, setLoginDetails] = useState({
		password: "",
		email: "",
	});
	// const [errorMsg, setErrorMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [passwordType, setPasswordType] = useState("password");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		setLoading(true);
		localStorage.setItem("email", loginDetails.email);
		localStorage.setItem("password", loginDetails.password);
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/login/`, loginDetails)
			.then(res => {
				if (res.status === 201) {
					toast.success(`${res?.data?.detail}`);
					navigate("/login-otp");
				}
			})
			.catch(error => {
				setLoading(false);
				// setErrorMsg(error?.response?.data?.errors[0]?.detail);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<Wrapper>
			<CarouselSlider />
			<LoginWrapper>
				<LoginHeader>
					<img src={Logo} alt="Bridger's Logo" />
					<LoginTitle>Welcome back to Bridger!</LoginTitle>
					<LoginInstruction>
						Kindly fill in the required details to log in to your account.
					</LoginInstruction>
				</LoginHeader>
				<form onSubmit={handleSubmit}>
					<Input
						inputType="email"
						title="Email Address"
						required
						value={loginDetails.email}
						name="email"
						placeHolder=""
						onChange={e => handleChange(e)}
						size="full"
					/>

					<Password>
						<Input
							inputType={passwordType}
							title="Password"
							required
							value={loginDetails.password}
							name="password"
							placeHolder=""
							onChange={e => handleChange(e)}
							size="full"
						/>

						<PasswordIcon>
							{passwordType === "password" ? (
								<FontAwesomeIcon icon={faEyeSlash} onClick={togglePassword} />
							) : (
								<FontAwesomeIcon icon={faEye} onClick={togglePassword} />
							)}
						</PasswordIcon>
					</Password>

					<LoginExtraPrompts>
						<Remember>
							<input type="checkbox" id="remember_me" name="remember_me" />
							<label htmlFor="remember_me">Remember Me</label>
						</Remember>

						<ForgotPassword>
							<Link to="/forgot-password">Forgot Password?</Link>
						</ForgotPassword>
					</LoginExtraPrompts>

					<Button
						title="Log In"
						variant="default"
						textColor="normal"
						size="full"
						disabled={loading}
						isLoading={loading}
					/>
				</form>
				<SignUp>
					Don"t have an account? <Link to="/signup">Sign Up</Link>
				</SignUp>
				{/* {errorMsg?.includes("verif") ? (
					<SignUp>
						Phone number not verified? <Link to="/verify-phone">Verify</Link>
					</SignUp>
				) : (
					""
				)} */}
			</LoginWrapper>
			<ToastContainer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 50px;
	overflow: hidden;
	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding-top: 60px;
		gap: 0px;
	}
`;
const LoginWrapper = styled.div`
	width: 34vw;
	padding: 100px 50px 0 55vw;

	@media (max-width: 768px) {
		width: 90vw;
		padding: 50px 0 20px 0;
	}
`;
const LoginExtraPrompts = styled.div`
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 15px 0 10px 0;
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
	}
`;
const LoginHeader = styled.div``;
const LoginTitle = styled.h2`
	font-size: 24px;
`;
const LoginInstruction = styled.p`
	font-size: 14px;
`;
const Remember = styled.div`
	font-size: 14px;

	input {
		margin-right: 10px;
	}
`;

const ForgotPassword = styled.div`
	font-size: 14px;

	a {
		color: #302361;
	}
`;
const SignUp = styled.div`
	padding-top: 15px;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	a {
		color: #302361;
	}
`;

const Password = styled.div``;
const PasswordIcon = styled.div`
	position: relative;
	top: -32px;
	left: 45%;
	@media (max-width: 425px) {
		left: 40%;
	}
`;
