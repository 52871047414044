import styled from "styled-components";
import Button from "../../Constants/FormComponents/Button";
import ModalComponent from "../../Constants/ModalComponent";
import { ToastContainer } from "react-toastify";
import { ActionType } from "../TeamMembers";

interface Props {
	actionType: ActionType | null;
	team: any;
	handleClose: any;
	action: { resend: any; activate: any; deactivate: any; delete: any };
	show: boolean;
	loading: boolean;
}

export default function Actions({
	actionType,
	show,
	loading,
	team,
	handleClose,
	action,
}: Props) {
	const ActionConfig = {
		[ActionType.Resend]: {
			title: "Are you sure?",
			description:
				"You are about resent invitation to this teammate. Do you want to go ahead with this action?",
			buttonText: "Yes, Resend Invite",
			buttonVariant: "default",
		},
		[ActionType.Activate]: {
			title: "Are you sure?",
			description:
				"You are about to reactivate this teammate. Do you want to go ahead with this action?",
			buttonText: "Yes, Reactivate",
			buttonVariant: "accept",
		},
		[ActionType.Deactivate]: {
			title: "Are you sure?",
			description:
				"You are about to deactivate this teammate. Do you want to go ahead with this action?",
			buttonText: "Yes, Deactivate",
			buttonVariant: "reject",
		},
		[ActionType.Delete]: {
			title: "Are you sure?",
			description:
				"You are about to delete this teammate. Do you want to go ahead wih this action?",
			buttonText: "Yes, Delete",
			buttonVariant: "reject",
		},
	};
	const { title, description, buttonText, buttonVariant } =
		ActionConfig[actionType!];
	return (
		<ModalComponent
			size="lg"
			toggleModal={handleClose}
			heading=""
			subHeading=""
			modal={show}
			body={
				<ModalWrapper>
					<ToastContainer />
					<h2>{title}</h2>
					<p>{description}</p>
					<br />
					<div>
						<div onClick={handleClose}>Cancel</div>
						<Button
							title={buttonText}
							size="sm"
							textColor="normal"
							variant={buttonVariant}
							isLoading={loading}
							onClick={() => action[actionType!](team)}
						/>
					</div>
				</ModalWrapper>
			}
		/>
	);
}
const ModalWrapper = styled.div`
	width: 82%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;

	text-align: left;

	h2 {
		text-align: left;
		margin-botton: 0px;
	}
	div {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		button {
			margin-left: 20px;
		}
	}
`;
