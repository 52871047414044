import React, { useEffect, useState } from "react";
import Envelop from "../../assets/emailEnvelop.png";
import styled from "styled-components";
import * as constants from "../Constants/api";
import Alert from "../Constants/Alert";

export default function EmailNotification() {
	const [emailAddress, setEmailAddress] = useState({
		email: "",
	});
	const [successmodal, setSuccessModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");

	useEffect(() => {
		const signUpEmail = localStorage.getItem("email") as string;
		setEmailAddress({ email: signUpEmail });
	}, []);

	const tooglemodal = () => {
		setSuccessModal(!successmodal);
	};

	const reSendVerification = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		constants.httprequest
			.post("/accounts/resend-email/", emailAddress)
			.then(() => {
				setSuccessMsg("Verification Link Resent");
			})
			.catch(() => {
				// Catch Error
			});
	};
	return (
		<EmailWrapper>
			<span>
				<img src={Envelop} alt="Envelop" />
			</span>

			<EmailTitle>Check your email!</EmailTitle>

			<EmailText>
				We sent an email with a verification link to {emailAddress.email}
			</EmailText>
			<ActionButtons>
				<Resend onClick={reSendVerification}>Resend verification link</Resend>
				{/* <NewEmail>Change Email Address</NewEmail> */}
			</ActionButtons>

			<Alert
				alertSeverity={"success"}
				open={successmodal}
				alertTitle={successMsg}
				closeModal={tooglemodal}
			/>
		</EmailWrapper>
	);
}
const EmailTitle = styled.h2`
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
`;
const EmailText = styled.p`
	font-size: 13px;
	line-height: 21px;
	max-width: 320px;
`;
const EmailWrapper = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
`;
const ActionButtons = styled.div`
	display: flex;
	flex-direction: column;

	padding-top: 150px;
`;
const Resend = styled.button`
	background: none;
	border: none;
	font-size: 14px;
	cursor: pointer;
`;
// const NewEmail = styled.button`
//     background:none;
//     border: none;
//     padding-top: 14px;
//     font-size: 14px;
// `
