import React, { useState, useContext, useEffect } from "react";
import Modal from "../Constants/ModalComponent";
import { AppContext } from "../../Context";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import * as constants from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../Constants/FormComponents/Button";
import Input from "../Constants/FormComponents/Input";

const EditCustomerModal = (props: any) => {
	const { Industries, Countries, fetchAllCustomerData, banks } =
		useContext(AppContext);
	const { currentCustomer, editModal, handleEditModal } = props;

	const [editFormState, setEditFormState] = useState({
		category: "",
		name: "",
		business_name: "",
		industry: "",
		rc_number: "",
		company_code: "",
		company_email: "",
		phone_number: "",
		country: "",
		bank_name: "",
		bank_account_no: "",
		bank_account_name: "",
		transaction_limit: "",
		tier: "",
		user: "",
	});

	useEffect(() => {
		setEditFormState({
			...editFormState,
			category: currentCustomer?.category,
			name: currentCustomer?.name,
			business_name: currentCustomer?.business_name,
			industry: currentCustomer?.industry,
			rc_number: currentCustomer?.rc_number,
			company_code: currentCustomer?.company_code,
			company_email: currentCustomer?.company_email,
			phone_number: currentCustomer?.phone_number,
			country: currentCustomer?.country,
			bank_name: currentCustomer?.bank_name,
			bank_account_no: currentCustomer?.bank_account_no,
			bank_account_name: currentCustomer?.bank_account_name,
			transaction_limit: currentCustomer?.transaction_limit,
			tier: currentCustomer?.tier,
			user: currentCustomer?.user,
		});
	}, []);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditFormState({ ...editFormState, [e.target.name]: e.target.value });
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setEditFormState({
			...editFormState,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleEditCustomer = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.patch(`/customers/${currentCustomer?.uid}/`, editFormState)
			.then(() => {
				setIsLoading(false);
				fetchAllCustomerData();
				handleEditModal();
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				toast.error(err?.response?.data?.errors[0]?.[0]?.detail);
			});
	};

	// This Block of code might be used later

	// const validateRCNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { rc_number, business_name } = editFormState;
	// 	if (rc_number && business_name) {
	// 		const body = { business_name, rc_number };
	// 		constants.httprequest
	// 			.post(`/accounts/business/resolve/`, body)
	// 			.then(res => {
	// 				setEditFormState({
	// 					...editFormState,
	// 					company_code: res?.data?.data?.company_code,
	// 					business_name: res?.data?.data?.company_name,
	// 				});
	// 			})
	// 			.catch(err => {
	// 				if (err?.response?.status === 401) {
	// 					navigate("/");
	// 				}
	// 			});
	// 	}
	// };

	const {
		category,
		name,
		business_name,
		industry,
		company_code,
		company_email,
		phone_number,
		bank_account_no,
		bank_account_name,
	} = editFormState;

	return (
		<>
			<Modal
				toggleModal={handleEditModal}
				modal={editModal}
				size="lg"
				heading=""
				subHeading=""
				body={
					<>
						{/* Both the Preview of the buyer details and the buyer form are rendered in the same container */}
						{/* When the continue button is pressed, the form closes and the preview opens */}
						{/* There is only one modal close, it closes the whole modal */}
						{/* Edit button only closes the Preview and opens the pre-filled form */}
						<Wrapper>
							<>
								<FormTitle>Edit Customer</FormTitle>
								<FormSubtitle>BASIC INFORMATION</FormSubtitle>
								<Form>
									<FormContainer>
										<Input
											inputType="number"
											title="RC Number"
											name="rc_number"
											size="sm"
											isDisabled
											onChange={handleChange}
											required
										/>
										<Input
											inputType="text"
											title="Company Name"
											name="business_name"
											value={business_name}
											size="sm"
											isDisabled
											onChange={handleChange}
											required
										/>
										<FormGroup>
											<label>
												Industry <span> * </span>
											</label>
											<select
												onChange={handleSelectChange}
												name="industry"
												value={industry}
												disabled
											>
												{Industries.map((eachindustry: String, index) => {
													return (
														<option value={`${eachindustry}`} key={index}>
															{eachindustry}
														</option>
													);
												})}
											</select>
										</FormGroup>
										<Input
											inputType="text"
											title="Company Code"
											name="company_code"
											value={company_code}
											size="sm"
											isDisabled
											required
										/>
										<Input
											inputType="text"
											title={`${category == "supplier" ? "Supplier's" : "Buyer's"} name`}
											name="name"
											value={name}
											size="sm"
											isDisabled
											onChange={handleChange}
											required
										/>
									</FormContainer>
									<FormSubtitle>CONTACT INFORMATION</FormSubtitle>
									<FormContainer>
										<Input
											inputType="email"
											title="Company Email"
											name="company_email"
											value={company_email}
											size="sm"
											isDisabled
											onChange={handleChange}
											required
										/>
										<Input
											inputType="tel"
											title="Phone Number"
											name="phone_number"
											value={phone_number}
											placeHolder="+234********"
											size="sm"
											isDisabled
											onChange={handleChange}
											required
										/>
										<FormGroup>
											<label>
												Country <span> * </span>
											</label>
											<select
												onChange={handleSelectChange}
												name="country"
												aria-disabled
												disabled
											>
												{Countries.map((country: String, index) => {
													return (
														<option value={`${country}`} key={index}>
															{country}
														</option>
													);
												})}
											</select>
										</FormGroup>
									</FormContainer>
									{category === "supplier" ? (
										<>
											<FormSubtitle>ACCOUNT INFORMATION</FormSubtitle>
											<FormContainer>
												<FormGroup>
													<label>
														Bank name <span> * </span>
													</label>
													<select onChange={handleSelectChange} name="bank_code">
														{banks.map((bank: any, index: any) => {
															return (
																<option value={bank?.code} key={index}>
																	{bank?.name}
																</option>
															);
														})}
													</select>
												</FormGroup>
												<Input
													inputType="number"
													title="Bank account number"
													name="bank_account_no"
													value={bank_account_no}
													isDisabled
													size="sm"
													onChange={handleChange}
													required
												/>
												<Input
													inputType="text"
													title="Bank account name"
													name="bank_account_name"
													value={bank_account_name}
													size="sm"
													isDisabled
													onChange={handleChange}
												/>
											</FormContainer>
										</>
									) : null}

									<ActionButtons>
										<Button
											title="Edit"
											onClick={handleEditCustomer}
											size="full"
											textColor="normal"
											variant="default"
											isLoading={isLoading}
											disabled={true}
										/>
									</ActionButtons>
								</Form>
							</>
							<ToastContainer />
						</Wrapper>
					</>
				}
			/>
		</>
	);
};

export default EditCustomerModal;

const Wrapper = styled.div`
	padding-left: 40px;
	padding-right: 40px;
	@media (max-width: 426px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;

const Form = styled.form`
	width: 100%;
`;
const FormContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const FormTitle = styled.h2`
	color: black;
	font-weight: 500;
	font-size: 18px;
	marginn-bottom: 3px;
`;
const FormSubtitle = styled.h5`
	font-size: 12px;
	font-weight: 500;
	color: #919191;
	text-align: left;
	margin-top: 0px;
	margin-bottom: 0px;
`;
const FormGroup = styled.div`
	text-align: left;
	width: 48%;
	margin-top: 12px;
	margin-bottom: 12px;
	label {
		font-size: 14px;
		font-weight: 400;
		margin: 0px;
		color: #484848;
		text-align: left;
		width: 100%;
		span {
			color: tomato;
		}
	}
	input {
		width: 100%;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		box-sizing: border-box;
		padding-left: 10px;
		padding-right: 10px;
	}
	select {
		width: 100%;
		height: 48px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
		box-sizing: border-box;
		border-radius: 2px;
		padding-left: 10px;
		padding-right: 10px;
	}
	@media (max-width: 426px) {
		width: 100%;
	}
`;
const ActionButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`;
