import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/logoTwo.svg";
import axios from "axios";
import * as constants from "../Constants/api";
import { Link, useNavigate } from "react-router-dom";
import CarouselSlider from "../Constants/Carousel/CarouselSlider";
import OTP from "../Constants/OTP";
import Loader from "../../assets/loader.gif";
import { ToastContainer, toast } from "react-toastify";

export default function LoginOtp() {
	const navigate = useNavigate();
	const [loginDetails, setLoginDetails] = useState({
		email: "",
		password: "",
	});
	const [otp, setOtp] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const loginEmail = localStorage.getItem("email") as string;
		const loginPassword = localStorage.getItem("password") as string;

		setLoginDetails({
			...loginDetails,
			email: loginEmail,
			password: loginPassword,
		});
	}, []);

	const onChange = (value: string) => setOtp(value);

	const ResendOtp = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/login/`, loginDetails)
			.then(res => {
				if (res.status === 201) {
					toast.success("Otp Sent");
				}
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const details = {
			...loginDetails,
			mfa_code: otp,
		};
		setLoading(true);
		e.preventDefault();
		axios
			.post(`${constants.API_URL}/accounts/login/final/`, details)
			.then(res => {
				localStorage.setItem("isAuthenticated", res?.data?.token);
				localStorage.setItem("uid", res?.data?.user?.uid);
				navigate("/dashboard");
				window.location.reload();
			})
			.catch(error => {
				setLoading(false);
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<Wrapper>
			<CarouselSlider />
			<LoginWrapper>
				<LoginHeader>
					<Link to="/">
						<img src={Logo} alt="Bridger's Logo" />
					</Link>
					<Title>Login</Title>
					<LoginInstruction>
						We sent a 6-digit code to {loginDetails?.email}. Kindly enter the code.
					</LoginInstruction>
				</LoginHeader>
				<form onSubmit={handleSubmit}>
					<OTP value={otp} valueLength={6} onChange={onChange} />

					{loading ? (
						<Button>
							<img src={Loader} alt="loader gif" />
						</Button>
					) : (
						<Button>Log In</Button>
					)}

					<ResendOtpBtn onClick={ResendOtp}>
						{" "}
						Didn’t get code? Resend OTP
					</ResendOtpBtn>
				</form>
			</LoginWrapper>
			<ToastContainer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 70px;
	overflow: hidden;
	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		padding-top: 100px;
		gap: 0px;
	}
`;
const LoginWrapper = styled.div`
	padding-left: 58vw;
	padding-top: 100px;
	span {
		display: flex;
		justify-content: space-between;
		padding-top: 15px;
	}
	@media (max-width: 900px) {
		padding-left: 52vw;
	}
	@media (max-width: 768px) {
		width: 90vw;
		padding: 20px 0;
	}
`;
const LoginHeader = styled.div``;
const Title = styled.h2`
	font-size: 24px;
`;
const LoginInstruction = styled.p`
	font-size: 14px;
	max-width: 340px;
	text-align: center;
	margin: 0 auto;
`;

const Button = styled.button`
	width: 34vw;
	height: 40px;
	background: #302361;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border: none;
	border-radius: 2px;
	margin-top: 50px;
	margin-bottom: 20px;
	cursor: pointer;
	img {
		height: 40px;
	}
	@media (max-width: 768px) {
		width: 90%;
		margin: 0 auto;
		margin-top: 30px;
	}
`;

const ResendOtpBtn = styled.div`
	cursor: pointer;
	padding-top: 10px;
`;
