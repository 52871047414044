import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as constants from "../Constants/api";
import { AppContext } from "../../Context";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../assets/loader.gif";
import Alert from "../Constants/Alert";
import { useNavigate , useLocation } from "react-router-dom";
import { FormatCurrency } from "../Constants/utils";
import {
	CreateOptionsCard,
	CreateOptionsContainer,
	CheckedCard,
	CreateButton,
} from "../Invoice/Index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";
import Modal from "../Constants/Modal";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";

export default function OrderPreview() {
	const location = useLocation();
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [handleSend, setHandleSend] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingAccept, setIsLoadingAccept] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const [errSendModal, setErrSendModal] = useState(false);
	const [errSend, setErrSend] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const [acceptModal, setAcceptModal] = useState<boolean>(false);
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const [errAcceptModal, setErrAcceptModal] = useState(false);
	const [errAccept, setErrAccept] = useState("");
	const [errRejectModal, setErrRejectModal] = useState(false);
	const [errReject, setErrReject] = useState("");
	const [rejectMessage, setRejectMessage] = useState("");
	const currentInvoice = location.state ? location.state.currentInvoice : {};

	const handleAcceptModal = () => {
		setAcceptModal(!acceptModal);
	};

	const handleRejectModal = () => {
		setRejectModal(!rejectModal);
	};

	const { userProfile, fetchAllInvoiceData } = useContext(AppContext);
	const navigate = useNavigate();

	const handleCreateModal = () => {
		setCreateModal(!createModal);
	};

	const handleSendCard = () => {
		setHandleSend("sendInvoice");
	};

	const handleShareLink = () => {
		setHandleSend("copylink");
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
		setCreateModal(false);
	};

	const handleBack = () => {
		navigate(-1);
	};

	const closeErrSend = () => {
		setErrSendModal(false);
	};

	const closeErrAccept = () => {
		setErrAcceptModal(false);
	};

	const closeErrReject = () => {
		setErrRejectModal(false);
	};

	const handleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setRejectMessage(event.currentTarget.value);
	};

	// console.log(currentInvoice);
	// If invoice has been deleted and a user goes bcak, redirect to purchase order
	useEffect(() => {
		if (!location.state) {
			navigate("/invoice");
		}
	}, [currentInvoice, navigate]);

	const ShareInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.get(`/inventory/invoices/${currentInvoice?.uid}/send/`)
			.then(() => {
				handleSuccessModal();
			})
			.catch(err => {
				setIsLoading(false);
				handleCreateModal();
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrSendModal(true);
				setErrSend(err?.response?.data?.errors[0]?.detail);
			});
	};

	const AcceptInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingAccept(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/options/`, {
				option: "accept",
			})
			.then(() => {
				fetchAllInvoiceData();
				navigate("/invoice");
			})
			.catch(err => {
				setIsLoadingAccept(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrAcceptModal(true);
				setErrAccept(err?.response?.data?.errors[0]?.detail);
			});
	};

	const RejectInvoice = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingReject(true);
		constants.httprequest
			.post(`/inventory/invoices/${currentInvoice?.uid}/options/`, {
				option: "reject",
				message: rejectMessage,
			})
			.then(() => {
				fetchAllInvoiceData();
				navigate("/purchase-order");
			})
			.catch(err => {
				setIsLoadingReject(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrRejectModal(true);
				setErrReject(err?.response?.data?.errors[0]?.detail);
			});
	};

	const handleDownloadInvoice = () => {
		window.open(currentInvoice?.pdf, "_blank");
		// window.location.assign(currentInvoice[0]?.pdf);
	};

	return (
		<>
			<Main
				tabName="Invoice Preview"
				rightSubHeader={
					<>
						<BackDiv onClick={handleBack}>
							<KeyboardBackspaceIcon /> Go Back
						</BackDiv>
					</>
				}
				mainBody={
					<DetailsContainer>
						<DetailsHeader>
							<InvoiceNumber>
								<div
									style={{
										backgroundColor:
											currentInvoice?.status === "rejected" ? "#cd0a0a" : "#302361",
									}}
								></div>
								<h4>{`Invoice # ${currentInvoice?.order_number}`}</h4>
							</InvoiceNumber>
							{currentInvoice?.status === "new" ? (
								<div>
									<AcceptBtn onClick={handleAcceptModal}>
										<DoneOutlinedIcon />
										Accept
									</AcceptBtn>
									<RejectBtn onClick={handleRejectModal}>
										<CloseIcon /> Reject
									</RejectBtn>
								</div>
							) : null}
						</DetailsHeader>
						<AddressContainer>
							<InvoiceAddress>
								<div>
									<div>
										<h6>Invoice from</h6>
										<h5>
											{userProfile?.first_name} {userProfile?.last_name}
										</h5>
										<p>{userProfile?.address}</p>
									</div>
									<div>
										<h6>Sent to</h6>
										<h5>{currentInvoice?.customer?.name}</h5>
										<p>{currentInvoice?.customer?.address}</p>
									</div>
								</div>
								<div>
									<div>
										<h6>Invoice Number</h6>
										<h5>{currentInvoice?.order_number}</h5>
									</div>

									<div>
										<h6>Creation Date</h6>
										<h5>{currentInvoice?.created_at?.substring(0, 10)}</h5>
									</div>
									<div>
										<h6>Due Date</h6>
										<h5>{currentInvoice?.payment_due_date?.substring(0, 10)}</h5>
									</div>
								</div>
							</InvoiceAddress>
							<PayableAmount>
								<p>Total Payable</p>
								<h1> ₦ {FormatCurrency(currentInvoice?.total)}</h1>
							</PayableAmount>
						</AddressContainer>
						<InvoiceTable>
							<TableHead>
								<SmallDesc>Item</SmallDesc>
								<Desc>Item Description</Desc>
								<SmallQty>Qty</SmallQty>
								<Qty>Quantity</Qty>
								<Rate>Rate</Rate>
								<Amount>Amount</Amount>
							</TableHead>
							{/* {currentInvoice?.items.map((invoice: InvoiceItemProp) => {
                return (
                  <TableBody key={uuidv4()}>
                    <Desc>{invoice?.description}</Desc>
                    <SmallDesc>{invoice?.description}</SmallDesc>
                    <Qty>{`${invoice?.quantity}`}</Qty>
                    <SmallQty>{`${invoice?.quantity}`}</SmallQty>
                    <Rate>{`₦ ${FormatCurrency(invoice?.unit_price)}`}</Rate>
                    <Amount>{`₦ ${FormatCurrency(invoice?.total)}`}</Amount>
                  </TableBody>
                );
              })} */}
						</InvoiceTable>
						<TableCalc>
							<LeftCalc>
								<div>
									<p>Payment Terms</p>
									<h6>{currentInvoice?.payment_terms}</h6>
								</div>
							</LeftCalc>
							<RightCalc>
								<div>
									<p>Sub Total</p>
									<h6>₦{FormatCurrency(currentInvoice?.sub_total)}</h6>
								</div>
								<div>
									<p>Miscellenous</p>
									<h6>₦{FormatCurrency(currentInvoice?.misc)}</h6>
								</div>
								<div>
									<p>Tax</p>
									<h6>₦{FormatCurrency(currentInvoice?.tax)}</h6>
								</div>
								<div>
									<p>Discount</p>
									<h6>₦{FormatCurrency(currentInvoice?.discount)}</h6>
								</div>
								<div>
									<p>Total</p>
									<h6>₦{FormatCurrency(currentInvoice?.total)}</h6>
								</div>
							</RightCalc>
						</TableCalc>
						<ActionButtons>
							<div>
								{/* <button>Edit Invoice</button> */}
								<SendButton onClick={handleCreateModal}>Share</SendButton>
								<Delete onClick={handleDeleteModal}>Delete</Delete>
							</div>
							<Download onClick={handleDownloadInvoice}>
								<CloudDownloadIcon /> Download Pdf
							</Download>
						</ActionButtons>
					</DetailsContainer>
				}
			/>
			<Modal
				toggleModal={handleCreateModal}
				heading="Share Invoice"
				subHeading="Please select your preferred method"
				modal={createModal}
				body={
					<>
						<CreateOptionsContainer>
							<CreateOptionsCard onClick={handleSendCard}>
								<div>
									<ScreenShareOutlinedIcon />
								</div>
								<div>
									<h4>Send to a saved Customer</h4>
									<p>Send invoice to an already saved customer.</p>
								</div>
								<CheckedCard>
									{handleSend === "sendInvoice" ? <CheckCircleIcon /> : null}
								</CheckedCard>
							</CreateOptionsCard>
							<CreateOptionsCard onClick={handleShareLink}>
								<div>
									<LinkIcon />
								</div>
								<div>
									<h4>Copy Link</h4>
									<p>Copy and send invoice link to customer.</p>
								</div>
								<CheckedCard>
									{handleSend === "copylink" ? <CheckCircleIcon /> : null}
								</CheckedCard>
							</CreateOptionsCard>
							{handleSend === "sendInvoice" ? (
								<CreateButton onClick={ShareInvoice}>
									{isLoading ? <img src={Loader} alt="Loader" /> : "Continue"}
								</CreateButton>
							) : null}
						</CreateOptionsContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleSuccessModal}
				heading=""
				subHeading=""
				modal={successModal}
				body={
					<>
						<SuccessTitle>Invoice Sent !</SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully created and sent an Invoice to{" "}
								{currentInvoice?.customer?.name}. Your customer will receive an email or
								notification containing the Invoice details.
							</p>
							<h6>You also have the option of saving this Invoice as a PDF.</h6>
							<div>
								<Download onClick={handleDownloadInvoice}>Download PDF</Download>
								<Continue onClick={handleSuccessModal}>Continue</Continue>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Alert
				alertSeverity={"error"}
				open={errSendModal}
				alertTitle={errSend || "Unable to Send Invoice"}
				closeModal={closeErrSend}
			/>
			<Alert
				alertSeverity={"error"}
				open={errAcceptModal}
				alertTitle={errAccept || "Unable to Accept Invoice"}
				closeModal={closeErrAccept}
			/>
			<Alert
				alertSeverity={"error"}
				open={errRejectModal}
				alertTitle={errReject || "Unable to Reject Invoice"}
				closeModal={closeErrReject}
			/>
			{deleteModal ? (
				<DeleteInvoiceModal
					currentOrder={currentInvoice}
					deleteModal={deleteModal}
					handleDeleteModal={handleDeleteModal}
				/>
			) : null}
			<Modal
				toggleModal={handleRejectModal}
				heading=""
				subHeading=""
				modal={rejectModal}
				body={
					<>
						<AcceptContainer>
							<h5>Reject Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentInvoice?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentInvoice?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentInvoice?.total}</h4>
								</div>
							</AcceptDetails>
							<div>
								<label>Reason for rejection</label>
								<textarea onChange={handleAreaChange}></textarea>
							</div>
							<AcceptButtons>
								<button onClick={handleRejectModal}>Cancel</button>
								<RejectBtn onClick={RejectInvoice}>
									{isLoadingReject ? <img src={Loader} alt="Loader" /> : "Reject"}
								</RejectBtn>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleAcceptModal}
				heading=""
				subHeading=""
				modal={acceptModal}
				body={
					<>
						<AcceptContainer>
							<h5>Accept Purchase Order</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentInvoice?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentInvoice?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentInvoice?.total}</h4>
								</div>
							</AcceptDetails>
							<AcceptButtons>
								<button onClick={handleAcceptModal}>Cancel</button>
								<AcceptBtn onClick={AcceptInvoice}>
									{isLoadingAccept ? <img src={Loader} alt="Loader" /> : "Accept"}
								</AcceptBtn>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
		</>
	);
}
export const DetailsContainer = styled.div`
	padding-bottom: 60px;
`;

const BackDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	:hover {
		cursor: pointer;
	}
`;

export const InvoiceNumber = styled.div`
	gap: 15px;
	display: flex;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 25px;
	div {
		width: 48px;
		height: 48px;
	}
	h4 {
		color: var(--mainBlue);
		font-size: 25px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	@media (max-width: 768px) {
		gap: 8px;
		div {
			width: 32px;
			height: 32px;
		}
		h4 {
			font-size: 16px;
		}
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const InvoiceAddress = styled.div`
	width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 300px;
		text-align: left;
		margin-top: 20px;
		margin-bottom: 20px;
		:first-child {
			margin-bottom: 30px;
		}
	}
	h6 {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	p {
		color: #6d6d6d;
		font-size: 12px;
		margin-top: 2px;
		margin-bottom: 2px;
	}
	h5 {
		font-size: 14px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	@media (max-width: 1200px) {
		width: 450px;

		div {
			width: 200px;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		h5 {
			font-size: 12px;
		}
	}
	@media (max-width: 526px) {
		div {
			width: 180px;
		}
	}
	@media (max-width: 426px) {
		div {
			width: 150px;
		}
	}
`;

export const PayableAmount = styled.div`
	margin-left: auto;
	text-align: right;
	p {
		font-size: 12px;
		color: #919191;
		margin-top: 20px;
		margin-bottom: 2px;
	}
	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 29px;
		color: var(--mainBlue);
	}
	@media (max-width: 1200px) {
		h1 {
			font-size: 24px;
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const AcceptBtn = styled.button`
	background-color: #4bb543;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 20px;
	border-color: transparent;
	img {
		width: 36px;
	}
`;

const AcceptContainer = styled.div`
	width: 90%;
	margin: auto;
	h5 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 23px;
		text-align: left;
		color: black;
	}

	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
		font-weight: 400;
	}
	h4 {
		font-size: 14px;
		color: black;
		font-size: 500;
		margin-top: 10px;
		margin-bottom: 0px;
	}
	textarea {
		height: 120px;
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-top: 5px;
	}
`;

const AcceptDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AcceptButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 40px;
	button {
		padding: 15px 30px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 500;
		:first-child {
			color: var(--mainBlue);
			border: 1px solid var(--mainBlue);
		}
		:last-child {
			color: white;
			border: transparent;
		}
	}
`;

const RejectBtn = styled.button`
	background-color: var(--textRed);
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 20px;
	border-color: transparent;
	width: 114px;
	height: 45px;
	img {
		width: 36px;
	}
`;

const DetailsHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	div {
		display: flex;
		gap: 20px;
	}
	button {
		color: white;
		font-weight: 400;
		padding: 15px 30px;
		border-color: transparent;
		display: flex;
		align-items: center;
		gap: 5px;
		height: 48px;
	}
`;

export const InvoiceTable = styled.div`
	width: 100%;
	margin-top: 40px;
`;

export const TableHead = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e9e9e9;
	font-size: 14px;
	font-weight: 500;
`;

export const TableBody = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Desc = styled.div`
	width: 35%;
	font-size: 14px;
	text-align: left;
	padding-left: 20px;
	@media (max-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const SmallDesc = styled.div`
	width: 30%;
	font-size: 12px;
	text-align: left;
	padding-left: 10px;
	@media (min-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Qty = styled.div`
	width: 10%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		display: none;
	}
`;

export const SmallQty = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
	width: 6%;
	font-size: 12px;
	text-align: left;
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Rate = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Amount = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: right;
	padding-right: 20px;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
		padding-right: 10px;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const TableCalc = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	@media (max-width: 576px) {
		flex-direction: column-reverse;
	}
`;

export const LeftCalc = styled.div`
	width: 221px;
	text-align: left;
	p {
		font-size: 12px;
		font-weight: 500;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	div {
		margin-bottom: 30px;
	}
	@media (max-width: 576px) {
		width: 188px;
		p {
			margin-bottom: 5px;
		}
		h6 {
			font-size: 12px;
		}
		a {
			font-size: 12px;
			margin-top: 0px;
		}
	}
`;

export const RightCalc = styled.div`
	width: 45%;
	div {
		padding-right: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
		font-weight: bold;
	}
	@media (max-width: 576px) {
		width: 60%;
		margin-left: auto;
		p {
			font-size: 12px;
		}
		h6 {
			font-size: 14px;
		}
		div {
			padding-right: 10px;
		}
	}
	@media (max-width: 426px) {
		width: 70%;
	}
`;

export const ActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	div {
		display: flex;
		gap: 10px;
	}
	@media (max-width: 576px) {
		flex-direction: column;
		gap: 10px;
		div {
			flex-direction: column;
			justify-content: center;
		}
	}
`;

export const SendButton = styled.button`
	background-color: var(--mainBlue);
	color: white !important;
	justify-content: center;
	height: 48px;
	:hover {
		background-color: #9791b0;
	}
`;

export const Delete = styled.button`
	background-color: var(--textRed);
	color: white !important;
	height: 48px;
	border-color: transparent !important;
	font-weight: 500;
`;

const SuccessTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 23px;
	text-align: left;
	color: black;
	width: 90%;
	margin: auto;
`;

const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;

const Download = styled.button`
	color: var(--mainBlue);
	font-size: 14px;
	border: 1px solid var(--mainBlue);
	font-weight: 500;
	height: 48px;
	background-color: transparent;
`;

const Continue = styled.button`
	background-color: var(--mainBlue);
	font-size: 14px;
	border: transparent;
	color: white;
	font-weight: 500;
	padding: 15px 30px;
`;
