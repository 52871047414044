import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Modal from "../Constants/ModalComponent";
import Main from "../Constants/Main";
import PeopleIcon from "@mui/icons-material/People";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import * as constants from "../../Components/Constants/api";
import { useNavigate } from "react-router-dom";
import { AppContext, CustomerProps } from "../../Context";
import AddBuyerModal from "./AddBuyerModal";
import EmptyImg from "../../assets/empty.png";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddSellerModal, { SubHeader, PreviewItem } from "./AddSellerModal";
import EditCustomerModal from "./EditCustomerModal";
import DeleteCustomerModal from "./DeleteCustomerModal";
import Button from "../Constants/FormComponents/Button";
import Table from "../Constants/Table";
import { TableColumn } from "react-data-table-component";
import CreditMeter from "../Constants/CreditMeter";
import PaginatedCards from "../Constants/PaginatedCards";
import LoadingGif from "../../assets/bridger.gif";
import { ToastContainer, toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindoSize";
import { v4 as uuidv4 } from "uuid";

const Index: React.FC = () => {
	const {
		handleCustomerBuyerModal,
		handleCustomerSellerModal,
		allCustomerData,
	} = useContext(AppContext);
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [addState, setAddState] = useState("");
	const [allCustomer, setAllCustomer] = useState<Array<CustomerProps>>([]);
	const [allCustomerCount, setAllCustomerCount] = useState<Number>(0);
	const [allBuyerCount, setAllBuyerCount] = useState<Number>(0);
	const [allSupplierCount, setAllSupplierCount] = useState<Number>(0);
	const [toggleState, setToggleState] = useState("all");
	const [customerType, setCustomerType] = useState("");
	const [currentCustomer, setCurrentCustomer] = useState<any>({});
	const [customerDetailsState, setCustomerDetailsState] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [smallCustomerList, setSmallCustomerList] = useState<any>([]);
	const [creditCheckResp, setCreditCheckResp] = useState<any>({});
	const [isCreditLoading, setIsCreditLoading] = useState(false);
	const [requestLoading, setRequestLoading] = useState(false);
	const [smallTab, setSmallTab] = useState("default");
	const screenSize = useWindowSize();
	const navigate = useNavigate();

	const handleCreateModal = () => {
		setCreateModal(!createModal);
		setAddState("");
		setCustomerType("");
	};

	const handleCustomerSwitch = () => {
		setSmallTab("customerdetails");
	};
	const handleCreditSwitch = () => {
		setSmallTab("creditrating");
	};

	useEffect(() => {
		if (screenSize < 991) {
			setSmallTab("customerdetails");
		} else {
			setSmallTab("default");
		}
	}, [screenSize]);

	const handleSearchCustomer = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value;
		const result = allCustomerData?.filter((customer: any) => {
			return customer?.name?.toLowerCase().indexOf(input.toLowerCase()) > -1;
		});
		if (result?.length > 0 && input?.length) {
			setAllCustomer(result);
			setAllCustomerCount(result?.length);
		} else {
			const AllBuyers = allCustomerData?.filter(
				customer => customer.category === "buyer"
			);
			const AllSuppliers = allCustomerData?.filter(
				customer => customer.category === "supplier"
			);
			setToggleState("all");
			setAllCustomer(allCustomerData);
			setAllCustomerCount(allCustomerData?.length);
			setAllBuyerCount(AllBuyers?.length);
			setAllSupplierCount(AllSuppliers?.length);
		}
	};

	const handleAddBuyer = () => {
		setAddState("addBuyer");
		setCustomerType("manualBuyer");
	};

	const handleAddSupplier = () => {
		setAddState("addSupplier");
		setCustomerType("manualSeller");
	};

	const handleManualBuy = () => {
		setCustomerType("manualBuyer");
	};

	// const handleImportBuy = () => {
	// 	setCustomerType("importBuyer");
	// };

	const handleManualSell = () => {
		setCustomerType("manualSeller");
	};

	// const handleImportSell = () => {
	// 	setCustomerType("importSeller");
	// };

	const handleViewDetailsModal = () => {
		setCustomerDetailsState(!customerDetailsState);
	};

	const handleDetails = (row: any) => {
		setCurrentCustomer(row);
		getCreditCheck(row?.uid);
		handleViewDetailsModal();
	};

	const handleSmallDetails = (id: any) => {
		const current = allCustomerData.filter(customer => customer.uid === id);
		setCurrentCustomer(current[0]);
		getCreditCheck(id);
		handleViewDetailsModal();
	};

	const clearCurrentDetails = () => {
		handleViewDetailsModal();
		setCurrentCustomer({});
	};

	const handleEditModal = (row: any) => {
		setCustomerDetailsState(false);
		setCurrentCustomer(row);
		setEditModal(!editModal);
	};

	const handleDeleteModal = (row: any) => {
		setCustomerDetailsState(false);
		setCurrentCustomer(row);
		setDeleteModal(!deleteModal);
	};

	useEffect(() => {
		const AllBuyers = allCustomerData?.filter(
			customer => customer.category === "buyer"
		);
		const AllSuppliers = allCustomerData?.filter(
			customer => customer.category === "supplier"
		);
		setToggleState("all");
		setAllCustomer(allCustomerData);
		setAllCustomerCount(allCustomerData?.length);
		setAllBuyerCount(AllBuyers?.length);
		setAllSupplierCount(AllSuppliers?.length);
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
		setSmallCustomerList(
			allCustomerData?.map((customer: any) => {
				return {
					name: customer?.name,
					email: customer?.company_email,
					tel: customer?.phone_number,
					category: customer?.category,
					date: `${customer?.created_at?.substring(0, 10)}`,
					uid: customer?.uid,
				};
			})
		);
	}, [allCustomerData, navigate]);

	const getCreditCheck = (id: any) => {
		setIsCreditLoading(true);
		constants.httprequest
			.get(`/customers/${id}/credit_score/`)
			.then(res => {
				setCreditCheckResp(res?.data?.data);
				setIsCreditLoading(false);
			})
			.catch(err => {
				setIsCreditLoading(false);
				if (err.response.status === 401) {
					navigate("/");
				}
			});
	};

	const RequestCreditCheck = () => {
		setRequestLoading(true);
		constants.httprequest
			.post(`/customers/${currentCustomer?.uid}/request_credit_check/`)
			.then(_res => {
				setRequestLoading(false);
				toast.success("Credit Check Requested");
				getCreditCheck(currentCustomer?.uid);
			})
			.catch(err => {
				setRequestLoading(false);
				if (err.response.status === 401) {
					navigate("/");
				}
				toast.error("Credit Check insuccesful");
			});
	};

	const AllBuyers = allCustomerData?.filter(
		customer => customer.category === "buyer"
	);

	const AllSuppliers = allCustomerData?.filter(
		customer => customer.category === "supplier"
	);

	const handleAllCustomer = () => {
		setAllCustomer(allCustomerData);
		setToggleState("all");
	};

	const handleBuyer = () => {
		setAllCustomer(AllBuyers);
		setToggleState("buyer");
	};

	const handleSupplier = () => {
		setAllCustomer(AllSuppliers);
		setToggleState("supplier");
	};

	const columns: TableColumn<any>[] = [
		{
			name: "Business Name",
			selector: row => row?.business_name,
			width: "20%",
			sortable: true,
		},
		{
			name: "Email",
			selector: row => row?.company_email,
			width: "25%",
			sortable: true,
		},
		{
			name: "Phone Number",
			selector: row => row?.phone_number,
			width: "20%",
		},
		{
			name: "Category",
			selector: row => row?.category,
			width: "15%",
			sortable: true,
		},
		{
			name: "Action",
			cell: row => (
				<div key={row?.id}>
					<EditTableRow
						onClick={() => {
							handleEditModal(row);
						}}
					>
						Edit
					</EditTableRow>
					<DeleteTableRow
						onClick={() => {
							handleDeleteModal(row);
						}}
					>
						Delete
					</DeleteTableRow>
				</div>
			),
			width: "20%",
		},
	];

	return (
		<>
			<Main
				tabName="Customer"
				rightSubHeader={
					<>
						<SubHeaderSearch>
							<SearchOutlinedIcon />
							<InputSearch
								placeholder="Search for customer"
								onChange={handleSearchCustomer}
							/>
						</SubHeaderSearch>
						<Button
							title="Add Customer"
							onClick={handleCreateModal}
							size="md"
							textColor="normal"
							variant="default"
							icon={<AddIcon />}
						/>
					</>
				}
				mainBody={
					<>
						<TableToggleContainer>
							<LeftTableToggle>
								<div
									onClick={handleAllCustomer}
									style={{
										borderBottom:
											toggleState === "all" ? "1px solid #302361" : "transparent",
										color: toggleState === "all" ? "#302361" : "#6D6D6D",
									}}
								>
									{`All Customers (${allCustomerCount})`}
								</div>
								<div
									onClick={handleBuyer}
									style={{
										borderBottom:
											toggleState === "buyer" ? "1px solid #302361" : "transparent",
										color: toggleState === "buyer" ? "#302361" : "#6D6D6D",
									}}
								>
									{`Buyers (${allBuyerCount})`}
								</div>
								<div
									style={{
										borderBottom:
											toggleState === "supplier" ? "1px solid #302361" : "transparent",
										color: toggleState === "supplier" ? "#302361" : "#6D6D6D",
									}}
									onClick={handleSupplier}
								>
									{`Supplier (${allSupplierCount})`}
								</div>
							</LeftTableToggle>
							<RightTableToggle />
						</TableToggleContainer>
						<LGTableContainer>
							<Table
								data={allCustomer}
								columns={columns}
								pagination
								paginationPerPage={20}
								onRowClicked={(row: any) => handleDetails(row)}
							/>
							{!allCustomer.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no{" "}
										{toggleState === "all"
											? "customers"
											: toggleState === "supplier"
											? "supplier"
											: "buyer"}{" "}
										Click on the button below to onboard one
									</p>
									<Button
										title="Add Customer"
										onClick={handleCreateModal}
										size="md"
										textColor="normal"
										variant="default"
										icon={<PersonAddAltIcon />}
									/>
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={smallCustomerList}
								date="date"
								valueOne="name"
								valueTwo="email"
								valueThree="tel"
								valueFour="category"
								id="uid"
								onClick={handleSmallDetails}
							/>
						</SMTBContainer>

						<Modal
							toggleModal={handleCreateModal}
							heading="Add new customer"
							subHeading="Please select the category of customer you want to add"
							modal={createModal}
							size="lg"
							body={
								<>
									<CreateOptionsContainer>
										<CreateOptionsCard onClick={handleAddBuyer}>
											<div>
												<PeopleIcon />
											</div>
											<div>
												<h4>Buyer</h4>
												<p>I want to add a customer who is a buyer</p>
												<button onClick={handleManualBuy}>
													<span>
														{customerType === "manualBuyer" ? (
															<CheckCircleIcon />
														) : (
															<CircleOutlined />
														)}
													</span>
													Add buyer manually
												</button>
												{/* Comment out import buyer from csv */}
												{/* <button onClick={handleImportBuy}>
													<span>
														{customerType === "importBuyer" ? (
															<CheckCircleIcon />
														) : (
															<CircleOutlined />
														)}
													</span>
													Import buyer from CSV
												</button> */}
											</div>
											<CheckedCard>
												{addState === "addBuyer" ? <CheckCircleIcon /> : <CircleOutlined />}
											</CheckedCard>
										</CreateOptionsCard>
										<CreateOptionsCard onClick={handleAddSupplier}>
											<div>
												<PeopleIcon />
											</div>
											<div>
												<h4>Supplier</h4>
												<p>I want to add a customer who is a supplier</p>
												<button onClick={handleManualSell}>
													<span>
														{customerType === "manualSeller" ? (
															<CheckCircleIcon />
														) : (
															<CircleOutlined />
														)}
													</span>
													Add supplier manually
												</button>
												{/* comment out import supplier from csv */}
												{/* <button onClick={handleImportSell}>
													<span>
														{customerType === "importSeller" ? (
															<CheckCircleIcon />
														) : (
															<CircleOutlined />
														)}
													</span>
													Import supplier from CSV
												</button> */}
											</div>
											<CheckedCard>
												{addState === "addSupplier" ? (
													<CheckCircleIcon />
												) : (
													<CircleOutlined />
												)}
											</CheckedCard>
										</CreateOptionsCard>
										{customerType === "manualBuyer" ? (
											<Button
												title="Continue"
												onClick={() => {
													handleCustomerBuyerModal();
													handleCreateModal();
												}}
												size="full"
												textColor="normal"
												variant="default"
											/>
										) : null}
										{customerType === "manualSeller" ? (
											<Button
												title="Continue"
												onClick={() => {
													handleCustomerSellerModal();
													handleCreateModal();
												}}
												size="full"
												textColor="normal"
												variant="default"
											/>
										) : null}
									</CreateOptionsContainer>
								</>
							}
						/>
					</>
				}
			/>
			<AddBuyerModal />
			<AddSellerModal />
			<Modal
				modal={customerDetailsState}
				toggleModal={clearCurrentDetails}
				heading="Customer's Details"
				subHeading=""
				size="xlg"
				headingDirection="left"
				body={
					<>
						<ToggleContainer>
							<div
								onClick={handleCustomerSwitch}
								style={{
									borderBottom:
										smallTab === "customerdetails" ? "1px solid #302361" : "transparent",
									color: smallTab === "customerdetails" ? "#302361" : "#6D6D6D",
								}}
							>
								Basic Information
							</div>
							<div
								onClick={handleCreditSwitch}
								style={{
									borderBottom:
										smallTab === "creditrating" ? "1px solid #302361" : "transparent",
									color: smallTab === "creditrating" ? "#302361" : "#6D6D6D",
								}}
							>
								Credit Rating
							</div>
						</ToggleContainer>
						<DetailsContainer>
							{smallTab === "customerdetails" || smallTab === "default" ? (
								<DetailsWrapper>
									<SubHeader>BASIC INFORMATION</SubHeader>
									<PreviewItem>
										<h6>RC Number</h6>
										<h5>{currentCustomer?.rc_number}</h5>
									</PreviewItem>
									<PreviewItem>
										<h6>Company Name</h6>
										<h5>{currentCustomer?.business_name}</h5>
									</PreviewItem>
									<PreviewItem>
										<h6>Industry</h6>
										<h5>{currentCustomer?.industry}</h5>
									</PreviewItem>
									{/* <PreviewItem>
										<h6>Company Code</h6>
										<h5>{currentCustomer?.company_code}</h5>
									</PreviewItem> */}
									<PreviewItem>
										<h6>Buyer's Name</h6>
										<h5>{currentCustomer?.name}</h5>
									</PreviewItem>
									<SubHeader>CONTACT INFORMATION</SubHeader>
									<PreviewItem>
										<h6>Company Email</h6>
										<h5>{currentCustomer?.company_email}</h5>
									</PreviewItem>
									<PreviewItem>
										<h6>Phone Number</h6>
										<h5>{currentCustomer?.phone_number}</h5>
									</PreviewItem>
									<PreviewItem>
										<h6>Country</h6>
										<h5>{currentCustomer?.country}</h5>
									</PreviewItem>
									{currentCustomer?.category !== "buyer" && (
										<>
											<SubHeader>OTHER INFORMATION</SubHeader>
											{currentCustomer?.tier && (
												<PreviewItem>
													<h6>Tier</h6>
													<h5>{currentCustomer?.tier}</h5>
												</PreviewItem>
											)}
											{currentCustomer?.transaction_limit && (
												<PreviewItem>
													<h6>Transaction Limit</h6>
													<h5>NGN {currentCustomer?.transaction_limit}</h5>
												</PreviewItem>
											)}
											{currentCustomer?.bank_account_name && (
												<PreviewItem>
													<h6>Bank Account Name</h6>
													<h5>{currentCustomer?.bank_account_name}</h5>
												</PreviewItem>
											)}
											{currentCustomer?.bank_account_no && (
												<PreviewItem>
													<h6>Bank Account No</h6>
													<h5>{currentCustomer?.bank_account_no}</h5>
												</PreviewItem>
											)}
											{currentCustomer?.bank_name && (
												<PreviewItem>
													<h6>Bank Name</h6>
													<h5>{currentCustomer?.bank_name}</h5>
												</PreviewItem>
											)}
										</>
									)}

									<ActionButtons>
										<Button
											title="Delete Customer"
											onClick={() => {
												handleDeleteModal(currentCustomer);
											}}
											size="sm"
											textColor="normal"
											variant="reject"
										/>
										<Button
											title="Edit Customer"
											onClick={() => {
												handleEditModal(currentCustomer);
											}}
											disabled
											size="sm"
											textColor="normal"
											variant="default"
										/>
									</ActionButtons>
								</DetailsWrapper>
							) : null}
							{creditCheckResp?.credit_score ? (
								<Divider>
									<hr />
								</Divider>
							) : null}

							{smallTab === "creditrating" || smallTab === "default" ? (
								<CreditWrapper>
									{creditCheckResp?.credit_score ? (
										<>
											<SubHeader>Credit Rating</SubHeader>
											<CreditSubHeading>
												Discover the credit score of your customers
											</CreditSubHeading>
										</>
									) : null}
									{isCreditLoading ? (
										<LoadingCredit>
											<img src={LoadingGif} alt="Bridger Loader" />
										</LoadingCredit>
									) : // if credit check, check if it is requested, display the ui or check if it is pending, display the ui, else display the credit check
									// if there is not credit check response, display the default for if it is not requested
									creditCheckResp?.credit_check_status === "updated" ? (
										<CreditSection>
											<div>
												<CreditMeter creditScore={creditCheckResp?.credit_score} />
											</div>
											<div>
												<table>
													<tbody>
														<tr>
															<td> {"<580"} - Poor </td>
															<td>Well below average, risky borrower</td>
														</tr>
														<tr>
															<td>580 - 669 - Fair</td>
															<td>Below average, risky borrower</td>
														</tr>
														<tr>
															<td>670 -739 - Good</td>
															<td>Slightly above average, good score</td>
														</tr>
														<tr>
															<td>740 -799 - Very Good</td>
															<td>Above average, dependable borrower</td>
														</tr>
														<tr>
															<td>800+ - Exceptional</td>
															<td>Well above average, exceptional borrower</td>
														</tr>
													</tbody>
												</table>
											</div>
										</CreditSection>
									) : creditCheckResp?.credit_check_status === "in progress" ? (
										<CreditProgress>
											<div>
												<h3>Credit Check Ongoing</h3>
												<p>
													This process takes an average of 24 hours. You will be notified
													once the check is complete.{" "}
												</p>
											</div>
										</CreditProgress>
									) : (
										<CreditInfo>
											{" "}
											<SubHeader>CREDIT RATING</SubHeader>
											<CreditSubHeading>
												Discover the credit score of your customers
											</CreditSubHeading>
											<h3>Why is this important?</h3>
											<ul>
												<li>
													It assesses the creditworthiness of customers, reducing the risk of
													potential defaults and late payments.
												</li>
												<li>
													It helps you make informed decisions on setting credit limits.
												</li>
												<li>Helps you manage risk and eliminate bad debt.</li>
											</ul>
											<Button
												title="Request Credit Rating"
												size="lg"
												variant="default"
												textColor="normal"
												onClick={RequestCreditCheck}
												isLoading={requestLoading}
											/>
										</CreditInfo>
									)}

									{/* <CreditHeading>Transactions</CreditHeading> */}
								</CreditWrapper>
							) : null}

							<ToastContainer />
						</DetailsContainer>
					</>
				}
			/>
			{editModal ? (
				<EditCustomerModal
					currentCustomer={currentCustomer}
					editModal={editModal}
					handleEditModal={handleEditModal}
				/>
			) : null}
			{deleteModal ? (
				<DeleteCustomerModal
					currentCustomer={currentCustomer}
					deleteModal={deleteModal}
					handleDeleteModal={handleDeleteModal}
				/>
			) : null}
		</>
	);
};

export default Index;

const SubHeaderSearch = styled.div`
	width: 252px;
	font-size: 12px;
	color: #b5b5b5;
	display: flex;
	align-items: center;
	position: relative;
	svg {
		position: absolute;
		left: 16px;
	}
	@media (max-width: 1200px) {
		display: none;
	}
`;
const InputSearch = styled.input`
	width: 100%;
	height: 40px;
	padding-left: 42px;
	border-radius: 4px;
	border-color: transparent;
	color: #b5b5b5;
	background-color: #f7f7f7;
`;

const TableToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: 50px;
	overflow: scroll;
	@media (max-width: 576px) {
		padding-top: 30px;
	}
`;

const LeftTableToggle = styled.div`
	display: flex;
	gap: 30px;
	padding-bottom: 3px;
	font-size: 14px;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 1200px) {
		font-size: 12px;
	}
	@media (max-width: 576px) {
		div {
			padding-bottom: 10px;
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
`;

const RightTableToggle = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

const DeleteTableRow = styled.button`
	border-color: transparent;
	text-decoration: underline;
	background-color: transparent;
	padding: 0px;
	color: #302361;
	font-size: 12px;
	margin-right: 5px;
`;

const EditTableRow = styled.button`
	border-color: transparent;
	text-decoration: underline;
	background-color: transparent;
	padding: 0px;
	color: #cd0a0a;
	font-size: 12px;
	margin-right: 5px;
`;

const LGTableContainer = styled.div`
	@media (max-width: 576px) {
		display: none;
	}
`;

const SMTBContainer = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
`;

export const CreateOptionsContainer = styled.div`
	margin: 50px;
	@media (max-width: 576px) {
		width: 90%;
		margin: auto;
	}
`;

export const CreateOptionsCard = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 30px;
	margin-bottom: 30px;
	border: 1px solid #d9d9d9;
	padding: 25px;
	svg {
		color: black;
	}
	div {
		:first-child {
			width: 36px;
		}
	}
	h4 {
		margin-bottom: 6px;
		margin-top: 0px;
		font-size: 14px;
		color: black;
	}
	p {
		margin-top: 0px !important;
		margin-bottom: 0px;
		font-size: 12px;
		color: #6d6d6d;
	}
	:hover {
		cursor: pointer;
	}
	button {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		background-color: transparent;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 5px;
		font-size: 14px;
		font-weight: bold;
		border-color: transparent;
		span {
			width: 16px;
		}
		svg {
			font-size: 12px;
		}
	}

	@media (max-width: 576px) {
		padding: 10px 25px;
		gap: 15px;
		h4 {
			font-size: 12px;
		}
		button {
			font-size: 12px;
			margin-top: 0px;
			padding-top: 3px;
			padding-bottom: 3px;
			span {
				width: 14px;
			}
		}
	}
`;

export const CreateButton = styled.button`
	background-color: var(--mainBlue);
	width: 100%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
	:disabled {
		background-color: #ccc;
		color: var(--mainBlue);
	}
`;

export const CheckedCard = styled.div`
	margin-left: auto;
	color: var(--mainBlue);

	svg {
		font-size: 12px;
	}
`;

export const EmptyData = styled.div`
	width: 100%;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	p {
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
		margin-bottom: 30px;
		color: black;
	}
	img {
		width: 180px;
		height: 140px;
	}
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: var(--mainBlue);
		width: 200px;
		height: 48px;
		margin: auto;
		border-color: transparent;
		color: white;
	}
`;

const DetailsContainer = styled.div`
	display: flex;
	gap: 20px;
	padding-left: 30px;
	padding-right: 30px;
	justify-content: space-between;

	@media (max-width: 576px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;

const Divider = styled.div`
	hr {
		width: 1px;
		height: 131px;
		background-color: #dfdfdf;
		border-color: transparent;
		position: absolute;
		top: 35%;
	}
	@media (max-width: 991px) {
		display: none;
	}
`;

const DetailsWrapper = styled.div`
	width: 30%;
	position: relative;
	@media (max-width: 576px) {
		width: 400px;
	}

	@media (max-width: 991px) {
		width: 100%;
	}

	@media (max-width: 426px) {
		width: 320px;
	}
`;

const CreditWrapper = styled.div`
	padding-left: 15px;
	width: 65%;
	position: relative;
	@media (max-width: 991px) {
		width: 100%;
		padding-left: 0px;
	}
`;

// const CreditHeading = styled.div`
// 	font-weight: 500;
// 	font-size: 12px;
// 	text-align: left;
// 	margin-right: auto;
// 	width: 400px;
// 	margin-bottom: 10px;
// 	margin-top: 25px;
// 	color: #484848;
// 	font-weight: 400;
// `;

const CreditSubHeading = styled.div`
	font-size: 12px;
	font-weight: 400;
	color: var(--textAsh);
`;

const ActionButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 90px;
`;

const CreditSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;
	overflow: scroll;
	gap: 20px;
	div {
		:first-child {
			width: 250px;
		}
		:second-child {
			width: 65%;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
	}
	td {
		border: 1px solid var(--textAsh);
		padding: 8px;
		font-size: 12px;
		font-weight: 500;
	}
	tr {
		td:first-child {
			color: var(--textAsh);
		}
		td:last-child {
			color: black;
		}
	}
	@media (max-width: 991px) {
		flex-direction: column;
		justify-content: center;
		gap: 0px;
	}
`;

const CreditInfo = styled.div`
	width: 70%;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 20px;
	h3 {
		color: black;
		font-weight: 500;
		font-size: 14px;
		margin-top: 8px;
	}
	li {
		font-size: 14px;
		line-height: 30px;
	}
	@media (max-width: 991px) {
		margin-right: auto;
		margin-left: 0px;
	}
`;

const CreditProgress = styled.div`
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	h3 {
		color: black;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 10px;
	}
	p {
		width: 283px;
		font-size: 12px;
		line-height: 16px;
	}
`;

const LoadingCredit = styled.div`
	height: 300px;
	width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	img {
		width: 60px;
	}
`;

export const ToggleContainer = styled.div`
	display: flex;
	gap: 30px;
	padding-top: 25px;
	padding-bottom: 15px;
	font-size: 18px;
	font-weight: 500;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 576px) {
		font-size: 16px;
		div {
			width: 140px;
			text-align: center;
			:first-child {
				width: 180px;
			}
		}
	}
	@media (max-width: 991px) {
		padding-left: 30px;
		padding-right: 30px;
		div {
			padding-bottom: 5px;
		}
	}
	@media (max-width: 426px) {
		font-size: 12px;
		gap: 15px;
		padding-left: 15px;
		padding-right: 15px;
		div {
			width: 140px;
			:first-child {
				width: 140px;
			}
		}
	}
	@media (min-width: 991px) {
		display: none;
	}
`;
