export const RE_DIGIT = new RegExp(/^\d+$/);
export const FormatCurrency = (number: any, showCurrency = false) => {
	if (showCurrency) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "NGN",
		}).format(number);
	}
	return new Intl.NumberFormat("en-US", { style: "currency", currency: "NGN" })
		.format(number)
		.replace("NGN", "")
		.trim();
};

export const FormatCount = (number: any) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "NGN",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
		.format(number)
		.replace("NGN", "")
		.trim();
};

export const Currency = [
	{ value: "ngn", label: "NGN (Naira)", symbol: "₦" },
	{ value: "cad", label: "CAD (Canadian Dollar)", symbol: "CAD" },
	{ value: "xof", label: "CFA (West African CFA Franc)", symbol: "CFA" },
	{ value: "cny", label: "CNY (Chinese Yuan)", symbol: "¥" },
	{ value: "egp", label: "EGP (Egyptian Pound)", symbol: "ج.م" },
	{ value: "eur", label: "EUR (Euro)", symbol: "€" },
	{ value: "gbp", label: "GBP (British Pound)", symbol: "£" },
	{ value: "ghs", label: "GHS (Ghanian Cedi)", symbol: "GH₵" },
	{ value: "jpy", label: "JPY (Japanese Yuan)", symbol: "¥" },
	{ value: "kes", label: "KES (Kenyan Shilling)", symbol: "Ksh" },
	{ value: "usd", label: "USD (United States Dollar)", symbol: "$" },
	{ value: "zar", label: "ZAR (South African Rand)", symbol: "R" },
];

export const Gender = [
	{ value: "female", label: "Female" },
	{ value: "male", label: "Male" },
];
export const Roles = [
	{ value: "operations", label: "Operations" },
	{ value: "finance", label: "Finance" },
];
export const Identification = [
	{ value: "INTERNATIONAL_PASSPORT", label: "International Passport" },
	{ value: "DRIVERS_LICENSE", label: "Driver’s License" },
	{ value: "VOTERS_CARD", label: "Permanent Voter’s Card" },
	{ value: "NATIONAL_ID", label: "National Identification Card" },
];

export const Industry = [
	{ value: "AGRICULTURE", label: "Agriculture" },
	{ value: "CONSTRUCTION", label: "Construction" },
	{ value: "EDUCATION", label: "Education" },
	{ value: "FINANCE", label: "Finance and Insurance" },
	{ value: "HEALTH_SERVICES", label: "Health Services" },
	{ value: "HOSPITALITY", label: "Hospitality" },
	{ value: "LOGISTICS", label: "Logistics" },
	{ value: "MANUFACTURING", label: "Manufacturing" },
	{ value: "MEDIA", label: "Media, Advertising and Publishing" },
	{ value: "REAL_ESTATE", label: "Real Estate" },
	{ value: "RETAIL", label: "Retail" },
	{ value: "SOFTWARE", label: "Software and Technology" },
	{ value: "OTHER", label: "Other" },
];

export const Treasury = [
	{ value: "SUPPLY_CHAIN_FINANCING", label: "Supply chain financing" },
	{ value: "PAYMENT_AUTOMATION", label: "Payment automation" },
	{ value: "CROSS_BORDER_PAYMENTS", label: "Cross-border payments" },
	{ value: "E_INVOICING", label: "E-invoicing" },
	{ value: "FX_RISK_MITIGATION", label: "FX risk mitigation" },
	{ value: "OTHERS", label: "Others" },
];

export const AnnualTurnOver = [
	{ value: "LESS_THAN_500K", label: "Less than 500 thousand Naira" },
	{
		value: "BETWEEN_500K_AND_1M",
		label: "500 thousand Naira to 1 million Naira",
	},
	{ value: "BETWEEN_1M_AND_5M", label: "1 million Naira to 5 million Naira" },
	{ value: "BETWEEN_5M_AND_10M", label: "5 million Naira to 10 million Naira" },
	{
		value: "BETWEEN_10M_AND_50M",
		label: "10 million Naira to 50 million Naira",
	},
	{
		value: "BETWEEN_50M_AND_100M",
		label: "50 million Naira and 100 million Naira",
	},
	{
		value: "BETWEEN_100M_AND_500M",
		label: "100 million Naira and 500 million Naira",
	},
	{ value: "ABOVE_500M", label: "Above 500 million Naira" },
];

export const CompanyType = [
	{
		value: "Sole proprietorship/Enterprise",
		label: "Sole proprietorship/Enterprise",
	},
	{ value: "Private Limited Company", label: "Private Limited Company" },
	{ value: "Public Limited Company", label: "Public Limited Company" },
	{ value: "Not-for-profit", label: "Not-for-profit" },
];

export const Country = [
	{ value: "DZ", label: "ALGERIA" },
	{ value: "AO", label: "ANGOLA" },
	{ value: "BJ", label: "BENIN" },
	{ value: "BW", label: "BOTSWANA" },
	{ value: "BF", label: "BURKINA FASO	" },
	{ value: "BI", label: "BURUNDI" },
	{ value: "CM", label: "CAMEROON" },
	{ value: "CV", label: "CAPE VERDE" },
	{ value: "CF", label: "CENTRAL AFRICAN REPUBLIC" },
	{ value: "TD", label: "CHAD" },
	{ value: "KM", label: "COMOROS" },
	{ value: "CG", label: "CONGO" },
	{ value: "CI", label: "COTE D’IVOIRE" },
	{ value: "DJ", label: "DJIBOUTI" },
	{ value: "EG", label: "EGYPT" },
	{ value: "GQ", label: "EQUATORIAL GUINEA" },
	{ value: "ER", label: "ERITREA" },
	{ value: "ET", label: "ETHIOPIA" },
	{ value: "GA", label: "GABON" },
	{ value: "GM", label: "GAMBIA" },
	{ value: "GH", label: "GHANA" },
	{ value: "GN", label: "GUINEA" },
	{ value: "GW", label: "GUINEA-BISSAU" },
	{ value: "KE", label: "KENYA" },
	{ value: "LS", label: "LESOTHO" },
	{ value: "LR", label: "LIBERIA" },
	{ value: "LY", label: "LIBYAN ARAB JAMAHIRIYA" },
	{ value: "MG", label: "MADAGASCAR" },
	{ value: "ML", label: "MALI" },
	{ value: "MW", label: "MALAWI" },
	{ value: "MR", label: "MAURITANIA" },
	{ value: "MU", label: "MAURITIUS" },
	{ value: "YT", label: "MAYOTTE" },
	{ value: "MA", label: "MOROCCO" },
	{ value: "MZ", label: "MOZAMBIQUE" },
	{ value: "NA", label: "NAMIBIA" },
	{ value: "NE", label: "NIGER" },
	{ value: "NG", label: "NIGERIA" },
	{ value: "RE", label: "REUNION ISLAND" },
	{ value: "RW", label: "RWANDA" },
	{ value: "SN", label: "SENEGAL" },
	{ value: "SC", label: "SEYCHELLES" },
	{ value: "SL", label: "SIERRA LEONE" },
	{ value: "SO", label: "SOMALIA" },
	{ value: "ZA", label: "SOUTH AFRICA" },
	{ value: "SS", label: "SOUTH SUDAN" },
	{ value: "SD", label: "SUDAN" },
	{ value: "SZ", label: "SWAZILAND" },
	{ value: "TZ", label: "TANZANIA" },
	{ value: "TG", label: "TOGO" },
	{ value: "TN", label: "TUNISIA" },
	{ value: "UG", label: "UGANDA" },
	{ value: "EH", label: "WESTERN SAHARA" },
	{ value: "ZM", label: "ZAMBIA" },
	{ value: "ZW", label: "ZIMBABWE" },
];

export const CheckDigit = (char: any) => {
	return RE_DIGIT.test(char) ? char : "";
};
export const CheckNull = (item: any) => {
	return item === null && null;
};
export const CheckString = (item: any) => {
	return typeof item === "string" ? item : "";
};
export const CheckBoolean = (item: any) => {
	return typeof item === "boolean" ? item : false;
};
export const CheckInt = (item: any) => {
	return Number.isInteger(item) ? item : 0;
};
export const CheckFloat = (item: any) => {
	return item === +item ? item : 0;
};
