import React from "react";
import styled from "styled-components";
// import { httprequest } from "../Constants/api";
import { ToastContainer } from "react-toastify";

export default function BVNVerification({ bvnVerify }: any) {
	return (
		<>
			<StatusBar onClick={() => bvnVerify()}>
				Click here to verify your BVN
			</StatusBar>

			<ToastContainer />
		</>
	);
}

const StatusBar = styled.div`
	padding: 20px 10px 20px 15px;
	color: #000;
	text-align: left;
	font-size: 14px;
	background: #fcf2dc;
	width: 100vw;
	margin-bottom: 20px;
	cursor: pointer;
`;
