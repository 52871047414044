import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { httprequest, BASE_URL } from "../Constants/api";
import { AppContext } from "../../Context";
import * as constants from "../../Components/Constants/api";
import EmptyImg from "../../assets/empty.png";
import TransferToBridger from "../../assets/TransferToBridger.png";
import Slider from "@mui/material/Slider";
import { ToastContainer, toast } from "react-toastify";
import PaginatedCards from "../Constants/PaginatedCards";
import { TableColumn } from "react-data-table-component";
import Button from "../Constants/FormComponents/Button";
import Table from "../Constants/Table";

import Main from "../Constants/Main";
import View from "../Settings/View";

import {
	SubHeaderButton,
	SubHeaderSearch,
	InputSearch,
	LGTableContainer,
	SMTBContainer,
	EmptyData,
} from "../Invoice/Index";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useCountdown } from "../Receive/Countdown";
import styled from "styled-components";
import OTP from "../Constants/OTP";
import { Box } from "@mui/material";
import { FormatCurrency } from "../Constants/utils";
import Status from "../Constants/Status";
import ModalComponent from "../Constants/ModalComponent";

const columns: TableColumn<any>[] = [
	{
		name: "Date",
		selector: row => row?.created_at?.substring(0, 10),
		width: "10%",
		sortable: true,
	},
	{
		name: "Invoice No",
		selector: row => row?.order_number,
		width: "15%",
		sortable: true,
		center: true,
	},
	{
		name: "Invoice From",
		selector: row => row?.payment_link?.creator?.user_full_name,
		width: "20%",
	},
	{
		name: "Invoice to",
		selector: row => row?.customer?.name,
		width: "20%",
		sortable: true,
	},
	{
		name: "Amount(₦)",
		selector: row => FormatCurrency(row?.total),
		width: "15%",
		sortable: true,
	},
	{
		name: "Status",
		cell: (row: { status: string }) => <Status statusState={row?.status} />,
		center: true,
		width: "20%",
	},
];
const Index: React.FC = () => {
	const [allInvoice, setAllInvoice] = useState<any>([]);
	const [acceptedInvoive, setAcceptedInvoice] = useState([]);

	const { allInvoiceData } = useContext(AppContext);
	const navigate = useNavigate();
	const AcceptInvoice = () => {
		navigate("/invoice");
	};

	const [paymentMethodModal, setPaymentMethodModal] = useState(false);
	const [confirmTransactionModal, setConfirmTransactionModal] = useState(false);
	const [transactionPinModal, setTransactionPinModal] = useState(false);
	const [toBridgerWallet, setToBridgerWallet] = useState(false);
	const [toBankTransfer, setToBankTransfer] = useState(false);
	const [toCardPayment, setToCardPayment] = useState(false);
	const [repaymentMethodModal, setRepaymentMethodModal] = useState(false);
	const [throughBridger, setThroughBridger] = useState(false);
	const [bankAcctModal, setBankAcctModal] = useState<boolean>(false);
	const [repaymentPlan, setRepaymentPlan] = useState("");
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState("");

	const [invoiceDetails, setInvoiceDetails] = useState({
		invoice_number: "",
		customer: "",
		amount: "",
		narration: "",
		channel: "",
		reference: "",
		wallet: "",
		pin: "",
	});

	const [bankInfo, setBankInfo] = useState({
		account_name: "",
		account_number: "",
		amount: "",
		bank_code: "",
		bank_name: "",
		expiry: "",
	});

	const [days, hours, minutes, seconds] = useCountdown(bankInfo?.expiry);
	const confirmTransactionBank = [
		{ field: "Invoice number", detail: invoiceDetails.invoice_number },
		{ field: "Customer", detail: invoiceDetails.customer },
		{ field: "Amount", detail: invoiceDetails.amount },
		{
			field: "Narration",
			detail: `Payment for invoice # ${invoiceDetails.invoice_number}`,
		},
	];

	/* eslint-disable */
	const sliderlabelOne = [
		"O days",
		<br />,
		repaymentPlan === "0_days" || repaymentPlan === "" ? "0%" : null,
	];

	const sliderlabelTwo = [
		"30 days",
		<br />,
		repaymentPlan === "30_days" ? "5%" : null,
	];

	const sliderlabelThree = [
		"60 days",
		<br />,
		repaymentPlan === "60_days" ? "10%" : null,
	];

	const sliderlabelFour = [
		"90 days",
		<br />,
		repaymentPlan === "90_days" ? "15%" : null,
	];

	const marks = [
		{
			value: 0,
			label: sliderlabelOne,
		},
		{
			value: 30,
			label: sliderlabelTwo,
		},
		{
			value: 60,
			label: sliderlabelThree,
		},
		{
			value: 90,
			label: sliderlabelFour,
		},
	];
	/* eslint-enable */

	useEffect(() => {
		const AllAccepted = allInvoiceData?.filter(
			invoice => invoice.status === "accepted"
		);
		setAllInvoice(AllAccepted);

		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
		getWalletDetails();
	}, [allInvoiceData, navigate]);

	useEffect(() => {
		setAcceptedInvoice(
			allInvoice.map((invoice: any) => {
				return {
					amount: `₦ ${Number(invoice?.total)?.toFixed(2)}`,
					invoiceTo: `Invoice To: ${invoice?.customer?.name}`,
					invoiceFrom: `Invoice From: ${invoice?.payment_link?.creator?.user_full_name}`,
					invoiceNo: invoice?.order_number,
					date: `${invoice?.created_at?.substring(0, 10)}`,
					status: <Status statusState={invoice?.status} />,
					uid: invoice?.uid,
				};
			})
		);
	}, [allInvoice]);

	const getWalletDetails = () => {
		httprequest
			.get("/wallet/")
			.then(response => {
				setInvoiceDetails({
					...invoiceDetails,
					wallet: response.data.results[0]?.uid,
				});
			})
			.catch(error => {
				if (error.response.status === 401) {
					navigate("/");
				}
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	const handleDetails = (row: any) => {
		// const current = allInvoiceData.filter(invoice => invoice.uid === id);
		setInvoiceDetails({
			...invoiceDetails,
			invoice_number: row.order_number,
			customer: row.customer.business_name,
			amount: row.payment_link.amount,
			narration: `Payment for invoice # ${row.order_number}`,
			reference: row.payment_link.reference,
		});

		setPaymentMethodModal(!paymentMethodModal);
	};

	const handleSmallCardsDetails = (id: any) => {
		const current = allInvoiceData.filter(invoice => invoice.uid === id);
		setInvoiceDetails({
			...invoiceDetails,
			invoice_number: current[0].order_number,
			customer: current[0].customer.business_name,
			amount: current[0].payment_link.amount,
			narration: `Payment for invoice # ${current[0].order_number}`,
			reference: current[0].payment_link.reference,
		});
		setPaymentMethodModal(!paymentMethodModal);
	};

	const onChange = (value: string) => {
		setOtp(value);
		setInvoiceDetails({ ...invoiceDetails, pin: value });
	};

	const payWtBridgerWallet = () => {
		setToBridgerWallet(true);
		setInvoiceDetails({ ...invoiceDetails, channel: "wallet" });
		setToBankTransfer(false);
		setToCardPayment(false);
		setThroughBridger(false);
	};
	const payWtBnkTransfer = () => {
		setToBridgerWallet(false);
		setToBankTransfer(true);
		setInvoiceDetails({ ...invoiceDetails, channel: "bank_transfer" });
		setToCardPayment(false);
		setThroughBridger(false);
	};
	const payWtCrdTransfer = () => {
		setToBridgerWallet(false);
		setToBankTransfer(false);
		setToCardPayment(true);
		setInvoiceDetails({ ...invoiceDetails, channel: "card" });
		setThroughBridger(false);
	};

	const paythrhBridger = () => {
		setToBridgerWallet(false);
		setToBankTransfer(false);
		setToCardPayment(false);
		setInvoiceDetails({ ...invoiceDetails, channel: "repayment" });
		setThroughBridger(true);
		handleRepaymentModal();
	};

	const handlePaymentModal = () => {
		setPaymentMethodModal(!paymentMethodModal);
	};

	const getValueText = (value: any) => {
		setRepaymentPlan(`${value}_days`);
		return `${value}`;
	};

	const handleConfirmTransactionModal = () => {
		setConfirmTransactionModal(!confirmTransactionModal);
	};

	const handleBankAcctModal = () => {
		setBankAcctModal(!bankAcctModal);
	};

	const handleRepaymentModal = () => {
		setRepaymentMethodModal(!repaymentMethodModal);
	};

	const redirectSuccess = () => {
		window.location.href = `${BASE_URL}/dashboard`;
	};

	const handleTransactionPinModal = () => {
		setTransactionPinModal(!transactionPinModal);
	};

	const financeInvoice = () => {
		const repaymentBody = {
			reference: invoiceDetails.reference,
			financing_type: "make_payment",
			financing_source_type: "invoice",
			amount_requested: invoiceDetails.amount,
			repayment_plan: repaymentPlan,
			reason_for_financing: invoiceDetails.narration,
		};
		setLoading(true);
		constants.httprequest
			.post("/financing/origination/", repaymentBody)
			.then(() => {
				toast.success("Financing request sent");
				setPaymentMethodModal(!paymentMethodModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setTransactionPinModal(!transactionPinModal);
				setRepaymentMethodModal(!repaymentMethodModal);
				setOtp("");
				setLoading(false);
			})
			.catch(err => {
				setOtp("");
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
				setPaymentMethodModal(!paymentMethodModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setTransactionPinModal(!transactionPinModal);
				setRepaymentMethodModal(!repaymentMethodModal);
				setLoading(false);
			});
	};

	const walletPay = () => {
		const walletPayDetails = {
			wallet: invoiceDetails.wallet,
			reference: invoiceDetails.reference,
			pin: invoiceDetails.pin,
		};
		setLoading(true);
		constants.httprequest
			.post("/payments/payment-link/pay/channel/wallet/", walletPayDetails)
			.then(res => {
				toast.success(`${res.data.detail}`);
				setPaymentMethodModal(!paymentMethodModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setTransactionPinModal(!transactionPinModal);
				setOtp("");
				setLoading(false);
			})
			.catch(err => {
				setOtp("");
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);
				setPaymentMethodModal(!paymentMethodModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setTransactionPinModal(!transactionPinModal);
				setLoading(false);
			});
	};

	const brigderPay = () => {
		if (invoiceDetails.channel === "wallet") {
			walletPay();
		} else {
			financeInvoice();
		}
	};

	const confirmTransactionAction = () => {
		if (
			invoiceDetails.channel === "bank_transfer" ||
			invoiceDetails.channel === "card"
		) {
			payForInvoice();
		} else {
			setTransactionPinModal(true);
		}
	};

	const payForInvoice = () => {
		const detail = {
			reference: invoiceDetails.reference,
			channel: invoiceDetails.channel,
			wallet: invoiceDetails.wallet,
		};
		setLoading(true);

		constants.httprequest
			.post("/payments/payment-link/initialize/", detail)
			.then(res => {
				setOtp("");
				if (invoiceDetails.channel === "bank_transfer") {
					setBankInfo({
						...bankInfo,
						account_name: res?.data?.payload?.bank_transfer?.account_name,
						account_number: res?.data?.payload?.bank_transfer?.account_number,
						amount: res?.data?.payload?.bank_transfer?.amount,
						bank_code: res?.data?.payload?.bank_transfer?.bank_code,
						bank_name: res?.data?.payload?.bank_transfer?.bank_name,
						expiry: res?.data?.payload?.bank_transfer?.expiry,
					});
					handleBankAcctModal();
				} else if (invoiceDetails.channel === "card") {
					window.location.href = res?.data.payload?.card?.payment_url;
				} else {
					toast.success("Payment Processing");
					setPaymentMethodModal(!paymentMethodModal);
					setConfirmTransactionModal(!confirmTransactionModal);
					setTransactionPinModal(!transactionPinModal);
					setLoading(false);
				}
			})
			.catch(err => {
				setOtp("");
				toast.error(`${err?.response?.data?.errors[0]?.detail}`);

				setPaymentMethodModal(!paymentMethodModal);
				setConfirmTransactionModal(!confirmTransactionModal);
				setLoading(false);
			});
	};
	return (
		<>
			<Main
				tabName="Make Payments"
				rightSubHeader={
					<>
						<SubHeaderSearch>
							<SearchOutlinedIcon />
							<InputSearch placeholder="Search for Invoice" />
						</SubHeaderSearch>
						<SubHeaderButton onClick={AcceptInvoice}>
							<DoneOutlinedIcon />
							Accept Invoice
						</SubHeaderButton>
					</>
				}
				mainBody={
					<>
						<LGTableContainer>
							<Table
								data={allInvoice}
								columns={columns}
								pagination
								paginationPerPage={10}
								onRowClicked={(row: any) => handleDetails(row)}
							/>
							{!allInvoice.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no accepted invoice, Go to invoices to accept your
										invoices
									</p>
									<button onClick={AcceptInvoice}>
										<AddIcon /> Accept Invoice
									</button>
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={acceptedInvoive}
								date="date"
								title="Accepted Invoices"
								valueOne="invoiceFrom"
								valueThree="invoiceNo"
								valueFour="amount"
								valueTwo="invoiceTo"
								id="uid"
								onClick={handleSmallCardsDetails}
							/>
						</SMTBContainer>
					</>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handlePaymentModal}
				heading="Payment method"
				subHeading="Please select your preferred method of payment."
				modal={paymentMethodModal}
				body={
					<PaymentModalWrapper>
						<TransferButton
							onClick={() => payWtBridgerWallet()}
							className={toBridgerWallet ? "active" : "inactive"}
						>
							<img src={TransferToBridger} />{" "}
							<Text>
								<h4>Bridger wallet</h4>{" "}
								<p>Make payment using the funds in your Bridger wallet.</p>
							</Text>
						</TransferButton>

						<TransferButton
							onClick={() => payWtBnkTransfer()}
							className={toBankTransfer ? "active" : "inactive"}
						>
							<img src={TransferToBridger} />{" "}
							<Text>
								<h4>Bank Transfer</h4>{" "}
								<p>Transfer money directly from your Bank account.</p>
							</Text>
						</TransferButton>

						<TransferButton
							onClick={() => payWtCrdTransfer()}
							className={toCardPayment ? "active" : "inactive"}
						>
							<img src={TransferToBridger} />{" "}
							<Text>
								<h4>Card Payment</h4>{" "}
								<p>Make payment using your bank card through Flutterwave.</p>
							</Text>
						</TransferButton>

						<TransferButton
							onClick={() => paythrhBridger()}
							className={throughBridger ? "active" : "inactive"}
						>
							<img src={TransferToBridger} />{" "}
							<Text>
								<h4>Pay through Bridger</h4>{" "}
								<p>Bridger makes this payment for you and you repay on a set date.</p>
							</Text>
						</TransferButton>
						<br />
						<Button
							title="Continue"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={handleConfirmTransactionModal}
						/>
					</PaymentModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleConfirmTransactionModal}
				heading="Confirm Transaction"
				subHeading="Kindly confirm the details of your transaction."
				modal={confirmTransactionModal}
				body={
					<ConfirmTransacModalWrapper>
						{confirmTransactionBank.map((detail, index) => {
							return <View key={index} field={detail.field} detail={detail.detail} />;
						})}
						<br />
						<Button
							title="Proceed"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={confirmTransactionAction}
							isLoading={loading}
						/>
					</ConfirmTransacModalWrapper>
				}
			/>
			<ToastContainer />

			<ModalComponent
				size="lg"
				toggleModal={handleTransactionPinModal}
				heading="Transaction PIN"
				subHeading={
					<>
						<ToastContainer /> "Enter your 4-digit Transaction PIN"
					</>
				}
				modal={transactionPinModal}
				body={
					<ConfirmTransacModalWrapper>
						<OTP value={otp} valueLength={4} onChange={onChange} />
						<br />
						<Button
							title="Continue"
							size="lg"
							variant="default"
							textColor="normal"
							onClick={brigderPay}
							isLoading={loading}
						/>
					</ConfirmTransacModalWrapper>
				}
			/>

			<ModalComponent
				size="lg"
				toggleModal={handleRepaymentModal}
				heading="Repayment Plan"
				subHeading="When do you intend to repay Bridger?."
				modal={repaymentMethodModal}
				body={
					<ConfirmTransacModalWrapper>
						<PaymentModalWrapper>
							<SliderContainer style={{ textAlign: "center" }}>
								<Box sx={{ width: 344 }}>
									<Slider
										aria-label="Always visible"
										defaultValue={0}
										getAriaValueText={getValueText}
										step={30}
										marks={marks}
										max={90}
										valueLabelDisplay="on"
									/>
								</Box>
							</SliderContainer>

							<Button
								title="Continue"
								size="lg"
								variant="default"
								textColor="normal"
								onClick={handleConfirmTransactionModal}
							/>
						</PaymentModalWrapper>
					</ConfirmTransacModalWrapper>
				}
			/>
			<ModalComponent
				size="lg"
				toggleModal={redirectSuccess}
				heading="Bank Account Information"
				subHeading=""
				modal={bankAcctModal}
				body={
					<>
						<BankInfoBody>
							<ReceiptBody>
								<div>
									<h4>Account Name</h4>
									<h5>{bankInfo?.account_name}</h5>
								</div>
								<div>
									<h4>Account Number</h4>
									<h5>{bankInfo?.account_number}</h5>
								</div>
								<div>
									<h4>Bank Name</h4>
									<h5>{bankInfo?.bank_name}</h5>
								</div>
								<div>
									<h4>Amount to be paid</h4>
									<h5>{bankInfo?.amount}</h5>
								</div>
							</ReceiptBody>
							<Timer>
								{days + hours + minutes + seconds <= 0 ? (
									"Count Down Expired"
								) : (
									<h2>
										{days} : {hours} : {minutes} : {seconds}
									</h2>
								)}
								<h6>Please Pay within this specified window</h6>
							</Timer>
						</BankInfoBody>
						<SuccessBody>
							<div>
								<Button
									title="Done"
									size="lg"
									variant="default"
									textColor="normal"
									onClick={redirectSuccess}
									isLoading={loading}
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
		</>
	);
};
const PaymentModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 0 auto;
`;

const ConfirmTransacModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 65px;
`;
const TransferButton = styled.div`
	display: flex;
	align-items: center;

	border-radius: 4px;
	width: 480px;
	margin: 20px 0px;
	padding: 3px 0 8px 20px;
	cursor: pointer;
	img {
		margin-top: 4px;
	}
	&.inactive {
		border: 1px solid #d9d9d9;
	}
	&.active {
		border: 1px solid var(--mainBlue);
	}

	@media (max-width: 576px) {
		width: 90%;
		margin: 0 auto;
		margin: 20px 0 0 0;
	}
`;
const Text = styled.div`
	padding-left: 10px;
	h4 {
		margin-bottom: 0px;
		font-size: 14px;
	}
	p {
		margin-top: 5px;
		font-size: 12px;
	}
`;
const BankInfoBody = styled.div`
	padding-left: 30px;
	padding-right: 30px;
`;
export const ReceiptBody = styled.div`
	div {
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #d4d4d4;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h5 {
			font-size: 12px;
			color: var(--mainBlue);
			font-weight: bold;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 65%;
			flex-wrap: wrap;
			text-align: right;
		}
		h4 {
			font-size: 12px;
			color: #484848;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 30%;
			text-align: left;
		}
	}
`;
const Timer = styled.div`
	text-align: center;
	padding-top: 20px;
	h2 {
		font-size: 36px;
		font-weight: bold;
		color: var(--mainBlue);
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;
export const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;
const SliderContainer = styled.div`
	padding-top: 50px;
	padding-bottom: 30px;
`;
export default Index;
