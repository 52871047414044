import styled from "styled-components";
import Bg from "../../assets/404_bg.png";
import errImg from "../../assets/404.png";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
	const navigate = useNavigate();

	return (
		<ErrorWrapper>
			<ErrContainer>
				<img src={errImg} alt="Error 404" />
				<h2>Something went wrong! .</h2>
				<p>
					The page you requested could not be found. We suggest you go back home.
				</p>
				<button onClick={() => navigate("/dashboard")}>Back to home</button>
			</ErrContainer>
		</ErrorWrapper>
	);
};

const ErrorWrapper = styled.div`
	background-image: url(${Bg});
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ErrContainer = styled.div`
	width: 370px;
	text-align: center;
    img{
        width: 100%;
    }
	h2 {
		font-size: 28px;
		color: var(--mainBlue);
		font-weight: 700;
		margin-top: 70px;
	}
	p {
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
	}
	button {
		width: 100%;
		height: 56px;
        border-color: transparent;
		background-color: var(--mainBlue);
		color: white;
		font-size: 18px;
		font-weight: 500;
		margin-top: 50px;
        :hover{
        cursor: pointer;
	}
    @media(max-width: 426px){
        width: 90%;
        margin: auto;
        h2{
            margin-top: 50px;
        }
        button {
            margin-top: 40px;
        }
    }
`;

export default Error404;
