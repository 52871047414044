import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { AppContext, OrderItemProp } from "../../Context";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import * as constants from "../Constants/api";
import Alert from "../Constants/Alert";
import { FormatCurrency } from "../Constants/utils";
import {
	CreateOptionsCard,
	CreateOptionsContainer,
	CheckedCard,
} from "./Index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";
import Modal from "../Constants/ModalComponent";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import DeleteOrderModal from "./DeleteOrderModal";
import { v4 as uuidv4 } from "uuid";
import Button from "../Constants/FormComponents/Button";

const OrderPreview = () => {
	const location = useLocation();
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [customerName, setCustomerName] = useState("");
	const [handleSend, setHandleSend] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingAccept, setIsLoadingAccept] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const [errSendModal, setErrSendModal] = useState(false);
	const [errSend, setErrSend] = useState("");
	const [errAcceptModal, setErrAcceptModal] = useState(false);
	const [errAccept, setErrAccept] = useState("");
	const [errRejectModal, setErrRejectModal] = useState(false);
	const [errReject, setErrReject] = useState("");
	const [rejectMessage, setRejectMessage] = useState("");
	const [acceptModal, setAcceptModal] = useState<boolean>(false);
	const [rejectModal, setRejectModal] = useState<boolean>(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const currentOrder = location.state ? location.state.currentOrder : {};

	const { allCustomerData, userProfile, fetchAllPurchaseOrderData } =
		useContext(AppContext);

	const navigate = useNavigate();

	const handleCreateModal = () => {
		setCreateModal(!createModal);
		setCustomerName("");
	};

	const handleSendCard = () => {
		setHandleSend("sendInvoice");
	};

	const handleShareLink = () => {
		setHandleSend("copylink");
	};

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
		setCreateModal(false);
		navigate("/purchase-order");
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setCustomerName(event.currentTarget.value);
	};

	const closeErrSend = () => {
		setErrSendModal(false);
	};

	const closeErrAccept = () => {
		setErrAcceptModal(false);
	};

	const closeErrReject = () => {
		setErrRejectModal(false);
	};

	const handleAcceptModal = () => {
		setAcceptModal(!acceptModal);
	};

	const handleRejectModal = () => {
		setRejectModal(!rejectModal);
	};

	const handleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
		setRejectMessage(event.currentTarget.value);
	};

	// If purchase order has been deleted and a user goes cak, redirect to purchase order
	useEffect(() => {
		if (!location.state) {
			navigate("/purchase-order");
		}
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
	}, [currentOrder, navigate]);

	const ShareOrder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		constants.httprequest
			.get(`/inventory/purchase_orders/${currentOrder?.uid}/send/`)
			.then(() => {
				fetchAllPurchaseOrderData();
				handleSuccessModal();
				navigate("/purchase-order");
			})
			.catch(err => {
				setIsLoading(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrSendModal(true);
				setErrSend(err?.response?.data?.errors[0]?.detail);
			});
	};

	const AcceptOrder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingAccept(true);
		constants.httprequest
			.post(`/inventory/purchase_orders/${currentOrder?.uid}/options/`, {
				option: "accept",
			})
			.then(() => {
				fetchAllPurchaseOrderData();
				navigate("/purchase-order");
			})
			.catch(err => {
				setIsLoadingAccept(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrAcceptModal(true);
				setErrAccept(err?.response?.data?.errors[0]?.detail);
			});
	};

	const RejectOrder = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoadingReject(true);
		constants.httprequest
			.post(`/inventory/purchase_orders/${currentOrder?.uid}/options/`, {
				option: "reject",
				message: rejectMessage,
			})
			.then(() => {
				navigate("/purchase-order");
			})
			.catch(err => {
				setIsLoadingReject(false);
				if (err?.response?.status === 401) {
					navigate("/");
				}
				setErrRejectModal(true);
				setErrReject(err?.response?.data?.errors[0]?.detail);
			});
	};

	const handleDownloadInvoice = () => {
		window.location.assign(currentOrder?.pdf);
	};

	return (
		<>
			<Main
				tabName="Purchase Order Preview"
				rightSubHeader={
					<>
						<BackDiv
							onClick={() => {
								navigate("/purchase-order");
							}}
						>
							<KeyboardBackspaceIcon /> Go Back
						</BackDiv>
					</>
				}
				mainBody={
					<DetailsContainer>
						<AcceptHeader>
							<InvoiceNumber>
								<div
									style={{
										backgroundColor:
											currentOrder?.status === "rejected" ? "#cd0a0a" : "#302361",
									}}
								></div>
								<h4>{`Purchase Order # ${currentOrder?.order_number}`}</h4>
							</InvoiceNumber>
							{currentOrder?.status === "new" ? (
								<div>
									<Button
										title="Accept"
										onClick={handleAcceptModal}
										size="sm"
										textColor="normal"
										variant="accept"
										icon={<DoneOutlinedIcon />}
									/>
									<Button
										title="Reject"
										onClick={handleRejectModal}
										size="sm"
										textColor="normal"
										variant="reject"
										icon={<CloseIcon />}
									/>
								</div>
							) : null}
						</AcceptHeader>
						<AddressContainer>
							<InvoiceAddress>
								<div>
									<div>
										<h6>Purchase Order from</h6>
										<h5>
											{userProfile?.first_name} {userProfile?.last_name}
										</h5>
										<p>{userProfile?.address}</p>
									</div>
									<div>
										<h6>Sent to</h6>
										<h5>{currentOrder?.customer?.business_name}</h5>
										<p>{currentOrder?.customer?.address}</p>
									</div>
								</div>
								<div>
									<div>
										<h6>Purchase Order Number</h6>
										<h5>{currentOrder?.order_number}</h5>
									</div>

									<div>
										<h6>Creation Date</h6>
										<h5>{currentOrder?.created_at?.substring(0, 10)}</h5>
									</div>
									<div>
										<h6>Due Data</h6>
										<h5>{currentOrder?.due_date?.substring(0, 10)}</h5>
									</div>
								</div>
							</InvoiceAddress>
							<PayableAmount>
								<p>Total Payable</p>
								<h1> ₦ {FormatCurrency(currentOrder?.total)}</h1>
							</PayableAmount>
						</AddressContainer>
						<InvoiceTable>
							<TableHead>
								<SmallDesc>Item</SmallDesc>
								<Desc>Item Description</Desc>
								<SmallQty>Qty</SmallQty>
								<Qty>Quantity</Qty>
								<Rate>Rate</Rate>
								<Amount>Amount</Amount>
							</TableHead>
							{currentOrder?.items?.map((order: OrderItemProp) => {
								return (
									<TableBody key={uuidv4()}>
										<Desc>{order?.description}</Desc>
										<SmallDesc>{order?.description}</SmallDesc>
										<Qty>{`${order?.quantity}`}</Qty>
										<SmallQty>{`${order?.quantity}`}</SmallQty>
										<Rate>{`₦ ${FormatCurrency(order?.unit_price)}`}</Rate>
										<Amount>₦ {FormatCurrency(order?.total)}</Amount>
									</TableBody>
								);
							})}
						</InvoiceTable>
						<TableCalc>
							<LeftCalc>
								<div>
									<p>Payment Terms</p>
									<h6>{currentOrder?.payment_terms}</h6>
								</div>
							</LeftCalc>
							<RightCalc>
								<div>
									<p>Sub Total</p>
									<h6>₦{FormatCurrency(currentOrder?.sub_total)}</h6>
								</div>
								<div>
									<p>Miscellenous</p>
									<h6>₦{FormatCurrency(currentOrder?.misc)}</h6>
								</div>
								<div>
									<p>Total</p>
									<h6>₦{FormatCurrency(currentOrder?.total)}</h6>
								</div>
							</RightCalc>
						</TableCalc>
						<ActionButtons>
							<div>
								<Button
									title="Share"
									onClick={handleCreateModal}
									size="md"
									textColor="normal"
									variant="default"
								/>
								<Button
									title="Delete"
									onClick={handleDeleteModal}
									size="md"
									textColor="normal"
									variant="reject"
								/>
							</div>
							<Button
								title="Download Pdf"
								onClick={handleDownloadInvoice}
								size="md"
								textColor="brand"
								variant="secondary"
								icon={<CloudDownloadIcon />}
							/>
						</ActionButtons>
					</DetailsContainer>
				}
			/>
			<Modal
				toggleModal={handleCreateModal}
				heading="Share Purchase Order"
				subHeading="Please select your preferred method"
				modal={createModal}
				size="lg"
				body={
					<>
						<CreateOptionsContainer>
							<CreateOptionsCard onClick={handleSendCard}>
								<div>
									<ScreenShareOutlinedIcon />
								</div>
								<div>
									<h4>Send to a saved Customer</h4>
									<p>Send purchase order to an already saved customer.</p>
								</div>
								<CheckedCard>
									{handleSend === "sendInvoice" ? <CheckCircleIcon /> : null}
								</CheckedCard>
							</CreateOptionsCard>
							{handleSend === "sendInvoice" ? (
								<FormGroup>
									<label>Customer name</label>
									<select
										onChange={handleSelectChange}
										name="customer"
										value={customerName}
									>
										{allCustomerData?.map((customer, i) => {
											return (
												<option value={`${customer.uid}`} key={i}>
													{customer?.name}
												</option>
											);
										})}
									</select>
								</FormGroup>
							) : null}
							<CreateOptionsCard onClick={handleShareLink}>
								<div>
									<LinkIcon />
								</div>
								<div>
									<h4>Copy Link</h4>
									<p>Copy and send purchase order link to customer.</p>
								</div>
								<CheckedCard>
									{handleSend === "copylink" ? <CheckCircleIcon /> : null}
								</CheckedCard>
							</CreateOptionsCard>
							{handleSend === "sendInvoice" ? (
								<Button
									title="Continue"
									onClick={ShareOrder}
									size="full"
									textColor="normal"
									variant="default"
									isLoading={isLoading}
									disabled={isLoading}
								/>
							) : null}
						</CreateOptionsContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleSuccessModal}
				heading=""
				subHeading=""
				size="lg"
				modal={successModal}
				body={
					<>
						<SuccessTitle>Purchase Order Sent !</SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully created and sent a purchase order. Your customer
								will receive an email or notification containing the purchase order
								details.
							</p>
							<h6>You also have the option of saving this purchase order as a PDF.</h6>
							<div>
								<Button
									title="Download PDF"
									size="full"
									textColor="normal"
									variant="default"
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Alert
				alertSeverity={"error"}
				open={errSendModal}
				alertTitle={errSend || "Unable to Send Purchase Order"}
				closeModal={closeErrSend}
			/>
			<Alert
				alertSeverity={"error"}
				open={errAcceptModal}
				alertTitle={errAccept || "Unable to Accept Purchase Order"}
				closeModal={closeErrAccept}
			/>
			<Alert
				alertSeverity={"error"}
				open={errRejectModal}
				alertTitle={errReject || "Unable to Reject Purchase Order"}
				closeModal={closeErrReject}
			/>
			{deleteModal ? (
				<DeleteOrderModal
					currentOrder={currentOrder}
					deleteModal={deleteModal}
					handleDeleteModal={handleDeleteModal}
				/>
			) : null}
			<Modal
				toggleModal={handleRejectModal}
				heading=""
				subHeading=""
				size="lg"
				modal={rejectModal}
				body={
					<>
						<AcceptContainer>
							<h5>Reject Invoice</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentOrder?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentOrder?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentOrder?.total}</h4>
								</div>
							</AcceptDetails>
							<div>
								<label>Reason for rejection</label>
								<textarea onChange={handleAreaChange}></textarea>
							</div>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleRejectModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Reject"
									onClick={RejectOrder}
									size="sm"
									textColor="normal"
									variant="reject"
									isLoading={isLoadingReject}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
			<Modal
				toggleModal={handleAcceptModal}
				heading=""
				subHeading=""
				size="lg"
				modal={acceptModal}
				body={
					<>
						<AcceptContainer>
							<h5>Accept Purchase Order</h5>
							<AcceptDetails>
								<div>
									<label>Invoice number</label>
									<h4>{currentOrder?.order_number}</h4>
								</div>
								<div>
									<label>Customer</label>
									<h4>{currentOrder?.customer?.name}</h4>
								</div>
								<div>
									<label>Amount</label>
									<h4>{currentOrder?.total}</h4>
								</div>
							</AcceptDetails>
							<AcceptButtons>
								<Button
									title="Cancel"
									onClick={handleAcceptModal}
									size="sm"
									textColor="brand"
									variant="secondary"
								/>
								<Button
									title="Accept"
									onClick={AcceptOrder}
									size="sm"
									textColor="normal"
									variant="accept"
									isLoading={isLoadingAccept}
								/>
							</AcceptButtons>
						</AcceptContainer>
					</>
				}
			/>
		</>
	);
};

export default OrderPreview;

const BackDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: #484848;
	font-size: 12px;
	font-weight: 500;
	:hover {
		cursor: pointer;
	}
`;

export const DetailsContainer = styled.div`
	padding-bottom: 60px;
`;

export const InvoiceNumber = styled.div`
	gap: 15px;
	display: flex;
	align-items: center;
	margin-top: 25px;
	margin-bottom: 25px;
	div {
		width: 48px;
		height: 48px;
		background-color: var(--mainBlue);
	}
	h4 {
		color: var(--mainBlue);
		font-size: 25px;
		font-weight: 500;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	@media (max-width: 768px) {
		gap: 8px;
		div {
			width: 32px;
			height: 32px;
		}
		h4 {
			font-size: 16px;
		}
	}
`;

const AcceptHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 25px;
	div {
		display: flex;
		gap: 20px;
	}
	button {
		color: white;
		font-weight: 400;
		padding: 15px 30px;
		border-color: transparent;
		display: flex;
		align-items: center;
		gap: 5px;
		height: 48px;
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const InvoiceAddress = styled.div`
	width: 600px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 300px;
		text-align: left;
		margin-top: 20px;
		margin-bottom: 20px;
		:first-child {
			margin-bottom: 30px;
		}
	}
	h6 {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	p {
		color: #6d6d6d;
		font-size: 12px;
		margin-top: 2px;
		margin-bottom: 2px;
	}
	h5 {
		font-size: 14px;
		margin-top: 0px;
		margin-bottom: 4px;
	}
	@media (max-width: 1200px) {
		width: 450px;

		div {
			width: 200px;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		h5 {
			font-size: 12px;
		}
	}
	@media (max-width: 526px) {
		div {
			width: 180px;
		}
	}
	@media (max-width: 426px) {
		div {
			width: 150px;
		}
	}
`;

export const PayableAmount = styled.div`
	margin-left: auto;
	text-align: right;
	p {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 29px;
		color: var(--mainBlue);
	}
	@media (max-width: 1200px) {
		h1 {
			font-size: 24px;
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
`;

const AcceptDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AcceptButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 40px;
	button {
		padding: 15px 30px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 500;
		:first-child {
			color: var(--mainBlue);
			border: 1px solid var(--mainBlue);
		}
		:last-child {
			color: white;
			border: transparent;
		}
	}
`;

export const DetailsHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const SmallPayableAmount = styled.div`
	text-align: right;
	@media (min-width: 768px) {
		display: none;
	}
	p {
		font-size: 12px;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 2px;
	}
	h1 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 24px;
		color: var(--mainBlue);
	}
	@media (max-width: 768px) {
		h1 {
			font-size: 14px;
		}
	}
`;
export const InvoiceTable = styled.div`
	width: 100%;
	margin-top: 40px;
`;
export const TableHead = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e9e9e9;
	font-size: 14px;
	font-weight: 500;
`;
export const TableBody = styled.div`
	width: 100%;
	height: 48px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ececec;
`;
export const Desc = styled.div`
	width: 35%;
	font-size: 14px;
	text-align: left;
	padding-left: 20px;
	@media (max-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const SmallDesc = styled.div`
	width: 30%;
	font-size: 12px;
	text-align: left;
	padding-left: 10px;
	@media (min-width: 576px) {
		display: none;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;
export const Qty = styled.div`
	width: 10%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		display: none;
	}
`;

export const SmallQty = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
	width: 6%;
	font-size: 12px;
	text-align: left;
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Rate = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: left;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const Amount = styled.div`
	width: 20%;
	font-size: 14px;
	text-align: right;
	padding-right: 20px;
	@media (max-width: 576px) {
		font-size: 12px;
		width: 24%;
		padding-right: 10px;
	}
	@media (max-width: 426px) {
		font-size: 11px;
	}
`;

export const TableCalc = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	@media (max-width: 576px) {
		flex-direction: column-reverse;
	}
`;

export const LeftCalc = styled.div`
	width: 221px;
	text-align: left;
	p {
		font-size: 12px;
		font-weight: 500;
		color: #919191;
		margin-top: 0px;
		margin-bottom: 10px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	div {
		margin-bottom: 30px;
	}
	@media (max-width: 576px) {
		width: 188px;
		p {
			margin-bottom: 5px;
		}
		h6 {
			font-size: 12px;
		}
		a {
			font-size: 12px;
			margin-top: 0px;
		}
	}
`;

export const RightCalc = styled.div`
	width: 45%;
	div {
		padding-right: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 14px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
		font-weight: bold;
	}
	@media (max-width: 576px) {
		width: 60%;
		margin-left: auto;
		p {
			font-size: 12px;
		}
		h6 {
			font-size: 14px;
		}
		div {
			padding-right: 10px;
		}
	}
	@media (max-width: 426px) {
		width: 70%;
	}
`;

const AcceptContainer = styled.div`
	width: 90%;
	margin: auto;
	h5 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 23px;
		text-align: left;
		color: black;
	}

	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
		font-weight: 400;
	}
	h4 {
		font-size: 14px;
		color: black;
		font-size: 500;
		margin-top: 10px;
		margin-bottom: 0px;
	}
	textarea {
		height: 120px;
		width: 100%;
		border: 1px solid #d9d9d9;
		margin-top: 5px;
	}
`;

export const ActionButtons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	flex-wrap: wrap;
	gap: 10px;
	div {
		display: flex;
		gap: 10px;
	}
	@media (max-width: 576px) {
		flex-direction: column;
		gap: 10px;
		button {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
		div {
			flex-direction: column;
			justify-content: center;
		}
	}
`;

export const FormGroup = styled.div`
	width: 100%;
	label {
		color: #484848;
		font-size: 14px;
		width: 100%;
	}
	input {
		width: calc(100% - 20px);
		height: 48px;
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 5px;
		border: 1px solid #d9d9d9;
	}
	select {
		width: 100%;
		height: 48px;
	}
`;

export const SuccessTitle = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 23px;
	text-align: left;
	color: black;
	width: 90%;
	margin: auto;
`;

export const SuccessBody = styled.div`
	width: 90%;
	margin: auto;
	p {
		font-size: 13px;
		line-height: 26px;
		font-weight: 500;
		text-align: left;
		color: black;
	}
	h6 {
		font-size: 13px;
		font-weight: 400;
		text-align: left;
		margin-top: 12px;
		margin-bottom: 0px;
		color: black;
	}
	div {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		gap: 20px;
	}
`;
