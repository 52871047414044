import React, { useState, useEffect } from "react";
import { PaylinkProps } from "../../Context";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import * as constants from "../Constants/api";
import Loader from "../../assets/loader.gif";
import Alert from "../Constants/Alert";
import Modal from "../Constants/Modal";
import { FormatCurrency } from "../Constants/utils";
import Logo from "../../assets/logo.svg";
import { SuccessTitle, SuccessBody } from "../Invoice/InvoicePreview";
import { BASE_URL } from "../Constants/api";
import { useCountdown } from "../../hooks/UseCountdown";
import Button from "../Constants/FormComponents/Button";

export default function Receipt() {
	const params = useParams();
	const { id } = params;
	const [invoiceData, setInvoiceData] = useState<Partial<PaylinkProps>>({});
	const [successModal, setSuccessModal] = useState<boolean>(false);
	const [bankAcctModal, setBankAcctModal] = useState<boolean>(false);
	const [errCreatePayModal, setErrCreatePayModal] = useState(false);
	const [errCreatePaylink, setErrCreatePaylink] = useState("");
	const [channel, setChannel] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [bankInfo, setBankInfo] = useState({
		account_name: "",
		account_number: "",
		amount: "",
		bank_code: "",
		bank_name: "",
		expiry: "",
	});
	const [days, hours, minutes, seconds] = useCountdown(bankInfo?.expiry);

	const handleSuccessModal = () => {
		setSuccessModal(!successModal);
	};

	const handleBankAcctModal = () => {
		setBankAcctModal(!bankAcctModal);
	};

	const redirectSuccess = () => {
		window.location.href = `${BASE_URL}/dashboard`;
	};

	const closeErrCreateModal = () => {
		setErrCreatePayModal(false);
	};

	const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
		setChannel(event.currentTarget.value);
	};

	useEffect(() => {
		constants.httprequest
			.get(`/payments/payment-link/retrieve/${id}/`)
			.then(res => {
				setInvoiceData(res?.data);
			})
			.catch(() => {});
	}, [id]);

	const handlePay = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setIsLoading(true);
		const body = { reference: id, channel: channel };
		constants.httprequest
			.post("/payments/payment-link/initialize/", body)
			.then(res => {
				setIsLoading(false);
				if (channel === "bank_transfer") {
					setBankInfo({
						...bankInfo,
						account_name: res?.data?.payload?.bank_transfer?.account_name,
						account_number: res?.data?.payload?.bank_transfer?.account_number,
						amount: res?.data?.payload?.bank_transfer?.amount,
						bank_code: res?.data?.payload?.bank_transfer?.bank_code,
						bank_name: res?.data?.payload?.bank_transfer?.bank_name,
						expiry: res?.data?.payload?.bank_transfer?.expiry,
					});
					handleBankAcctModal();
				} else {
					window.location.href = res?.data.payload?.card?.payment_url;
				}
			})
			.catch(err => {
				setIsLoading(false);
				setErrCreatePayModal(true);
				setErrCreatePaylink(err?.response?.data?.errors[0]?.detail);
			});
	};

	const isDisabled = () => {
		if (channel) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<Container>
			<ReceiptContainer>
				<Center>
					<img src={Logo} alt="Bridger Africa" />
				</Center>
				<ReceiptWrapper>
					<ReceiptHeader>
						<div>
							<h6>Billed from</h6>
							<h6>Billed to</h6>
						</div>
						<div>
							<h5>{invoiceData?.creator?.company_name}</h5>
							<h5>{invoiceData?.recipient_company_name}</h5>
						</div>
						<div>
							<h4>{invoiceData?.creator?.user_full_name}</h4>
							<h4>{invoiceData?.recipient_name}</h4>
						</div>
						<div>
							<h6>{invoiceData?.creator?.company_email}</h6>
							<h6>{invoiceData?.recipient_email}</h6>
						</div>
					</ReceiptHeader>
					<Center>
						<h4>PAYMENT INFORMATION</h4>
					</Center>

					<ReceiptBody>
						<div>
							<h4>Payment amount</h4>
							<h5>NGN {FormatCurrency(invoiceData?.amount)}</h5>
						</div>
						<div>
							<h4>Date Created</h4>
							<h5>{invoiceData?.created_date}</h5>
						</div>
						<div>
							<h4>Due Date</h4>
							<h5>{invoiceData?.due_date}</h5>
						</div>
						<div>
							<h4>Customer's Name</h4>
							<h5>{invoiceData?.creator?.company_name}</h5>
						</div>
						<div>
							<h4>Phone Number</h4>
							<h5>{invoiceData?.recipient_phone}</h5>
						</div>
						<div>
							<h4>Email Address</h4>
							<h5>{invoiceData?.recipient_email}</h5>
						</div>
					</ReceiptBody>
					<div>
						<FormLabel>
							Select Payment Option<span>*</span>
						</FormLabel>
						<FormSelect name="channel" onChange={handleSelectChange}>
							<option value="">--- Select Payment option--- </option>
							<option value="card">Card</option>
							<option value="bank_transfer">Bank Transfer</option>
						</FormSelect>
					</div>
					<Form>
						<PayButton disabled={isDisabled()} onClick={handlePay}>
							{isLoading ? <img src={Loader} alt="Loader" /> : "Pay Now"}
						</PayButton>
					</Form>
				</ReceiptWrapper>
			</ReceiptContainer>
			<Modal
				toggleModal={handleSuccessModal}
				heading=""
				subHeading=""
				modal={successModal}
				body={
					<>
						<SuccessTitle>Payment made </SuccessTitle>
						<SuccessBody>
							<p>
								You have successfully Paid to {invoiceData?.recipient_name}. Your
								customer will receive an email or notification containing the payment
								request details.
							</p>
							<div>
								<Button
									title="Continue"
									variant="default"
									textColor="normal"
									size="full"
									onClick={handleSuccessModal}
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Modal
				toggleModal={redirectSuccess}
				heading="Bank Account Information"
				subHeading=""
				modal={bankAcctModal}
				body={
					<>
						<BankInfoBody>
							<ReceiptBody>
								<div>
									<h4>Account Name</h4>
									<h5>{bankInfo?.account_name}</h5>
								</div>
								<div>
									<h4>Account Number</h4>
									<h5>{bankInfo?.account_number}</h5>
								</div>
								<div>
									<h4>Bank Name</h4>
									<h5>{bankInfo?.bank_name}</h5>
								</div>
								<div>
									<h4>Amount to be paid</h4>
									<h5>{bankInfo?.amount}</h5>
								</div>
							</ReceiptBody>
							<Timer>
								{days + hours + minutes + seconds <= 0 ? (
									"Count Down Expired"
								) : (
									<h2>
										{days} : {hours} : {minutes} : {seconds}
									</h2>
								)}
								<h6>Please Pay within this specified window</h6>
							</Timer>
						</BankInfoBody>
						<SuccessBody>
							<div>
								<Button
									title="Done"
									variant="default"
									textColor="normal"
									size="full"
									onClick={redirectSuccess}
								/>
							</div>
						</SuccessBody>
					</>
				}
			/>
			<Alert
				open={errCreatePayModal}
				alertSeverity="error"
				alertTitle={errCreatePaylink || "Error"}
				closeModal={closeErrCreateModal}
			/>
		</Container>
	);
}

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	background-color: #e5e5e5;
`;
const ReceiptContainer = styled.div`
  width: 600px;
  margin: auto;
  position:relative;
  color: blue;
  img {
    width: 100px;
    padding-bottom: 15px;
  }
  margin:auto;
}
@media(max-width: 576px){
  width: 400px;
}
@media(max-width: 426px){
  width: 340px;
}
`;

export const ReceiptWrapper = styled.div`
	width: 100%;
	// height:430px;
	background-color: white;
	border-radius: 10px;
	padding: 25px;
	box-sizing: border-box;
	p {
		font-size: 12px;
		width: 200px;
		color: #ccc;
		text-align: left;
		margin-bottom: 0px;
	}
`;

export const ReceiptHeader = styled.div`
	div {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 8px;
		margin-bottom: 8px;
		h6 {
			font-size: 12px;
			color: #919191;
			margin-top: 0px;
			margin-bottom: 0px;
		}
		h5 {
			font-size: 14px;
			color: black;
			font-weight: 500;
			margin-top: 0px;
			margin-bottom: 0px;
		}
		h4 {
			font-size: 12px;
			color: #65676c;
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}
	@media (max-width: 426px) {
		div {
			:last-child {
				display: none;
			}
		}
	}
`;

export const Center = styled.div`
	text-align: center;
	img {
		width: 105px;
		height: 28px;
	}
	h4 {
		font-size: 14px;
		margin-top: 30px;
		margin-bottom: 20px;
		color: black;
	}
`;

const Form = styled.form`
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const FormLabel = styled.label`
	display: block;
	font-size: 12px;
	text-align: left;
	line-height: 21px;
	color: #65676c;
	margin-bottom: 5px;
	margin-top: 20px;
	span {
		color: tomato;
		margin-left: 5px;
	}
`;

const FormSelect = styled.select`
	width: 100%;
	background: #f4f4f4;
	border-radius: 4px;
	padding: 10px;
	height: 45px;
	border: 1px solid #65676c;
	box-sizing: border-box;
	font-size: 13px;
	::placeholder {
		color: #c4c4c4;
	}
`;
const BankInfoBody = styled.div`
	padding-left: 30px;
	padding-right: 30px;
`;

export const ReceiptBody = styled.div`
	div {
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #d4d4d4;
		display: flex;
		justify-content: space-between;
		align-items: center;
		h5 {
			font-size: 12px;
			color: var(--mainBlue);
			font-weight: bold;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 65%;
			flex-wrap: wrap;
			text-align: right;
		}
		h4 {
			font-size: 12px;
			color: #484848;
			margin-top: 0px;
			margin-bottom: 0px;
			width: 30%;
			text-align: left;
		}
	}
`;

export const PayButton = styled.button`
	background-color: var(--mainBlue);
	width: 100%;
	height: 56px;
	color: white;
	outline: 0;
	border-color: transparent;
	margin-top: 60px;
	font-size: 18px;
	img {
		width: 20px;
	}
	:disabled {
		background-color: #ccc;
		color: black;
	}
`;

const Timer = styled.div`
	text-align: center;
	padding-top: 20px;
	h2 {
		font-size: 36px;
		font-weight: bold;
		color: var(--mainBlue);
		margin-bottom: 10px;
	}
	h6 {
		font-size: 16px;
		font-weight: 400;
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;
