import React, { useState } from "react";
import styled from "styled-components";
import Main from "../../Constants/Main";
import Notifications from "./Notifications";
import Invoices from "./Invoices";
import TransactionLimits from "./TransactionLimits";

const Preferences: React.FC = () => {
	const [toggleState, setToggleState] = useState("invoices");

	const handleTransLimit = () => setToggleState("invoices");
	// const handleNotifications = () => setToggleState("notifications");
	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<ToggleContainer>
							<div
								onClick={handleTransLimit}
								style={{
									borderBottom:
										toggleState === "transactionLimits"
											? "1px solid #302361"
											: "transparent",
									color: toggleState === "transactionLimits" ? "#302361" : "#6D6D6D",
								}}
							>
								Invoices
							</div>
							{/* <div
								style={{
									borderBottom:
										toggleState === "notifications" ? "1px solid #302361" : "transparent",
									color: toggleState === "notifications" ? "#302361" : "#6D6D6D",
								}}
								onClick={handleNotifications}
							>
								Notifications
							</div> */}
						</ToggleContainer>
						{toggleState === "invoices" ? (
							<Invoices />
						) : toggleState === "notifications" ? (
							<Notifications />
						) : toggleState === "transactionLimits" ? (
							<TransactionLimits />
						) : null}
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 42vw;
`;

export const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 52px;
`;

export const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;

export const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

export const PageBody = styled.div`
	padding-top: 20px;
`;

export const PreferencesSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #ececec;
	padding-bottom: 15px;
`;

export const PreferenceSubSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 1px solid #ececec;
	padding-bottom: 15px;
`;

export const PageWord = styled.div`
	text-align: left;
	max-width: 60%;
	h2 {
		margin-top: 24px;
		font-size: 14px;
		font-weight: 500;
	}
	h3 {
		margin-top: 24px;
		font-size: 14px;
		font-weight: 400;
		color: var(--textAsh);
	}
	h4 {
		margin-bottom: 8px;
		font-size: 14px;
		font-weight: 500;
	}
	p {
		font-size: 12px;
		color: #6d6d6d;
		margin-top: 5px;
	}
`;

export const PageToggle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 15px;
	width: 35%;
	span {
		padding-bottom: 8px;
		font-size: 14px;
		& .icon {
			color: #302361;
		}
	}
	button {
		background: transparent;
		border: none;
	}
`;

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 47px;
	margin-top: 50px;
`;

export const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;

export const ButtonFilled = styled.button`
	border: none;
	border-radius: 3px;
	padding: 12px 25px;
	background: #302361;
	color: #fff;
`;

export const ToggleContainer = styled.div`
	display: flex;
	gap: 30px;
	padding-bottom: 3px;
	font-size: 14px;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 1200px) {
		font-size: 12px;
	}
	@media (max-width: 576px) {
		div {
			padding-bottom: 10px;
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
`;

export const SwitchSection = styled.div`
	text-align: left;
	label {
		font-weight: 500 !important;
		font-size: 14px !important;
		color: var(--mainBlue);
		margin-left: 0px;
		margin-top: 20px;
	}
`;

export default Preferences;
