import React, { useState } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import * as constants from "../Constants/api";
import Edit from "../Constants/FormComponents/Edit";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useNavigate } from "react-router-dom";
import Alert from "../Constants/Alert";

const CompanyDetailEdit: React.FC = () => {
	const navigate = useNavigate();

	const [companyDocuments, setCompanyDocuments] = useState({
		cac_document: "",
		memart_document: "",
		rc_number: "",
		cac_number_type: "RC",
	});
	const [fileUploadName, setFileUploadName] = useState({
		cac_document: "",
		memart_document: "",
	});
	const [modal, setModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files ?? []);
		const file = files[0];
		setFileUploadName({ ...fileUploadName, [e.target.name]: file.name });

		setCompanyDocuments({
			...companyDocuments,
			[e.target.name]: file,
		});
	};
	const tooglemodal = () => {
		setModal(!modal);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const form_data = new FormData();
		Object.entries(companyDocuments).forEach(data => {
			form_data.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.patch("/accounts/company/", form_data)
			.then(res => {
				if (res.status === 201) {
					navigate("/settings/documents");
				}
			})
			.catch(error => {
				setModal(true);
				setErrorMsg(error?.response?.data?.errors[0]?.detail);
			});
	};
	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<PageHeader>
							<span>
								<PageTitle>Company Details</PageTitle>
								<PageInstruction>
									View and manage details of your company.
								</PageInstruction>
							</span>
						</PageHeader>

						<PageBody>
							<form onSubmit={handleSubmit}>
								<Edit
									title="RC Number*"
									inputType="text"
									value={companyDocuments.rc_number}
									name="rc_number"
									placeHolder="12345678"
									onChange={e => handleChange(e)}
								/>
								<UploadLogoContainer>
									<div>
										<CloudUploadIcon />
										<p>Upload a copy of your ID card here</p>
										<small>(Only JPEG, PNG, PDF formats are accepted)</small>
										<p>{fileUploadName.cac_document}</p>
									</div>
									<input
										type="file"
										name="cac_document"
										onChange={e => handleChangeFiles(e)}
									/>
								</UploadLogoContainer>

								<UploadLogoContainer>
									<div>
										<CloudUploadIcon />
										<p>Upload a copy of your ID card here</p>
										<small>(Only JPEG, PNG, PDF formats are accepted)</small>
										<p>{fileUploadName.memart_document}</p>
									</div>
									<input
										type="file"
										name="memart_document"
										onChange={e => handleChangeFiles(e)}
									/>
								</UploadLogoContainer>

								<ButtonGroup>
									<ButtonOutlined>Cancel</ButtonOutlined>{" "}
									<ButtonFilled>Save Changes</ButtonFilled>
								</ButtonGroup>
							</form>
						</PageBody>
						<Alert
							alertSeverity={"error"}
							open={modal}
							alertTitle={errorMsg}
							closeModal={tooglemodal}
						/>
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 45vw;
`;

const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;

const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

const PageBody = styled.div`
	padding-top: 20px;
	padding-bottom: 50px;
`;

const UploadLogoContainer = styled.div`
	max-width: 600px;
	height: 140px;
	border: 1px solid #d9d9d9;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		color: #454545;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 14px;
		color: #454545;
	}
	small {
		font-size: 12px;
		color: #919191;
	}
	input {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		cursor: pointer;
	}
	div {
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-right: 47px;
	margin-top: 50px;
`;

const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;

const ButtonFilled = styled.button`
	border: none;
	border-radius: 3px;
	padding: 12px 25px;
	background: #302361;
	color: #fff;
`;

export default CompanyDetailEdit;
