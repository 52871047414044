import { useState } from "react";
import ReactPaginate from "react-paginate";
import TableCard from "./TableCard";
import styled from "styled-components";

function PaginatedCards({
	itemsPerPage,
	items,
	title,
	onClick,
	date,
	status,
	valueOne,
	valueTwo,
	valueThree,
	valueFour,
	id,
}: any) {
	const [itemOffset, setItemOffset] = useState(0);

	const endOffset = itemOffset + itemsPerPage;
	const currentItems = items?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(items?.length / itemsPerPage);

	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * itemsPerPage) % items?.length;

		setItemOffset(newOffset);
	};

	return (
		<>
			<TableCard
				currentItems={currentItems}
				title={title}
				onClick={onClick}
				date={date}
				cardStatus={status}
				valueOne={valueOne}
				valueTwo={valueTwo}
				valueThree={valueThree}
				valueFour={valueFour}
				id={id}
			/>
			<PaginateWrapper>
				<ReactPaginate
					breakLabel="..."
					nextLabel="next >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel="< previous"
					renderOnZeroPageCount={null}
					containerClassName="paging"
					activeClassName="paging_link-active"
				/>
			</PaginateWrapper>
		</>
	);
}

const PaginateWrapper = styled.div`
	padding-top: 10px;
	padding-bottom: 15px;

	li {
		background-color: white;
		border-color: 1px solid var(--mainBlue);
		padding: 5px 12px;
		border-radius: 4px;
		justify-content: center;
		font-size: 14px;
	}
`;
export default PaginatedCards;
