import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/bridger-beta.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import {
	faUsers,
	faGear,
	faWallet,
	faFileInvoice,
	faMoneyBill,
	faChartLine,
	faAngleRight,
	faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
	toggle: boolean;
}

interface INav {
	display: string;
}

const Header: React.FC<Props> = props => {
	const navigate = useNavigate();
	const [settingsState, setSettingsState] = useState(false);
	// const [makePaymentState, setMakePaymentState] = useState(false);
	const [receivePaymentState, setReceivePaymentState] = useState(false);

	const LogoutUser = () => {
		localStorage.clear();
		navigate("/");
	};

	const handleSettingsToggle = () => {
		setSettingsState(!settingsState);
	};

	// const handleMakePayment = () => {
	// 	setMakePaymentState(!makePaymentState);
	// };

	const handleReceivePayment = () => {
		setReceivePaymentState(!receivePaymentState);
	};

	// Side Bar needs Optimization. Too VerboSE, Leverage React Router Routes
	const settingsItem = [
		{
			name: "User Profile",
			route: "/settings",
			active: "false",
			display: "flex",
		},
		{
			name: "Documents",
			route: "/settings/documents",
			active: "false",
			display: "flex",
		},
		{
			name: "Team Members",
			route: "/settings/team-members",
			active: "false",
			display: "flex",
		},
		{
			name: "Security",
			route: "/settings/security/view",
			active: "false",
			display: "flex",
		},
		{
			name: "Preferences",
			route: "/settings/preferences",
			active: "false",
			display: "flex",
		},
	];
	const ReceivePaymentItem = [
		{
			name: "Payment link",
			route: "/receive/payment-link",
			active: "false",
			display: "flex",
		},
		{
			name: "Financing",
			route: "/receive/early-pay",
			active: "false",
			display: "flex",
		},
	];

	// const MakePaymentItem = [

	// 	{
	// 		name: "Invoice Payment",
	// 		route: "/makepayment",
	// 		active: "false",
	// 		display: "flex",
	// 	},

	// 	{
	// 		name: "Direct Transfer",
	// 		route: "/makepayment/direct-transfer",
	// 		active: "false",
	// 		display: "flex",
	// 	},
	// ];

	const Item = [
		{
			name: "Overview",
			icon: <FontAwesomeIcon icon={faChartLine} />,
			route: "/dashboard",
			active: "false",
			display: "flex",
		},
		{
			name: "Customers",
			icon: <FontAwesomeIcon icon={faUsers} />,
			route: "/customer",
			active: "true",
			display: "flex",
		},
		{
			name: "Invoice",
			icon: <FontAwesomeIcon icon={faFileInvoice} />,
			route: "/invoice",
			active: "false",
			display: "flex",
		},
		// {
		// 	name: "Purchase Order",
		// 	icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
		// 	route: "/purchase-order",
		// 	active: "false",
		// 	display: "flex",
		// },
		{
			name: "Wallet",
			icon: <FontAwesomeIcon icon={faWallet} />,
			route: "/wallet",
			active: "false",
			display: "flex",
		},
	];

	const SettingsTab = () => {
		return (
			<>
				<NavItems onClick={handleSettingsToggle}>
					<SettingsDrop>
						<span>
							<FontAwesomeIcon icon={faGear} />
						</span>{" "}
						Settings
						<span>
							{settingsState ? (
								<FontAwesomeIcon icon={faAngleDown} />
							) : (
								<FontAwesomeIcon icon={faAngleRight} />
							)}
						</span>
					</SettingsDrop>
				</NavItems>

				{settingsState ? (
					<SettingsBlock>
						{settingsItem.map(item => {
							return (
								<NavItems key={uuidv4()}>
									<SettingsItems to={item.route} display="flex" end>
										{item.name}
									</SettingsItems>
								</NavItems>
							);
						})}
					</SettingsBlock>
				) : null}
			</>
		);
	};

	// const MakePaymentTab = () => {

	// return (
	// 	<>
	// 		<NavItems onClick={handleMakePayment}>
	// 			<SettingsDrop>
	// 				<span>
	// 					<FontAwesomeIcon icon={faCoins} />
	// 				</span>{" "}
	// 				Make Payment
	// 				<span>
	// 					{makePaymentState ? (
	// 						<FontAwesomeIcon icon={faAngleDown} />
	// 					) : (
	// 						<FontAwesomeIcon icon={faAngleRight} />
	// 					)}
	// 				</span>
	// 			</SettingsDrop>
	// 		</NavItems>
	// 		{makePaymentState ? (
	// 			<SettingsBlock>
	// 				{MakePaymentItem.map(item => {
	// 					return (
	// 						<NavItems key={uuidv4()}>
	// 							<SettingsItems to={item.route} display="flex" end>
	// 								{item.name}
	// 							</SettingsItems>
	// 						</NavItems>
	// 					);
	// 				})}
	// 			</SettingsBlock>
	// 		) : null}
	// 	</>
	// );
	// };

	const ReceivePaymentTab = () => {
		return (
			<>
				<NavItems onClick={handleReceivePayment}>
					<SettingsDrop>
						<span>
							<FontAwesomeIcon icon={faMoneyBill} />
						</span>{" "}
						Receive Payment
						<span>
							{receivePaymentState ? (
								<FontAwesomeIcon icon={faAngleDown} />
							) : (
								<FontAwesomeIcon icon={faAngleRight} />
							)}
						</span>
					</SettingsDrop>
				</NavItems>
				{receivePaymentState ? (
					<SettingsBlock>
						{ReceivePaymentItem.map(item => {
							return (
								<NavItems key={uuidv4()}>
									<SettingsItems to={item.route} display="flex" end>
										{item.name}
									</SettingsItems>
								</NavItems>
							);
						})}
					</SettingsBlock>
				) : null}
			</>
		);
	};

	const NavTabs = () => {
		return (
			<>
				<LogoContainer to="/">
					<img src={Logo} alt="Bridger Logo" />
				</LogoContainer>
				<NavContainer>
					<Nav>
						{Item.map(item => {
							return (
								<>
									<NavItems key={uuidv4()}>
										<Items to={item.route} display={item.display}>
											<span>{item.icon}</span>
											{item.name}
										</Items>
									</NavItems>
								</>
							);
						})}
						{/* <MakePaymentTab /> */}
						<ReceivePaymentTab />
						<SettingsTab />
					</Nav>
				</NavContainer>

				<LogoutContainer>
					<hr />
					<Logout onClick={LogoutUser}>
						<LogoutOutlinedIcon /> Log Out
					</Logout>
				</LogoutContainer>
			</>
		);
	};

	return (
		<>
			{/* Large Screens */}
			<Abs>
				<Wrapper>
					<NavTabs />
				</Wrapper>
			</Abs>

			{/* Small Screen */}
			{props.toggle ? (
				<SmallWrapper>
					<NavTabs />
				</SmallWrapper>
			) : null}
		</>
	);
};

export default Header;

const Abs = styled.div`
	position: relative;
	width: 241px;
	top: 0;
	left: 0;
	@media (max-width: 991px) {
		display: none;
	}
`;

const Wrapper = styled.div`
	background-color: white;
	width: 241px;
	height: 100vh;
	position: fixed;
	z-index: 3;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	@media (max-width: 991px) {
		display: none;
	}
`;

const SmallWrapper = styled.div`
	background-color: var(--mainBlue);
	width: 241px;
	height: 100vh;
	position: fixed;
	z-index: 3;
	@media (min-width: 991px) {
		display: none;
	}
`;

const LogoContainer = styled(Link)`
	width: 120px;
	height: 24px;
	margin: auto;
	img {
		margin-top: 37px;
	}
	@media (max-width: 991px) {
		filter: invert(100%);
	}
`;

const NavContainer = styled.div`
	height: calc(100vh - 120px);
	overflow: scroll;
	position: relative;
	// @media (max-width: 1366px) {
	// 	height: calc(100vh - 200px);
	// }
	@media (max-width: 1200px) {
		height: calc(100vh - 120px);
	}
`;

const Nav = styled.div`
	margin: auto;
	margin-top: 70px;
	position: relative;
	.active {
		color: white;
		text-decoration: none;
		background-color: var(--mainBlue);
	}
	@media (max-width: 1200px) {
		margin-top: 20px;
		padding-bottom: 60px;
	}
`;

const NavItems = styled.div`
	width: 100%;
`;

const Items = styled(NavLink)<INav>`
	padding-left: 25px;
	padding-right: 25px;
	margin: auto;
	display: ${props => props.display};
	align-items: center;
	color: var(--textAsh);
	height: 50px;
	font-size: 12px;
	text-decoration: none;
	span {
		width: 40px;
	}
	@media (max-width: 991px) {
		color: white;
	}
`;

const SettingsDrop = styled.div`
	padding-left: 25px;
	padding-right: 25px;
	margin: auto;
	display: flex;
	align-items: center;
	color: var(--textAsh);
	height: 50px;
	font-size: 12px;
	text-decoration: none;
	:hover {
		cursor: pointer;
	}
	span {
		width: 40px;
	}

	@media (max-width: 1200px) {
		height: 50px;
	}
	@media (max-width: 991px) {
		color: white;
	}
`;

const SettingsBlock = styled.div`
	.active {
		color: var(--mainBlue) !important;
		text-decoration: none;
		background-color: #eaeaea !important;
	}
`;

const SettingsItems = styled(NavLink)<INav>`
	padding-left: 65px;
	padding-right: 25px;
	margin: auto;
	display: ${props => props.display};
	align-items: center;
	color: var(--textAsh);
	height: 40px;
	font-size: 12px;
	text-decoration: none;
	:hover {
		color: black;
		border-radius: 3px;
	}
	span {
		width: 40px;
	}
	@media (max-width: 991px) {
		color: white;
	}
`;

const LogoutContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50px;
	background-color: white;
	padding-bottom: 32px;
	font-size: 12px;
	hr {
		border: 0.5px solid #e1e1e1;
	}
	svg {
		font-size: 16px;
	}
	@media (max-width: 991px) {
		background-color: var(--mainBlue);
		color: var(--textRed);
	}
`;

const Logout = styled.div`
	color: var(--textAsh);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	gap: 10px;
	margin-top: 20px;
	padding-top: 12px;
	:hover {
		cursor: pointer;
	}
	@media (max-width: 991px) {
		color: var(--textRed);
	}
`;
