import React, { useContext, useEffect, useState } from "react";
import { httprequest } from "../Constants/api";
import { ToastContainer, toast } from "react-toastify";
import Main from "../Constants/Main";
import Table from "./Components/Table";
import Top from "./Components/Top";
import { AppContext } from "../../Context";

const Index: React.FC = () => {
	const { userProfile, wallet, getWalletDetails } = useContext(AppContext);

	const [transactions, setTransactions] = useState<any>([]);
	const [jumbotronText, setJumbotronText] = useState({
		title: "",
		note: "",
		plainText: "",
	});

	const filteredWallet = wallet.filter((item: any) => item.currency === "NGN");

	const [walletDetails, setWalletDetails] = useState({
		account_balance: filteredWallet[0]?.balance,
		bank_name: filteredWallet[0]?.bank_name,
		account_number: filteredWallet[0]?.account_number,
		uid: filteredWallet[0]?.uid,
		credit: filteredWallet[0]?.spending_aggregate?.total_credit,
		debit: filteredWallet[0]?.spending_aggregate?.total_debit,
		wallet: filteredWallet[0]?.uid,
		business_name: filteredWallet[0]?.account_name,
	});

	const getWalletTransactions = (walletId: string) => {
		httprequest
			.get(`/wallet/${walletId}/transactions/`)
			.then(response => {
				setTransactions(response?.data?.results);
			})
			.catch(error => {
				if (error?.response?.status === 403) {
					toast.error("You do not have permission to view this page");
				} else {
					toast.error(`${error?.response?.data?.errors[0]?.detail}`);
				}
			});
	};

	const getJumbotronText = () => {
		httprequest
			.get("/administration/jumbotron-note/")
			.then(response => {
				setJumbotronText({
					title: response?.data?.results[0]?.title,
					note: response?.data?.results[0]?.note,
					plainText: response?.data?.results[0]?.plain_note,
				});
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	useEffect(() => {
		getJumbotronText();
	}, []);

	useEffect(() => {
		setWalletDetails({
			account_balance: filteredWallet[0]?.balance,
			bank_name: filteredWallet[0]?.bank_name,
			account_number: filteredWallet[0]?.account_number,
			uid: filteredWallet[0]?.uid,
			credit: filteredWallet[0]?.spending_aggregate?.total_credit,
			debit: filteredWallet[0]?.spending_aggregate?.total_debit,
			wallet: filteredWallet[0]?.uid,
			business_name: filteredWallet[0]?.account_name,
		});
		getWalletTransactions(filteredWallet[0]?.uid);
	}, [wallet]);

	return (
		<>
			<Main
				tabName="Wallet"
				rightSubHeader={<></>}
				mainBody={
					<>
						<Top
							account_balance={walletDetails?.account_balance}
							wallet_bank_name={walletDetails?.bank_name}
							wallet_account_number={walletDetails?.account_number}
							credit={walletDetails?.credit}
							debit={walletDetails?.debit}
							userProfile={userProfile}
							walletUid={walletDetails?.wallet}
							business_name={walletDetails?.business_name}
							getWalletDetails={getWalletDetails}
							jumbotronText={jumbotronText}
						/>{" "}
						<Table transactions={transactions} walletUid={walletDetails?.wallet} />;
					</>
				}
			/>
			<ToastContainer />
		</>
	);
};

export default Index;
