import { useEffect, useState } from "react";

const useWindowSize = () => {
	const getCurrentWidth = () => window.innerWidth;
	const [screenSize, setScreenSize] = useState(getCurrentWidth());

	useEffect(() => {
		const updateDimension = () => {
			setScreenSize(getCurrentWidth());
		};
		window.addEventListener("resize", updateDimension);

		return () => {
			window.removeEventListener("resize", updateDimension);
		};
	}, [screenSize]);

	return screenSize;
};

export default useWindowSize;
