import React, { useState } from "react";
import styled from "styled-components";
import Main from "../Constants/Main";
import * as constants from "../Constants/api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Identification } from "../Constants/utils";
import SelectWrapper from "../Constants/FormComponents/SelectWrapper";
import Input from "../Constants/FormComponents/Input";

const PersonalDetailsEdit: React.FC = () => {
	const navigate = useNavigate();

	const [userDetails, setUserDetails] = useState({
		id_image: "",
		id_image_back: "",
		id_type: "INTERNATIONAL_PASSPORT",
		id_number: "",
	});

	const [fileUploadName, setFileUploadName] = useState({
		id_image: "",
		id_image_back: "",
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
	};

	const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
	};

	const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files ?? []);
		const file = files[0];
		setUserDetails({
			...userDetails,
			[e.target.name]: file,
		});
		setFileUploadName({ ...fileUploadName, [e.target.name]: file.name });
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		const form_data = new FormData();

		Object.entries(userDetails).forEach(data => {
			form_data.append(data[0], data[1]);
		});
		e.preventDefault();
		constants.httprequest
			.patch("/accounts/user/profile/", form_data)
			.then(res => {
				if (res.status === 201) {
					toast.success(`${res?.data?.detail}`);
					navigate("/settings");
				}
			})
			.catch(error => {
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};

	return (
		<>
			<Main
				tabName="Settings"
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<PageHeader>
							<span>
								<PageTitle>Personal Details</PageTitle>
								<PageInstruction>
									View and manage your personal details.
								</PageInstruction>
							</span>
						</PageHeader>

						<PageToggle>
							<Link to="/settings">Personal Details</Link>
							<Link to="/settings/companydetailsview/edit">Company Details Edit</Link>
						</PageToggle>

						<PageBody>
							<form onSubmit={handleSubmit}>
								<SelectWrapper
									title="ID Type"
									required
									size="full"
									children={
										<select
											name="id_type"
											value={userDetails.id_type}
											onChange={e => handleChangeSelect(e)}
										>
											{Identification.map(item => {
												return (
													<option key={item.value} value={item.value}>
														{item.label}
													</option>
												);
											})}
										</select>
									}
								/>

								<Input
									size="full"
									title="ID Number"
									inputType="text"
									value={userDetails.id_number}
									name="id_number"
									placeHolder="1234455"
									onChange={e => handleChange(e)}
								/>

								<UploadLogoContainer>
									<div>
										<CloudUploadIcon />
										<p>Upload a copy of your ID card here</p>
										<small>(Only JPEG, PNG, PDF formats are accepted)</small>
										<p>{fileUploadName?.id_image}</p>
									</div>
									<input
										type="file"
										name="id_image"
										onChange={e => handleChangeFiles(e)}
									/>
								</UploadLogoContainer>

								<UploadLogoContainer>
									<div>
										<CloudUploadIcon />
										<p>Upload a copy of the back of your ID card here</p>
										<small>(Only JPEG, PNG, PDF formats are accepted)</small>
										<p>{fileUploadName?.id_image_back}</p>
									</div>
									<input
										type="file"
										name="id_image_back"
										onChange={e => handleChangeFiles(e)}
									/>
								</UploadLogoContainer>

								<ButtonGroup>
									<ButtonOutlined>Cancel</ButtonOutlined>{" "}
									<ButtonFilled>Save Changes</ButtonFilled>
								</ButtonGroup>
							</form>
						</PageBody>
						<ToastContainer />
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 40vw;
	padding-bottom: 50px;
	@media (max-width: 768px) {
		width: 60vw;
	}
	@media (max-width: 600px) {
		width: 70vw;
	}
	@media (max-width: 425px) {
		width: 95vw;
		margin: 0 auto;
	}
`;
const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;
const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;
const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;

const PageBody = styled.div`
	padding-top: 20px;
`;
const UploadLogoContainer = styled.div`
	max-width: 600px;
	height: 140px;
	border: 1px solid #d9d9d9;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		color: #454545;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 14px;
		color: #454545;
	}
	small {
		font-size: 12px;
		color: #919191;
	}
	input {
		position: absolute;
		width: 80px;
		height: 80px;
		opacity: 0;
		cursor: pointer;
	}
	div {
	}
`;

const ButtonGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
`;
const ButtonOutlined = styled.button`
	border: 1px solid #9791b0;
	border-radius: 3px;
	padding: 12px 25px;
	background: none;
	color: #302361;
	margin-right: 20px;
`;
const ButtonFilled = styled.button`
	border: none;
	border-radius: 3px;
	padding: 12px 25px;
	background: #302361;
	color: #fff;
`;
const PageToggle = styled.div`
	display: flex;
	padding-top: 30px;
	font-size: 14px;
	color: #6d6d6d;
	gap: 30px;
	Link {
		cursor: pointer;
		color: #6d6d6d;
		text-decoration: none;
	}
`;
export default PersonalDetailsEdit;
