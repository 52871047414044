import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AppContext } from "../../Context";
import * as constants from "../../Components/Constants/api";
import EmptyImg from "../../assets/empty.png";
import CreateModal from "./CreateModal";
import Main from "../Constants/Main";
// import { FormatCurrency } from "../Constants/utils";
import Button from "../Constants/FormComponents/Button";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Table from "../Constants/Table";
import { TableColumn } from "react-data-table-component";
import Status from "../Constants/Status";
import PaginatedCards from "../Constants/PaginatedCards";

const Index = () => {
	const [allInvoice, setAllInvoice] = useState<any>([]);
	const { allInvoiceData, handleCreateInvoiceModal } = useContext(AppContext);
	const [allInvoiceCount, setAllInvoiceCount] = useState<Number>(0);
	const [allOverdueCount, setAllOverdueCount] = useState<Number>(0);
	const [allNewCount, setAllNewCount] = useState<Number>(0);
	const [allCreatedCount, setAllCreatedCount] = useState<Number>(0);
	const [toggleState, setToggleState] = useState("all");
	const [smallInvoiceList, setSmallInvoicelist] = useState<any>([]);
	const navigate = useNavigate();

	const handleSearchInvoice = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value;

		const result = allInvoiceData?.filter((invoice: any) => {
			return (
				invoice?.customer?.name.toLowerCase().indexOf(input.toLowerCase()) > -1
			);
		});
		if (result?.length > 0 && input?.length) {
			setAllInvoice(result);
			setAllInvoiceCount(result?.length);
		} else {
			const AllNew = allInvoiceData?.filter(
				item =>
					item.status === "new" ||
					item.status === "accepted" ||
					item.status === "rejected"
			);
			const AllCreated = allInvoiceData?.filter(
				item =>
					item.status === "created" ||
					item.status === "invoice accepted" ||
					item.status === "invoice rejected" ||
					item.status === "sent"
			);
			setAllInvoice(allInvoiceData);
			setToggleState("all");
			setAllInvoiceCount(allInvoiceData?.length);
			setAllNewCount(AllNew?.length);
			setAllCreatedCount(AllCreated?.length);
		}
	};

	useEffect(() => {
		const AllNew = allInvoiceData?.filter(
			item =>
				item.status === "new" ||
				item.status === "accepted" ||
				item.status === "rejected"
		);

		const AllOverdue = allInvoiceData?.filter(item => item.status === "overdue");

		const AllCreated = allInvoiceData?.filter(
			item =>
				item.status === "created" ||
				item.status === "invoice accepted" ||
				item.status === "invoice rejected" ||
				item.status === "sent"
		);

		setAllInvoice(allInvoiceData);
		setToggleState("all");
		setAllOverdueCount(AllOverdue?.length);
		setAllInvoiceCount(allInvoiceData?.length);
		setAllNewCount(AllNew?.length);
		setAllCreatedCount(AllCreated?.length);
		constants.httprequest
			.get("/customers")
			.then(() => {})
			.catch(err => {
				if (err.response.status === 401) {
					navigate("/");
				}
			});
		setSmallInvoicelist(
			allInvoiceData?.map(invoice => {
				return {
					date: `${invoice?.created_at?.substring(0, 10)}`,
					amount: `₦ ${Number(invoice?.total)?.toFixed(2)}`,
					invoiceTo: `Invoice To: ${invoice?.customer?.business_name}`,
					invoiceFrom: `Invoice From: ${invoice?.payment_link?.creator?.company_name}`,
					invoiceNo: invoice?.order_number,
					status: <Status statusState={invoice?.status} />,
					uid: invoice?.uid,
				};
			})
		);
	}, [allInvoiceData, navigate]);

	const handleAllInvoice = () => {
		setAllInvoice(allInvoiceData);
		setToggleState("all");
	};

	const handleAllOverdue = () => {
		const AllOverdue = allInvoiceData?.filter(item => item.status === "overdue");
		setAllInvoice(AllOverdue);
		setToggleState("overdue");
	};

	const handleDetails = (row: any) => {
		if (row) {
			navigate("/invoice/preview", {
				state: { currentInvoice: row },
			});
		}
	};

	const handleSmallDetails = (id: any) => {
		const current = allInvoiceData?.filter(invoice => invoice?.uid === id);
		if (current) {
			navigate("/invoice/preview", {
				state: { currentInvoice: current[0] },
			});
		}
	};

	const handleNew = () => {
		const allNew = allInvoiceData?.filter(
			item =>
				item.status === "new" ||
				item.status === "accepted" ||
				item.status === "rejected"
		);
		setAllInvoice(allNew);
		setToggleState("new");
	};

	const handleCreated = () => {
		const allCreated = allInvoiceData?.filter(
			item =>
				item.status === "created" ||
				item.status === "invoice accepted" ||
				item.status === "invoice rejected" ||
				item.status === "sent"
		);
		setAllInvoice(allCreated);
		setToggleState("created");
	};

	const columns: TableColumn<any>[] = [
		{
			name: "Date",
			selector: row => row?.created_at?.substring(0, 10),
			width: "10%",
			sortable: true,
		},
		{
			name: "Invoice No",
			selector: row => row?.order_number,
			width: "15%",
			sortable: true,
			center: true,
		},
		{
			name: "Invoice From",
			selector: row => row?.created_by?.company_name,
			width: "20%",
		},
		{
			name: "Invoice to",
			selector: row => row?.customer?.business_name,
			width: "20%",
			sortable: true,
		},
		{
			name: "Amount",
			selector: row => row?.total_display,
			width: "15%",
			sortable: true,
		},
		{
			name: "Status",
			cell: row =>
				row?.is_paid ? (
					<Status statusState="paid" />
				) : (
					<Status statusState={row?.status} />
				),
			center: true,
			width: "20%",
		},
	];

	return (
		<>
			<Main
				tabName="Invoices"
				rightSubHeader={
					<>
						<SubHeaderSearch>
							<SearchOutlinedIcon />
							<InputSearch
								placeholder="Search by customer name"
								onChange={handleSearchInvoice}
							/>
						</SubHeaderSearch>

						<Button
							title="Accept Invoice"
							onClick={handleNew}
							size="md"
							textColor="brand"
							variant="secondary"
							icon={<DoneOutlinedIcon />}
						/>
						<Button
							title="Create Invoice"
							onClick={handleCreateInvoiceModal}
							size="md"
							textColor="normal"
							variant="default"
							icon={<AddIcon />}
						/>
					</>
				}
				mainBody={
					<>
						<TableToggleContainer>
							<LeftTableToggle>
								<div
									onClick={handleAllInvoice}
									style={{
										borderBottom:
											toggleState === "all" ? "1px solid #302361" : "transparent",
										color: toggleState === "all" ? "#302361" : "#6D6D6D",
									}}
								>
									{`All Invoices (${allInvoiceCount})`}
								</div>
								<div
									onClick={handleNew}
									style={{
										borderBottom:
											toggleState === "new" ? "1px solid #302361" : "transparent",
										color: toggleState === "new" ? "#302361" : "#6D6D6D",
									}}
								>
									{`Received (${allNewCount})`}
								</div>
								<div
									style={{
										borderBottom:
											toggleState === "created" ? "1px solid #302361" : "transparent",
										color: toggleState === "created" ? "#302361" : "#6D6D6D",
									}}
									onClick={handleCreated}
								>
									{`Created (${allCreatedCount})`}
								</div>
								<div
									onClick={handleAllOverdue}
									style={{
										borderBottom:
											toggleState === "overdue" ? "1px solid #302361" : "transparent",
										color: toggleState === "overdue" ? "#302361" : "#6D6D6D",
									}}
								>
									{`Overdue (${allOverdueCount})`}
								</div>
							</LeftTableToggle>
							<RightTableToggle></RightTableToggle>
						</TableToggleContainer>
						<LGTableContainer>
							<Table
								data={allInvoice}
								columns={columns}
								pagination
								paginationPerPage={10}
								onRowClicked={(row: any) => handleDetails(row)}
							/>
							{!allInvoice.length && (
								<EmptyData>
									<img src={EmptyImg} alt="Empty " />
									<p>
										You currently have no{" "}
										{toggleState === "all"
											? "invoice,"
											: toggleState === "due"
											? "due invoice,"
											: toggleState === "accepted"
											? "accepted invoice"
											: toggleState === "rejected"
											? "rejected invoice,"
											: toggleState === "created"
											? "created invoice,"
											: toggleState === "overdue"
											? "overdue invoice"
											: "new invoice,"}{" "}
										{toggleState !== "overdue" &&
											"Click on the button below to create one"}
									</p>
									{toggleState !== "overdue" && (
										<Button
											title="Create Invoice"
											onClick={handleCreateInvoiceModal}
											size="md"
											textColor="normal"
											variant="default"
											icon={<AddIcon />}
										/>
									)}
								</EmptyData>
							)}
						</LGTableContainer>
						<SMTBContainer>
							<PaginatedCards
								key={uuidv4()}
								itemsPerPage={10}
								items={smallInvoiceList}
								date="date"
								valueOne="invoiceFrom"
								valueThree="invoiceNumber"
								valueFour="amount"
								valueTwo="invoiceTo"
								onClick={handleSmallDetails}
								id="uid"
							/>
						</SMTBContainer>
						<CreateModal />
					</>
				}
			/>
		</>
	);
};

export default Index;

export const SubHeaderButton = styled.button`
	background-color: var(--mainBlue);
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	padding: 12px 30px;
	gap: 10px;
	outline: 0;
	border-color: transparent;
	font-size: 14px;
	svg {
		height: 14px;
	}
	@media (max-width: 1200px) {
		padding: 12px 24px;
		gap: 6px;
		font-size: 12px;
	}
	@media (max-width: 576px) {
		font-size: 10px;
		gap: 4px;
		padding: 12px 20px;
	}
	@media (max-width: 426px) {
		width: 100%;
		font-size: 12px;
	}
`;

export const SubHeaderSearch = styled.div`
	width: 252px;
	font-size: 12px;
	color: #b5b5b5;
	display: flex;
	align-items: center;
	position: relative;

	svg {
		position: absolute;
		left: 16px;
	}
	@media (max-width: 1200px) {
		display: none;
	}
`;

export const InputSearch = styled.input`
	width: 100%;
	height: 40px;
	padding-left: 42px;
	border-radius: 4px;
	border-color: transparent;
	color: #b5b5b5;
	background-color: #f7f7f7;
`;

export const SubHeaderBorderedButton = styled.button`
	background-color: white;
	padding: 12px 30px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--mainBlue);
	gap: 10px;
	outline: 0;
	font-size: 14px;
	border: 1px solid var(--mainBlue);
	svg {
		height: 14px;
	}
	@media (max-width: 1200px) {
		padding: 12px 24px;
		gap: 6px;
		font-size: 12px;
	}
	@media (max-width: 576px) {
		font-size: 10px;
		gap: 4px;
		padding: 12px 20px;
	}
	@media (max-width: 426px) {
		width: 100%;
		font-size: 12px;
	}
`;

export const TableToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-top: 50px;
	overflow: scroll;
	@media (max-width: 576px) {
		padding-top: 30px;
	}
`;

export const LeftTableToggle = styled.div`
	display: flex;
	gap: 30px;
	padding-bottom: 3px;
	font-size: 14px;
	div {
		:hover {
			cursor: pointer;
		}
	}
	@media (max-width: 1200px) {
		font-size: 12px;
	}
	@media (max-width: 576px) {
		div {
			padding-bottom: 10px;
			width: 140px;
			:first-child {
				width: 180px;
			}
		}
	}
`;

export const RightTableToggle = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

export const TableTop = styled.div`
	width: calc(100% - 50px);
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	padding-top: 14px;
	padding-bottom: 14px;
	font-size: 12px;
	background-color: #e9e9e9;
	padding-left: 25px;
	padding-right: 25px;
	div {
		:first-child {
			width: 10%;
			text-align: left;
			font-weight: 500;
		}
		:last-child {
			width: 10%;
			text-align: center;
			font-weight: 500;
		}
		:nth-child(2) {
			width: 10%;
			text-align: left;
			font-weight: 500;
		}
		:nth-child(3) {
			width: 22%;
			text-align: left;
			font-weight: 500;
		}
		:nth-child(4) {
			width: 22%;
			text-align: left;
			font-weight: 500;
		}
		:nth-child(5) {
			width: 15%;
			text-align: left;
			font-weight: 500;
		}
	}
	@media (max-width: 768px) {
		width: calc(100% - 30px);
		padding-left: 15px;
		padding-right: 15px;
	}
`;

export const TableContent = styled.div`
	width: calc(100% - 50px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 12px;
	padding-left: 25px;
	padding-right: 25px;
	border-bottom: 1px solid #ececec;
	span {
		font-weight: 500;
	}
	:hover {
		background-color: #f7f7f7;
		cursor: pointer;
	}

	div {
		:first-child {
			width: 10%;
			text-align: left;
		}
		:last-child {
			width: 10%;
			text-align: center;
			padding-top: 5px;
			padding-bottom: 5px;
			font-weight: 500;
			border-radius: 5px;
		}
		:nth-child(2) {
			width: 10%;
			text-align: left;
		}
		:nth-child(3) {
			width: 22%;
			text-align: left;
		}
		:nth-child(4) {
			width: 22%;
			text-align: left;
		}
		:nth-child(5) {
			width: 15%;
			text-align: left;
		}
	}
	@media (max-width: 768px) {
		width: calc(100% - 30px);
		padding-left: 15px;
		padding-right: 15px;
	}
`;

export const LGTableContainer = styled.div`
	@media (max-width: 576px) {
		display: none;
	}
`;

export const SMTBContainer = styled.div`
	@media (min-width: 576px) {
		display: none;
	}
`;

export const SmallTableContent = styled.div`
	width: 90%;
	margin: auto;
	padding-top: 23px;
	padding-bottom: 23px;
	margin-top: 10px;
	margin-bottom: 10px;
	box-shadow: 0px 11px 30px rgba(182, 202, 216, 0.2);
	box-sizing: border-box;
	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	span {
		padding-right: 5px;
	}
	h6 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-weight: 400;
		font-size: 12px;
	}
	h5 {
		margin-top: 0px;
		margin-bottom: 0px;
		color: #6d6d6d;
		font-weight: 400;
		font-size: 12px;
		font-weight: bold;
	}
	p {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0px;
		margin-bottom: 0px;
	}
	h4 {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0px;
		margin-bottom: 0px;
		text-align: right;
	}
	@media (max-width: 426px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`;

export const CreateOptionsContainer = styled.div`
	margin: 50px;
	@media (max-width: 576px) {
		width: 90%;
		margin: auto;
	}
`;

export const CreateOptionsCard = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-top: 30px;
	margin-bottom: 30px;
	border: 1px solid #d9d9d9;
	padding: 25px;
	svg {
		color: black;
	}
	div {
		:first-child {
			width: 36px;
		}
	}
	h4 {
		margin-bottom: 6px;
		margin-top: 0px;
		font-size: 14px;
		color: black;
	}
	p {
		margin-top: 0px !important;
		margin-bottom: 0px;
		font-size: 12px;
		color: #6d6d6d;
	}
	:hover {
		cursor: pointer;
	}
	@media (max-width: 576px) {
		padding: 10px 25px;
		gap: 15px;
		h4 {
			font-size: 12px;
		}
		button {
			font-size: 12px;
			margin-top: 0px;
			padding-top: 3px;
			padding-bottom: 3px;
			span {
				width: 14px;
			}
		}
	}
`;

export const CreateButton = styled.button`
	background-color: var(--mainBlue);
	width: 100%;
	outline: 0;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	color: white;
	height: 56px;
`;

export const CheckedCard = styled.div`
	margin-left: auto;
	color: var(--mainBlue);
`;

export const EmptyData = styled.div`
	width: 100%;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	p {
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
		margin-bottom: 30px;
		color: black;
	}
	img {
		width: 180px;
		height: 140px;
	}
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background-color: var(--mainBlue);
		width: 200px;
		height: 48px;
		margin: auto;
		border-color: transparent;
		color: white;
	}
`;
