import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Main from "../Constants/Main";
import View from "./View";
import { httprequest } from "../Constants/api";
import { useTruncate } from "../../hooks/useTruncate";
import { ToastContainer, toast } from "react-toastify";
import KYCStatusBar from "./KYCStatusBar";

const CompanyDetailsView: React.FC = () => {
	const navigate = useNavigate();

	const [profileDetails, setProfileDetails] = useState({
		industry_type: "",
		address: "",
		type: "",
		name: "",
		incorporation_date: "",
		website: "",
		treasury_needs: "",
		annual_turnover: "",
		cac_document: "",
		memart_document: "",
		cac_details: "",
		logo: "",
	});

	const getDetails = () => {
		httprequest
			.get("/accounts/company/")
			.then(response => {
				setProfileDetails({
					industry_type: response.data?.industry_type,
					address: response.data?.address,
					type: response.data?.type,
					name: response.data?.name,
					treasury_needs: response.data.treasury_needs,
					incorporation_date: response.data?.incorporation_date,
					website: response.data?.website,
					annual_turnover: response.data?.annual_turnover,
					cac_document: response.data?.cac_document,
					memart_document: response.data?.memart_document,
					cac_details: `${response.data?.cac_number_type} - ${response.data?.cac_number}`,
					logo: response.data?.logo,
				});
			})
			.catch(error => {
				if (error?.response?.status === 401) {
					navigate("/");
				}
				toast.error(`${error?.response?.data?.errors[0]?.detail}`);
			});
	};
	useEffect(() => {
		getDetails();
	}, []);

	return (
		<>
			<Main
				tabName="Settings"
				leftSubHeader={<KYCStatusBar />}
				rightSubHeader={""}
				mainBody={
					<Wrapper>
						<PageHeader>
							<span>
								<PageTitle>Company Details</PageTitle>
								<PageInstruction>
									View and manage details of your company.
								</PageInstruction>
							</span>
							{/* <Button>
								<Link to="/settings/companydetailsview/edit">Edit</Link>
							</Button> */}
						</PageHeader>
						<PageToggle>
							<Link to="/settings">Personal Details</Link>
							<Link to="/settings/companydetailsview">Company Details</Link>
						</PageToggle>

						<PageBody>
							<TooglePage>
								<p>Company Details</p>{" "}
							</TooglePage>
							<CompanyLogoDisplay>
								<CompanyLogoText>Upload your Company Logo</CompanyLogoText>{" "}
								<CompanyLogoBox>
									<CompanyLogo src={`${profileDetails?.logo}`} alt="Company's Logo" />
								</CompanyLogoBox>
							</CompanyLogoDisplay>
							<View field="Registered Business Name" detail={profileDetails?.name} />
							<View field="Type of Company" detail={profileDetails?.type} />
							<View field="Industry Type" detail={profileDetails?.industry_type} />
							<View
								field="Business Website"
								detail={useTruncate(profileDetails?.website, 50, "...")}
							/>
							<View field="Role in Company" detail="" />
							<View field="Number of Employees" detail="" />
							<View field="Treasury Needs" detail={profileDetails?.treasury_needs} />
							<View field="Annual Turnover" detail={profileDetails?.annual_turnover} />
							<View
								field="Year of Incorporation"
								detail={profileDetails?.incorporation_date}
							/>
							<View field="Office Address" detail={profileDetails?.address} />
							<View field="CAC Details" detail={profileDetails?.cac_details} />
							<View
								field="CAC Document"
								detail={useTruncate(profileDetails?.cac_document, 40, "...")}
							/>
							<View
								field="MEMART Document"
								detail={useTruncate(profileDetails?.memart_document, 40, "...")}
							/>
						</PageBody>
						<ToastContainer />
					</Wrapper>
				}
			/>
		</>
	);
};

const Wrapper = styled.div`
	width: 45vw;
	@media (max-width: 768px) {
		width: 60vw;
	}
	@media (max-width: 600px) {
		width: 70vw;
	}
	@media (max-width: 425px) {
		width: 95vw;
		margin: 0 auto;
	}
`;
const PageHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;
const PageTitle = styled.h2`
	font-size: 22px;
	font-weight: 500;
	padding: 0;
	margin: 0;
	text-align: left;
`;
const TooglePage = styled.div`
	display: flex;
	padding-bottom: 30px;
	p {
		padding-right: 30px;
		font-size: 14px;
		color: #6d6d6d;
	}
`;
const CompanyLogoDisplay = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
`;
const CompanyLogoText = styled.div`
	color: #484848;
	font-size: 14px;
	margin-bottom: 8px;
`;
const CompanyLogo = styled.img`
	height: 45px;
`;
const CompanyLogoBox = styled.div`
	border: 1px solid #d9d9d9;
	padding: 5px 8px;
`;

const PageInstruction = styled.p`
	font-size: 12px;
	font-weight: 400;
	padding: 0;
	padding-top: 5px;
	margin: 0;
	text-align: left;
	color: #6d6d6d;
`;
// const Button = styled.button`
// 	align-self: center;
// 	background: none;
// 	padding: 7px 25px;
// 	border: 1px solid #9791b0;
// 	color: #302361;
// 	font-size: 14px;
// 	cursor: pointer;
// `;

const PageBody = styled.div`
	padding-top: 20px;
`;
const PageToggle = styled.div`
	display: flex;
	padding-top: 30px;
	font-size: 14px;
	color: #6d6d6d;
	gap: 30px;
	Link {
		cursor: pointer;
		color: #6d6d6d;
		text-decoration: none;
	}
`;

export default CompanyDetailsView;
